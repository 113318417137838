//Library
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AxiosError, AxiosResponse } from 'axios'
import __ from 'languages/index'

//Components
import HeaderPage from './components/user.header'
import CardUserInfo from './components/user.card.info'
import UserSkeleton from './components/user.skeleton'

//Store
import { follow, getDataUser, unFollow, uploadImage } from './store/user.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'
import { getRequestHome, isFulfilledListRequest } from 'entities/homepage/home.store.reducer'

//Interface
import { TypeDataUser } from './interface'

//Hooks
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { EnumTypeToast, useToast } from '../../hooks/useToast'

//Scss
import './media/user.detail.scss'
import { TypedRequest } from '../../interfaces/request.interface'
import BigLayout from '../../layouts/bigLayout'
import { updateDataUser } from '../../store/user.store.reducer'
import ItemHomePost from 'entities/homeNew/components/ItemHomePost'
import { Box, Stack } from '@mui/material'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import helpers from 'helpers/index'

const UserDetail = () => {
  const { user_id } = useParams()
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataUser, setDataUser] = useState<TypeDataUser>()
  const toast = useToast()
  const dataRequest = useAppSelector(
    state => state.home.dataRequest,
    (next, prev) => helpers.isEqual(next, prev)
  )

  const { hasMore, refresh, totalItems, setParamRequest, loadMore, isRefreshing, updateData } =
    useInfiniteScroll<TypedRequest>(
      {
        user_id: user_id.toString(),
        auth_id: user_data?._id,
        limit: 6
      },
      getRequestHome,
      true,
      dataRequest,
      isFulfilledListRequest
    )

  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      if (!file) return
      let formData = new FormData()
      formData.append('file[]', file)
      dispatch(uploadImage(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          dispatch(updateDataUser({ _id: user_id, user_cover: res?.data[0]?.src }))
            .unwrap()
            .then(() => {
              setDataUser(dataUser => ({ ...dataUser, user_cover: res?.data[0]?.src }))
              toast.show({
                content: `${__('user_detail_update_cover_photo_success')}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((error: AxiosError) => {
              console.log(`updateDataUser_${error}`)
              toast.show({
                content: `${__('user_detail_update_cover_photo_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        })
        .catch((error: AxiosError) => {
          console.log(`uploadImage_${error}`)
        })
    },
    [dataUser, user_id]
  )

  const handleUnFollow = useCallback(
    (_id: string) => {
      dispatch(unFollow({ partner_id: _id }))
        .unwrap()
        .then(() => {
          setDataUser(dataUser => ({ ...dataUser, is_follow: false }))
        })
        .catch((error: AxiosError) => {
          console.log(`unFollow_${error}`)
          toast.show({
            content: `${__('user_detail_unfollow_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [dataUser]
  )

  const handleFollow = useCallback(
    (_id: string) => {
      dispatch(follow({ partner_id: _id }))
        .unwrap()
        .then(() => {
          setDataUser(dataUser => ({ ...dataUser, is_follow: true }))
        })
        .catch((error: AxiosError) => {
          console.log(`follow_${error}`)
          toast.show({
            content: `${__('user_detail_follow_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [dataUser]
  )

  useEffect(() => {
    if (!user_id) return
    setIsLoading(true)
    setParamRequest(paramsRequest => ({ ...paramsRequest, user_id: user_id.toString() }))
    dispatch(getDataUser(user_id))
      .unwrap()
      .then((res: AxiosResponse<TypeDataUser>) => {
        setIsLoading(false)
        setDataUser(res?.data)
      })
      .catch((error: AxiosError) => {
        console.log(`getDataUser_${error}`)
        setIsLoading(false)
      })
  }, [user_id])

  return (
    <BigLayout>
      <div id="userDetail" className="userDetail_container">
        {isLoading ? (
          <UserSkeleton />
        ) : (
          <>
            <HeaderPage
              dataUser={dataUser}
              handleImageUpload={handleImageUpload}
              handleFollow={handleFollow}
              handleUnFollow={handleUnFollow}
            />
            <div className="userDetail_body row">
              <div className="col-12 col-lg-4">
                <div className="userDetail_body_left ">
                  <CardUserInfo dataUser={dataUser} />
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="userDetail_body_right">
                  <Box className="mt-4">
                    <InfiniteScrollContainer
                      isRefreshing={isRefreshing}
                      dataLength={dataRequest.length}
                      next={loadMore}
                      hasMore={hasMore}
                      refreshFunction={refresh}
                    >
                      <Stack gap={2.5}>
                        {dataRequest?.map(post => {
                          return (
                            <ItemHomePost
                              key={post._id}
                              post={post}
                              is_user_logged_in={is_user_logged_in}
                            />
                          )
                        })}
                      </Stack>
                    </InfiniteScrollContainer>
                  </Box>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </BigLayout>
  )
}

export default UserDetail
