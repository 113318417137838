import { createApi } from '@reduxjs/toolkit/query/react'
import {
  CreateCategoryPayload,
  CreatePollPayload,
  CreatePostPayload,
  EditPostPayload,
  GetCategoriesResponse,
  GetListPostCommentResponse,
  GetNewsFeedResponse,
  UpdateCategoryPayload,
  UpdatePostPayload,
  VotingPayload
} from './homeApi.type'
import { InjectPaginateParams } from 'interfaces/query.interface'
import { convertParamsToQueryString } from 'entities/challenge/store/challengeApi'
import { Poll, PostCommentType } from 'interfaces/home.interface'
import { TypedCategory, TypedPoll, TypedRequest } from '../../../interfaces/request.interface'
import helpers from 'helpers/index'

const baseQuery = helpers.getBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/request',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  },
  credentials: 'same-origin'
})

const updatePost = (
  post: TypedRequest,
  key: keyof TypedRequest,
  payload: Partial<TypedRequest>
) => {
  return Object.assign(post, { [key]: payload })
}

export const homeApi = createApi({
  baseQuery,
  reducerPath: 'homeApi',
  tagTypes: ['NewsFeedPostList', 'NewsFeedPost', 'Comment', 'Category'],
  refetchOnMountOrArgChange: 30,
  endpoints: build => ({
    getNewsFeeds: build.query<GetNewsFeedResponse, InjectPaginateParams<{}>>({
      query: params => {
        return `/list${convertParamsToQueryString({ ...params })}`
      },

      providesTags: ['NewsFeedPostList'],

      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const newQueryArgs = { ...queryArgs }
        if (newQueryArgs.page) {
          delete newQueryArgs.page
        }
        return newQueryArgs
      },
      transformResponse: (response: TypedRequest[], meta) => {
        return {
          data: response,
          total: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg, baseQueryMeta }) => {
        if (currentCacheData && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),

    getPostDetail: build.query<TypedRequest, { postId: string }>({
      query: ({ postId }) => {
        return {
          url: `/detail/${postId}`,
          method: 'GET'
        }
      },
      providesTags: (result, error, arg) => [{ type: 'NewsFeedPost', id: arg.postId }]
    }),

    likePost: build.mutation<
      TypedRequest,
      { postId: string; currentFetchParams?: InjectPaginateParams<{}> }
    >({
      query: ({ postId }) => ({
        url: `/like-request`,
        method: 'POST',
        body: {
          id: postId
        }
      }),

      async onQueryStarted({ postId, currentFetchParams }, { dispatch, queryFulfilled }) {
        const getPostDetail = dispatch(
          homeApi.util.updateQueryData('getPostDetail', { postId }, old => {
            if (old) {
              old.is_like = true
              old.like_number += 1
            }
            return old
          })
        )
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            old.data?.map(post => {
              if (post._id === postId) {
                post.is_like = true
                post.like_number += 1
              }
              return post
            })
          })
        )

        try {
          await queryFulfilled
        } catch (e) {
          getPosts.undo()
          getPostDetail.undo()
        }
      }
    }),

    unlikePost: build.mutation<
      TypedRequest,
      { postId: string; currentFetchParams?: InjectPaginateParams<{}> }
    >({
      query: ({ postId }) => ({
        url: `/dislike-request`,
        method: 'POST',
        body: {
          id: postId
        }
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'NewsFeedPost', id: arg.postId }],
      async onQueryStarted({ postId, currentFetchParams }, { dispatch, queryFulfilled }) {
        // const getPosts = dispatch(
        //   homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
        //     old.data?.map(post => {
        //       if (post._id === postId) {
        //         post.is_like = false
        //         post.like_number -= 1
        //       }
        //       return post
        //     })
        //   })
        // )

        const getPostDetail = dispatch(
          homeApi.util.updateQueryData('getPostDetail', { postId }, old => {
            if (old) {
              old.is_like = false
              old.like_number -= 1
            }
            return old
          })
        )
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            old.data?.map(post => {
              if (post._id === postId) {
                post.is_like = false
                post.like_number -= 1
              }
              return post
            })
          })
        )

        try {
          await queryFulfilled
        } catch (e) {
          getPosts.undo()
          getPostDetail.undo()
        }
      }
    }),

    getPostComments: build.query<GetListPostCommentResponse, { postId: string }>({
      query: ({ postId }) => ({
        url: '/list-comment',
        method: 'GET',
        params: {
          request_id: postId
        }
      }),
      providesTags: (result, error, arg) => [{ type: 'Comment', id: arg.postId }]
    }),

    addComment: build.mutation<
      PostCommentType,
      {
        postId?: string
        content?: string
        commentId?: string
        currentFetchParams?: InjectPaginateParams<{}>
      }
    >({
      query: ({ postId, content, commentId }) => ({
        url: '/create-comment',
        method: 'POST',
        body: {
          request_id: postId,
          content,
          parent_id: commentId
        }
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Comment', id: arg.postId },
        { type: 'NewsFeedPost', id: arg.postId }
      ],

      async onQueryStarted(
        { postId, content, commentId, currentFetchParams },
        { dispatch, queryFulfilled }
      ) {
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            old.data?.map(post => {
              if (post._id === postId) {
                post.comment_number += 1
              }
              return post
            })
          })
        )
        const getPostDetail = dispatch(
          homeApi.util.updateQueryData('getPostDetail', { postId }, old => {
            if (old) {
              old.comment_number += 1
            }
            return old
          })
        )

        try {
          await queryFulfilled
        } catch (e) {
          getPosts.undo()
          getPostDetail.undo()
        }
      }
    }),

    likeComment: build.mutation<PostCommentType, { commentId: string; postId?: string }>({
      query: ({ commentId }) => ({
        url: '/create-like-comment',
        method: 'POST',
        body: {
          comment_id: commentId
        }
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Comment', id: arg.postId }],
      async onQueryStarted({ postId, commentId }, { dispatch, queryFulfilled }) {
        const getPostComments = dispatch(
          homeApi.util.updateQueryData('getPostComments', { postId }, old => {
            old?.map(comment => {
              if (comment._id === commentId) {
                comment.is_like = true
              }
              return comment
            })
          })
        )

        try {
          await queryFulfilled
        } catch (e) {
          getPostComments.undo()
        }
      }
    }),

    voting: build.mutation<
      Poll,
      {
        payload: VotingPayload
        optimisticPayload?: TypedPoll[]
        currentFetchParams?: InjectPaginateParams<{}>
      }
    >({
      query({ payload }) {
        return {
          url: '/vote-poll',
          method: 'POST',
          body: {
            poll_id: payload.pollId,
            request_id: payload.postId
          }
        }
      },
      async onQueryStarted(
        { payload, optimisticPayload, currentFetchParams },
        { dispatch, queryFulfilled, getCacheEntry, extra }
      ) {
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            const selectedPost = old.data?.find(post => post._id === payload.postId)
            if (selectedPost) {
              selectedPost.poll_ids = optimisticPayload
            }
          })
        )

        const getDetail = dispatch(
          homeApi.util.updateQueryData('getPostDetail', { postId: payload.postId }, old => {
            const selectedPost = old
            if (selectedPost) {
              selectedPost.poll_ids = optimisticPayload
            }
          })
        )

        try {
          await queryFulfilled
        } catch (e) {
          getPosts.undo()
          getDetail.undo()
        }
      }
    }),
    updatePost: build.mutation<
      TypedRequest,
      {
        payload: UpdatePostPayload
        optimisticPayload?: Partial<TypedRequest>
        currentFetchParams?: InjectPaginateParams<{}>
      }
    >({
      query: params => ({
        url: `/update`,
        method: 'PATCH',
        body: params.payload
      }),

      async onQueryStarted(
        { payload, optimisticPayload, currentFetchParams },
        { dispatch, queryFulfilled, getCacheEntry, extra }
      ) {
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            old.data?.map(post => {
              if (post._id === payload._id) {
                post = Object.assign(post, optimisticPayload)
              }
              return post
            })
          })
        )

        const getPostDetail = dispatch(
          homeApi.util.updateQueryData('getPostDetail', { postId: payload._id }, old => {
            console.log('optimisticPayload detail', optimisticPayload)
            return Object.assign(old, optimisticPayload)
          })
        )

        try {
          await queryFulfilled
        } catch (err) {
          getPosts.undo()
          getPostDetail.undo()
        }
      }
    }),
    deletePost: build.mutation<
      TypedRequest,
      { postId: string; currentFetchParams?: InjectPaginateParams<{}> }
    >({
      query: ({ postId }) => ({
        url: `/delete/${postId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ postId, currentFetchParams }, { dispatch, queryFulfilled }) {
        const getPosts = dispatch(
          homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
            old.data = old.data?.filter(post => post._id !== postId)
            return old
          })
        )

        try {
          await queryFulfilled
        } catch (err) {
          getPosts.undo()
        }
      }
    }),
    addPost: build.mutation<
      TypedRequest,
      {
        payload: CreatePostPayload
        currentFetchParams: InjectPaginateParams<{}>
      }
    >({
      query: params => ({
        url: `/create`,
        method: 'POST',
        body: params.payload
      }),

      async onQueryStarted({ currentFetchParams }, { dispatch, queryFulfilled }) {
        try {
          const createdPost = await queryFulfilled
          const patchResult = dispatch(
            homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
              old.data?.unshift(createdPost.data)
              return old
            })
          )
        } catch (err) {}
      }
    }),

    editPost: build.mutation<
      TypedRequest,
      {
        payload: EditPostPayload & {
          _id: string
        }
        currentFetchParams?: InjectPaginateParams<{}>
      }
    >({
      query(arg) {
        return {
          url: `/update`,
          method: 'PATCH',
          body: arg.payload
        }
      },
      async onQueryStarted({ currentFetchParams }, { dispatch, queryFulfilled }) {
        try {
          const editPost = await queryFulfilled
          const patchResult = dispatch(
            homeApi.util.updateQueryData('getNewsFeeds', currentFetchParams, old => {
              // old.data?.unshift(createdPost.data)
              old.data.map(item => (item._id === editPost.data._id ? editPost.data : item))
              // return old
            })
          )
        } catch (err) {}
      }
    }),

    createPoll: build.mutation<Poll[], CreatePollPayload>({
      query: params => ({
        url: `/create-poll`,
        method: 'POST',
        body: params
      })
    }),

    getPostCategories: build.query<GetCategoriesResponse, void>({
      query: () => ({
        url: '/list-category',
        method: 'GET',
        params: {
          limit: 100,
          page: 1
        }
      }),
      providesTags: ['Category']
    }),

    createCategory: build.mutation<TypedCategory, CreateCategoryPayload>({
      query: payload => ({
        url: '/create-category',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Category']
    }),

    updateCategory: build.mutation<TypedCategory, UpdateCategoryPayload>({
      query: payload => ({
        url: '/update-category',
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Category']
    }),
    deleteCategory: build.mutation<TypedCategory, UpdateCategoryPayload>({
      query: payload => ({
        url: `/delete-category/${payload?._id}`,
        method: 'DELETE',
        body: payload
      }),
      invalidatesTags: ['Category']
    })
  })
})

export const {
  useGetNewsFeedsQuery,
  useGetPostDetailQuery,
  useGetPostCommentsQuery,
  useLikePostMutation,
  useUnlikePostMutation,
  useAddCommentMutation,
  useVotingMutation,
  useUpdatePostMutation,
  useLikeCommentMutation,
  useDeletePostMutation,
  useAddPostMutation,
  useCreatePollMutation,
  useGetPostCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useEditPostMutation
} = homeApi

export default homeApi
