import "./media/courses.study.scss"
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {clearEntity, getChildrenModule, getEntity, postViewModule} from "entities/courses/courses.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import {TypedModule} from "entities/courses/courses.interface";
import CoursesViewModulesItem from "entities/courses/components/courses.view.modules.item";
import _Helmet from "components/helmet";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward} from "@fortawesome/free-solid-svg-icons";
import __ from "languages/index";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {lightPalette} from "../../styles/themes/palettes/defaultPalete";
import helpers from "helpers/index";
import HeaderComponent from "../../layouts/Header";
import { Box } from "@mui/material";
import ReactPlayer from 'react-player';


export default function CoursesStudy() {

  let useParam = useParams();
  const user_data = useAppSelector(state => state.user.user_data);
  const [moduleId, setModuleId] = useState<string | undefined>(useParam.module_id)
  const [fileSource, setFileSource] = useState<{uri:string, fileName:string}>()
  const [isPlayVideo, setIsPlayVideo] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState<number>(0);
  const dispatch = useAppDispatch();
  const entity = useAppSelector(state => state.courses.entity);
  const childrenModule: TypedModule[] = useAppSelector(state => state.courses.childrenModule);
  const [modules, setModules] = useState<TypedModule[]>([])
  const [isHasError, setIsHasError] = useState<boolean>(false)
  const channel_data: any = useAppSelector(state => state.user.channel_data)
  const [isVideo, setIsVideo] = useState(false)

  const refModuleId = useRef(moduleId);
  const videoRef = useRef<any>(null);
  const targetTime = useRef<number>(0);
  const interval = useRef<any>();
  const sortChildrenModule = [...childrenModule]?.sort((a,b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))


  const timeCourse = sortChildrenModule?.filter((item) => item?._id === refModuleId.current)
    .map((item) => item?.media_id?.media_meta?.duration)
  const childrenModuleItem = sortChildrenModule?.filter((item) => item?._id === refModuleId.current)
  const isView = Boolean(childrenModuleItem?.[0]?.is_view)


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    if (useParam.course_id) {
      dispatch(getEntity({
        _id: useParam.course_id,
        auth_id: user_data?._id
      }))
      dispatch(getChildrenModule({
        page: 1,
        limit: 1000,
        course_id: useParam.course_id,
        auth_id: user_data?._id
      }))
    }

    return (() => {
      dispatch(clearEntity())
    })
  }, []);

  useEffect(() => {
    if (Array.isArray(sortChildrenModule) && useParam.module_id !== undefined && refModuleId.current !== useParam.module_id) {
      refModuleId.current = useParam.module_id;
      setModuleId(useParam.module_id)
      if (sortChildrenModule?.find(item => item?._id === refModuleId.current)?.media_id?.media_url) {
        let file = sortChildrenModule?.find(item => item?._id === refModuleId.current)?.media_id;
        setIsVideo(file?.media_mime_type?.includes("video"))
        setFileSource({
          uri: file?.media_url,
          fileName: helpers.convertUTF8ToUnicodeText(file?.media_file_name||"File")
        })
        setIsHasError(false)
      } else {
        setIsHasError(true)
      }

    } else {
      if (Array.isArray(sortChildrenModule) && sortChildrenModule?.length > 0) {
        let modulesForDisplay = sortChildrenModule.filter(item => !item.parent_id).map(item => ({
          ...item,
          subModule: sortChildrenModule.filter(itemModule => itemModule.parent_id === item._id)
        }))
        setModules(modulesForDisplay)

        if (!refModuleId.current && modulesForDisplay?.[0]?.subModule?.[0]?.media_id?.media_url) {
          refModuleId.current = modulesForDisplay?.[0]?.subModule?.[0]?._id;
          setModuleId(modulesForDisplay?.[0]?.subModule?.[0]?._id)

          let file = sortChildrenModule?.find(item => item?._id === refModuleId.current)?.media_id;
          setIsVideo(file?.media_mime_type?.includes("video"))
          setFileSource({
            uri: file?.media_url,
            fileName: helpers.convertUTF8ToUnicodeText(file?.media_file_name||"File")
          })
        } else {
          if (sortChildrenModule?.find(item => item?._id === refModuleId.current)?.media_id?.media_url) {
            let file = sortChildrenModule?.find(item => item?._id === refModuleId.current)?.media_id;
            setIsVideo(file?.media_mime_type?.includes("video"))
            setFileSource({
              uri: file?.media_url,
              fileName: helpers.convertUTF8ToUnicodeText(file?.media_file_name||"File")
            })
            setIsHasError(false)
          } else {
            setIsHasError(false)
          }
        }
      }
    }
  }, [useParam.module_id, childrenModule]);

  useEffect(() => {
    if (isPlayVideo && !isView && isVideo) {
      interval.current = setInterval(() => {
        if (targetTime.current <= videoRef?.current?.getCurrentTime()) {
          targetTime.current++
          setCurrentTime(targetTime.current);
        }
      }, 1000);
    }

    if ((!isPlayVideo || isView) && interval.current) clearInterval(interval.current)
  }, [isPlayVideo, videoRef, isView, isVideo]);

  useEffect(() => {
    if (currentTime == Math.round(timeCourse?.[0] * 0.3) && !isView) {
      dispatch(postViewModule({module_id: refModuleId.current}))
    }
  }, [currentTime, isView, timeCourse]);

  const resetCountTime = useCallback((id: string) => {
    if (id !== refModuleId.current) {
      targetTime.current = 0
      setCurrentTime(0);
    }
  }, [])


  const renderItemModule = useCallback((module: TypedModule, index: number) => {
    return <CoursesViewModulesItem isCanJoinCourse key={module?._id} idSelected={refModuleId.current} module={module}
                                   resetCountTime={resetCountTime}
                                   isLast={index === modules.length - 1}
                                   alreadyOpen={moduleId ? module?.subModule?.findIndex(item => item?._id === moduleId) !== -1 : index === 0}/>
  }, [modules, fileSource, moduleId, childrenModule])


  // ======================     FOR S.E.O @jamviet.com            ======================
  
  return (
    <div id={'course-study'}>
      <_Helmet title={entity?.title || __("course_study_meta")}/>
      {/*<Header shortHeader/>*/}
      <HeaderComponent />
      <div className="wrapper">
        <div className="container-fluid container_study bg-dark overflow">
          <Row className="study_container">
            <Col md={9} className="col_left_container_study_video p-0 bg-dark h-100">
              <div className="video_wrap position-relative h-100">
                {isHasError ? (
                  <div key={fileSource?.uri||""} className="container_study_videocontainer">
                    <div className="container_study_videocontainer_error">
                      <p>
                        <b>
                          {
                            `${__("course_study_error_text")}`
                          }
                        </b>
                      </p>
                    </div>
                  </div>
                ) : fileSource ? (
                  isVideo ?
                    <>
                      <div onContextMenu={e => e.preventDefault()} className="w-100 h-100">
                      <Box component={ReactPlayer}
                          id={refModuleId.current}
                          ref={videoRef}
                          sx={{width: '100% !important', height: '100% !important', p: theme=> theme.spacing(2)}}
                          onPause={() => setIsPlayVideo(false)}
                          onPlay={() => setIsPlayVideo(true)}
                          url={fileSource?.uri||""}
                          playing 
                          controls 
                        />
                        {/* <img src={channel_data} width="40" className="videoWatermark" /> */}
                        <img
                          src={
                            channel_data?.avatar?.media_thumbnail?.length > 0
                              ? channel_data?.avatar?.media_thumbnail
                              : channel_data?.avatar?.media_url
                          }
                          alt=""
                          width="40"
                          className="videoWatermark"
                        />
                      </div>
                    </>
                    :
                    <DocViewer
                      // documents={[{uri:"https://media.whiteg.app/lgbtapp.s3.ap-southeast-1.amazonaws.com/2024/04/13_1713005006917/65a0e61077afb33d99649a03/sustainability-12-03362%20(1).pdf",fileName: '123'}]}
                      documents={[fileSource]}
                               theme={{
                                 // @ts-ignore
                                 primary: lightPalette.primary.main,
                                 secondary: "#ffffff",
                                 // @ts-ignore
                                 tertiary: lightPalette.primary.main,
                                 textPrimary: "#ffffff",
                                 // @ts-ignore
                                 textSecondary: lightPalette.primary.main,
                                 textTertiary: "#00000099",
                                 disableThemeScrollbar: false,
                               }}
                               pluginRenderers={DocViewerRenderers}/>
                ) : null}
              </div>
            </Col>
            <Col md={3} className="courses_right_column_jamviet_add px-0">
              <div className="coursestudy_viewmodule d-flex flex-column">
                <div className="d-flex flex-column pb-4 px-2 coursestudy_viewmodules">
                  <div className="coursestudy_txtnamecourse">{entity?.title}</div>

                  {sortChildrenModule && sortChildrenModule?.length > 0 ? (
                    <>
                      <div className="coursestudy_txtnumofmodule">
                        {`${entity?.module_count - entity?.module_child_count || '0'} ${__("course_view_text")} ${' '} ${entity?.module_child_count || '0'} ${__("course_view_less")}`}
                      </div>

                      <div>
                        {modules.map(renderItemModule)}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="coursestudy_empty">{__("course_no_content")}</div>
                    </>
                  )}
                </div>
              </div>
              <Link
                to={'/courses/view/' + useParam.course_id}
                className="text-decoration-none btn btn-primary btn-lg coursestudy_btnback"
              >
                <FontAwesomeIcon icon={faBackward} className="coursestudy_iconback"/> {__("course_back")}
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
