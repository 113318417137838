import Modal from 'react-bootstrap/Modal'
import '../media/css/modal.scss'
import React from 'react'
import __ from 'languages/index'
import { Button,  Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface  TypeModalNotificationProp{
    show:boolean;
    redirectTo: string;
    handleChange: ()=> void;
}

const ModalNotification: React.FC<TypeModalNotificationProp> =({show,redirectTo,handleChange }) =>{
  return(
      <Modal show={show}
             onHide={handleChange}
             aria-labelledby="contained-modal-title-vcenter"
             className="modal-sm"
             centered>
        <Modal.Header closeButton>
          <Modal.Title>{__("notification")}</Modal.Title>
        </Modal.Header>
          <Modal.Body >
            <Typography >
              {__("payment_modal_notification_text")}
            </Typography>
          </Modal.Body>
          <Modal.Footer>
            <Button component={Link} to={redirectTo} sx={{textTransform: 'none'}}>
              Xem khoá học
            </Button>
          </Modal.Footer>
      </Modal>
  )
}
export  default ModalNotification;
