import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Typography
} from '@mui/material'
import { ChevronDownIcon, ChevronRightIcon } from '../../icons'
import { useNavigate } from 'react-router'
import { CurrentTheme } from '../../styles/themes'
import { useLocation } from 'react-router-dom'
import helpers from 'helpers'
import styled from '@emotion/styled'
import BlockWebOnMobileModal from 'components/BlockWebOnMobileModal'
import TypedChannel from '../../interfaces/channel.interface'

type Props = {
  label: string
  path: string
  icon?: React.ReactNode
  subMenu?: {
    label: string
    path: string
  }[]
  setToggle?: Dispatch<SetStateAction<boolean>>
  isSubMenu?: boolean
  channel_data?: TypedChannel
}

// const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
//   return <RouterLink ref={ref} {...itemProps} role={undefined} />
// })

const SidebarItem = ({ label, icon, path, subMenu, setToggle, isSubMenu, channel_data }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const checkMobile = helpers.isMobile()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isShowSubMenu, setIsShowSubMenu] = React.useState(false)
  const [isShowModalBlock, setIsShowModalBlock] = React.useState(false)
  const [isSelectedMenu, setIsSelectedMenu] = React.useState(false)
  const subMenuConfigs = subMenu?.map(item => ({
    ...item,
    isShow: true
  }))

  const handleClickItem = (e: React.MouseEvent<HTMLDivElement>) => {
    // !subMenu?.length ? navigate(path) : setAnchorEl(Boolean(anchorEl) ? null : e.currentTarget)
    if (!subMenu?.length) {
      setToggle && setToggle(false)
      navigate(path)
    } else {
      setIsShowSubMenu(!isShowSubMenu)
    }
  }

  const renderItemHaveSubMenu = () => {
    return (
      <Collapse
        in={isShowSubMenu}
        timeout={'auto'}
        unmountOnExit
        sx={{
          bg: 'white'
        }}
      >
        <List component="div" disablePadding>
          {subMenuConfigs?.map((item, index) => (
            <SidebarItem
              key={index}
              label={item.label}
              path={item.path}
              // icon={item.icon}
              setToggle={setToggle}
              channel_data={channel_data}
            />
          ))}
        </List>
      </Collapse>
    )
  }

  useEffect(() => {
    if (helpers.isEmpty(subMenu)) {
      if (pathname.includes(path) && pathname === path) return setIsSelectedMenu(true)
      setIsSelectedMenu(false)
    }
  }, [pathname, path, subMenu])

  return (
    <>
      <ListItemButton onClick={handleClickItem} sx={listItemButtonStyled} selected={isSelectedMenu}>
        <ListItemIcon
          sx={{
            minWidth: theme => theme.spacing(isSubMenu ? 1.5 : checkMobile ? 5 : 4)
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography fontWeight={600} noWrap>
              {label}
            </Typography>
          }
        />

        {subMenu && (isShowSubMenu ? <ChevronDownIcon /> : <ChevronRightIcon />)}
      </ListItemButton>
      {renderItemHaveSubMenu()}
      <BlockWebOnMobileModal channelData={channel_data} showModal={isShowModalBlock} />
    </>
  )
}

export default SidebarItem

const listItemButtonStyled: SxProps<CurrentTheme> = {
  borderRadius: theme => theme.spacing(1.5),
  '&.Mui-selected': {
    backgroundColor: theme => theme.palette.divider,
    '& .MuiListItemText-root': {
      color: theme => theme.palette.primary.main
    },
    '& .expand-icon': {
      color: theme => theme.palette.primary.main
    }
  }
}

const SubMenuMobileContainer = styled(Drawer)(({ theme }) => ({
  width: '100%',
  '& .content': {
    '& .header': {
      borderBottom: '0.5px solid var(--Other-Line, #E0E2E6)',
      '&_left': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  }
}))
