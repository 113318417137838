import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16146 3H18.8385C19.3657 2.99998 19.8205 2.99997 20.195 3.03057C20.5904 3.06287 20.9836 3.13419 21.362 3.32698C21.9265 3.6146 22.3854 4.07354 22.673 4.63803C22.8658 5.01641 22.9371 5.40963 22.9694 5.80497C23 6.17954 23 6.6343 23 7.16144V16.8386C23 17.3657 23 17.8205 22.9694 18.195C22.9371 18.5904 22.8658 18.9836 22.673 19.362C22.3854 19.9265 21.9265 20.3854 21.362 20.673C20.9836 20.8658 20.5904 20.9371 20.195 20.9694C19.8205 21 19.3657 21 18.8386 21H5.16144C4.6343 21 4.17954 21 3.80497 20.9694C3.40963 20.9371 3.01641 20.8658 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1.13419 18.9836 1.06287 18.5904 1.03057 18.195C0.999967 17.8205 0.999983 17.3657 1 16.8385V7.16146C0.999983 6.63431 0.999967 6.17955 1.03057 5.80497C1.06287 5.40963 1.13419 5.01641 1.32698 4.63803C1.6146 4.07354 2.07354 3.6146 2.63803 3.32698C3.01641 3.13419 3.40963 3.06287 3.80497 3.03057C4.17955 2.99997 4.63431 2.99998 5.16146 3ZM4.63072 5.00147L11.3861 10.2556C11.7472 10.5365 12.2528 10.5365 12.6139 10.2556L19.3693 5.00147C19.2043 5.00011 19.0165 5 18.8 5H5.2C4.98346 5 4.79574 5.00011 4.63072 5.00147ZM20.9922 6.27294L13.8418 11.8343C12.7585 12.6769 11.2415 12.6769 10.1582 11.8343L3.00781 6.27294C3.00036 6.512 3 6.80903 3 7.2V16.8C3 17.3766 3.00078 17.7488 3.02393 18.0322C3.04612 18.3038 3.0838 18.4045 3.109 18.454C3.20487 18.6422 3.35785 18.7951 3.54601 18.891C3.59546 18.9162 3.69617 18.9539 3.96784 18.9761C4.25117 18.9992 4.62345 19 5.2 19H18.8C19.3766 19 19.7488 18.9992 20.0322 18.9761C20.3038 18.9539 20.4045 18.9162 20.454 18.891C20.6422 18.7951 20.7951 18.6422 20.891 18.454C20.9162 18.4045 20.9539 18.3038 20.9761 18.0322C20.9992 17.7488 21 17.3766 21 16.8V7.2C21 6.80903 20.9996 6.512 20.9922 6.27294Z"
        />
      </svg>
    </SvgIcon>
  )
}
