//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEntitySlice, serializeAxiosError, IQueryParams } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'

//Interface
import { TypeCreateBank, TypeWithdrawal } from 'entities/infoPayment/payment.interface'

export const initialState = {
  dataProduct: {
    listDataProduct: [],
    totalProduct: 0
  },
  dataListAddMentor: {
    dataListMentor: [],
    totalListMentor: 0
  },
  dataListMentor: {
    dataListMentor: [],
    totalListMentor: 0
  },
  dataChatroom: {},
  totalUser: 0,
  isDeleteMentorSuccess: false,
  actionsCheck: {
    type: '',
    isSuccess: false,
    statusCode: 0
  }
}

export const getProduct = createAsyncThunk(
  'mentor/get_product',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`course/list`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const hieuGetMentorsAndUsers = createAsyncThunk(
  'channel/users',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      return await axios.get<any>(`channel/users`, { params: params })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getCommission = createAsyncThunk(
  'mentor/get_commission',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      return await axios.get<any>(`channel/me`, { params: params })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateCommission = createAsyncThunk(
  'mentor/update_commission',
  async (body: { mentor_commission: string; user_commission: string }, { rejectWithValue }) => {
    try {
      return await axios.patch<any>(`channel/update`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const createMentor = createAsyncThunk(
  'mentor/create_mentor',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`channel/add-mentor`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const addUserToChannel = createAsyncThunk(
  'channel/add-user',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`channel/add-user`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteUserOfMentor = createAsyncThunk(
  'mentor/delete_user_of_mentor',
  async (user_id: string, { rejectWithValue }) => {
    try {
      return await axios.delete<any>(`channel/mentor/${user_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const detachUserFromMentor = createAsyncThunk(
  'mentor/detachUserFromMentor',
  async (body: { member_user_id: string; mentor_user_id: string }, { rejectWithValue }) => {
    try {
      return await axios.delete<any>(
        `channel/detach-user/${body?.mentor_user_id}/${body?.member_user_id}`
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getDataTransaction = createAsyncThunk(
  'mentor/get_data_transaction',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`transaction/user-list`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getDataUserIncome = createAsyncThunk(
  'mentor/get_data_user_income',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`transaction/user-income`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListBank = createAsyncThunk(
  'mentor/get_list_bank',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`transaction/list-bank`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const createAccountBank = createAsyncThunk(
  'mentor/create_account_bank',
  async (body: TypeCreateBank, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(`transaction/create-bank`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateAccountBank = createAsyncThunk(
  'mentor/update_account_bank',
  async (body: Partial<TypeCreateBank>, { rejectWithValue }) => {
    try {
      const response = await axios.patch<any>(`transaction/update-bank`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const withDrawal = createAsyncThunk(
  'mentor/withdrawal',
  async (body: TypeWithdrawal, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(`transaction/withdrawal`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteAccountBank = createAsyncThunk(
  'mentor/delete_account_bank',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(`transaction/delete-bank/${id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListTransactionAdmin = createAsyncThunk(
  'mentor/get_list_transaction_admin',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return await axios.get<any>(`transaction/admin-list`, { params: params })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateStatusTransactionAdmin = createAsyncThunk(
  'mentor/update_status_transaction_admin',
  async (body: { _id: string; status: string }, { rejectWithValue }) => {
    try {
      return await axios.patch<any>(`transaction/admin-update`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const createChatroomToUser = createAsyncThunk(
  'mentor/create_chatroom_to_user',
  async (body: { partner_id: string; chat_type: string | 'personal' }, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`chat-room/create`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const MENTOR_REDUCER = createEntitySlice({
  name: 'mentor',
  initialState: initialState as any,
  reducers: {
    clearError: () => initialState,

    clearDaTaChatRoom: state => {
      state.dataChatroom = initialState.dataChatroom
    },

    clearDataListMentor: state => {
      state.dataListMentor = []
    },

    clearDataListAddMentor: state => {
      state.dataListAddMentor = initialState.dataListAddMentor
    },

    checkDeleteMentor: (state, action) => {
      state.isDeleteMentorSuccess = action.payload
    },

    setActionsCheck: (state, action) => {
      state.actionsCheck = {
        type: action.payload.type,
        isSuccess: action.payload.isSuccess,
        statusCode: action.payload.statusCode
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProduct.fulfilled, (state, action) => {
        state.dataProduct = {
          listDataProduct: action.payload?.data,
          totalProduct: parseInt(action.payload.headers['x-total-count'] || 0, 10)
        }
      })
      .addCase(hieuGetMentorsAndUsers.fulfilled, (state, action) => {
        state.dataListMentor = {
          dataListMentor: action.payload?.data,
          totalListMentor: parseInt(action.payload.headers['x-total-count'] || 0, 10)
        }
      })
      .addCase(createChatroomToUser.fulfilled, (state, action) => {
        state.dataChatroom = action.payload?.data
      })
  }
})
export const { clearError, clearDaTaChatRoom, clearDataListAddMentor, reset } =
  MENTOR_REDUCER.actions
// Reducer
export default MENTOR_REDUCER.reducer
