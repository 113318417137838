import __ from 'languages/index'
import { ConfigVerify } from 'entities/channel-settings/service/service.type'

export const verifyUserConfigs = [
  {
    key: ConfigVerify.SMS,
    value: __('verify_user_phone'),
    description: __('verify_user_phone_des')
  },
  {
    key: ConfigVerify.EMAIL,
    value: __('verify_user_email'),
    description: __('verify_user_email_des')
  },

]
