import './media/courses.list.scss'

import React, { useCallback, useEffect, useMemo } from 'react'

import { useAppSelector } from 'config/store'
import CoursesListItem from 'entities/courses/components/courses.list.item'
import { TypedCourse, TypedCourseByCategory } from 'entities/courses/courses.interface'
import CoursesListSkeleton from 'entities/courses/skeletons/courses.list.skeleton'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { getListCoursesByCategoryId } from 'entities/courses/services/courses.service'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import BigLayout from '../../layouts/bigLayout'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { formatString } from 'entities/support/utils'
import { Box, Button, Stack, Typography } from '@mui/material'
import SearchComponent from 'components/SearchComponent'
import { PlusIcon } from 'icons'
import Capacity from 'components/Capacity'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import CoursesItem from './components/courses.item'

export default function CoursesListForCategory() {
  const { courses_id } = useParams()
  const navigate = useNavigate()
  const user_data = useAppSelector(state => state.user.user_data)

  const {
    data,
    hasMore,
    totalItems,
    paramRequest,
    refresh,
    loadMore,
    isRefreshing,
    updateParamRequest
  } = useInfiniteScroll<TypedCourseByCategory>(
    {
      search: '',
      limit: 12,
      category_id: courses_id,
      auth_id: user_data?._id,
      order_by: 'DESC'
    },
    getListCoursesByCategoryId
  )

  const categoryName = useMemo(() => {
    return data?.[0]?.course_category_id?.course_category_title || ''
  }, [data])

  const onChangeSearch = useCallback((event: any) => {
    updateParamRequest({ search: event?.target?.value })
  }, [])

  useEffect(() => {
    if (!courses_id) return navigate('/courses')
  }, [courses_id])

  return (
    <BigLayout>
      <div id={'course-list'}>
        <_Helmet title={`${__('course_list')} ${`${totalItems}`} ${__('course_list_lesson')}`} />
        <div className="mainFrame">
          <div className="container">
            <div className="course_main_wrap">
              {/* Head */}
              <BreadcrumbComponent
                breadcrumbName={{
                  '/courses': __('courses'),
                  [`/courses/category`]: categoryName
                }}
              />
              <Stack paddingBottom={2} direction={'row'} justifyContent={'space-between'} gap={2}>
                <Typography variant="h5" textTransform={'none'} fontWeight={700}>
                  {categoryName}
                </Typography>
                <Stack direction={'row'} gap={2}>
                  <SearchComponent
                    placeholder={__('course_upload_search_placeholder')}
                    onChange={onChangeSearch}
                  />
                  {!paramRequest.query && (
                    <Capacity current_user_can={['boss', 'admin', 'mentor']}>
                      <Button
                        component={Link}
                        to="/courses/add"
                        sx={{
                          ':focus': {
                            background: theme => theme.palette.primary.main
                          }
                        }}
                        startIcon={<PlusIcon />}
                      >
                        <Typography textTransform={'none'} fontWeight={600}>
                          {__('course_add_title')}
                        </Typography>
                      </Button>
                    </Capacity>
                  )}
                </Stack>
              </Stack>
              {/* End Head */}

              <InfiniteScrollContainer
                isRefreshing={isRefreshing}
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                refreshFunction={refresh}
                skeleton={<CoursesListSkeleton />}
                showEndMessage={Boolean(!data.length)}
                textEmptyMessage={formatString(__('data_empty'), `${__('courses').toLowerCase()}`)}
              >
                <Box
                  display={'grid'}
                  gridTemplateColumns={'repeat(4,minmax(0,1fr))'}
                  gap={3}
                  id="course_wrap"
                >
                  {data.map((courseData: TypedCourseByCategory, index) => {
                    return (
                      <CoursesItem
                        key={`course_loop_${index}`}
                        course={courseData}
                        categoryName={courseData?.course_category_id?.course_category_title}
                      />
                    )
                  })}
                </Box>
              </InfiniteScrollContainer>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </BigLayout>
  )
}
