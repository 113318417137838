import { useEffect, useRef, useState } from 'react'

export const useGetThumbnail = () => {
  const wrapperRef = useRef(null)
  const [thumbnailSize, setThumbnailSize] = useState({ width: 300, height: 300 })

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef?.current) {
        setThumbnailSize({
          width: wrapperRef?.current?.clientWidth * 2 || 300,
          height: wrapperRef?.current?.clientHeight * 2 || 300
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [wrapperRef?.current])

  const handleGetThumbnailUrl = (url: string) => {
    if (!url) return null
    return `${process.env.REACT_APP_THUMB_URL}/unsafe/${thumbnailSize.width}x${thumbnailSize.height}/${url}`
  }

  return { ref: wrapperRef, getThumbnailUrl: handleGetThumbnailUrl }
}
