import ChannelSettingsContentContainer from '../components/ChannelSettingsContentContainer'
import { Box, Button, Stack, Switch, TableCell, TableRow, Typography } from '@mui/material'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import RenderView from 'components/RenderView'
import TableDrapDrop from 'entities/channel-settings/components/TableDrapDrop'
import __helpers from 'helpers/index'
import { PlusIcon } from '../../../icons'
import __ from 'languages/index'
import React, { useCallback, useEffect, useState } from 'react'
import { TypeCoursesOffline, TypeOfflineTime } from 'entities/channel-settings/service/service.type'
import { StyledTableCell } from 'entities/channel-settings/components/StyledTableCell'
import RowActions from 'entities/channel-settings/components/RowActions'
import AddEditCourseOfflineModal from 'entities/channel-settings/post-category/AddEditCourseOfflineModal'
import { useAppDispatch, useAppSelector } from 'config/store'
import { getCourseOffline } from '../../../store/user.store.reducer'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { groupedDates, renderCourseOfflineTime } from 'entities/channel-settings/helpers'
import CalendarCourse from 'entities/channel-settings/post-category/CalendarCourse'
import { convertMonthYearTime } from 'helpers/dateAndTime'
import {
  channelInfo,
  deleteCourseOffline,
  updateChannelSetting
} from 'entities/channel-settings/store/channel.slice'
import TypedChannel from '../../../interfaces/channel.interface'
import { formatString } from 'entities/support/utils'

export interface TypeCourseOfflineTime{
  year: string,
  month: {
    value: string
    has_user_register: boolean
  } [],
}

const CoursesOfflineSetting = ()=>{
  const channelSetting = useAppSelector(channelInfo)
  const [channelData,setChannelData] = useState<TypedChannel>(channelSetting)
  const [coursesOffline, setCoursesOffline] = useState<TypeCoursesOffline[]>(null)
  const [updateCourseOffline, setUpdateCourseOffline] = useState<{
    type: 'EDIT' | 'ADD' | 'VIEW'
    value: TypeCoursesOffline
  }>(null)

  const [coursesOfflineTime, setCoursesOfflineTime] = useState<Partial<TypeCourseOfflineTime>[]>([])

  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false)
  const [isShowAddEditForm, setIsShowAddEditForm] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isValidate, setIsValidate] = useState<boolean>(false)
  const [convertData, setConvertData] = useState<TypeOfflineTime[]>([])
  const [isApproval,setIsApproval] = useState<boolean>(channelData?.require_course_offline ?? false)

  const toast = useToast()
  const dispatch = useAppDispatch()
  const closeCourseOfflineModal = () => {
    setUpdateCourseOffline(null)
    setCoursesOfflineTime(null)
    setIsShowAddEditForm(false)
  }
  const getListCourse = async () => {
    try {
      setIsLoading(true)
        await getCourseOffline({})
        .then(res =>{
          setCoursesOffline(res?.data)
          setIsLoading(false)
        })
        .catch(err =>{
          console.log(`getCourseOffline${err}`)
          toast.show({
            content: `${__('get_course_offline_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    }catch (err){
      setIsLoading(false)
      console.log(`getCourseOffline${err}`)
    }
  }


  const handleUpdateChannelInfo = (updatedChannel: Partial<TypedChannel>, title: string) => {
    dispatch( updateChannelSetting(updatedChannel))
      .unwrap()
      .then(res => {
        setChannelData(res?.data)
        toast.show({
          content: formatString(__('channel_setting_success'), title),
          type: EnumTypeToast.Success
        })
      })
      .catch(err => {
        console.log('updateChannelSetting', err)
        toast.show({
          content: `${err}}`,
          type: EnumTypeToast.Error
        })
      })
  }

  const handleOpenAddTime = useCallback(()=>{
    setIsShowCalendar(true)
  },[])

  const handleAddTime = useCallback((value: {year: string, month: string}) => {
    if (__helpers.isEmpty(coursesOfflineTime)) {
      setCoursesOfflineTime([{ year: value.year, month: [{value: value.month, has_user_register: false}] }]);
    } else {
      const newData = [...coursesOfflineTime];
      const existingYearIndex = newData.findIndex(item => item.year === value.year);
      if (existingYearIndex !== -1) {
        const existingMonthIndex = newData[existingYearIndex].month.findIndex(month => month.value === value.month);
        if (existingMonthIndex === -1) {
          newData[existingYearIndex].month.push({value: value.month, has_user_register: false});
        }
      } else {
        newData.push({ year: value.year, month: [{value: value.month, has_user_register: false}] });
      }
      setCoursesOfflineTime(newData);
    }
    setIsShowCalendar(false);
  }, [coursesOfflineTime]);

  const handleDeleteTimeItem = useCallback((value: { year: string, month: string }) => {
    const newData = [...coursesOfflineTime];
    const existingYearIndex = newData.findIndex(item => item.year === value.year);

    if (existingYearIndex !== -1) {
      const monthIndex = newData[existingYearIndex].month.findIndex(month => month.value === value.month);
      if (monthIndex !== -1) {
        newData[existingYearIndex].month.splice(monthIndex, 1);
        if (newData[existingYearIndex].month.length === 0) {
          newData.splice(existingYearIndex, 1);
        }
        setCoursesOfflineTime(newData);
      }
    }
  }, [coursesOfflineTime]);


  const handleClickRow = (item: TypeCoursesOffline) =>setUpdateCourseOffline({
    type: 'VIEW',
    value: item
  })

  const handleDeleteCourse = useCallback((item : TypeCoursesOffline)=>{
    if(item?.has_user_register) {
      toast.show({
        content: `${__('delete_course_offline_has_user_register')}`,
        type: EnumTypeToast.Error
      })
    } else {
      dispatch(
        deleteCourseOffline({_id: item?._id})
      )
        .unwrap()
        .then(res=>{
          getListCourse()
          setIsDelete(false)
          toast.show({
            content: `${__('delete_course_offline_success')}`,
            type: EnumTypeToast.Success
          })
        })
        .catch(err => {
          console.log('err',err)
          toast.show({
            content: `${__('delete_course_offline_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    }

  },[])

  useEffect(() => {
    getListCourse()
  }, [])

  useEffect(() => {
    setIsShowAddEditForm(updateCourseOffline?.type === 'EDIT' || updateCourseOffline?.type === 'ADD' || updateCourseOffline?.type === 'VIEW' && !isDelete)
    if(__helpers.isEmpty(updateCourseOffline?.value?.course_offline_time)) return
    let convertData = convertMonthYearTime(updateCourseOffline?.value?.course_offline_time)
    setConvertData(convertData)
    if (convertData){
      const data = groupedDates(convertData)
      setCoursesOfflineTime(data)
    }

  }, [updateCourseOffline])

  useEffect(()=>{
    if(!convertData || !coursesOfflineTime) return
    let validate =  JSON.stringify(groupedDates(convertData).sort()) === JSON.stringify(coursesOfflineTime.sort())
    setIsValidate(validate)
  },[coursesOfflineTime,isDelete])


  const renderHeader = () => {
    return (
      <TableRow
        sx={{
          width: '100%'
        }}
      >
        <StyledTableCell width={'max-content'}>{__("channel_setting_table_courses_offline_code")}</StyledTableCell>
        <StyledTableCell width={'max-content'}>{__("channel_setting_table_courses_offline_title")}</StyledTableCell>
        <StyledTableCell width={'max-content'}>{__("channel_setting_table_courses_offline_time")}</StyledTableCell>
        <StyledTableCell width={'max-content'}  sx={{ textAlign: 'end'}}>{__("channel_setting_table_action")}</StyledTableCell>
      </TableRow>
    )
  }

  const renderBodyRow = (item: TypeCoursesOffline, index: number) => {
    return (
      <>
        <TableCell>
          <Box
            sx={{
              padding: theme => theme.spacing(0.25, 1),
              borderRadius: theme => theme.spacing(1.5),
              bgcolor: theme => theme.palette.background.default,
              width: 'max-content'
            }}
          >
            <Typography>{item?.course_offline_code}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              padding: theme => theme.spacing(0.25, 1),
              borderRadius: theme => theme.spacing(1.5),
              bgcolor: theme => theme.palette.background.default,
              width: 'max-content'
            }}
          >
            <Typography>{item?.course_offline_title}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {`${renderCourseOfflineTime(item?.course_offline_time)}`}
          </Typography>
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => setUpdateCourseOffline({
              type: 'EDIT',
              value: item
            })}
            onClickDeleteBtn={()=> {
              setIsDelete(true)
              handleDeleteCourse(item)
            }}
            disableDelete={item?.has_user_register}
          />
        </TableCell>
      </>
    )
  }

  return(
    <ChannelSettingsContentContainer>
      <Stack gap={2}>
        <ChannelSettingsTitle>{__("courses_offline_list_title")}</ChannelSettingsTitle>
        <Box key={`courses_offline_setting`} >
          <Stack direction={'row'} alignItems={'center'}>
            <Switch
              checked={isApproval}
              onChange={e =>{
                setIsApproval(e.target.checked)
                handleUpdateChannelInfo({
                  require_course_offline: e.target.checked
                },__('courses_offline_list_title'))
              }}
            />
            <Typography>{__("courses_offline_setting")}</Typography>
          </Stack>
          <Typography
            color={'text.secondary'}
            p={theme => theme.spacing(1,0)}
          >{__("courses_offline_setting_description")}</Typography>
        </Box>

        <RenderView
          view={
            <TableDrapDrop
              data={coursesOffline}
              setData={setCoursesOffline}
              renderTableRow={renderBodyRow}
              renderHeader={renderHeader}
              onClickRow={!isDelete && handleClickRow}
            />
          }
          isLoading={isLoading}
          isEmpty={Boolean(!coursesOffline || __helpers.isEmpty(coursesOffline))}
        />
        <Button
          sx={{ mr: 'auto' , textTransform: 'none'}}
          startIcon={<PlusIcon />}
          onClick={()=>setUpdateCourseOffline({
            type: 'ADD',
            value: null
          })}
        >
          {__('courses_offline_btn_add')}
        </Button>
      </Stack>
      {
        isShowAddEditForm &&
        <AddEditCourseOfflineModal
          showModal={isShowAddEditForm}
          handleCloseModal={closeCourseOfflineModal}
          handleOpenAddTime={handleOpenAddTime}
          coursesOfflineTime={coursesOfflineTime}
          handleDeleteTimeItem={handleDeleteTimeItem}
          updateCourseOffline={updateCourseOffline}
          getListCourse={getListCourse}
          isValidate={isValidate}
        />
      }

      {
        isShowCalendar && (
          <CalendarCourse
            isShow={isShowCalendar}
            handleClose={()=>setIsShowCalendar(false)}
            handleAddTime={handleAddTime}

          />
        )
      }
    </ChannelSettingsContentContainer>
  )
}
export default CoursesOfflineSetting
