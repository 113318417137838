import axios from 'axios'
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { TypedChannelLevel, TypedGift, TypedMedia, TypedModule } from './gift.interface'
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import helpers from 'helpers'

export const initialState = {
  entity: null as TypedGift,
  childrenModule: null as TypedGift[],
  mediaDefault: null as TypedMedia[],
  entities: null as TypedGift[],
  levels: null as TypedChannelLevel[],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}

const apiUrl = 'gift'

export const getMediaDefault = createAsyncThunk(
  'gift/media_default',
  async ({ type }: { type: string }) => {
    return await axios.get<TypedMedia>(`settings/default-avatar/${type}`)
  },
  { serializeError: serializeAxiosError }
)

export const getEntity = createAsyncThunk(
  'gift/fetch_entity',
  async ({ _id, auth_id }: { _id: string; auth_id: string }) => {
    return await axios.get<TypedGift>(`${apiUrl}/detail-gift/${_id}?auth_id=${auth_id}`)
  },
  { serializeError: serializeAxiosError }
)

export const createGift = createAsyncThunk(
  'gift/create_gift',
  async (data: {
    gift_type?: string
    name?: string
    media_id?: TypedMedia
    description?: string
    price?: string
    stock_qty?: string
    gift_conditions?: string
    priority?: string
  }) => {
    return await axios.post<TypedGift>(`${apiUrl}/create-gift`, data)
  },
  { serializeError: serializeAxiosError }
)

export const createModule = createAsyncThunk(
  'gift/create_module',
  async (data: { course_id: string; title: string; parent_id?: string; media_id?: string }) => {
    return await axios.post<TypedModule>(`${apiUrl}/create-module`, data)
  },
  { serializeError: serializeAxiosError }
)

export const updateGift = createAsyncThunk(
  'gift/update_gift',
  async (data: {
    _id: string
    name?: string
    media_id?: TypedMedia
    description?: string
    price?: string
    stock_qty?: string
    gift_conditions?: string
  }) => {
    return await axios.patch<TypedGift>(`${apiUrl}/update-gift`, data)
  },
  { serializeError: serializeAxiosError }
)

export const getLevels = createAsyncThunk(
  'gift/get_levels',
  async (params: { channel_id?: string }) => {
    const EndURL = helpers.buildEndUrl(params)
    const requestUrl = `channel/list-level${EndURL}`
    return axios.get<any>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const deleteModule = createAsyncThunk(
  'gift/delete_module',
  async (__id: string) => {
    return await axios.delete<TypedModule>(`${apiUrl}/delete-module/${__id}`)
  },
  { serializeError: serializeAxiosError }
)

export const deleteCourse = createAsyncThunk(
  'gift/delete_course',
  async (__id: string) => {
    return await axios.delete<TypedGift>(`${apiUrl}/delete/${__id}`)
  },
  { serializeError: serializeAxiosError }
)

export const getEntities = createAsyncThunk('gift/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object)
  const requestUrl = `${apiUrl}/list-gift${EndURL}`
  return axios.get<any>(requestUrl)
})

export const postViewModule = createAsyncThunk('gift/post_view_module', async (object: any) => {
  return axios.post<any>(`${apiUrl}/view`, object)
})

export const getChildrenModule = createAsyncThunk(
  'gift/get_children_module',
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `${apiUrl}/list-module${EndURL}`
    return axios.get<any>(requestUrl)
  }
)

export const updateEntity = createAsyncThunk(
  'gift/update_entity',
  async (entity: TypedGift, thunkAPI) => {
    let mainID = String(entity._id)
    delete entity._id
    const result = await axios.put<TypedGift>(`${apiUrl}/${mainID}`, helpers.cleanEntity(entity))
    //   thunkAPI.dispatch(getEntities({}));
    return result
  },
  { serializeError: serializeAxiosError }
)

export const updateModule = createAsyncThunk(
  'gift/update_module',
  async (data: { _id: string; title: string; media_id?: string }) => {
    return await axios.patch<TypedModule>(`${apiUrl}/update-module`, data)
  },
  { serializeError: serializeAxiosError }
)

export const deleteEntity = createAsyncThunk(
  'gift/delete_entity',
  async (_id: string) => {
    return await axios.delete<any>(`${apiUrl}/${_id}`)
  },
  { serializeError: serializeAxiosError }
)

// test checkauthentication
export const createEntity = createAsyncThunk(
  'gift/create_entity',
  async (entity: TypedGift) => {
    return await axios.post<TypedGift>(`course/create`, helpers.cleanEntity(entity))
  },
  { serializeError: serializeAxiosError }
)

export const GIFT_REDUCER = createEntitySlice({
  name: 'gift',
  initialState: initialState as any,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => {
      return { ...state, ...initialState }
    },
    clearEntity: state => {
      state.entity = null
    }
  },

  extraReducers(builder) {
    builder

      .addCase(createEntity.fulfilled, (state, action) => {
        state.creating = false
        state.createdSuccess = true
        state.entity = action.payload.data
      })
      .addCase(createEntity.pending, (state, action) => {
        state.creating = true
        state.createdSuccess = false
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.creating = false
        state.errorMessage = action.payload
      })

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })

      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false
        ;(state.entities = action.payload.data),
          (state.totalItems = parseInt(action.payload.headers['x-total-count'] || 1000, 10))
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        state.errorMessage = action.payload
      })
      .addCase(getChildrenModule.fulfilled, (state, action) => {
        state.childrenModule = action.payload.data
      })
      .addCase(getChildrenModule.rejected, (state, action) => {
        state.childrenModule = null
      })
      .addCase(getMediaDefault.fulfilled, (state, action) => {
        state.mediaDefault = action.payload.data
      })
      .addCase(getMediaDefault.rejected, (state, action) => {
        state.mediaDefault = null
      })
      .addCase(getLevels.fulfilled, (state, action) => {
        state.levels = action.payload.data
      })
      .addCase(getLevels.rejected, (state, action) => {
        state.levels = null
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.entity = null
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        state.childrenModule = state.childrenModule.filter(i => i._id !== action.payload.data?._id)
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.childrenModule = state.childrenModule.map(i =>
          i._id === action.payload.data?._id ? action.payload.data : i
        )
      })
      .addCase(postViewModule.fulfilled, (state, action) => {
        if (action?.payload?.data?.module_id) {
          return {
            ...state,
            childrenModule: state.childrenModule.map(i =>
              i?._id === action?.payload?.data?.module_id
                ? {
                    ...i,
                    is_view: true
                  }
                : i
            )
          }
        }
        return state
      })
      .addMatcher(isPending(getEntity, createGift, updateGift), (state, action) => {
        state.loading = true
      })
      .addMatcher(isRejected(getEntity, createGift, updateGift), (state, action) => {
        state.loading = false
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(getEntity, createGift, updateGift), (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addMatcher(isFulfilled(createModule), (state, action) => {
        state.childrenModule = [...(state.childrenModule || []), action.payload.data]
      })
  }
})
export const { clearError, clearEntity, reset } = GIFT_REDUCER.actions
// Reducer
export default GIFT_REDUCER.reducer
