import React, { useCallback } from 'react'
import { Order } from '../order.type'
import '../media/css/index.scss'
import { useNavigate } from 'react-router-dom'
import DateTime from 'date-and-time'
import { showStatus } from '../helps/index'
import __helpers from 'helpers/index'
import __ from 'languages/index'
import { Box, styled, Theme, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { IQueryParams } from 'config/reducer.utils'
import CustomPagination from 'components/CustomPagination'

interface TypedOrderListItemProps {
  orders: Order[]
  pathname: string
  isRefresh: boolean
  paramsRequest: IQueryParams
  totalCount: number
  handleFilterByField: (type, value) => void
  isAdmin?: boolean
}

const OrderListTable: React.FC<TypedOrderListItemProps> = ({
  orders,
  pathname,
  isRefresh,
  paramsRequest,
  totalCount,
  handleFilterByField,
  isAdmin = false
}) => {
  const navigate = useNavigate()

  const clickOrderItemHandler = (orderId: string | number) => {
    if (isAdmin) {
      navigate(`/orders/approve/${orderId}`)
    } else {
      navigate(`/orders/detail/${orderId}`)
    }
  }
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    handleFilterByField('page', value)
  }

  const columns: GridColDef<Order>[] = [
    {
      field: 'short_id',
      headerName: `${__('order_table_code')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return <Typography> {`#${value}`} </Typography>
      }
    },
    {
      field: 'plan_id',
      headerName: `${__('order_table_product_name')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return <Typography>{value[0]?.name} </Typography>
      }
    },
    {
      field: 'price',
      headerName: `${__('order_table_total_payment')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return `${__helpers.formatNumberWithCommas(value) || 0} VND`
      }
    },
    {
      field: 'status',
      headerName: `${__('order_table_status')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        const handleSetColorText = useCallback(
          (value, theme: Theme) => {
            switch (value) {
              case 'pending':
                return `${theme.palette.warning.main}`
              case 'processing':
                return `${theme.palette.warning.main}`
              case 'cancel':
                return `${theme.palette.error.main}`
              case 'success':
                return `${theme.palette.success.main}`
              case 'close':
                return `${theme.palette.error.main}`
              default:
                return `${theme.palette.text.primary}`
            }
          },
          [value]
        )
        return (
          <Typography color={theme => handleSetColorText(value, theme)}>
            {showStatus(value)}
          </Typography>
        )
      }
    },
    {
      field: 'user_id',
      headerName: `${__('order_table_orderer')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return <Typography>{value[0]?.display_name} </Typography>
      }
    },
    {
      field: 'createdAt',
      headerName: `${__('order_table_date')}`,
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return <Typography>{DateTime.format(new Date(value), 'YYYY-MM-DD HH:mm')}</Typography>
      }
    }
  ]

  return (
    <OrderListContainer>
      <DataGrid
        rowHeight={100}
        showCellVerticalBorder={false}
        rows={orders}
        disableColumnMenu
        rowSpacingType={'margin'}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: paramsRequest.limit,
              page: +paramsRequest.page
            }
          }
        }}
        sortingMode="server"
        onRowClick={value => clickOrderItemHandler(value?.row?._id)}
        slots={{
          pagination: () => (
            <CustomPagination
              handleChangePage={handleChangePage}
              page={+paramsRequest.page}
              count={Math.ceil(totalCount / paramsRequest.limit)}
            />
          )
        }}
        disableRowSelectionOnClick
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          borderRadius: theme => theme.spacing(1.5),
          boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
          '& .MuiDataGrid-cell': {
            height: '100px'
          }
        }}
        getRowId={row => row?._id}
        loading={isRefresh}
      />
    </OrderListContainer>
  )
}

export default OrderListTable

const OrderListContainer = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-main': {
    padding: theme.spacing(2)
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.secondary,
    // margin: theme.spacing(0,2),
    borderRadius: theme.spacing(1.5)
  },
  '& .super-app-theme--header:hover': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'hidden'
    }
  },
  '& .MuiDataGrid-root': {
    boxShadow: 'none',
    borderRadius: 0,
    borderColor: 'transparent',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within,& .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-columnHeader:focus-visible, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':
      {
        outline: 'none',
        outlineWidth: 0,
        outlineOffset: 0
      }
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
    display: 'flex',
    visibility: 'visible',
    width: 'auto'
  },
  '& .MuiDataGrid-iconSeparator': {
    visibility: 'hidden'
  },
  '& .MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.divider
    // padding: theme.spacing(2,2,2,0)
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))
