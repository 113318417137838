
  export interface CreateConfigLevelPayload  {
    level_number: string
    level_point: string
    title: string
  }
  export interface TypeCategoryCourse  {
    _id?: string,
    course_category_title?: string,
    category_course_avatar?: string,
    category_visibility?: number,
  }

  export interface TypeOfflineTime{
    _id: string,
    time: string,
    has_user_register: boolean
  }
  export  interface  TypeCoursesOffline {
    _id: string
    course_offline_title?: string
    course_offline_code?: string
    course_offline_time?: TypeOfflineTime[]
    has_user_register?: boolean
    user_id?: string
    createdAt?: string
    updatedAt?: string
  }
  export  interface  TypePayloadCoursesOffline {
    _id: string
    course_offline_title?: string
    course_offline_code?: string
    course_offline_time?: Partial<TypeOfflineTime>[]
  }
export interface TypeDataLevel {
  createdAt: string
  level_number: string
  level_point: string
  title: string
  total_member: number
  updatedAt: string
  user_id: string
  __v: number
  _id: string
}
export enum ChannelStatus {
  PUBLIC = 'public',
  PRIVATE = 'private'
}
export enum ConfigVerify {
  EMAIL = 'email',
  SMS = 'sms'
}

export enum ConfigPayment {
  TRANSFER = 'transfer_info',
  VNPAY = 'vnpay_config'
}
