//Library
import { createRef } from 'react'
import { useParams } from 'react-router-dom'

//Components
import Theme404 from '../../layout/404'
import courses_view from './courses.view'
import courses_update from './courses.update'
import courses_income from './courses.income'
import courses_update_modules from './courses.update.modules'
import CourseJoined from './course.joined'
import CoursesListForCategory from './course.list.for.category'
import CourseAdd from './course.add'
import CourseList from './course.list'

/**
 *   Create index file for Comment
 */
export default function Courses() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'list'

  let ActualPage: any
  switch (Param) {
    case 'list':
      ActualPage = CourseList
      break

    case 'view':
      ActualPage = courses_view
      break

    case 'update':
      ActualPage = courses_update
      break
    case 'category':
      ActualPage = CoursesListForCategory
      break

    case 'add':
      ActualPage = CourseAdd
      break

    case 'income':
      ActualPage = courses_income
      break

    case 'update-module':
      ActualPage = courses_update_modules
      break

    case 'course-joined':
      ActualPage = CourseJoined
      break

    default:
      ActualPage = Theme404
      break
  }
  return <div>{<ActualPage />}</div>
}
