import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'
import NewsComponent from './news.list'
import NewsDetails from './news.detail'
import NewsSearch from './news.search'
import NewsCategory from './news.category'
import NewsAdd from './news.add'

export default function News() {
  let useParam = {} as any
  useParam = useParams()

  let Param = useParam.slug || 'list'

  let ActualPage: any
  switch (Param) {
    case 'list':
      ActualPage = NewsComponent
      break
    case 'search':
      ActualPage = NewsSearch
      break
    case 'category':
      ActualPage = NewsCategory
      break
    case 'detail':
      ActualPage = NewsDetails
      break
    case 'add':
      ActualPage = NewsAdd
      break
    case 'edit':
      ActualPage = NewsAdd
      break

    default:
      ActualPage = Theme404
      break
  }

  return (
    <div className="mainFrame">
      <ActualPage />
    </div>
  )
}
