//Library
import { Box, Button, IconButton, Stack, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Grid2 from '@mui/material/Unstable_Grid2'
import Grid from '@mui/material/Unstable_Grid2'
import __ from 'languages/index'
import { createRef, useEffect, useState } from 'react'

//Components
import Search from './components/Search'
import NotificationComponent from './components/NotificationComponent'
import MessageComponent from './components/MessageComponent'
import UserInformation, { UserInformationSkeleton } from './components/UserInformation'
import LottieComponent from './components/LottieComponent'
import GlobalProgressBar from 'components/GlobalProgressBar'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { clearError } from 'store/global.warning.store.reducer'

//Image
import imageDefault from 'media/images/image_default.png'

//Helpers
import helpers from 'helpers'
import Menu3LineIcon from 'icons/Menu3LineIcon'
import { EnumTypeToast, useToast } from '../hooks/useToast'
import SidebarMobile from 'components/SidebarMobile'
import useWindowSize from 'hooks/useWindownSize'
import { ROUTER } from 'constant/router'

export default function HeaderComponent() {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const [toggle, setToggle] = useState(false)

  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const loading = useAppSelector(state => state.global_process_bar.loading)
  const errorMessage = useAppSelector(state => state.global_notification.errorMessage)
  const loadingDoneFirstTime = useAppSelector(state => state.user.loadingDoneFirstTime)
  const checkMobile = helpers.isMobile()
  const { widthCurrent } = useWindowSize()

  // console.log(helpers.isMobile())
  const renderErrorMessage = (errorMessage: string) => {
    let messageText = ''
    switch (errorMessage) {
      case 'not_enough_level_to_join_this_event':
        messageText = __('notification_error_not_enough_level_to_join_this_event')
        break
      case 'required_join_course':
        messageText = __('notification_error_required_join_course')
        break
      case 'user_input_data_belong_to_other':
        messageText = __('notification_error_user_input_data_belong_to_other')
        break
      case 'user_not_in_channel':
        messageText = __('user_not_in_channel')
        break
      case `${__('product_already_in_stock')}`:
        messageText = __('product_already_in_stock')
        break
      default:
        messageText = errorMessage
        break
    }
    return messageText
  }

  useEffect(() => {
    if (!errorMessage) return
    toast.show({
      title: __('notification_error_title'),
      content: renderErrorMessage(errorMessage),
      type: EnumTypeToast.Error
    })
    dispatch(clearError())
  }, [errorMessage])

  return (
    <HeaderContainer
      sx={
        !checkMobile && {
          borderBottomWidth: '1px',
          borderBottomColor: theme => theme.palette.divider,
          borderBottomStyle: 'solid'
        }
      }
      id="headerComponent"
      xs={12}
      container
      spacing={3}
      columns={12}
    >
      {/*Start: Logo channel */}
      <Grid xs={3}>
        {/* mobile */}
        <Stack gap={2} direction={'row'} className="logo_mobile">
          <IconButton
            aria-label="search"
            onClick={() => setToggle(true)}
            sx={{ padding: !helpers.isMobile() ? '0 0 0 20px' : 0 }}
          >
            <Menu3LineIcon color="inherit" />
          </IconButton>

          <Link to="/" className="headerComponent_logo">
            <img
              alt="logo"
              src={channel_data?.channel_avatar || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
            />
          </Link>
        </Stack>

        {/* end mobile */}
        <Stack className="logo" gap={2} direction={'row'}>
          <Link to="/" className="headerComponent_logo">
            <img
              alt="logo"
              src={channel_data?.channel_avatar || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
            />
            <Typography className="channel_name" color="text.primary" fontWeight={600}>
              {channel_data?.channel_name || ''}
            </Typography>
          </Link>
        </Stack>
      </Grid>
      {/*End: Logo channel */}

      <Grid className="header_right" xs={9} container spacing={3} columns={9}>
        {/*Start: Search */}
        <Grid
          xs={5}
          sx={{
            display: 'grid',
            alignItems: 'center'
          }}
        >
          <Search />
        </Grid>
        {/*End: Search */}
        <Grid xs={3}>
          {loadingDoneFirstTime ? (
            helpers.isEmpty(user_data) ? (
              <Box className="login_wrapper">
                <Link to={ROUTER.AUTH.LOGIN} className="button_link">
                  <Button sx={{ height: '100%', width: '100%' }}>
                    <Typography
                      variant="body1"
                      textTransform={'none'}
                      color="primary.contrastText"
                      fontWeight={600}
                    >
                      {__('header_login')}
                    </Typography>
                  </Button>
                </Link>
                <Link to={ROUTER.AUTH.REGISTER} className="button_link">
                  <Button
                    variant="outlined"
                    sx={{
                      border: theme => `1px solid ${theme.palette.divider}`,
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <Typography
                      variant="body1"
                      textTransform={'none'}
                      color="text.primary"
                      fontWeight={600}
                    >
                      {__('header_signup')}
                    </Typography>
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box className="information">
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    alignItems: 'center',
                    columnGap: theme => theme.spacing(2)
                  }}
                >
                  <NotificationComponent widthCurrent={widthCurrent} />
                  <MessageComponent widthCurrent={widthCurrent} />
                </Box>
                <UserInformation widthCurrent={widthCurrent} />
              </Box>
            )
          ) : (
            <UserInformationSkeleton />
          )}
        </Grid>
      </Grid>

      {/* mobile */}
      <Grid className="header_right_mobile" xs={9} spacing={2} columns={8}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <Box className="icon_button">
            <Search />
          </Box>
          {loadingDoneFirstTime ? (
            helpers.isEmpty(user_data) ? (
              <Box className="box_button">
                <Link to={ROUTER.AUTH.LOGIN} className="button_link">
                  <Button className="button_action">
                    <Typography
                      variant="body1"
                      textTransform={'none'}
                      color="primary.contrastText"
                      fontWeight={600}
                      fontSize="12px"
                    >
                      {__('header_login')}
                    </Typography>
                  </Button>
                </Link>
                <Link to={ROUTER.AUTH.REGISTER} className="button_link">
                  <Button
                    variant="outlined"
                    className="button_action"
                    sx={{
                      border: theme => `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <Typography
                      variant="body1"
                      textTransform={'none'}
                      color="text.primary"
                      fontWeight={600}
                      fontSize="12px"
                    >
                      {__('header_signup')}
                    </Typography>
                  </Button>
                </Link>
              </Box>
            ) : (
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <NotificationComponent widthCurrent={widthCurrent} />

                <MessageComponent widthCurrent={widthCurrent} />
                <UserInformation widthCurrent={widthCurrent} />
              </Stack>
            )
          ) : (
            <UserInformationSkeleton />
          )}
        </Stack>
      </Grid>

      {/* end mobile */}
      <LottieComponent />
      <GlobalProgressBar show={loading} />
      <Box className="sidebar_mobile">
        <SidebarMobile channelData={channel_data} toggle={toggle} setToggle={setToggle} />
      </Box>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(Grid2)(({ theme }) => ({
  maxWidth: theme.spacing(240),
  margin: '0 auto',
  background: theme.palette.background.paper,
  boxShadow: '0px 1px 2px -4px rgba(14, 20, 38, 0.05)',
  position: 'fixed',
  height: 'auto',
  width: '100%',
  zIndex: 999,
  '& .header_right': {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
    height: '100%',
    marginTop: 0,
    alignItems: 'center'
  },
  '& .headerComponent_logo': {
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(6.5)} auto`,
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(2),
    width: 'auto',
    textDecoration: 'none',
    '& img': {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
      borderRadius: '12%',
      objectFit: 'cover'
    },
    '& .channel_name': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '@media (max-width:480px)': {
        display: 'none'
      }
    }
  },
  '& .login_wrapper': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: theme.spacing(6.25),
    columnGap: theme.spacing(3)
  },
  '& .button_link': {
    width: '100%'
  },
  '& .information': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: theme.spacing(2)
  },

  '& .header_right_mobile': {
    display: 'none'
  },

  '& .logo_mobile': {
    display: 'none'
  },

  '& .sidebar_mobile': {
    display: 'none'
  },

  '@media(max-width: 1024px)': {
    '& .sidebar_mobile': {
      display: 'block'
    },
    '& .header_right': {
      display: 'none'
    },
    '& .header_right_mobile': {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '& .box_button': {
        marginLeft: '0',
        '& .button_link': {
          margin: '0 3px'
        }
      },
      '& .icon_button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        // width: '44px',
        // height: '44px',
        // paddingRight: '8px'
      }
    },
    '& .logo': {
      display: 'none',

      '&_mobile': {
        display: 'flex'
      }
    }
  }
}))
