import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'
import React, { useEffect, useState } from 'react'
import { Box, IconButton, styled } from '@mui/material'
import { ChevronRightIcon } from 'icons'
import 'swiper/css'

type Props<T> = {
  listItem: T[]
  renderBodyItem: (item: T) => React.ReactNode
}

const SwiperSlice = <T,>(props: Props<T>) => {
  const { listItem = [], renderBodyItem } = props
  const [isShowButtonNext, setIsShowButtonNext] = useState<boolean>(true)
  const [isShowButtonPrev, setIsShowButtonPrev] = useState<boolean>(false)

  const ButtonNext = () => {
    const swiper = useSwiper()
    useEffect(() => {
      setIsShowButtonNext(!swiper?.isEnd || false)
    }, [swiper?.isEnd])

    return (
      <IconButton
        onClick={() => {
          setIsShowButtonPrev(true)
          typeof swiper.slideNext() === 'function' && swiper.slideNext()
        }}
        sx={{ color: theme => theme.palette.primary.dark }}
        className="button button_next"
      >
        <ChevronRightIcon color="inherit" />
      </IconButton>
    )
  }

  const ButtonPrev = () => {
    const swiper = useSwiper()

    return (
      <IconButton
        onClick={() => {
          setIsShowButtonNext(true)
          typeof swiper.slidePrev() === 'function' && swiper.slidePrev()
        }}
        sx={{ color: theme => theme.palette.primary.dark }}
        className="button button_prev"
      >
        <ChevronRightIcon sx={{ transform: 'rotate(180deg)' }} color="inherit" />
      </IconButton>
    )
  }

  return (
    <SwiperSlideContainer>
      <Swiper
        onReachEnd={() => setIsShowButtonNext(false)}
        onReachBeginning={() => setIsShowButtonPrev(false)}
        onSlideChange={e => {
          setIsShowButtonNext(!e?.isEnd)
          setIsShowButtonPrev(!e?.isBeginning)
        }}
        spaceBetween={24}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation]}
      >
        {listItem?.map((item: T, idx: number) => (
          <SwiperSlide key={`item_${idx}`}>{renderBodyItem(item)}</SwiperSlide>
        ))}
        {isShowButtonNext && <ButtonNext />}
        {isShowButtonPrev && <ButtonPrev />}
      </Swiper>
    </SwiperSlideContainer>
  )
}
export default SwiperSlice

const SwiperSlideContainer = styled(Box)(({ theme }) => ({
  '& .swiper-wrapper': {
    display: 'flex'
  },
  '& .button': {
    width: theme.spacing(5),
    height: theme.spacing(5),
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 1,
    borderRadius: '50%',
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    ':hover': {
      background: theme.palette.background.paper
    }
  },
  '& .button_next': {
    right: theme.spacing(2.875)
  },
  '& .button_prev': {
    left: theme.spacing(-2.5)
  }
}))
