import React, { useCallback, useEffect, useRef, useState } from 'react'

import DeleteConfirm from 'components/deleteConfirm'
import { useAppDispatch, useAppSelector } from 'config/store'
import { TypedEvent } from 'entities/calendar/calendar.interface'
import { deleteEvent } from 'entities/calendar/calendar.store.reducer'
import CoursesListSkeleton from 'entities/courses/skeletons/courses.list.skeleton'
import helpers from 'helpers/index'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import EventItem from './event.item'
import Title from 'components/Title'
import __ from 'languages/index'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { clearError } from 'entities/courses/courses.store.reducer'
import Capacity from 'components/Capacity'
import { getListEvent } from 'entities/event/event.service'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { IconButton } from '@mui/material'

const EventListComponent = () => {
  const dispatch = useAppDispatch()
  const user_data = useAppSelector(state => state.user.user_data)

  const rootSearchPath = window.location.search
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || {}
  const { data, hasMore, refresh, loadMore, isRefreshing, deleteItemById } =
    useInfiniteScroll<TypedEvent>(
      {
        search: '',
        limit: 12,
        auth_id: user_data?._id,
        order_by: 'DESC',
        ...StringQuery
      },
      getListEvent
    )

  const navigate = useNavigate()
  const channel_data = useAppSelector(state => state.user.channel_data)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)

  // Refs
  const idDeleteRef = useRef<string>('')
  const toast = useToast()
  const [show, setShow] = useState(false)

  /**
   * Jamviet.com fixed Long
   */

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearError())
  }, [])

  const onDelete = useCallback((_id: string) => {
    setShow(true)
    idDeleteRef.current = _id
  }, [])

  const _onClose = useCallback(async result => {
    setShow(false)
    if (result) {
      if (idDeleteRef.current) {
        let title = `${__('notification')}`
        let content = ''
        let type = EnumTypeToast.Success
        try {
          await dispatch(deleteEvent({ _id: idDeleteRef.current }))
            .unwrap()
            .then(() => {
              deleteItemById(idDeleteRef.current)
              content = `${__('event_delete_success')}`
            })
            .catch(() => {
              content = `${__('event_delete_error')}`
              type = EnumTypeToast.Error
            })
        } catch (error) {
          content = `${__('event_delete_error')}`
          type = EnumTypeToast.Error
        }

        toast.show({
          title,
          content,
          type
        })
      }
    }
    idDeleteRef.current = ''
  }, [])

  const _gotoCalendar = useCallback(() => {
    navigate('/calendar')
  }, [])

  const _gotoAddEvent = useCallback(() => {
    navigate('/event/add')
  }, [])

  const onLike = useCallback((like: boolean) => {
    let title = `${__('notification')}`
    let content = ''
    let type = EnumTypeToast.Success
    if (like) {
      content = `${__('event_join_success')}`
    } else {
      content = `${__('event_join_cancel')}`
      type = EnumTypeToast.Error
    }
    toast.show({
      title,
      content,
      type
    })
  }, [])

  return (
    <div id="long_event">
      <DeleteConfirm show={show} onClose={_onClose} title={__('notification')} />

      <div className="d-flex align-items-center justify-content-between">
        <Title text={__('event_title')} />
        <div className="d-flex align-items-center justify-content-between">
          <IconButton
            disabled={!is_user_logged_in}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: theme => theme.spacing(1.5),
              color: '#fff'
            }}
            className="me-2"
            onClick={_gotoCalendar}
          >
            <i className="bi bi-calendar2-check-fill fs-4"></i>
          </IconButton>
          <Capacity current_user_can={'boss'}>
            <IconButton
              disabled={!is_user_logged_in}
              onClick={_gotoAddEvent}
              sx={{
                backgroundColor: theme => theme.palette.primary.main,
                borderRadius: theme => theme.spacing(1.5),
                color: '#fff'
              }}
            >
              <i className="bi bi-plus-circle-fill fs-4"></i>
            </IconButton>
          </Capacity>
        </div>
      </div>
      {/*{!isShowPopup && <div className='mt-4' style={{maxWidth: 600, marginLeft: 'auto'}}>*/}
      {/*  <PopupUpgrade text= {`còn 30 livestream`} url='/upgrade' handleClickBtnClose={handleClickBtnClose}/>*/}
      {/*</div>}*/}
      <hr />
      <InfiniteScrollContainer
        isRefreshing={isRefreshing}
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        refreshFunction={refresh}
        skeleton={<CoursesListSkeleton />}
        showEndMessage={Boolean(!data.length)}
      >
        <Row>
          {data.map(item => {
            return <EventItem key={item?._id} onDelete={onDelete} item={item} onLike={onLike} />
          })}
        </Row>
      </InfiniteScrollContainer>
    </div>
  )
}

export default EventListComponent
