import { useAppDispatch, useAppSelector } from 'config/store'
import { TypedLevel } from 'entities/calendar/calendar.interface'
import { getListLevel } from 'entities/calendar/calendar.store.reducer'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import __ from 'languages/index'
import { Box, Tooltip, Typography, styled } from '@mui/material'
import { ChevronDownIcon } from 'icons'

interface Props {
  setCondition: ({ key, value }: { key: string; value: string }) => void
}

const DATA = [
  { key: 'all', title: `${__('challenge_condition_all')}` },
  { key: 'point', title: `${__('challenge_condition_point')}` },
  { key: 'level', title: `${__('challenge_condition_level')}` }
]

const ChallengeCondition = (props: Props, ref) => {
  const { setCondition } = props
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)

  // Refs Input
  const conditionInputRef = useRef(null)
  const valueInputRef = useRef(null)
  // ======================================

  // Refs
  const conditionRef = useRef<string>('')
  const valueRef = useRef<string>('')
  // ======================================

  // State
  const [type, setType] = useState<'point' | 'level' | 'all'>('all')
  const [listLevel, setListLevel] = useState<TypedLevel[]>([])
  // ======================================

  useImperativeHandle(ref, () => ({
    setDefaultValue: (key: string, value: string) => setDefaultValue(key, value)
  }))

  // useEffect
  useEffect(() => {
    getDataLevel()
  }, [])
  // ======================================

  // Function
  const setDefaultValue = useCallback((key: string, value: string) => {
    conditionRef.current = key
    conditionInputRef.current.value = key
    valueRef.current = value
    setTimeout(() => {
      if (valueInputRef.current) {
        valueInputRef.current.value = value
      }
    }, 200)
    // @ts-ignore
    setType(key)
    if (key == 'level') {
      setCondition({ key, value })
    }
    if (key == 'point') {
      setCondition({ key, value })
    }
  }, [])

  const getDataLevel = useCallback(async () => {
    const res: any = await dispatch(getListLevel({}))
    const data: TypedLevel[] = res?.payload?.data
    if (data) {
      setListLevel(data)
    }
  }, [])

  const _onConditionChange = useCallback(event => {
    const key = event.target.value
    conditionRef.current = key
    setType(key)
    if (key == 'level') {
      setCondition({ key, value: '1' })
    }
    if (key == 'point') {
      setCondition({ key, value: '1' })
    }
  }, [])

  const _onValueChange = useCallback(
    event => {
      const value = event.target.value
      setCondition({ key: type, value })
      if (type == 'point') {
        if (value.match(/\D+/g)) {
          valueInputRef.current.value = valueRef.current
        } else {
          valueRef.current = value
          valueInputRef.current.value = value
        }
      } else {
        valueRef.current = value
      }
    },
    [type]
  )

  // ======================================

  return (
    <ChallengeConditionContainer className="mb-2">
      <Box className="box_condition">
        <Typography>{__('challenge_add_detail_condition')}</Typography>
        <Form.Select
          className="select_condition"
          aria-label={`${__('challenge_chose_condition')}`}
          ref={conditionInputRef}
          onChange={_onConditionChange}
          required
          id="choose_condition"
        >
          {DATA.map(item => {
            return (
              <option key={item?.key} value={item?.key}>
                {item?.title}
              </option>
            )
          })}
        </Form.Select>
        <ChevronDownIcon />
      </Box>

      {type == 'point' ? (
        <Tooltip
          sx={{
            marginTop: '16px'
          }}
          title={__('challenge_condition_point_placeholder')}
        >
          <Form.Control
            className="input"
            placeholder={__('challenge_condition_point_placeholder')}
            ref={valueInputRef}
            onChange={_onValueChange}
            min={0}
            type="text"
            maxLength={12}
            required
            id="value"
          />
        </Tooltip>
      ) : type == 'level' ? (
        <Box mt={2} className="box_condition">
          <Form.Select
            style={{
              marginTop: '10px'
            }}
            className="select_condition"
            ref={valueInputRef}
            onChange={_onValueChange}
            required
            id="value"
          >
            {listLevel.map(item => {
              return (
                <option key={item?._id} value={item?.level_number}>
                  Level {item?.level_number} - {item?.title}
                </option>
              )
            })}
          </Form.Select>
          <ChevronDownIcon />
        </Box>
      ) : null}
      <div className="invalid-feedback">
        {type == 'point'
          ? `${__('challenge_condition_point_sub_text')}`
          : `${__('challenge_condition_level_sub_text')}`}
      </div>
    </ChallengeConditionContainer>
  )
}

export default forwardRef(ChallengeCondition)

const ChallengeConditionContainer = styled(Box)(({ theme }) => ({
  '& .box_condition': {
    background: '#F3F4F5',
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    height: '56px',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      top: '13px',
      right: '16px',
      color: '#0E1426'
    },

    '& p': {
      fontSize: '14px'
    },
    '& .select_condition': {
      background: '#F3F4F5',
      border: 'none',
      padding: 0,
      margin: 0,
      fontSize: '14px',

      '& option': {
        fontSize: '14px'
      }
    },

    '& .form-select:focus': {
      boxShadow: 'none !important'
    }
  },

  '& .input': {
    background: '#F3F4F5',
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    height: '56px',
    marginTop: theme.spacing(2),
    border: 'none',

    '&:focus': {
      boxShadow: 'none !important'
    }
  }
}))
