import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, Modal, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import Table from '../components/Table'
import __ from 'languages/index'
import { StyledTableCell } from '../components/StyledTableCell'
import { CloseIcon, GroupIcon, PlusIcon } from 'icons'
import RowActions from '../components/RowActions'
import RenderView from 'components/RenderView'
import { formatString } from 'entities/support/utils'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import AddLevelForm from 'entities/channel-settings/level/AddLevelForm'
import { createConfigLevel, deleteLevel, getConfigsLevel, updateConfigLevel } from 'entities/channel-settings/service'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { AxiosError } from 'axios'
import { CreateConfigLevelPayload, TypeDataLevel } from 'entities/channel-settings/service/service.type'
import { useAppDispatch } from 'config/store'

interface TypeLevelConfig{
  configsLevel: TypeDataLevel[]
  isFetchingList: boolean
}
const Level = () => {
  const [showAddForm, setShowAddtForm] = useState<boolean>(false)
  const [selectedLevel, setSelectedLevel] = useState<TypeDataLevel>(null)
  const [configsLevel, setConfigLevel] = useState<TypeDataLevel[]>([])
  const [isFetchingList, setIsFetchingList] = useState<boolean>(false)
  const [isValidatePonit, setIsvalidatePoint] = useState<string>('')
  const toast = useToast()
  const dispatch = useAppDispatch()

  const closeForm = () => {
    setShowAddtForm(false)
    setSelectedLevel(null)
  }

  const handleUpdateLevel = async () => {

    try {
      await updateConfigLevel({
        ...selectedLevel
      })
        .then((res)=>{
          setSelectedLevel(null)
          getListLevel()
          toast.show({
            content: formatString(__('channel_setting_update_level_success'), res?.data?.title),
            type: EnumTypeToast.Success
          })
        })
        .catch((err) => {
          console.log('updateConfigLevel_err', err)
          if(!!isValidatePonit){
            toast.show({
              content: formatString(__(isValidatePonit), selectedLevel?.title),
              type: EnumTypeToast.Error
            })
          }else {
            toast.show({
              content: `${err}`,
              type: EnumTypeToast.Error
            })
          }

        })
    } catch (err) {
      console.log('err', err)
    }

  }

  const openForm = () => {
    setShowAddtForm(true)
  }

  const handleDeleteRow = () => {
    const levelId = configsLevel[configsLevel?.length - 1]?._id
    dispatch(deleteLevel(levelId))
      .unwrap()
      .then((res)=>{
        getListLevel()
        toast.show({
          content: formatString(__('channel_setting_delete_level_success')),
          type: EnumTypeToast.Success
        })
      })
      .catch(err=>{
        toast.show({
          content: `${err}`,
          type: EnumTypeToast.Error
        })
        console.log('deleteLevel', err)
      })
  }


  const rowsFormatter = useCallback((listLevel: TypeDataLevel[]) => {
    return listLevel.map((level, index) => ({
      ...level,
      hideDeleteBtn: index < listLevel.length - 1
    }))
  },[configsLevel])

  const validateEditPoint = useCallback((level: TypeDataLevel) => {
    const indexData = configsLevel?.findIndex((item)=> item?._id === level?._id)
    if(indexData == 0 || level?.level_point > configsLevel[indexData + 1]?.level_point){
      setIsvalidatePoint('channel_setting_level_max_limit')
    }else{
      setIsvalidatePoint('channel_setting_level_min_limit')
    }
  },[configsLevel])

  const handleSubmitAddForm = async (value: CreateConfigLevelPayload) => {
    const payload = {
      title: value.title,
      level_number: value.level_number.toString(),
      level_point: value.level_point.toString()
    }
    try {
       await createConfigLevel({
        ...payload
      })
        .then((res)=>{
          getListLevel()
          toast.show({
            content: formatString(__('channel_setting_level_success'), res?.data?.title),
            type: EnumTypeToast.Success
          })
        })
        .catch((err: AxiosError) => {
          console.log('createConfigLevel_err', err)
          toast.show({
            content: `${err}}`,
            type: EnumTypeToast.Error
          })
        })
      closeForm && closeForm()
    } catch (err) {
      console.log('err', err)
    }
  }

  const getListLevel = async () => {
    try {
      setIsFetchingList(true)
      await getConfigsLevel({})
        .then((res)=>{
        setConfigLevel(res?.data)
      })
    } catch (err) {
      console.log('getConfigsLevel',err)
    } finally {
      setIsFetchingList(false)
    }
  }

  useEffect(() => {
    getListLevel()
  }, [])
  const header = () => {
    return (
      <TableRow>
        <StyledTableCell>{__('channel_setting_table_level')}</StyledTableCell>
        <StyledTableCell>{__('channel_setting_table_level_name')}</StyledTableCell>
        <StyledTableCell>{__('channel_setting_table_level_point')}</StyledTableCell>
        <StyledTableCell>{__('channel_setting_table_quantity_member')}</StyledTableCell>
        <StyledTableCell sx={{ textAlign: 'end' }}>
          {__('channel_setting_table_action')}
        </StyledTableCell>
      </TableRow>
    )
  }

  const renderBodyRow = (item: TypeDataLevel & { hideDeleteBtn: boolean }) => {
    return (
      <>
        <TableCell>
          {formatString(__('channel_setting_table_level_data'), item.level_number)}
        </TableCell>
        <TableCell>
          {item?._id === selectedLevel?._id ? (
            <TextField
              sx={{
                maxWidth: 'fit-content'
              }}
              size="small"
              value={selectedLevel?.title}
              onChange={e => {
                setSelectedLevel({
                  ...selectedLevel,
                  title: e.target.value
                })
              }}
              autoFocus
              label={__('channel_setting_table_level_name')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                bgcolor: theme => theme.palette.background.default,
                width: 'fit-content'
              }}
            >
              <Typography>{item?.title}</Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {item?._id === selectedLevel?._id ? (
            <TextField
              sx={{
                maxWidth: '100px'
              }}
              size="small"
              value={selectedLevel?.level_point}
              onChange={e => {
                setSelectedLevel({
                  ...selectedLevel,
                  level_point: e.target.value
                })
                validateEditPoint({
                  ...selectedLevel,
                  level_point: e.target.value
                })
              }}
              autoFocus
              label={__('channel_setting_table_level_point')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                bgcolor: theme => theme.palette.background.default,
                width: 'fit-content'
              }}
            >
              <Typography
                sx={{
                  color: theme => theme.palette.primary.main,
                  fontWeight: 600
                }}
              >
                {`${item.level_point} ${__('point')}`}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" color={'text.secondary'} gap={0.5}>
            <GroupIcon fontSize={'small'} />
            <Typography color="text.secondary">{item.total_member}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => {
              setSelectedLevel(item)
            }}
            editing={item?._id === selectedLevel?._id}
            onClickDeleteBtn={!item.hideDeleteBtn &&  handleDeleteRow}
            onConfirm={handleUpdateLevel}
          />
        </TableCell>
      </>
    )
  }


  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_setting_data_level')}</ChannelSettingsTitle>
      <Typography>{__('channel_setting_data_level_sub_text')}</Typography>
      <RenderView
        view={
          <Table
            data={rowsFormatter(configsLevel)}
            renderTableRow={renderBodyRow}
            renderHeader={header}
          />
        }
        isLoading={isFetchingList}
      />

      <Button sx={{ mr: 'auto' }} startIcon={<PlusIcon />} onClick={openForm}>
        {__('channel_setting_add_level')}
      </Button>
      <Modal open={showAddForm} onClose={closeForm}>
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: '500px'
          }}
        >
          <ModalHeader direction="row">
            <Typography className="title">{__("channel_setting_add_level")}</Typography>
            <IconButton onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            <AddLevelForm
              level={configsLevel[configsLevel?.length - 1]}
              handleSubmitForm={handleSubmitAddForm}
              initialLevelPoint={configsLevel?.length}
              setConfigLevel={setConfigLevel}
            />
          </ModalBody>
        </ModalContainer>
      </Modal>
    </Stack>
  )
}

export default Level
