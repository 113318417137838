import __ from 'languages/index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { AxiosError, AxiosResponse } from 'axios'
import { useAppDispatch, useAppSelector } from 'config/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import helpers from 'helpers'
import './media/css/index.scss'
import {
  updatePassword,
  verifyPassword
} from 'entities/forgotPassword/forgot.password.store.reducer'
import _Helmet from 'components/helmet'
import { Alert, Box, Button, IconButton, keyframes, Paper, styled, Typography } from '@mui/material'
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import { ArrowLeftIcon } from 'icons'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'
import InputVerificationCode from 'components/InputVerificationCode'

const PasswordValidateCodePage = () => {
  const [validateCode, setValidateCode] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState<number>(120)
  const [isCounting, setIsCounting] = useState<boolean>(true)
  const [sessionId, setSessionId] = useState<string>('')
  const [error, setError] = useState<string>('')

  const toast = useToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const checkMobile = helpers.isMobile()
  const channel_data = useAppSelector(state => state.user.channel_data)

  const handleSubmitVerifyCode = useCallback(
    (verification_code: string) => {
      const formData = {
        verification_code: verification_code,
        session_id: sessionId
      }
      dispatch(verifyPassword(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          toast.show({
            content: `${__('validate_code_page_verify_success')}`,
            type: EnumTypeToast.Success
          })
          navigate(`/forgot-password/change-password`)
          localStorage.setItem(
            'forgotPasswordSession',
            JSON.stringify({
              sessionId: res?.data?.session_id || ''
            })
          )
        })
        .catch((error: AxiosError) => {
          console.log(`sendOTP_${error?.message}`, '')
          setError(`${error?.message}`)
        })
    },
    [validateCode, sessionId]
  )

  const countResendTime = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }, [timeLeft])

  useEffect(() => {
    let timer
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    }
    if (timeLeft === 0) {
      setIsCounting(false)
      setTimeLeft(120)
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isCounting, timeLeft])

  const handleResendPassword = useCallback(() => {
    const { user_input } = location?.state
    dispatch(
      updatePassword({
        user_input: user_input
      })
    )
      .unwrap()
      .then((res: AxiosResponse) => {
        setIsCounting(true)
        toast.show({
          content: `${__('forgot_password_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((error: AxiosError) => {
        console.log(`send_${error}`)
        toast.show({
          content: `${__(error.message || 'our_system_meet_error')}`,
          type: EnumTypeToast.Error
        })
      })
  }, [location])

  useEffect(() => {
    try {
      const forgotPasswordProps: { sessionId: string; user_input: string } = JSON.parse(
        localStorage.getItem('forgotPasswordProps')
      )
      if (!forgotPasswordProps?.sessionId) {
        navigate('/login')
      }
      setSessionId(forgotPasswordProps?.sessionId)
    } catch (error) {
      toast.show({
        content: `${error}`,
        type: EnumTypeToast.Error
      })
      navigate('/login')
    }

    return () => {
      localStorage.removeItem('forgotPasswordProps')
    }
  }, [])

  return (
    <>
      <_Helmet title={'forgot_password_verify_title'} />
      <PasswordValidateCodePageContainer id="validateCodePage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />

        <Box id={'box_form'} className={`wrapper ${checkMobile && 'mobile'}`}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: checkMobile ? '100%' : 'auto'
            }}
          >
            {error && <Alert severity="error">{error}</Alert>}
            {checkMobile && (
              <IconButton
                sx={{
                  mt: '24px',
                  justifyContent: 'flex-start'
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowLeftIcon />
              </IconButton>
            )}
            <Box padding={theme => theme.spacing(3)}>
              <h2>{__('forgot_password_verify_title')}</h2>
              <p>{__('forgot_password_verify_sub_title')}</p>
              <Form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault()
                  handleSubmitVerifyCode(validateCode)
                }}
                className="validateCodePage_validate_number_code"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <label className="fw-bold mb-1">{__('forgot_password_verification_code')}</label>
                  {!isCounting ? (
                    <Button
                      variant="text"
                      onClick={handleResendPassword}
                      className="btn btn-outline-primary btn_resend_code "
                    >
                      {__('forgot_password_resend_code')}
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      disabled={isCounting}
                      className="btn btn-outline-primary btn_resend_code"
                    >
                      {`${__('validate_code_page_resend')} ${countResendTime}`}
                    </Button>
                  )}
                </div>

                <InputVerificationCode
                  onCompleted={handleSubmitVerifyCode}
                  onChange={(value: string) => {
                    setValidateCode(value)
                  }}
                />

                <Button
                  disabled={validateCode?.length < 6}
                  type="submit"
                  sx={{ p: theme => theme.spacing(2.1875, 2), mt: theme => theme.spacing(3) }}
                >
                  <Typography textTransform={'none'}>{__('forgot_password_btn_verify')}</Typography>
                </Button>
              </Form>
            </Box>
          </Paper>
          {/*<div className="mt-4">*/}
          {/*  <span>{__("forgot_password_verify_support_text")}</span>*/}
          {/*  <a href="#" onClick={() => navigate('/') } role="button" className=" ms-1 fw-bold text-primary">*/}
          {/*    {__("forgot_password_verify_support_contact")}*/}
          {/*  </a>*/}
          {/*</div>*/}
        </Box>
      </PasswordValidateCodePageContainer>
    </>
  )
}
export default PasswordValidateCodePage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`

const PasswordValidateCodePageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',

  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },

  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 468px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  }
}))
