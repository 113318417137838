//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//Config
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface

//Helpers
import helpers from 'helpers'
import { TypedChatHistoryRoom, TypedChatRoom } from '../chat.interface'

export const initialState = {}

export const getListInboxChatRoom = createAsyncThunk(
  'chat/get_list_inbox_chat_room',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return await axios.get<TypedChatRoom[]>(`/chat-room/list`, {
        params: params
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListMediaChatRoom = createAsyncThunk(
  'chat/get_list_media_chat_room',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const { _id } = params
      helpers.clearValueEmptyInObject(params)
      delete params?._id
      return await axios.get<unknown>(`/chat-media/room/${_id}`, {
        params: params
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getDataMessageChatRoom = createAsyncThunk(
  'chat/get_data_message_chat_room',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const { _id } = params
      helpers.clearValueEmptyInObject(params)
      delete params?._id
      return await axios.get<TypedChatHistoryRoom[]>(`/chat-history/room/${_id}`, {
        params: params
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getDataUserTexting = createAsyncThunk(
  'chat/get_data_user_texting',
  async (_id: string, { rejectWithValue }) => {
    try {
      return await axios.get<TypedChatRoom>(`/chat-room/${_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const viewMessageOfClient = createAsyncThunk(
  'chat/view_message_of_client',
  async (_id: string, { rejectWithValue }) => {
    try {
      return await axios.post<unknown>(`/chat-room/view/${_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const sendMessageToClient = createAsyncThunk(
  'chat/send_message_To_client',
  async (
    body: { chat_room_id: string; media_data?: string; chat_content?: string },
    { rejectWithValue }
  ) => {
    try {
      return await axios.post<unknown>(`/chat-history/create`, body, {
        headers: { 'Content-Type': 'application/json' }
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const uploadFileOfChat = createAsyncThunk(
  'chat/upload_file_of_chat',
  async (body: FormData, { rejectWithValue }) => {
    try {
      return await axios.post<unknown>(
        `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
        body,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const CHAT_REDUCER = createEntitySlice({
  name: 'chat',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = CHAT_REDUCER.actions

export default CHAT_REDUCER.reducer
