import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import JoinChannel from './channel.join'

export default function Channel() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'channel'
  let ActualPage: any

  switch (Param) {
    case 'join':
      ActualPage = JoinChannel
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className={Param === 'join' ? '' : 'mainFrame'}>
      <div className="">{<ActualPage />}</div>
    </div>
  )
}
