import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography
} from '@mui/material'
import _Helmet from 'components/helmet'
import React, { useCallback, useEffect, useState } from 'react'
import __, { ___ } from 'languages/index'
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'
import { useAppDispatch, useAppSelector } from 'config/store'
import { Link } from 'react-router-dom'
import { ROUTER } from 'constant/router'
import { ArrowLeftIcon } from '../icons'
import SearchComponent from 'components/SearchComponent'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { useInfiniteScroll } from '../hooks/useInfiniteScroll'
import { TypeCoursesOffline } from 'entities/channel-settings/service/service.type'
import { getCourseOffline, updateDataUser } from '../store/user.store.reducer'
import __helpers from 'helpers/index'
import { EnumTypeToast, useToast } from '../hooks/useToast'
import CourseOfflineItem from './component/CourseOfflineItem'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom';
import { animation } from './helpers'


export enum EnumCourseOffline{
  JOINED="join",
  NOTJOINED="Not_joined"
}

export interface TypeDataCourse{
  _id: string
  time_id: string
}

const CoursesOffline = () =>{
  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const [selectTime,setSelectTime]= useState<{
    isCheck: EnumCourseOffline,
    course: TypeDataCourse[]
  }>(null)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const {
    data,
    hasMore,
    loadMore,
    isRefreshing,
    updateParamRequestDebounce,
    paramRequest,
    refresh
  } = useInfiniteScroll<TypeCoursesOffline>({
    limit: 10,
    order_by: "DESC",
    course_offline_title: ''
  }, getCourseOffline)
  const handleChangeSearch = (value) => {
    updateParamRequestDebounce({course_offline_title: value});
  };

  const handleOnSubmit = useCallback(()=>{
    const courseOfflines =  !!selectTime?.course ?  selectTime?.course.map(item => item?.time_id) : []
    dispatch(updateDataUser({
      _id: user_data?._id,
      course_offlines: courseOfflines ?? []
    }))
      .unwrap()
      .then((res) => {
        navigate('/')
        toast.show({
          content: `${__('user_update_information_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((error: AxiosError) => {
        console.log(`updateDataUser_${error}`)
        toast.show({
          content: `${__('user_update_information_failed')}`,
          type: EnumTypeToast.Error
        })
      })
  },[selectTime])

  return(
    <>
      <_Helmet title={__("courses_offline_label")} />
      <CoursesOfflineContainer id="coursesOffline">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        >
          <Box id={'background_svg_default'} />
          <Box id={'box_form'} className="wrapper">
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                maxWidth: theme => theme.spacing(76),
                height: 'auto',
                width: '100%'
              }}
            >
              <Box>
                <Box
                  display={'grid'}
                  rowGap={theme => theme.spacing(3)}
                  padding={theme => theme.spacing(3)}
                >
                  <Box justifyContent={'flex-start'}>
                    <IconButton sx={{ p: 0 }}>
                      <Link to={ROUTER.AUTH.REGISTER}>
                        <ArrowLeftIcon sx={{ color: theme => theme.palette.text.primary }} />
                      </Link>
                    </IconButton>
                  </Box>
                  <Typography
                    variant="h5"
                    textTransform={'none'}
                    fontWeight={700}
                    textAlign={'start'}
                  >
                    {__('courses_offline_title')}
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    p={theme => theme.spacing(1,0)}
                  >
                    {__("courses_offline_sub_title")}
                  </Typography>
                  <SearchComponent value={paramRequest?.course_offline_title ?? ''}  placeholder={__('courses_offline_search')} onChange={handleChangeSearch} />
                  <Box
                    component={'form'}
                    rowGap={theme => theme.spacing(2)}
                  >
                    <Box>
                      <Stack
                        direction={'row'}
                        className={'courses_offline_item'}
                      >
                        <Typography>{__("courses_offline_not_joined")}</Typography>
                        <Checkbox
                          checked={selectTime?.isCheck === EnumCourseOffline.NOTJOINED}
                          onChange={()=> setSelectTime({
                            isCheck: EnumCourseOffline?.NOTJOINED,
                            course: null
                          })}
                          color="primary"
                        />

                      </Stack>
                      <Box>
                        <InfiniteScrollContainer
                          isRefreshing={isRefreshing}
                          dataLength={data.length}
                          next={loadMore}
                          hasMore={hasMore}
                          refreshFunction={refresh}
                          scrollableTarget={"scrollableDiv"}
                        >
                          {
                            data?.map((item, index)=>(
                              <CourseOfflineItem
                                key={`CourseOfflineItem_${index}`}
                                selectTime={selectTime}
                                course={item}
                                setSelectTime={setSelectTime}
                              />
                            ))
                          }
                        </InfiniteScrollContainer>
                      </Box>


                    </Box>
                    <Button
                      disabled={__helpers.isEmpty(selectTime)}
                      onClick={handleOnSubmit}
                      sx={{
                        width: '100%'
                      }}
                    >
                      {__("btn_confirm")}
                    </Button>
                  </Box>
                </Box>

              </Box>
            </Paper>
          </Box>

        </Box>

      </CoursesOfflineContainer>
    </>

  )
}
export default CoursesOffline

const CoursesOfflineContainer = styled(Box)(({theme})=>({
  height: '100vh',
  overflow: 'hidden',
  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& #box_form': {
    position: 'absolute',
    top: 0
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 824px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
  '& .courses_offline_item':{
    borderRadius: theme.spacing(1.5),
    border: `${theme.spacing(0.2)} solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75,1),
    marginBottom: theme.spacing(2)
  },

  '& .form': {
    display: 'grid',
    rowGap: theme.spacing(1.25)
  },
  '& .link': {
    color: theme.palette.text.primary
  }
}))
