import React from 'react'
import { useParams } from 'react-router-dom'
import Theme404 from '../../layout/404'
import Orders from './order.list'
import OrderDetailView from './order.detail'
import OrderListChannel from './order.list.channel'
import OrderApproveOfChannel from './order.approve'
import __ from 'languages/index'

export default function Order() {
  let useParam = {} as any
  useParam = useParams()

  let Param = useParam.slug || 'list'

  let ActualPage: any

  switch (Param) {
    case 'list':
      ActualPage = Orders
      break
    case 'detail':
      ActualPage = OrderDetailView
      break
    case 'orders-channel':
      ActualPage = OrderListChannel
      break
    case 'approve':
      ActualPage = OrderApproveOfChannel
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <>
      <div className="mainFrame">
        <ActualPage />
      </div>
    </>
  )
}
