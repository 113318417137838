//Library
import React, { KeyboardEvent, Ref, RefObject, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'

import __ from 'languages/index'

export interface ReactInputVerificationCodeProps {
  length?: number
  onChange?: (data: string) => void
  onCompleted?: (data: string) => void
  type?: 'alphanumeric' | 'number'
}

const InputVerificationCode = ({
  length = 6,
  onChange = () => null,
  onCompleted = () => null,
  type = 'number'
}: ReactInputVerificationCodeProps) => {
  const inputRefs: RefObject<HTMLInputElement>[] = Array.from({ length: length }, () =>
    useRef<HTMLInputElement>(null)
  )

  const fillValues = (value: string) =>
    new Array(length).fill('').map((_, index) => value[index] ?? '')

  const [validateCode, setValidateCode] = useState<string[]>(fillValues(''))
  const [focusedIndex, setFocusedIndex] = useState<number>(-1)

  const validate = (input: string) => {
    if (type === 'number') {
      return /^\d/.test(input)
    }
    if (type === 'alphanumeric') {
      return /^[a-zA-Z0-9]/.test(input)
    }
    return true
  }

  const selectInputContent = (index: number) => {
    const input = inputRefs?.[index]?.current
    if (input) {
      requestAnimationFrame(() => {
        input?.select()
      })
    }
  }

  const setValue = (value: string, index: number) => {
    const nextValues = [...validateCode]
    nextValues[index] = value
    setValidateCode(nextValues)
    const stringifiedValues = nextValues.join('')
    const isCompleted = stringifiedValues.length === length
    onChange(stringifiedValues)
    if (isCompleted) {
      onCompleted(stringifiedValues)
      return
    }
  }

  const focusInput = (index: number) => {
    const input = inputRefs[index]?.current
    if (input) {
      requestAnimationFrame(() => {
        input.focus()
      })
    }
  }

  const blurInput = (index: number) => {
    const input = inputRefs[index]?.current
    if (input) {
      requestAnimationFrame(() => {
        input.blur()
      })
    }
  }
  const onInputFocus = (index: number) => {
    const input = inputRefs[index]?.current
    if (input) {
      setFocusedIndex(index)
      selectInputContent(index)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const eventValue = event.target.value
    /**
     * ensure we only display 1 character in the input
     * by clearing the already setted value
     */
    const value = eventValue.replace(validateCode[index], '')

    /**
     * if the value is not valid, don't go any further
     * and select the content of the input for a better UX
     */
    if (!validate(value)) {
      selectInputContent(index)
      return
    }

    /**
     * otp code
     */
    if (value.length > 1) {
      setValidateCode(fillValues(eventValue))
      const isCompleted = eventValue.length === length
      onChange(eventValue)
      if (isCompleted) {
        onCompleted(eventValue)
        blurInput(index)
        return
      }
      return
    }
    setValue(value, index)
    /**
     * if the input is the last of the list
     * blur it, otherwise focus the next one
     */
    if (index === length - 1) {
      blurInput(index)
      return
    }
    focusInput(index + 1)
  }

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const eventKey = event.key
    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      /**
       * prevent trigger a change event
       * `onInputChange` won't be called
       */
      event.preventDefault()
      setValue('', focusedIndex)
      focusInput(index - 1)
      return
    }
    /**
     * since the value won't change, `onInputChange` won't be called
     * only focus the next input
     */
    if (eventKey === validateCode[index]) {
      focusInput(index + 1)
    }
  }

  const onInputPaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()
    const pastedValue = event.clipboardData.getData('text')
    const nextValues = pastedValue.slice(0, length)
    if (!validate(nextValues)) {
      return
    }
    setValidateCode(fillValues(nextValues))
    const isCompleted = nextValues.length === length
    onChange(nextValues)
    if (isCompleted) {
      onCompleted(nextValues)
      blurInput(index)
      return
    }
    focusInput(nextValues.length)
  }

  const handleSelect = ref => {
    setTimeout(() => {
      ref?.current?.select()
    }, 0)
  }

  return (
    <InputVerificationCodeContainer id="inputVerificationCode">
      {inputRefs?.map((inputRef: Ref<HTMLInputElement>, index: number) => (
        <Box
          component={'input'}
          key={`input_${index}`}
          value={validateCode[index]}
          className="input_item"
          autoFocus={!index}
          onClick={() => handleSelect(inputRef)}
          ref={inputRef}
          onChange={event => onInputChange(event, index)}
          onFocus={() => onInputFocus(index)}
          onKeyDown={event => onInputKeyDown(event, index)}
          onPaste={event => onInputPaste(event, index)}
        />
      ))}
    </InputVerificationCodeContainer>
  )
}

export default InputVerificationCode

const InputVerificationCodeContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, minmax(0,1fr))',
  columnGap: theme.spacing(2),
  width: '100%',
  '& .input_item': {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    borderRadius: theme.spacing(1.5),
    textAlign: 'center',
    border: `1px solid ${theme.palette.divider}`,
    outline: 'none',
    ':focus-visible': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `${theme.palette.primary.main} 0px 0px 6px 0.2rem`
    },
    '::selection': {
      backgroundColor: theme.palette.primary.main,
      color: 'background.paper'
    }
  }
}))
