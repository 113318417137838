import { SvgIcon, SvgIconProps } from '@mui/material'

const BookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 17" fill="currentColor">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25327 4.15079C5.91857 3.17869 4.07883 3.17869 2.74413 4.15079C2.69032 4.18999 2.66536 4.24561 2.66536 4.2974V12.0884C2.66536 12.4566 2.96384 12.755 3.33203 12.755H6.50799C6.79754 12.755 7.0776 12.8176 7.33203 12.9327V4.2974C7.33203 4.24561 7.30708 4.18999 7.25327 4.15079ZM7.9987 3.04451C6.19964 1.76023 3.74856 1.76974 1.95915 3.07302C1.5686 3.35748 1.33203 3.80978 1.33203 4.2974V12.0884C1.33203 13.1929 2.22746 14.0884 3.33203 14.0884H6.50799C6.7605 14.0884 6.99134 14.231 7.10427 14.4569L7.34278 14.9339C7.61304 15.4744 8.38436 15.4744 8.65461 14.9339L8.89313 14.4569C9.00605 14.231 9.2369 14.0884 9.48941 14.0884H12.6654C13.7699 14.0884 14.6654 13.1929 14.6654 12.0884V4.2974C14.6654 3.80978 14.4288 3.35748 14.0382 3.07302C12.2488 1.76974 9.79776 1.76023 7.9987 3.04451ZM8.66536 4.2974V12.9327C8.9198 12.8176 9.19985 12.755 9.48941 12.755H12.6654C13.0336 12.755 13.332 12.4566 13.332 12.0884V4.2974C13.332 4.24561 13.3071 4.18999 13.2533 4.15079C11.9186 3.17869 10.0788 3.17869 8.74413 4.15079C8.69032 4.18999 8.66536 4.24561 8.66536 4.2974Z" />
      </svg>
    </SvgIcon>
  )
}

export default BookIcon
