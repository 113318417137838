import { Box, Button, Divider, Paper, Stack, Typography, styled } from '@mui/material'
import BigLayout from 'layouts/bigLayout'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _Helmet from 'components/helmet'
import __, { ___ } from 'languages/index'
import SearchComponent from 'components/SearchComponent'
import FilterIcon from 'icons/FilterIcon'
import { CheckInactiveIcon, PlusIcon } from 'icons'
import PopoverComponent from 'components/PopoverComponent'
import NewsItem from './Components/news.item'
import { getNewsToCategory } from './services'
import { TypedNews, TypedNewsForCategory } from './interface'
import helpers from 'helpers'
import ComponentEmpty from 'components/componentEmpty'
import SwiperSlice from 'components/SwiperSlice'
import Capacity from 'components/Capacity'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { Link, useNavigate } from 'react-router-dom'

interface TypedCategory {
  _id: string
  news_category_name: string
}

const NewsComponent = () => {
  const navigate = useNavigate()
  const [categoryData, setCategoryData] = useState<TypedCategory[]>([])
  const [tabFilter, setTabFilter] = useState<string>('')
  const { data, paramRequest, setParamRequest, hasMore, isRefreshing, loadMore, refresh } =
    useInfiniteScroll<TypedNewsForCategory>(
      {
        query: '',
        _id: '',
        keyAttribute: '_id'
      },
      getNewsToCategory,
      true
    )

  const isEmptyNews = useMemo(() => {
    return data.every(data => helpers.isEmpty(data?.news) === true)
  }, [data])

  const handleChangeSearch = useCallback(
    (value: string) => {
      navigate(`/news/search?query=${value}`, { replace: true })
    },
    [paramRequest]
  )

  const handleSeeAllNews = useCallback(
    (categoryId: string) => {
      navigate(`/news/category/${categoryId || ''}`, { replace: true })
    },
    [data]
  )

  const renderFilter = useCallback(
    _function => {
      return (
        <Stack
          gap={1.5}
          p={2}
          maxHeight={theme => theme.spacing(62.5)}
          overflow={'auto'}
          width={theme => theme.spacing(41)}
        >
          <Typography variant="subtitle2" fontWeight={600}>
            {__('filter_title')}
          </Typography>
          <Typography fontWeight={600}>{__('according_to_category')}</Typography>
          <Divider sx={{ opacity: 1 }} />
          <Stack p={theme => theme.spacing(0, 1)} gap={1}>
            {categoryData?.map((value: TypedCategory, idx: number) => (
              <Button
                key={idx}
                onClick={() => {
                  setParamRequest(paramRequest => ({
                    ...paramRequest,
                    category_id: value?._id
                  }))
                  _function()
                  setTabFilter(value?._id)
                }}
                variant="text"
                sx={{
                  p: theme => theme.spacing(2),
                  borderRadius: theme => theme.spacing(1.5),
                  background: theme =>
                    value?._id === tabFilter
                      ? theme.palette.primary.backgroundHover
                      : theme.palette.background.default,
                  justifyContent: 'flex-start'
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  textTransform={'none'}
                  color={value?._id === tabFilter ? 'primary.main' : 'text.primary'}
                >
                  {value?.news_category_name || '1'}
                </Typography>
              </Button>
            ))}
          </Stack>
        </Stack>
      )
    },
    [categoryData, tabFilter, paramRequest]
  )

  useEffect(() => {
    if (helpers.isEmpty(data) || paramRequest?._id) return
    setCategoryData([
      { _id: '', news_category_name: __('all') },
      ...data?.map(item => ({
        _id: item?._id,
        news_category_name: item?.news_category_name
      }))
    ])
  }, [data, paramRequest])

  return (
    <BigLayout>
      <_Helmet title="news" />
      <NewsContainer>
        {/* Head */}
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h5" textTransform={'none'} fontWeight={700}>
            {__('news')}
          </Typography>
          <Stack direction={'row'} gap={2}>
            <SearchComponent placeholder={__('search_for_news')} onChange={handleChangeSearch} />
            {!paramRequest.query && categoryData?.length > 6 && (
              <PopoverComponent renderChildren={renderFilter}>
                <Stack
                  component={Paper}
                  direction={'row'}
                  gap={1}
                  sx={{
                    border: theme => `1px solid ${theme.palette.divider}`,
                    p: theme => theme.spacing(1.5, 3)
                  }}
                >
                  <Stack sx={{ position: 'relative' }}>
                    <FilterIcon sx={{ color: theme => theme.palette.text.primary }} />
                    {paramRequest?.category_id && (
                      <CheckInactiveIcon className="icon_active_filter" />
                    )}
                  </Stack>
                  <Typography textTransform={'none'} fontWeight={600} color="text.primary">
                    {__('news_filter')}
                  </Typography>
                </Stack>
              </PopoverComponent>
            )}
            {!paramRequest.query && (
              <Capacity current_user_can={['boss', 'admin', 'mentor']}>
                <Button
                  component={Link}
                  to="/news/add"
                  sx={{
                    ':focus': {
                      background: theme => theme.palette.primary.main
                    }
                  }}
                  startIcon={<PlusIcon />}
                >
                  <Typography textTransform={'none'} fontWeight={600}>
                    {__('add_news')}
                  </Typography>
                </Button>
              </Capacity>
            )}
          </Stack>
        </Stack>
        {/* End Head */}

        <Stack gap={3}>
          {helpers.isEmpty(data) ? (
            <ComponentEmpty text="" />
          ) : (
            <InfiniteScrollContainer
              isRefreshing={isRefreshing}
              dataLength={data.length}
              next={loadMore}
              hasMore={hasMore}
              textEmptyMessage=""
              refreshFunction={refresh}
              showEndMessage={false}
              pullDownToRefresh={false}
            >
              <Stack gap={2}>
                {isEmptyNews ? (
                  <ComponentEmpty text="" />
                ) : (
                  <>
                    {data.map((item: TypedNewsForCategory, idx: number) => (
                      <React.Fragment key={idx}>
                        {!helpers.isEmpty(item?.news) && (
                          <Stack gap={2}>
                            <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                              <Typography variant="h6" textTransform={'none'} fontWeight={700}>
                                {item?.news_category_name || ''}
                              </Typography>
                              <Button
                                onClick={() => handleSeeAllNews(item?._id)}
                                sx={{ p: 0.5 }}
                                variant="text"
                              >
                                <Typography textTransform={'none'} fontWeight={600}>
                                  {__('header_see_all')}
                                </Typography>
                              </Button>
                            </Stack>

                            <SwiperSlice
                              listItem={item?.news}
                              renderBodyItem={(data: TypedNews) => <NewsItem newsData={data} />}
                            />
                          </Stack>
                        )}

                        {helpers.isEmpty(item?.news) && paramRequest?._id && (
                          <ComponentEmpty text="" />
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Stack>
            </InfiniteScrollContainer>
          )}
        </Stack>
      </NewsContainer>
    </BigLayout>
  )
}

export default NewsComponent

const NewsContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  '& .swiper-slide': {
    height: 'unset'
  },
  '& .icon_active_filter': {
    position: 'absolute',
    right: -1,
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.primary.main
  }
}))
