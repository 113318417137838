import { Box, Stack, styled, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { IRootState, useAppDispatch, useAppSelector } from 'config/store'
import { paymentStatusObj } from 'entities/orders/data'
import { getOrderdetailOrder } from 'entities/orders/order.store.reducer'
import { Order } from 'entities/orders/order.type'
import __helpers from 'helpers/index'
import __, { ___ } from 'languages/index'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import addressData from '../challenge/media/diachinh.json'

const PrintOrder = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [detailOrder, setDetailOrder] = useState<Order>({})
  const [isPrinted, setIsPrinted] = useState(false)
  const channel = useAppSelector((state: IRootState) => state.user.channel_data)
  useEffect(() => {
    dispatch(
      getOrderdetailOrder({
        _id: id
      })
    )
      .unwrap()
      .then(res => {
        setDetailOrder(res.data)
      })
      .catch((err: AxiosError) => {
        console.log('error', err)
      })
  }, [])

  useEffect(() => {
    if (!channel?.channel_name) return
    if (Object.keys(detailOrder).length && !isPrinted) {
      window.print()
      setIsPrinted(true)
    }
  }, [detailOrder, channel?.channel_name])

  return (
    <PrintContainer>
      {Object.keys(detailOrder).length ? (
        <>
          <Box id="bills" className="bills">
            <Stack className="header_bills">
              <Typography variant="h6">
                {___('order {code}', {
                  code: <>#{detailOrder?.order_pnr || ''}</>
                })}
              </Typography>
              <Typography variant="body1">
                {moment(new Date()).format('YYYY/MM/DD hh:mm:ss')}
              </Typography>
            </Stack>

            <Box className="info_channel">
              <Typography variant="h6">CH: {channel?.channel_name || ''}</Typography>
              <Typography variant="body1">
                {___('phone {phoneNumber}', {
                  phoneNumber: <>{detailOrder?.stock?.hotline || ''}</>
                })}
              </Typography>
            </Box>
            <Box className="list_product">
              <Typography mt={1} variant="subtitle2">
                {__('detail_order')}
              </Typography>
              <Typography mt={2} className="title_products" variant="subtitle2">
                {__('name_product')} | {__('price')} | {__('SL')} | {__('total_price')}
              </Typography>

              <Box mt={1} className="products">
                {detailOrder?.orders_to_product?.length &&
                  detailOrder?.orders_to_product?.map((res, index) => (
                    <Typography key={index}>
                      {index + 1}. {res?.product?.product_name || ''} |{' '}
                      {__helpers.formatNumberWithCommas(res?.product_original_price)} VND |{' '}
                      {res?.item_quantity || '0'} |{' '}
                      {__helpers.formatNumberWithCommas(
                        res?.product_original_price * res?.item_quantity
                      )}{' '}
                      VND{' '}
                    </Typography>
                  ))}
              </Box>
            </Box>
            <Box className="total_bill">
              <Typography variant="subtitle2">
                {___('subTotal {money}', {
                  money: (
                    <>
                      {__helpers.formatNumberWithCommas(detailOrder?.order_total_original_price)}{' '}
                      VND{' '}
                    </>
                  )
                })}
              </Typography>
              <Typography variant="subtitle2">
                {___('total_cost_discount {money}', {
                  money: (
                    <>
                      {__helpers.formatNumberWithCommas(
                        detailOrder?.order_total_original_price - detailOrder?.order_total_price
                      )}{' '}
                      VND
                    </>
                  )
                })}
              </Typography>
              <Typography variant="subtitle2">
                {___('fee {money}', {
                  money: <>{__helpers.formatNumberWithCommas(detailOrder?.order_total_fee)} VND</>
                })}
              </Typography>
              <Typography variant="subtitle2">
                {___('total_bill {money}', {
                  money: (
                    <>
                      {__helpers.formatNumberWithCommas(
                        detailOrder?.order_total_price + detailOrder?.order_total_fee
                      )}{' '}
                      VND
                    </>
                  )
                })}
              </Typography>
            </Box>

            <Box className="user_info">
              <Typography>
                {___('bill_user_name {name}', {
                  name: (
                    <strong>
                      {' '}
                      &nbsp; {detailOrder?.orders_to_address?.order_customer_name || ''}{' '}
                    </strong>
                  )
                })}
              </Typography>
              <Typography>
                {___('bill_user_address {address}', {
                  address: (
                    <strong>
                      {' '}
                      &nbsp;
                      {detailOrder?.orders_to_address?.order_customer_address} &nbsp;
                      {
                        addressData[detailOrder?.orders_to_address?.order_customer_address_city][
                          'quan-huyen'
                        ][detailOrder?.orders_to_address?.order_customer_address_district]?.[
                          'xa-phuong'
                        ][detailOrder?.orders_to_address?.order_customer_address_ward]?.name
                      }{' '}
                      &nbsp;
                      {
                        addressData?.[detailOrder?.orders_to_address?.order_customer_address_city][
                          'quan-huyen'
                        ][detailOrder?.orders_to_address?.order_customer_address_district]?.name
                      }{' '}
                      &nbsp;
                      {
                        addressData[detailOrder?.orders_to_address?.order_customer_address_city]
                          ?.name
                      }
                    </strong>
                  )
                })}
              </Typography>

              <Typography>
                {___('phone {phoneNumber}', {
                  phoneNumber: (
                    <strong>
                      &nbsp; {detailOrder?.orders_to_address?.order_customer_phonenumber || ''}{' '}
                    </strong>
                  )
                })}
              </Typography>
              <Typography>
                {___('bill_order_payment_type {type}', {
                  type: <strong>&nbsp; - COD</strong>
                })}
              </Typography>
              <Typography>
                {___('bill_order_payment_status {status}', {
                  status: <strong>&nbsp; {paymentStatusObj[detailOrder?.payment_status]} </strong>
                })}
              </Typography>
            </Box>
            <Stack className="footer">
              <Stack className="receiver">
                <Typography variant="subtitle1">{__('gift_table_receiver')}</Typography>
                <Typography variant="body1">{__('sign_and_write_full_name')}</Typography>
              </Stack>
              <Stack className="sale_agent">
                <Typography variant="subtitle1">{__('sale_agent')}</Typography>
                <Typography variant="body1">{__('sign_and_write_full_name')}</Typography>
              </Stack>
            </Stack>
          </Box>
        </>
      ) : null}
    </PrintContainer>
  )
}

export default PrintOrder

const PrintContainer = styled(Box)(({ theme }) => ({
  '& .bills': {
    margin: 'auto',
    width: '20%',
    '& .header_bills': {
      alignItems: 'center'
    },
    '& .info_channel': {
      marginTop: theme.spacing(4)
    },

    '& .list_product': {
      borderBottom: '2px solid #000',
      padding: theme.spacing(1, 0)
    },

    '& .total_bill': {
      borderBottom: '2px solid #000',
      padding: theme.spacing(1, 0)
    },

    '& .user_info': {
      borderBottom: '2px solid #000',
      padding: theme.spacing(1, 0)
    },
    '& .footer': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 0, 15, 0),

      '& div': {
        alignItems: 'center'
      }
    }
  },

  '@media print': {
    width: '100%',
    margin: 'auto',
    '.bills': {
      width: '100%',
      height: '100%',
      overflow: 'visible',
      position: 'relative'
    }
  }
}))
