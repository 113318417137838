import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  ListGroup,
  Modal,
  ModalProps
} from 'react-bootstrap'
import useDebounce from 'hooks/useDebounce'
import InfiniteList from 'components/InfiniteList'
import Avatar from 'components/Avatar'
import { User } from '../challenge.interface'
import { getChannelUsers } from '../services'
import { useAddUsersToChallengeMutation } from 'entities/challenge/store/challengeApi'
import LoadingButton from 'components/LoadingButton'
import __ from 'languages/index'
import { useParams } from 'react-router-dom'

type Props = ModalProps & {
  title?: string
  onAddUsers: (ids: string[]) => void
}

const DEFAULT_LIMIT = 10

const InviteUserModal = ({
  onAddUsers,
  title = `${__('challenge_invite_user_date_title')}`,
  ...rest
}: Props) => {
  const { challenge_id } = useParams()

  const [searchValue, setSearchValue] = useState<string>('')
  const [listUser, setListUser] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [selectedUser, setSelectedUser] = useState<string[]>([])

  const debounceSearchValue = useDebounce(searchValue, 500)

  const [, { isLoading: isAddingUsers }] = useAddUsersToChallengeMutation()

  const fetchOptions = useMemo(
    () => ({
      params: {
        challenge_id: challenge_id,
        search: debounceSearchValue.length ? debounceSearchValue : undefined,
        page,
        limit: DEFAULT_LIMIT
      }
    }),
    [page, debounceSearchValue, challenge_id]
  )

  useEffect(() => {
    setPage(1)
  }, [debounceSearchValue])

  useEffect(() => {
    ;(async function () {
      try {
        setIsLoading(true)
        const res = await getChannelUsers(fetchOptions)
        const users: User[] = res.data.map(item => ({
          _id: item?._id,
          display_name: item?.display_name,
          user_avatar: item?.user_avatar
        }))
        if (page === 1) {
          setListUser(users)
        } else {
          setListUser(prev => [...prev, ...users])
        }
        setTotalCount(res.headers['x-total-count'])
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [fetchOptions])
  const searchUserHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value
    setSearchValue(newSearchValue)
  }

  const inviteUserHandler = () => {
    onAddUsers(selectedUser)
    setSelectedUser([])
    closeModalHandler()
  }

  const closeModalHandler = () => {
    setSearchValue('')
    setSelectedUser([])
    rest.onHide()
  }

  const selectUserHandler = (userId: string) => {
    if (selectedUser && selectedUser.includes(userId)) {
      setSelectedUser(prev => prev.filter(id => id !== userId))
    } else setSelectedUser([...selectedUser, userId])
  }

  const renderUserItem = (user: User, ref) => (
    <ListGroup.Item
      key={user?._id}
      ref={ref}
      role="button"
      onClick={() => selectUserHandler(user._id)}
      className="d-flex gap-2"
    >
      <Form.Check
        checked={selectedUser.includes(user._id)}
        value={user._id}
        onChange={e => console.log(e.target.checked)}
      />
      <Avatar src={user.user_avatar} width={32} height={32} />
      <FormLabel>{user.display_name}</FormLabel>
    </ListGroup.Item>
  )

  return (
    <Modal {...rest} onHide={closeModalHandler}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column align-items-center py-4"
        style={{
          maxHeight: '600px',
          overflowY: 'auto'
        }}
      >
        <InputGroup>
          <FormControl
            placeholder={__('challenge_invite_user_date_search')}
            onChange={searchUserHandler}
            value={searchValue}
          />
        </InputGroup>
        <Form className="w-100 mt-2">
          <InfiniteList
            as={ListGroup}
            isLoading={isLoading}
            list={listUser}
            onLoadMore={() => setPage(prev => prev + 1)}
            hasNextPage={listUser.length < totalCount}
            renderItem={renderUserItem}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModalHandler}>
          {__('btn_close')}
        </Button>

        <LoadingButton
          onClick={inviteUserHandler}
          variant="primary"
          isLoading={isAddingUsers}
          loadingProps={{
            className: 'me-2'
          }}
          disabled={!!!selectedUser.length || isAddingUsers}
        >
          {__('challenge_chose_action_btn_add')}
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default InviteUserModal
