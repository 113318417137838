import React, { useState } from 'react'
import { Button, Col, Container, Form, FormControl, FormGroup, Image, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { CreateTicketPayload, CreateTicketSchema } from '../support.validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateNewTicketMutation, useGetCategoriesQuery } from '../store/supportApi'
import FileUploader from 'components/FileUploader'
import TypedMedia from 'interfaces/media.interface'
import { uploadFile } from 'entities/courses/services/courses.media.service'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { useNavigate } from 'react-router-dom'
import LinkToListTicket from '../components/LinkToListTicket'
import __ from 'languages/index'
import BigLayout from '../../../layouts/bigLayout'

const CreateTicket = () => {
  const [medias, setMedias] = useState<TypedMedia[]>([] as TypedMedia[])

  const { data: categories, isLoading } = useGetCategoriesQuery({ channel_id: undefined })
  const [createTicket, { isLoading: creatingTicket }] = useCreateNewTicketMutation()

  const toast = useToast()

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<CreateTicketPayload>({
    resolver: zodResolver(CreateTicketSchema)
  })

  const uploadMedia = async (files: File[]) => {
    if (!files) return
    const formData = new FormData()
    Array.from(files).forEach(file => {
      formData.append('file[]', file)
    })

    try {
      const res = await uploadFile(formData)
      const medias = res.map(media => media.callback) as TypedMedia[]
      setMedias(prev => [...prev, ...medias])
    } catch (err) {
      throw err
    }
  }
  const navigate = useNavigate()

  return (
    <BigLayout>
      <Container className="py-4">
        <LinkToListTicket />
        <h5 className="fs-4">{__('support_create_ticket')}</h5>
        <p className="text-black">{__('support_create_ticket_sub_title')}</p>
        <ol>
          <li>{__('support_create_ticket_describe')}</li>
          <li>{__('support_create_ticket_add_information')}</li>
        </ol>
        <Row
          as={Form}
          gap={2}
          className="border rounded-2 p-3 m-auto"
          onSubmit={handleSubmit(data => {
            createTicket(data)
              .unwrap()
              .then(() => {
                toast.show({
                  type: EnumTypeToast.Success,
                  content: `${__('support_create_ticket_success')}`
                })
              })
              .then(() => {
                navigate('/support', { replace: true })
              })
              .catch(err => {
                toast.show({
                  type: EnumTypeToast.Error,
                  content: err?.data?.message
                })
              })
          })}
        >
          <Form.Text className="fs-5 fw-semibold mb-3">{__('support_create_one_ticket')}</Form.Text>
          <Controller
            control={control}
            name="post_category"
            render={({ field }) => (
              <Form.Select as={Col} className="p-2 mb-3" {...field}>
                <option value="">{__('support_chose_item')}</option>
                {categories?.map(category => (
                  <option key={category?._id} value={category?._id}>
                    {category?.category_content}
                  </option>
                ))}
              </Form.Select>
            )}
          />

          {Boolean(watch('post_category')) && (
            <>
              <FormGroup as={Col} className="p-0 mb-3">
                <FormControl
                  type={'text'}
                  placeholder={__('support_title')}
                  {...register('post_title')}
                />
                {errors.post_title && (
                  <p className="text-danger m-0">{errors.post_title.message}</p>
                )}
              </FormGroup>
              <FormGroup as={Col} xs={12} className="p-0 mb-3">
                <FormControl
                  {...register('post_content')}
                  as={'textarea'}
                  placeholder={__('support_describe')}
                  style={{
                    height: '100px'
                  }}
                />
                {errors.post_content && (
                  <p className="text-danger m-0">{errors.post_content.message}</p>
                )}
              </FormGroup>
              <FormGroup className="p-0 mb-3">
                <FileUploader
                  onUpload={uploadMedia}
                  acceptFile="image/*"
                  notice={__('support_upload_media')}
                >
                  {() =>
                    medias.map(media => <Image src={media?.media_url} width={60} height="auto" />)
                  }
                </FileUploader>
              </FormGroup>
            </>
          )}

          <Col xs={12} className="p-0" md={3}>
            <Button
              className="w-100"
              type={'submit'}
              disabled={!watch('post_category') || isSubmitting || creatingTicket}
            >
              {__('support_send')}
            </Button>
          </Col>
        </Row>
      </Container>
    </BigLayout>
  )
}

export default CreateTicket
