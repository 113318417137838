import React from 'react'
import Avatar from 'components/Avatar'
import { Card, CardBody, CardText, CardTitle, Image, Stack } from 'react-bootstrap'
import TypedUser from 'interfaces/user.interface'
import { PostStatus } from '../ticket.type'
import { formatDate } from './TicketItem'
import NavigateUser from 'entities/challenge/component/NavigateUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from 'components/IconButton'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import MediaView from 'components/MediaView'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import __ from 'languages/index'
import helpers from 'helpers'

type Props = {
  title: string
  description: string
  status: PostStatus
  createdAt: string
  fromUser: Pick<TypedUser, '_id' | 'display_name' | 'user_avatar'>
  attachData?: any
}

const AttachDataItem = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <Stack>
      <label
        htmlFor="name"
        className={'text-muted fw-semibold'}
        style={{
          color: '#ccc'
        }}
      >
        {label}
      </label>
      {children}
    </Stack>
  )
}

const SupportTicket = ({ title, description, status, fromUser, createdAt, attachData }: Props) => {
  const toast = useToast()

  const copySuccess = () => {
    toast.show({
      type: EnumTypeToast.Success,
      content: `${__('support_copy_success')}`
    })
  }

  const renderAttachInfo = () => {
    const textClasses = 'fw-semibold m-0'
    const contentParsed = helpers.isJson(attachData?.content) ? JSON.parse(attachData?.content) : {}
    return (
      <Stack
        gap={2}
        className="p-3 rounded-2"
        style={{
          backgroundColor: '#f7f7f7'
        }}
      >
        <AttachDataItem label={__('support_attach_name')}>
          <p className={textClasses}>{attachData?.full_name}</p>
        </AttachDataItem>
        <AttachDataItem label={__('support_attach_edit_time')}>
          <p className={textClasses}>{formatDate(attachData?.updatedAt)}</p>
        </AttachDataItem>

        <div>
          <label
            className={'text-muted fw-semibold  mb-2'}
            style={{
              color: '#ccc'
            }}
          >
            <FontAwesomeIcon icon={faPaperclip} className="me-2" />
            Attachment
          </label>
          <Stack direction="horizontal" gap={5}>
            <MediaView type="image" mediaUrl={contentParsed?.image_logo || ''}>
              <Stack direction="horizontal" className="d-flex align-items-start">
                <Image width={50} src={contentParsed?.image_logo || ''} />
                <span
                  className="fw-bold ms-2"
                  style={{
                    fontSize: '14px'
                  }}
                >
                  Logo
                </span>
              </Stack>
            </MediaView>

            <MediaView type="image" mediaUrl={contentParsed?.image_upload || ''}>
              <Stack direction="horizontal" className="d-flex align-items-start">
                <Image width={50} src={contentParsed?.image_upload || ''} />
                <span
                  className="fw-bold ms-2"
                  style={{
                    fontSize: '14px'
                  }}
                >
                  {__('support_cover_photo')}
                </span>
              </Stack>
            </MediaView>
          </Stack>
        </div>
        <AttachDataItem label={__('support_attach_url_home')}>
          <Stack
            direction="horizontal"
            className="border rounded-2 p-1 d-flex justify-content-between"
          >
            <a href={contentParsed?.url_home || ''} target="_blank" rel="noopener noreferrer">
              {contentParsed?.url_home || ''}
            </a>
            <CopyToClipboard text={contentParsed?.url_home || ''} onCopy={copySuccess}>
              <IconButton variant="none" icon={faCopy} role="button" />
            </CopyToClipboard>
          </Stack>
        </AttachDataItem>
        <AttachDataItem label={__('support_attach_url_policy')}>
          <Stack
            direction="horizontal"
            className="border rounded-2 p-1 d-flex justify-content-between"
          >
            <a href={contentParsed?.url_policy || ''} target="_blank" rel="noopener noreferrer">
              {contentParsed?.url_policy || ''}
            </a>
            <CopyToClipboard text={contentParsed?.url_policy || ''} onCopy={copySuccess}>
              <IconButton variant="none" icon={faCopy} role="button" />
            </CopyToClipboard>
          </Stack>
        </AttachDataItem>

        <AttachDataItem label={__('support_attach_url_terms')}>
          <Stack
            direction="horizontal"
            className="border rounded-2 p-1 d-flex justify-content-between"
          >
            <a href={contentParsed?.url_policy || ''} target="_blank" rel="noopener noreferrer">
              {contentParsed?.url_policy || ''}
            </a>
            <CopyToClipboard text={contentParsed?.url_policy || ''} onCopy={copySuccess}>
              <IconButton variant="none" icon={faCopy} role="button" />
            </CopyToClipboard>
          </Stack>
        </AttachDataItem>
      </Stack>
    )
  }

  return (
    <div>
      <div className="d-flex justify-content-start align-items-start">
        <NavigateUser _id={fromUser?._id}>
          <Avatar src={fromUser?.user_avatar} roundedCircle width={35} height={35} />
        </NavigateUser>
        <Card className="ms-2 flex-grow-1">
          <CardBody>
            <div className="d-flex justify-content-end align-items-center">
              <Stack
                direction="vertical"
                className="d-flex justify-content-start align-items-start"
              >
                <NavigateUser _id={fromUser?._id}>
                  <CardTitle>{fromUser?.display_name}</CardTitle>
                </NavigateUser>
                <span
                  className=""
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {formatDate(createdAt, 'DD/MM/YYYY')}
                </span>
              </Stack>
            </div>
            <CardText
              style={{
                whiteSpace: 'pre-line'
              }}
            >
              {description}
            </CardText>
            {Boolean(attachData) && renderAttachInfo()}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default SupportTicket
