import { Stack } from '@mui/material'
import ChannelCensorship from 'entities/channel-settings/Capability/ChannelCensorship'
import VerifyUser from 'entities/channel-settings/Capability/VerifyUser'
import ChannelMission from 'entities/channel-settings/Capability/ChannelMission'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelSetting } from 'entities/channel-settings/store/channel.slice'
import TypedChannel, { TypeTreasureConfig } from '../../../interfaces/channel.interface'
import { formatString } from 'entities/support/utils'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import React, { useState } from 'react'
import CourseCommission from 'entities/channel-settings/Capability/CourseCommission'


export interface TypeChannelConfig{
  channelSetting: TypedChannel,
  handleUpdateChannelInfo:(updatedChannel: Partial<TypedChannel>, title: string) => void
  editCommission?:TypeTreasureConfig
  setEditCommission?: React.Dispatch<React.SetStateAction<TypeTreasureConfig>>

}
const CapabilityChannel = ()=>{
  const channelSetting = useAppSelector(channelInfo)
  const [channelData,setChannelData] = useState<TypedChannel>(channelSetting)
  const [editCommission, setEditCommission] = useState<TypeTreasureConfig>(null)

  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleUpdateChannelInfo = (updatedChannel: Partial<TypedChannel>, title: string) => {
    dispatch( updateChannelSetting(updatedChannel))
      .unwrap()
      .then(res => {
        setChannelData(res?.data)
        setEditCommission(null)
        toast.show({
          content: formatString(__('channel_setting_success'), title),
          type: EnumTypeToast.Success
        })
      })
      .catch(err => {
        console.log('updateChannelSetting', err)
        toast.show({
          content: `${err}}`,
          type: EnumTypeToast.Error
        })
      })
  }
  return(
    <Stack rowGap={2}>
      <ChannelCensorship channelSetting={channelData} handleUpdateChannelInfo={handleUpdateChannelInfo}/>
      <VerifyUser channelSetting={channelData} handleUpdateChannelInfo={handleUpdateChannelInfo}/>
      <CourseCommission channelSetting={channelData} handleUpdateChannelInfo={handleUpdateChannelInfo}/>
      <ChannelMission
        channelSetting={channelData}
        handleUpdateChannelInfo={handleUpdateChannelInfo}
        editCommission={editCommission}
        setEditCommission={setEditCommission}/>
    </Stack>
  )
}
export default CapabilityChannel
