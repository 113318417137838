import { SvgIcon, SvgIconProps } from '@mui/material'

export default function NewsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="30"
        viewBox="0 0 36 30"
        fill="currentColor"
      >
        <path
          d="M3.61914 4.41333H35.2738V23.8761C35.2738 24.6958 34.9499 25.482 34.3734 26.0616C33.7969 26.6413 33.015 26.9669 32.1997 26.9669H3.61914V4.41333Z"
          fill="#EBE2CE"
        />
        <path
          d="M35.2712 23.4474V24.457C35.2712 25.7212 34.7718 26.9335 33.8828 27.8274C32.9937 28.7213 31.788 29.2235 30.5307 29.2235H6.99364C6.17085 29.2237 5.35607 29.061 4.59584 28.7445C3.83561 28.4281 3.14483 27.9642 2.56294 27.3793C1.98106 26.7944 1.51947 26.0999 1.20455 25.3356C0.889628 24.5713 0.727539 23.7522 0.727539 22.9249V0.318115H29.4138V23.3446C29.4126 23.7711 29.4989 24.1932 29.6675 24.5845C29.9252 25.2124 30.3919 25.7306 30.9877 26.0505C31.5836 26.3704 32.2715 26.4721 32.9337 26.3381C33.5959 26.2042 34.1912 25.8429 34.6176 25.3162C35.044 24.7895 35.2751 24.1301 35.2712 23.4509V23.4474Z"
          fill="#FFF5E8"
        />
        <path
          d="M11.0266 4.99048V7.93787H10.2127L9.09935 6.59879V7.93787H8.13574V4.99048H8.94961L10.063 6.32956V4.99048H11.0266Z"
          fill="#F36362"
        />
        <path
          d="M13.9391 7.1904V7.93964H11.501V4.99048H13.8845V5.73973H12.4752V6.08512H13.7083V6.79363H12.4752V7.18154L13.9391 7.1904Z"
          fill="#F36362"
        />
        <path
          d="M19.0479 4.99048L18.1089 7.93787H17.0519L16.5781 6.36321L16.0795 7.93787H15.0225L14.0801 4.99048H15.0983L15.6092 6.6661L16.15 4.99048H17.059L17.5699 6.68736L18.1089 4.99048H19.0479Z"
          fill="#F36362"
        />
        <path
          d="M19.0801 7.70434L19.3989 6.97989C19.6934 7.15063 20.0262 7.24327 20.3661 7.24913C20.6462 7.24913 20.7466 7.19067 20.7466 7.08971C20.7466 6.71952 19.1259 7.01886 19.1259 5.9189C19.1259 5.37158 19.5839 4.92522 20.4911 4.92522C20.8753 4.91797 21.2553 5.00619 21.5974 5.18205L21.2997 5.90296C21.0489 5.76615 20.7694 5.69089 20.4841 5.68332C20.1952 5.68332 20.1018 5.76834 20.1018 5.86931C20.1018 6.22356 21.7225 5.92776 21.7225 7.01355C21.7225 7.55378 21.2663 8.00723 20.3573 8.00723C19.913 8.01585 19.4737 7.91168 19.0801 7.70434Z"
          fill="#F36362"
        />
        <path d="M25.4215 5.99829H22.6064V6.23033H25.4215V5.99829Z" fill="#00A2DD" />
        <path d="M26.7322 6.7356H22.6064V6.96763H26.7322V6.7356Z" fill="#00A2DD" />
        <path d="M7.06996 5.99829H4.25488V6.23033H7.06996V5.99829Z" fill="#00A2DD" />
        <path d="M7.06811 6.7356H2.94238V6.96763H7.06811V6.7356Z" fill="#00A2DD" />
        <path d="M26.5466 8.4021H3.12402V8.63414H26.5466V8.4021Z" fill="#00A2DD" />
        <path d="M12.0732 12.0881H3.08887V23.4473H12.0732V12.0881Z" fill="#FCB13A" />
        <path d="M19.4374 12.0881H12.8066V12.747H19.4374V12.0881Z" fill="#8EE4FD" />
        <path d="M19.4374 13.6008H12.8066V14.2597H19.4374V13.6008Z" fill="#8EE4FD" />
        <path d="M19.4374 15.1133H12.8066V15.7722H19.4374V15.1133Z" fill="#8EE4FD" />
        <path d="M19.4374 16.6243H12.8066V17.2832H19.4374V16.6243Z" fill="#8EE4FD" />
        <path d="M19.4374 18.137H12.8066V18.7959H19.4374V18.137Z" fill="#8EE4FD" />
        <path d="M19.4374 19.6479H12.8066V20.3069H19.4374V19.6479Z" fill="#8EE4FD" />
        <path d="M19.4374 21.1604H12.8066V21.8193H19.4374V21.1604Z" fill="#8EE4FD" />
        <path d="M19.4374 22.6714H12.8066V23.3303H19.4374V22.6714Z" fill="#8EE4FD" />
        <path d="M26.5829 16.6243H20.4912V17.2832H26.5829V16.6243Z" fill="#8EE4FD" />
        <path d="M26.5829 18.137H20.4912V18.7959H26.5829V18.137Z" fill="#8EE4FD" />
        <path d="M26.5829 19.6479H20.4912V20.3069H26.5829V19.6479Z" fill="#8EE4FD" />
        <path d="M26.5829 21.1604H20.4912V21.8193H26.5829V21.1604Z" fill="#8EE4FD" />
        <path d="M26.5829 22.6714H20.4912V23.3303H26.5829V22.6714Z" fill="#8EE4FD" />
        <path d="M26.5445 12.0881H20.5303V14.5697H26.5445V12.0881Z" fill="#00A2DD" />
        <path
          d="M33.5353 22.7655C33.5253 23.1913 33.3738 23.6014 33.105 23.9305C32.8361 24.2595 32.4654 24.4886 32.052 24.5811H29.6756C29.507 24.1898 29.4206 23.7676 29.4219 23.3412V1.83618H30.6938L30.6251 2.78027H33.5423C33.5423 2.78027 33.6392 21.1962 33.5353 22.7655Z"
          fill="#D6CFBB"
        />
        <path
          d="M32.0502 24.6165C31.6152 24.6156 31.1983 24.4413 30.8909 24.1318C30.5835 23.8224 30.4106 23.4031 30.4102 22.9657V1.83618H30.8259V22.9657C30.8051 23.1397 30.8212 23.3163 30.8733 23.4836C30.9253 23.6509 31.012 23.8052 31.1277 23.9364C31.2434 24.0675 31.3855 24.1725 31.5445 24.2444C31.7035 24.3163 31.8759 24.3535 32.0502 24.3535C32.2246 24.3535 32.397 24.3163 32.556 24.2444C32.715 24.1725 32.857 24.0675 32.9727 23.9364C33.0884 23.8052 33.1752 23.6509 33.2272 23.4836C33.2792 23.3163 33.2954 23.1397 33.2746 22.9657V2.77318H33.6921V22.9657C33.6916 23.4034 33.5185 23.823 33.2107 24.1325C32.9029 24.442 32.4855 24.616 32.0502 24.6165Z"
          fill="#FFF5E8"
        />
      </svg>
    </SvgIcon>
  )
}
