import { Box, InputBase, Paper, styled } from '@mui/material'
import { SearchIcon } from 'icons'
import __ from 'languages/index'
import debounce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'

interface TypedProps {
  value?: string
  placeholder: string
  onChange: (value: string) => void
}

const SearchComponent = ({ value, onChange, placeholder }: Partial<TypedProps>) => {
  const [textSearch, setTextSearch] = useState<string>('')

  const reduceRequestSearch = useMemo(() => {
    return debounce(_value => {
      typeof onChange === 'function' && onChange(_value)
    }, 500)
  }, [])

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value || '')
    reduceRequestSearch(e.target.value || '')
  }

  const handleEnter = (value: string) => {
    setTextSearch(value || '')
    typeof onChange === 'function' && onChange(value)
  }

  useEffect(() => {
    setTextSearch(value)
  }, [value])

  return (
    <SearchContainer>
      <Paper
        component="form"
        onKeyPress={e => {
          if (e?.which === 13 || e?.keyCode === 13) {
            e.preventDefault()
            handleEnter(textSearch)
          }
        }}
        className="search_wrapper"
      >
        <SearchIcon
          sx={{
            width: theme => theme.spacing(3),
            height: theme => theme.spacing(3),
            color: theme => theme.palette.text.secondary
          }}
        />
        <InputBase
          value={textSearch || ''}
          onChange={handleChangeValue}
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder || __('header_search')}
        />
      </Paper>
    </SearchContainer>
  )
}

export default SearchComponent

const SearchContainer = styled(Box)(({ theme }) => ({
  '& .search_wrapper': {
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.divider}`,
    caretColor: theme.palette.primary.main
  }
}))
