import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import '../media/gift.receiver.add.modal.scss'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useAppDispatch, useAppSelector } from 'config/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp91, faMedal } from '@fortawesome/free-solid-svg-icons'
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { getListMembers } from 'entities/gift/services/gift.receiver.service'
import { TypedDataMember } from '../../../interfaces/member.interface'
import { getLevels } from 'entities/gift/gift.store.reducer'
import { TypedChannelLevel } from 'entities/gift/gift.interface'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { Box } from '@mui/material'

interface TypedGiftReceiverAddModalProps {
  addReceiver: (receiver: TypedDataMember) => void
  listReceiverIdsAdded: string[]
}

const GiftReceiverAddModal = forwardRef(
  ({ addReceiver, listReceiverIdsAdded }: TypedGiftReceiverAddModalProps, ref) => {
    const channel_data = useAppSelector(state => state.user.channel_data)
    const [show, setShow] = useState(false)
    const levels = useAppSelector(state => state.gift.levels)
    const toast = useToast()
    const dispatch = useAppDispatch()
    const {
      data,
      refresh,
      isRefreshing,
      hasMore,
      paramRequest,
      setParamRequest,
      loadMore,
      updateParamRequest,
      updateParamRequestDebounce
    } = useInfiniteScroll<TypedDataMember>(
      {
        limit: 20,
        order_by: 'DESC',
        search: ''
      },
      getListMembers
    )

    useImperativeHandle(ref, () => ({
      show: () => {
        setShow(true)
      }
    }))

    useEffect(() => {
      dispatch(getLevels({}))
    }, [channel_data])
    const onAddReceiver = useCallback((receiver: TypedDataMember) => {
      addReceiver(receiver)

      toast.show({
        title: `${__('notification')}`,
        type: EnumTypeToast.Success,
        content: `${__('gift_add')}` + receiver?.display_name
      })
    }, [])

    const renderItemUser = useCallback(
      (item: TypedDataMember) => {
        if (listReceiverIdsAdded.includes(item?._id)) {
          return null
        }

        return (
          <div key={item?._id} className="giftreceiveraddmodal_itemcontainer">
            <div className="giftreceiveraddmodal_item">
              <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' }}>
                <img
                  src={item?.user_avatar_thumbnail || item?.user_avatar}
                  className="giftreceiveraddmodal_imgavatar"
                />

                <div className="giftreceiveraddmodal_info">
                  <div className="giftreceiveraddmodal_name">{item?.display_name}</div>
                  <div className="d-flex align-items-center giftreceiveraddmodal_container_user_txtlvl">
                    <FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />
                    <div style={{ marginRight: 25 }}>
                      {item?.point} {__('gift_condition_point')}
                    </div>
                    <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
                    {__('gift_condition_level')} {item?.level_number}
                  </div>
                </div>
              </Box>

              <button
                onClick={() => onAddReceiver(item)}
                className="btn giftreceiveraddmodal_btn_add"
              >
                {__('gift_given')}
              </button>
            </div>
          </div>
        )
      },
      [data, listReceiverIdsAdded]
    )

    const onChangeSearch = useCallback((event: any) => {
      updateParamRequestDebounce({ search: event?.target?.value })
    }, [])

    const onHide = useCallback(() => {
      setShow(false)
      setParamRequest({
        limit: 20,
        order_by: 'DESC',
        search: ''
      })
    }, [])

    const onKeyDown = useCallback(event => {
      if (event.key.toLowerCase() === 'enter') {
        event.preventDefault()
      }
    }, [])

    const handleOnSubmitFilterForm = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
      updateParamRequest({
        level_number: `${event?.target?.value}`
      })
    }, [])

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="giftreceiveraddmodal"
        centered
      >
        <Modal.Body className={'giftreceiveraddmodal_body'}>
          <div className="giftreceiveraddmodal_container">
            <div className="d-flex align-items-center w-100 justify-content-between mb-3">
              <Form className="w-50">
                <Form.Group className="">
                  <Form.Control
                    type="text"
                    placeholder={__('member_search')}
                    onChange={onChangeSearch}
                    onKeyDown={onKeyDown}
                  />
                </Form.Group>
              </Form>
              <div>
                <Form.Select
                  aria-label=""
                  onChange={handleOnSubmitFilterForm}
                  defaultValue={paramRequest?.level_number ? paramRequest?.level_number : ''}
                  style={{ cursor: 'pointer' }}
                >
                  <option key={'-1'} value="">
                    {__('gift_condition_level')}
                  </option>
                  {levels?.map((item: TypedChannelLevel, index: number) => (
                    <option key={index.toString()} value={item?.level_number}>
                      {__('gift_condition_level')} {item?.level_number}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <button
                onClick={() => setShow(false)}
                className="btn giftreceiveraddmodal_btn_close d-flex align-items-center"
              >
                {__('btn_close')}
              </button>
            </div>

            <div className="overflow-y-scroll gift_scroll_bar" id="scrollableDiv">
              <InfiniteScrollContainer
                isRefreshing={isRefreshing}
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                refreshFunction={refresh}
                scrollableTarget={'scrollableDiv'}
                textEmptyMessage={__('gift_search_member_empty')}
              >
                <div className="row ms-0 me-0" id="course_wrap">
                  {data?.map(renderItemUser)}
                </div>
              </InfiniteScrollContainer>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
)

export default GiftReceiverAddModal
