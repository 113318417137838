import React from 'react'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SxProps
} from '@mui/material'
import { channelSettingsSidebarConfigs } from '../channelSettingsRoutes'
import { CurrentTheme } from 'styles/themes'
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom'

interface ListItemLinkProps {
  icon?: React.ReactElement
  primary: string
  to: string
  selected?: boolean
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />
})

const ListItemLink = ({ icon, primary, to, selected }: ListItemLinkProps) => {
  return (
    <ListItemButton sx={listItemButtonStyled} selected={selected}>
      <ListItem component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </ListItemButton>
  )
}

const ChannelSettingSidebar = () => {
  const location = useLocation()
  const { pathname } = location
  const checkActive = (path: string) => {
    const pathNameArray = pathname.split('/')
    return pathNameArray.includes(path)
  }

  return (
    <Paper>
      <List
        dense
        sx={{
          paddingX: 1
        }}
      >
        {channelSettingsSidebarConfigs.map(route => (
          <ListItemLink
            selected={checkActive(route.link)}
            key={route.label}
            to={route.link}
            primary={route.label}
            icon={<route.icon />}
          />
        ))}
      </List>
    </Paper>
  )
}

export default ChannelSettingSidebar

const listItemButtonStyled: SxProps<CurrentTheme> = {
  borderRadius: theme => theme.spacing(1),
  '& > a': {
    textDecoration: 'none',
    color: 'inherit'
  }
}
