import DateTime from 'date-and-time'
import { TypeOfflineTime } from 'entities/channel-settings/service/service.type'
import dayjs from 'dayjs'
import __helpers from 'helpers/index'

export const formatDate = (time: string, format: string = 'HH:mm:ss DD/MM/YYYY') => {
  return DateTime.format(new Date(time), format)
}
export const calculateTimeAgo = (time: string) => {
  const currentTime = Date.now()
  const timeAgo = currentTime - new Date(time).getTime()

  const seconds = Math.floor(timeAgo / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  if (days > 0) {
    if (days > 6) return formatDate(time, 'DD/MM/YYYY')
    return `${days} ngày trước`
  }
  if (hours > 0) {
    return `${hours} giờ trước`
  }
  if (minutes > 0) {
    return `${minutes} phút trước`
  }
  if (seconds > 0) {
    return `${seconds} giây trước`
  }
  return 'Vừa xong'
}

export  const convertMonthYearTime = (item: TypeOfflineTime[] )=>{
  if(__helpers.isEmpty(item)) return
  let dataConvert = []
  let monthNames = [
    "Tháng 1", "Tháng 2", "Tháng 3",
    "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7",
    "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"
  ];
  const convertItem = item.map(value=>{
    return {
      ...value,
      time: new Date(value?.time)
    };
  })
  const data = [...convertItem].map((value)=>{
    if (!isNaN(value?.time.getTime())) {
      const month = monthNames[value?.time.getMonth()];
      const year = value?.time.getFullYear()
      return {
        ...value,
        time: `${month}, ${year}`
      }
    }
  })
  dataConvert.push(...data)
  return data
}
