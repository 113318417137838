import { Button, Stack } from '@mui/material'
import __ from 'languages/index'
import React from 'react'
import TextField from 'components/TextFieldMUI'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { validateLabel } from 'entities/support/utils'
import __helpers from 'helpers/index'
import { CreateConfigLevelPayload, TypeDataLevel } from 'entities/channel-settings/service/service.type'

type Props = {
  level?: TypeDataLevel
  initialLevelPoint?: number
  handleSubmitForm?: (value: CreateConfigLevelPayload) => void
  setConfigLevel: React.Dispatch<React.SetStateAction<TypeDataLevel[]>>
}
const AddLevelForm = ({ level, initialLevelPoint,handleSubmitForm }: Props) => {
  const { fields , submit, dirty, submitting }  = useForm({
    fields: {
      title: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_level_name')}`)?.required)]
      }),
      level_number: useField<string>({
        value:  `${initialLevelPoint + 1}` ?? '1',
        validates: [notEmpty(validateLabel(`${__('channel_setting_level')}`)?.required)]
      }),
      level_point: useField<string>({
        value: '0',
        validates: [
          notEmpty(validateLabel(`${__('channel_setting_level_point')}`)?.required),
          inputVal => {
            if (!__helpers.isNumber(inputVal)) {
              return `${__('channel_setting_level_point_validate')}`
            }
            if (inputVal < level?.level_point) {
              return `${__('channel_setting_level_point_min')}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        handleSubmitForm(values)
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  return (
    <Stack component="form" gap={2} onSubmit={(e)=>{
      e.preventDefault()
      submit()
    }}>
      <TextField
        {...fields.level_number}
        min={0}
        placeholder={__('channel_setting_level')}
        label={__('channel_setting_level')}
        disabled={true}
      />
      <TextField
        {...fields.title}
        label={__('channel_setting_level_name')}
        placeholder={__('channel_setting_level_name')}
      />
      <TextField
        {...fields.level_point}
        min={0}
        placeholder={__('channel_setting_level_point')}
        label={__('channel_setting_level_point')}
      />
      <Button
        sx={{
          ml: 'auto'
        }}
        type="submit"
        disabled={!dirty || submitting}
      >
        {__('btn_save')}
      </Button>
    </Stack>
  )
}

export default AddLevelForm
