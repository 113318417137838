//Library
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import {
  Avatar,
  Box,
  Button,
  Chip,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  IconButton,
  Drawer
} from '@mui/material'

//Image
import avatarDefault from 'media/images/avatar_default.png'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { getListMemberRanking } from '../store/leaderBoard.store.reducer'

//Hooks
import { useInfinite } from 'hooks/useInfinite'

//Interface
import { TypedMemberRanking } from '../interface'

//Helpers
import helpers from 'helpers'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { CrownTopIcon, StarBadgeIcon } from 'icons'
import InfomationIcon from 'icons/InfomationIcon'
import { backgroundLeaderBoard } from '../data'

const dataTab = [
  {
    id: 0,
    label: `${__('leader_board_week')}`
  },
  {
    id: 1,
    label: `${__('leader_board_month')}`
  },
  {
    id: 2,
    label: `${__('all')}`
  }
]

const RatingLeaderBoard = ({ setToggle }) => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [listMembers, setListMembers] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEmptyData, setIsEmptyData] = useState<boolean>(false)
  const toast = useToast()
  const navigate = useNavigate()

  const { ref } = useInfinite(!isEmptyData, () => {
    setPage(page => page + 1)
  })

  const handleChangeTab = useCallback(
    (index: number) => {
      if (tabIndex === index) return
      setTabIndex(index)
      setListMembers([])
      setPage(1)
      setIsEmptyData(false)
    },
    [tabIndex]
  )

  const params = useMemo(() => {
    let orderType: string = ''
    switch (tabIndex) {
      case 0:
        orderType = 'point_week'
        break
      case 1:
        orderType = 'point_month'
        break
      case 2:
        orderType = 'point'
        break

      default:
        orderType = 'point_week'
        break
    }
    return {
      page: page,
      order_by: 'DESC',
      order_type: orderType,
      channel_role: 'user',
      limit: 20
    }
  }, [page, tabIndex])

  const getPointByTime = useCallback(
    (item: TypedMemberRanking) => {
      switch (tabIndex) {
        case 0:
          return item?.point_week
        case 1:
          return item?.point_month
        default:
          return item?.point
      }
    },
    [tabIndex]
  )

  const topMembers = useMemo(() => {
    const arrData = new Array(1, 2, 3)
    if (helpers.isEmpty(listMembers)) return arrData
    if (listMembers?.length < 3) {
      return [...listMembers, ...arrData.slice(listMembers?.length)]
    }
    return listMembers?.slice(0, 3)
  }, [listMembers])

  const handleViewTopMember = useCallback(
    (data, top: number) => {
      return (
        <>
          <Box
            display={'grid'}
            className="box_top_rank"
            justifyItems={'center'}
            position={'relative'}
            height={'auto'}
            paddingBottom={theme => theme.spacing(top === 1 ? 2.5 : 5)}
            marginTop={theme => theme.spacing(-8.75)}
            onClick={() => navigate(`/user/detail/${data?.user_id?._id}`)}
          >
            <Avatar
              className={top === 1 ? 'top1' : 'avt_user'}
              src={
                data?.user_id?.user_avatar || data?.user_id?.user_avatar_thumbnail || avatarDefault
              }
              sx={{
                width: theme => theme.spacing(top === 1 ? 17.5 : 15),
                border: `5px solid  #F90`,
                height: theme => theme.spacing(top === 1 ? 17.5 : 15)
              }}
              alt="avatar"
            />

            {top === 1 && (
              <Box
                sx={{
                  width: theme => theme.spacing(10.75),
                  height: theme => theme.spacing(8.875),
                  position: 'absolute',
                  left: theme => theme.spacing(-5),
                  top: theme => theme.spacing(-2.5)
                }}
                className="icon_crown"
              >
                <CrownTopIcon sx={{ width: '100%', height: '100%' }} />
              </Box>
            )}
            <Box
              className={`icon_rank ${top === 1 && 'icon_rank_top1'}`}
              sx={{
                width: theme => theme.spacing(5),
                height: theme => theme.spacing(5),
                position: 'absolute',
                left: '50%',
                top: theme => theme.spacing(top === 1 ? 14.125 : 11.625),
                transform: 'translate(-50%, 0)'
              }}
            >
              <Box className="level">
                <Typography
                  variant="h6"
                  sx={{ transform: 'rotate(-45deg)' }}
                  color={'background.paper'}
                >
                  {top || '1'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography
            className="truncate"
            variant="subtitle1"
            fontWeight={600}
            color={'background.paper'}
          >
            {data?.user_id?.display_name || '--'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: theme => theme.spacing(1),
              alignItems: 'center',
              borderRadius: theme => theme.spacing(1.5),
              background: theme => theme.palette.primary.main,
              padding: theme => theme.spacing(1.125, 2, 1, 2),
              height: 'auto'
            }}
            className="box_point"
          >
            <StarBadgeIcon />
            <Typography
              fontWeight={600}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              color="background.paper"
            >
              {helpers.convertNumber(getPointByTime(data) || '0')}
            </Typography>
          </Box>
        </>
      )
    },
    [tabIndex]
  )

  const handleViewMember = useCallback(
    (data, idx: number) => {
      return (
        <Button variant="text" sx={{ p: 0, width: '100%' }}>
          <Link
            to={`/user/detail/${data?.user_id?._id}`}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <Paper className="item_user">
              <Typography fontWeight={600}>{idx + 1}</Typography>
              <Avatar
                src={
                  data?.user_id?.user_avatar ||
                  data?.user_id?.user_avatar_thumbnail ||
                  avatarDefault
                }
                alt="avatar"
              />

              <Typography
                variant="subtitle2"
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                textAlign={'start'}
                fontWeight={600}
              >
                {data?.user_id?.display_name || '--'}
              </Typography>
              <Chip
                className="chip"
                avatar={<StarBadgeIcon />}
                label={`${helpers.convertNumber(getPointByTime(data) || '0')}`}
                variant="outlined"
              />
            </Paper>
          </Link>
        </Button>
      )
    },
    [tabIndex]
  )

  useEffect(() => {
    params.page < 2 && setIsLoading(true)
    dispatch(getListMemberRanking(params))
      .unwrap()
      .then((res: AxiosResponse<TypedMemberRanking[]>) => {
        setIsLoading(false)
        if (helpers.isEmpty(res?.data)) {
          return setIsEmptyData(true)
        }
        setIsEmptyData(false)
        setListMembers(listMembers => [...listMembers, ...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log(`getListMember_${error}`)
        setIsEmptyData(false)
        setListMembers([])
        setIsLoading(false)
        toast.show({
          content: `${__('leader_board_error')}`,
          type: EnumTypeToast.Error
        })
      })
  }, [params])

  return (
    <RatingLeaderBoardContainer className="image_background">
      <Box className="background">
        <Box
          component={'img'}
          src={`data:image/svg+xml,${encodeURIComponent(backgroundLeaderBoard({ primaryColor: channel_data?.channel_primary_color }))}`}
          className="img"
        />
      </Box>

      <Box
        p={theme => theme.spacing(3, 3, 0, 3)}
        display={'grid'}
        justifyItems={'center'}
        position={'relative'}
        rowGap={theme => theme.spacing(3)}
      >
        <Typography textTransform={'none'} component={'h5'} color="background.paper" variant="h6">
          {__('leader_board_title')}
        </Typography>
        <IconButton onClick={() => setToggle(true)} className="icon_infomation">
          <InfomationIcon fill="#fff" />
        </IconButton>
        <Paper className="tabs">
          {dataTab?.map((item, idx) => (
            <Button
              key={idx}
              variant="text"
              onClick={() => handleChangeTab(idx)}
              className={tabIndex === idx ? 'active' : ''}
            >
              <Typography textTransform={'none'} variant="body2" color="background.paper">
                {__(item?.label)}
              </Typography>
            </Button>
          ))}
        </Paper>
      </Box>

      <Box
        display={'grid'}
        className="box_rank_container"
        alignItems={'flex-end'}
        padding={theme => theme.spacing(0, 4.5)}
        height={theme => theme.spacing(56.25)}
      >
        <Box className="top_user" height={theme => theme.spacing(30.625)}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={theme => theme.spacing(1.25)}
            alignItems={'center'}
          >
            {topMembers?.length > 1 && handleViewTopMember(topMembers[1], 2)}
          </Box>
          <Box position={'relative'}>
            <Paper className="top_user_wrapper">
              {topMembers?.length > 0 && handleViewTopMember(topMembers[0], 1)}
            </Paper>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={theme => theme.spacing(1.25)}
            alignItems={'center'}
          >
            {topMembers?.length > 2 && handleViewTopMember(topMembers[2], 3)}
          </Box>
        </Box>
      </Box>
      {/*Start: List user */}
      <Box
        className="box_list_user"
        sx={{
          marginTop: theme => theme.spacing(-3.75),
          position: 'relative',
          zIndex: 1,
          padding: theme => theme.spacing(0, 2.75, 2.75)
        }}
      >
        <Box className="list_user">
          <List
            sx={{
              display: 'grid',
              rowGap: theme => theme.spacing(1.25),
              p: theme => theme.spacing(2.5, 2),
              zIndex: 1
            }}
          >
            {listMembers?.length > 3 ? (
              <>
                {listMembers?.map((item, idx: number) => (
                  <ListItem key={idx} ref={ref} sx={{ p: 0, display: idx < 3 ? 'none' : 'flex' }}>
                    {handleViewMember(item, idx)}
                  </ListItem>
                ))}
              </>
            ) : (
              <>
                {new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13)?.map((item, idx: number) => (
                  <ListItem key={idx} ref={ref} sx={{ p: 0, display: idx < 3 ? 'none' : 'flex' }}>
                    {handleViewMember(item, idx)}
                  </ListItem>
                ))}
              </>
            )}
          </List>
          <Box
            sx={{
              position: 'absolute',
              top: theme => theme.spacing(-3.75),
              left: '50%',
              transform: 'translate(-50%, 0)'
            }}
          >
            <Box className="border_list_user">
              <Box
                sx={{
                  position: 'absolute',
                  left: theme => theme.spacing(3),
                  top: theme => theme.spacing(3.125),
                  width: theme => theme.spacing(1.5),
                  height: theme => theme.spacing(1.5),
                  background: theme => theme.palette.primary.light,
                  borderRadius: '50%'
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/*End: List user */}
    </RatingLeaderBoardContainer>
  )
}

export default RatingLeaderBoard

const RatingLeaderBoardContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  position: 'relative',

  width: '100%',
  '& .background': {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: theme.spacing(1.5),
      '&_mobile': {
        display: 'none'
      }
    }
  },
  '& .list_user': {
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    width: '100%',
    background: theme.palette.primary.dark,
    borderRadius: theme.spacing(1.5)
  },
  '& .tabs': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    maxWidth: theme.spacing(40.875),
    columnGap: theme.spacing(0.75),
    width: '100%',
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(6.25),
    padding: theme.spacing(0.75)
  },
  '& .active': {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.primary.dark,
    borderRadius: theme.spacing(3.75),
    padding: theme.spacing(1),
    width: '100%'
  },
  '& .item_user': {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '30px 44px 1fr auto',
    columnGap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    alignItems: 'center'
  },
  '& .chip': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyItems: 'center',
    background: theme.palette.background.default,
    border: 'none',
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(0.875),
    fontWeight: 600
  },
  '& .top_user': {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
    borderRadius: '20px 20px 0px 0px',
    background: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.backgroundHover} 100%)`
  },
  '& .top_user_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(1.25),
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: theme.spacing(38.75),
    borderRadius: theme.spacing(2.5, 2.5, 0, 0),
    background: `linear-gradient(180deg, ${theme.palette.primary.dark} 25.73%, rgba(255, 132, 143, 0.00) 100%)`
  },
  '& .level': {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: '100%',
    transform: 'rotate(45deg)',
    borderRadius: theme.spacing(0.75),
    border: '1px solid  #FDDEA1',
    background:
      'linear-gradient(0deg, #F90 6.37%, #FFA200 17.97%, #FFB300 45.38%, #FFBD00 74.9%, #FFC100 111.8%)'
  },
  '& .border_list_user': {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    backgroundColor: theme.palette.primary.dark,
    transform: 'rotate(45deg)',
    borderRadius: theme.spacing(8.25, 2.25),
    position: 'relative'
  },
  '& .truncate': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  '& .box_top_rank': {
    cursor: 'pointer',

    '& .avt_user': {
      width: theme.spacing(15),
      border: `5px solid  #F90`,
      height: theme.spacing(15),

      '& .top1': {
        width: theme.spacing(17.5),
        border: `5px solid  #F90`,
        height: theme.spacing(17.5)
      }
    }
  },
  '& .icon_infomation': {
    display: 'none'
  },

  '@media(max-width: 1024px)': {
    borderRadius: 0,
    '& .icon_infomation': {
      display: 'block',
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(1)
    },

    '& .box_rank_container': {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(-5),

      '& .box_top_rank': {
        cursor: 'pointer',

        '& .avt_user': {
          width: theme.spacing(9.97),
          border: `5px solid  #F90`,
          height: theme.spacing(9.97)
        },

        '& .top1': {
          width: theme.spacing(11.3),
          border: `5px solid  #F90`,
          height: theme.spacing(11.3)
        },

        '& .icon_crown': {
          width: theme.spacing(5.5),
          height: theme.spacing(4.5),
          left: theme.spacing(-2.2),
          top: theme.spacing(-1)
        },

        '& .icon_rank': {
          width: theme.spacing(3.25),
          height: theme.spacing(3.25),
          top: theme.spacing(7.75)
        },

        '& .icon_rank_top1': {
          width: theme.spacing(3.25),
          height: theme.spacing(3.25),
          top: theme.spacing(9.125)
        },

        '& .level': {
          '& h6': {
            fontSize: '20px'
          }
        }
      },
      '& .truncate': {
        fontSize: theme.spacing(1.8),
        padding: theme.spacing(0, 1)
      },

      '& .box_point': {
        width: '92%',
        padding: theme.spacing(1, 1.5),
        '& p': {
          fontSize: '14px'
        }
      }
    },

    '& .box_list_user': {
      padding: theme.spacing(1, 1.5),
      marginTop: theme.spacing(-12),

      '& .list_user': {
        background: '#F8D7DA'
      },
      '& .border_list_user': {
        background: '#F8D7DA'
      }
    }
  }
}))
