import axios, { AxiosResponse } from 'axios'
import TypedChannel from 'interfaces/channel.interface'
import { CreateConfigLevelPayload, TypeCategoryCourse, TypeDataLevel } from './service.type'
import { createAsyncThunk } from '@reduxjs/toolkit'
import helpers from 'helpers/index'
import { IQueryParams, serializeAxiosError } from 'config/reducer.utils'

type Response<T, D> = Promise<AxiosResponse<T, D>>

export const getVietnamBanks = () => {
  return axios.get('https://api.vietqr.io/v2/banks')
}

export const detailChannel = (): Response<TypedChannel, void> => {
  return axios.get('/settings')
}

export const inviteUserByEmail = (payload: { email: string }) => {
  const url = '/channel/invite-email'
  return axios.post<{ email: string }, string>(url, {
    email: payload.email
  })
}

export const updateChannel = (payload: any): any => {
  const url = '/channel/update'
  const updatedPayload = {
    ...payload,
    avatar: payload.avatar._id,
    point_data: JSON.stringify(payload.point_data),
    mentor_commission: payload.mentor_commission.toString(),
    user_commission: payload.user_commission.toString(),
    bank_qr_code: payload.bank_qr_code?._id
  }
  return axios.patch(url, updatedPayload)
}
export const getCategoryCourse = (params: IQueryParams) => {
  helpers.clearValueEmptyInObject(params)
  return axios.get<any[]>(`/course_category`, { params: params })
}

export const updateCategoryCourse= (payload: TypeCategoryCourse ) => {
  const url = '/course_category'
  return axios.patch<any>(url, payload)
}

export const deleteCategoryCourse = (_id: string) => {
  return axios.delete<any>(`/course_category/${_id}`)
}
export const createCategoryCourse= ( payload: TypeCategoryCourse) => {
  const url = '/course_category'
  return axios.post<any>(url, payload)
}

/** Level */
export const getConfigsLevel = (params: Record<string, string>) => {
  const url = '/channel/list-level'
  return axios.get<TypeDataLevel[]>(url, {
    params: params
  })
}

export const updateConfigLevel = (payload: Partial<TypeDataLevel> & Pick<TypeDataLevel, '_id'>) => {
  const url = '/channel/update-level'
  return axios.patch<any>(url, payload)
}

export const deleteLevel = createAsyncThunk(
  'channel_setting/delete_level',
  async (_id: string, { rejectWithValue }) => {
    try {
      return await axios.delete<unknown>(`/channel/delete-level/${_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)
export const createConfigLevel = (payload: CreateConfigLevelPayload) => {
  const url = '/channel/create-level'
  return axios.post<any>(url, payload)
}


export default { detailChannel, updateChannel, inviteUserByEmail }
