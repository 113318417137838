import __ from 'languages/index'

export enum EnumCategory {
  POST = 'post_category',
  COURSE = 'course_category',
  NEWS = 'news_category'
}

export enum EnumDisplay {
  DISPLAY = 1,
  HIDDEN = 0,
}

export  const configTypeCategory = [
  {
    key: EnumCategory.POST,
    title: __("channel_setting_title_post_categories")
  },
  {
    key: EnumCategory.COURSE,
    title: __("channel_setting_title_course_categories")
  },
  {
    key: EnumCategory.NEWS,
    title: __("channel_setting_title_news_categories")
  },

]

export const optionCategoryStatus = [
  {
    key: EnumDisplay.HIDDEN,
    value: __("channel_setting_hidden")
  },
  {
    key: EnumDisplay?.DISPLAY,
    value: __("channel_setting_display")
  }
]
