import React, { useState } from 'react'
import { Box, Button, Radio, Stack, Switch, TextField, Typography } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import FileUploader from 'entities/channel-settings/components/FileUploader'
import { GlobeIcon, LockIcon } from '../../../icons'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { channelStatusStyled } from 'entities/channel-settings/Capability/ChannelCensorship'

const GeneralDisplay = () => {
  const [mediaCurrency, setMediaCurrency] = useState<FileList | File[]>(null)
  const [mediaAvatar, setMediaAvatar] = useState<FileList | File[]>(null)
  return (
    <ChannelSettingsContentContainer>
      <Stack gap={2}>
        <Stack direction={'row'}>
          <ChannelSettingsTitle>{__('channel_setting_general_display')} </ChannelSettingsTitle>
          <Button
            // onClick={handleSave}
            sx={{
              marginLeft: 'auto',
              textTransform: 'none',
              fontSize: theme => theme.spacing(2),
              minWidth: theme => theme.spacing(12)
            }}
          >
            {__('btn_save')}
          </Button>
        </Stack>
        <Stack direction={'column'} gap={2}>
          <Typography variant={'subtitle2'} textTransform={'none'}>{__('channel_setting_general_display_list_member')}</Typography>
          <Stack direction={'row'} gap={3}>
            <Box
              role="button"
              // onClick={() =>
              //   handleUpdateChannelInfo({
              //     public_status: ChannelStatus.PUBLIC
              //   })
              // }
              sx={{
                ...channelStatusStyled,
                // backgroundColor: theme => isPublicChannel && theme.palette.background.default
              }}
            >
              <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
                <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
                  <GlobeIcon />
                  <Typography variant={'subtitle2'}>{__('channel_setting_overview_public')}</Typography>
                </Stack>
                <Radio
                  className={'selector'}
                  // checked={isPublicChannel}
                />
              </Stack>
              <Typography>{__('channel_setting_display_public_sub_text')}</Typography>
            </Box>

            <Box
              role="button"
              // onClick={() =>
              //   handleUpdateChannelInfo({
              //     public_status: ChannelStatus.PRIVATE
              //   })
              // }
              sx={{
                ...channelStatusStyled,
                // backgroundColor: theme => !isPublicChannel && theme.palette.background.default
              }}
            >
              <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
                <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
                  <LockIcon />
                  <Typography variant={'subtitle2'}>
                    {__('challenge_add_detail_status_private')}
                  </Typography>
                </Stack>
                <Radio
                  className={'selector'}
                  // checked={!isPublicChannel}
                />
              </Stack>
              <Typography>{__('channel_setting_display_private_sub_text')}</Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={'column'} gap={2}>
          <Typography variant={'subtitle2'} textTransform={'none'}>{__('channel_setting_general_display_currency')}</Typography>
          <Grid container spacing={2}>
            <Grid container xs={12}>
              <Grid xs={3}>
                <FileUploader
                  acceptFiles="image/*"
                  onUploaded={setMediaCurrency}
                  uploadText={__("channel_setting_general_display_currency_upload_text")}
                  placeholder={
                    (mediaCurrency !== null) && (
                      <Box
                        component={'img'}
                        // src={setMediaCurrency()}
                        sx={{
                          borderRadius: theme => theme.spacing(1.5),
                          width: theme => theme.spacing(16),
                          height: theme => theme.spacing(16)
                        }}
                      />
                    )
                  }
                />
              </Grid>
              <Grid xs={9}>
                <Stack gap={2}>
                  <TextField
                    label={__('channel_setting_general_display_currency_name')}
                    placeholder={__('channel_setting_general_display_currency_placeholder')}
                  />
                 <Typography>{__("channel_setting_general_display_currency_sub_text")}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction={'column'} gap={2}>
          <Typography variant={'subtitle2'} textTransform={'none'}>{__('channel_setting_general_display_avatar')}</Typography>
          <Grid container spacing={2}>
            <Grid container xs={12}>
              <Grid xs={3} minHeight={theme => theme.spacing(16)}>
                <FileUploader
                  acceptFiles="image/*"
                  onUploaded={setMediaAvatar}
                  uploadText={__("channel_setting_general_display_avatar_upload_text")}
                  placeholder={
                    (mediaAvatar !== null) && (
                      <Box
                        component={'img'}
                        // src={setMediaCurrency()}
                        sx={{
                          borderRadius: theme => theme.spacing(1.5),
                          width: theme => theme.spacing(16),
                          height: theme => theme.spacing(16)
                        }}
                      />
                    )
                  }
                />
              </Grid>
              <Grid xs={9}>
                <Typography>{__("channel_setting_general_display_avatar_sub_text")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction={'column'} gap={2}>
          <Typography variant={'subtitle2'} textTransform={'none'}>{__('channel_setting_general_display_navigate')}</Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Switch
              // checked={channel?.need_approval}
              // onChange={e =>
              //   handleUpdateChannelInfo({
              //     need_approval: e.target.checked
              //   })
              // }
            />
            <Typography>{__('channel_setting_general_display_navigate_sub_text')}</Typography>
          </Stack>
        </Stack>

      </Stack>
    </ChannelSettingsContentContainer>

  )
}

export default GeneralDisplay
