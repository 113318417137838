import { useAppDispatch, useAppSelector } from 'config/store'
import { getListGame } from 'entities/challenge/challenge.store.reducer'
import React, { useCallback, useEffect } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { TypedGame } from 'entities/challenge/challenge.interface'
import Title from 'components/Title'
import __ from 'languages/index'
import helpers from 'helpers/index'
import { Box, Typography, styled } from '@mui/material'
import createChallengImg from '../../media/img/create_challenge.png'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
const Cookbook = props => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const listCookbook = useAppSelector(state => state.challenge?.listCookbook)
  const permission_data = useAppSelector(state => state.user.permission_data)

  // useEffect
  useEffect(() => {
    getCookbook()
  }, [])
  // ===============================================

  useEffect(() => {
    if (permission_data) {
      console.log(permission_data, 'permission_data')
      console.log(
        helpers.current_user_can('boss', permission_data),
        'helpers.current_user_can("boss", permission_data)'
      )
      if (!helpers.current_user_can('boss', permission_data)) {
        navigate('/error')
      }
    }
  }, [permission_data])

  // Function
  const _onItemClick = useCallback((item: TypedGame) => {
    // dispatch(setCookbook(item))
    navigate(`/challenge/add/cookbook=${item?._id || ''}`)
  }, [])

  const getCookbook = useCallback(async () => {
    dispatch(getListGame({ type: 'system' }))
  }, [])
  // ===============================================

  // render view
  const renderEmptyTemplate = useCallback(() => {
    return (
      <Col className="box_create" md="3" xs="6">
        <Box onClick={() => _onItemClick(null)} className="gap-2 mb-2 clickable">
          <img src={createChallengImg} />
          <Box className="content">
            <Typography variant="subtitle2" className="mt-3" textTransform={'none'}>
              {__('challenge_new_game')}
            </Typography>
            <Typography mt={2} variant="body1" className="cookbook-description">
              {__('challenge_cookbook_empty_template_description')}
            </Typography>
          </Box>
        </Box>
      </Col>
    )
  }, [])

  const renderItemCookbook = () => {
    return listCookbook?.map((item: TypedGame) => {
      return (
        <Col className="box_create" md="3" xs="6" key={item?._id}>
          <Box onClick={() => _onItemClick(item)} className="mb-3 me-3 clickable">
            <Image
              src={item?.media_id?.media_url || item?.media_id?.media_thumbnail}
              style={{
                objectFit: 'cover',
                height: 160,
                width: '100%',
                borderRadius: '12px 12px 0 0'
              }}
            />

            <Box className="content">
              <Typography variant="subtitle2" className="mt-3">
                {item?.title}
              </Typography>
              <Typography mt={2} className="cookbook-description">
                {item?.description}
              </Typography>
            </Box>
          </Box>
        </Col>
      )
    })
  }
  // ===============================================

  return (
    <CookbookContainer id="challenge_cookbook">
      <BreadcrumbComponent
        breadcrumbName={{
          '/challenge': 'Challenge',
          '/challenge/cookbook': 'Thêm mới'
        }}
      />
      <div className="align-items-center justify-content-between">
        <Typography variant="h5" mt={2} textTransform={'none'}>
          {__('challenge_cookbook_title')}
        </Typography>
        <Typography variant="subtitle2" mt={2}>
          {__('challenge_cookbook_sub_title')}
        </Typography>
      </div>
      <Row>
        {renderEmptyTemplate()}
        {renderItemCookbook()}
      </Row>
    </CookbookContainer>
  )
}

export default Cookbook
const CookbookContainer = styled(Box)(({ theme }) => ({
  '& .box_create': {
    height: '296.38px',
    borderRadius: '12px',
    boxShadow: '0px 8px 40px 0px #0E142614',
    width: '282px',
    background: '#fff',
    padding: 0,
    margin: theme.spacing(3, 3, 0, 0),

    '& .clickable': {
      '& img': {
        width: '100%'
      },
      '& .content': {
        padding: theme.spacing(2, 3)
      }
    }
  }
}))
