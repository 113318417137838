export enum EnumMediaType {
  Video = 'video',
  Image = 'image',
  File = 'file'
}

export enum EnumTypeUpload {
  Media = 'media',
  File = 'file'
}

export enum EnumPostType {
  Media = 'media',
  File = 'file',
  Poll = 'poll'
}

interface TypedMediaMeta {
  key?: string
  value?: string
  _id?: string
}

export default interface TypedMedia {
  _id?: string
  media_url?: string
  media_url_presign?: string
  media_type?: EnumMediaType
  media_thumbnail?: string
  media_content?: string
  media_square?: string
  media_mime_type?: string
  media_file_name?: string
  chat_history_id?: string
  chat_room_id?: string
  media_status?: number
  gender?: string
  sexual_content?: number
  data_ai?: string
  media_meta?: TypedMediaMeta[]
  media_files?: string[]
  createBy?: string
  createdAt?: string
  updatedAt?: string
}

export interface TypedMediaResponse {
  src: string
  callback: TypedMedia
}

export interface ImageSlide {
  type: 'image'
  src: string
  width: number
  height: number
  srcSet: {
    src: string
    width: number
    height: number
  }[]
}

export interface VideoSlide {
  type: 'video'
  src: string
  poster: string
  width: number
  height: number
  sources: {
    src: string
    type: string
  }[]
}
