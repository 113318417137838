import React, { useState } from 'react'
import { Stack } from '@mui/material'
import GeneralDisplay from 'entities/channel-settings/display-system/GeneralDisplay'
import FeatureDisplay from 'entities/channel-settings/display-system/FeatureDisplay'
import __helpers from 'helpers/index'


export interface  TypeFeatures{
  _id: string
  original_name: string
  display_name: string
  status:string
}

const DisplaySystem = () => {
  const listFeature = [
    {
      _id: '1',
      original_name: 'Điểm danh',
      display_name: 'Điểm danh',
      status: '0'
    },
    {
      _id: '2',
      original_name: 'Khoa hoc',
      display_name: 'khoa hoc',
      status: '1'
    }
  ]
  return (
    <Stack gap={2}>
     <GeneralDisplay/>
      <FeatureDisplay
        // isLoading={fetchingCategories}
        isEmpty={!listFeature || __helpers.isEmpty(listFeature)}
        listData={listFeature}
      />
    </Stack>
  )
}

export default DisplaySystem
