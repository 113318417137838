//Library
import __ from 'languages/index'
import { Helmet } from 'react-helmet'

//Store
import { useAppSelector } from 'config/store'

//Helper
import helpers from 'helpers'
import TypedChannel from 'interfaces/channel.interface'

const _Helmet = ({ title }: { title: string }) => {
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

  function getPermalink(): string {
    return helpers.getRootDomain(`/r` + location.pathname)
  }

  return (
    <Helmet>
      <title>{__(title)}</title>
      <meta name="description" content={__(title)} />
      <meta property="og:title" content={__(title)} />
      <meta property="og:url" content={getPermalink()} />
      <meta name="canonical" content={getPermalink()} />
      <meta property="og:image" content={''} />
      <link rel="icon" type="image/x-icon" href={channel_data?.channel_avatar ?? '/favicon.ico'} />
      <link rel="apple-touch-icon" href={channel_data?.channel_avatar ?? '/favicon.ico'} />
    </Helmet>
  )
}

export default _Helmet
