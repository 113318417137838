import __, { ___ } from 'languages/index'
import { Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import './media/css/index.scss'
import { useAppDispatch, useAppSelector } from 'config/store'
import { updatePassword } from 'entities/forgotPassword/forgot.password.store.reducer'
import helpers from 'helpers'
import _Helmet from 'components/helmet'
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import { Alert, Box, Button, IconButton, keyframes, Paper, styled, Typography } from '@mui/material'
import TextFieldMUI from 'components/TextFieldMUI'
import { ArrowLeftIcon, MobileIcon } from 'icons'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'

export interface TypeChangePassWold {}
const ForgotPasswordPage: React.FC<TypeChangePassWold> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const channel_data = useAppSelector(state => state.user.channel_data)

  const checkMobile = helpers.isMobile()
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const useFields = {
    phoneOrEmail: useField<string>({
      value: '',
      validates: [
        notEmpty(`${__('forgot_password_email_or_phone_not_empty')}`),
        inputVal => {
          if (!helpers.isEmail(inputVal) && !helpers.isPhoneNumber(inputVal)) {
            return `${__('forgot_password_email_or_phone_error')}`
          }
        }
      ]
    })
  }

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        setIsLoading(true)
        const _forgotPassword: any = await dispatch(
          updatePassword({
            user_input: values.phoneOrEmail
          })
        )
        if (typeof _forgotPassword.error !== 'undefined') {
          setError(_forgotPassword.error.message || `${__('login_error_unknown')}`)
          setIsLoading(false)
          return { status: 'success' }
        }
        const response = _forgotPassword?.payload?.data || ''
        setIsLoading(false)
        navigate('/forgot-password/verify')
        localStorage.setItem(
          'forgotPasswordProps',
          JSON.stringify({
            sessionId: response?.session_id || '',
            user_input: response?.user_input || ''
          })
        )
        return { status: 'success' }
      } catch (err) {
        setIsLoading(false)
        console.error(`Submit error`, err)
        const message = err?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = err?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  return (
    <>
      <_Helmet title={'forgot_password_title'} />
      <ForgotPasswordContainer id="forgotPasswordPage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />

        <Box id={'box_form'} className="wrapper">
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              minWidth: checkMobile ? 'auto' : theme => theme.spacing(76),
              // height: checkMobile ? '100%' : 'auto',
              width: checkMobile ? '100%' : 'unset'
            }}
          >
            {checkMobile && (
              <IconButton
                sx={{ mt: '24px', justifyContent: 'flex-start' }}
                onClick={() => navigate(-1)}
              >
                <ArrowLeftIcon />
              </IconButton>
            )}
            <Box>
              {error && <Alert severity="error">{error}</Alert>}
              <Box
                display={'grid'}
                rowGap={theme => theme.spacing(3)}
                padding={theme => theme.spacing(3)}
              >
                <h1>{__('forgot_password_title')}</h1>

                {checkMobile && (
                  <Typography mt="24px" color="##626F82" fontSize="16px">
                    {__('forgot_password_title_mobile')}
                  </Typography>
                )}
                <Form onSubmit={submit} id="forgotPassword" className="forgotPassword_content">
                  <div className="forgotPassword_body">
                    <div className="forgotPassword_body_item">
                      <TextFieldMUI
                        type="text"
                        placeholder={__('login_user_name_label')}
                        autoFocus
                        name="phoneOrEmail"
                        value={fields.phoneOrEmail.value || ''}
                        onChange={fields.phoneOrEmail.onChange}
                        iconStart={<MobileIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      />

                      {fields.phoneOrEmail?.error && (
                        <span className="form-text text-danger">{fields.phoneOrEmail?.error}</span>
                      )}
                    </div>
                  </div>
                  <div className="forgotPassword_footer">
                    <Button
                      onClick={submit}
                      disabled={!dirty || submitting || isLoading}
                      type="submit"
                      sx={{ p: theme => theme.spacing(2.1875, 2) }}
                    >
                      <Typography textTransform={'none'}>
                        {isLoading ? __('btn_processing') : __('forgot_password_btn')}
                      </Typography>
                    </Button>

                    {!checkMobile && (
                      <>
                        {/*<div className="helpText small text-muted mt-4">{___('In case you encounter difficulties retrieving your password, please contact the {support_center_link} for assistance in resetting your password.', {*/}
                        {/*  support_center_link: <Link to={'https://gamifa.vn/about-me/'}>{__('support_center')}</Link>*/}
                        {/*})}</div>*/}

                        <Box
                          component={Link}
                          sx={{ color: theme => `${theme.palette.primary.main} !important` }}
                          to={'/login'}
                          className="forgotPassword_footer_comebackLogin"
                        >
                          {__('forgot_password_comeback_login')}
                        </Box>
                      </>
                    )}
                  </div>
                </Form>
              </Box>
            </Box>
          </Paper>
        </Box>
      </ForgotPasswordContainer>
    </>
  )
}
export default ForgotPasswordPage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`
const ForgotPasswordContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },

  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 468px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  }
}))
