import { Box, Button, IconButton, Typography, styled } from '@mui/material'
import { CloseIcon, UploaderIcon } from 'icons'
import __ from 'languages/index'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import uploadLoading from 'media/json/uploadLottie.json'
import useUploadFile from 'hooks/useUploadFile'
import helpers from 'helpers'

const UploadFileComponent = ({
  title,
  mediaUrl,
  setMediaUrl,
  removeMediaUrl,
  multiple,
  accept = 'image/*',
  isRequired = false,
  ...rest
}: {
  title?: string
  mediaUrl?: string
  setMediaUrl?: Function
  removeMediaUrl?: Function
  multiple?: boolean
  accept?: string
  isRequired?: boolean
  rest?: React.InputHTMLAttributes<HTMLInputElement>
}) => {
  const [fileData, setFileData] = useState<File[]>([])
  const [linkPreviewMedia, setLinkPreviewMedia] = useState<string>('')

  // const { mediaUrl: uploadedThumbnail, loading: uploadingThumb } = useMediaUpload(mediaURl)

  const { srcFiles: uploadedThumbnail, loading: uploadingThumb } = useUploadFile(fileData, {
    initialFiles: [{ media_url: mediaUrl || '' }]
  })

  const handleFileChange = e => {
    setFileData(e.target.files)
    setLinkPreviewMedia(URL.createObjectURL(e.target.files?.[0]))
  }

  const handleClose = () => {
    removeMediaUrl()
    URL.revokeObjectURL(linkPreviewMedia)
    setLinkPreviewMedia('')
  }

  useEffect(() => {
    if (helpers.isEmpty(fileData)) return
    setMediaUrl(uploadedThumbnail?.[0]?.media_url)
  }, [uploadedThumbnail])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(linkPreviewMedia)
      setLinkPreviewMedia('')
    }
  }, [])

  return (
    <UploadFileContainer>
      {uploadingThumb ? (
        <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
          {fileData?.[0]?.type.includes('video') ? (
            <video style={{ width: '100%', height: '100%' }} preload={'auto'} muted>
              <source src={mediaUrl || linkPreviewMedia} type="video/mp4" />
            </video>
          ) : (
            <img
              src={mediaUrl || linkPreviewMedia}
              width={60}
              height={60}
              style={{ opacity: 0.5 }}
              alt="image"
            />
          )}
          <StyledLottie animationData={uploadLoading} autoPlay loop />
        </Box>
      ) : (
        <>
          {mediaUrl ? (
            <>
              <Box width={'100%'} height={'100%'} overflow={'hidden'}>
                {fileData?.[0]?.type.includes('video') ? (
                  <video style={{ width: '100%', height: '100%' }} preload={'auto'} muted>
                    <source src={mediaUrl || linkPreviewMedia} type="video/mp4" />
                  </video>
                ) : (
                  <img src={mediaUrl} alt="image" />
                )}
              </Box>
              <IconButton onClick={handleClose} className="close_button">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Button
              component="label"
              className={`button_wrapper ${isRequired ? 'required' : ''}`}
              variant="text"
            >
              <Box className={`upload_wrapper ${isRequired ? 'required' : ''}`}>
                <UploaderIcon
                  color="inherit"
                  sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                />
                <Typography textTransform={'none'} textAlign={'center'} variant="body2">
                  {title}
                </Typography>
              </Box>
              <input
                {...rest}
                hidden
                type="file"
                accept={accept}
                multiple={multiple}
                onChange={handleFileChange}
              />
            </Button>
          )}
        </>
      )}
    </UploadFileContainer>
  )
}

export default UploadFileComponent

UploadFileComponent.defaultProps = {
  title: __('upload_image'),
  multiple: false
}

const StyledLottie = styled(Lottie)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: theme.spacing(15),
  svg: {
    '& path': {
      fill: theme.palette.primary.main,
      stroke: ''
    }
  }
}))

const UploadFileContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2),
  width: '100%',
  height: '100%',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(3)
  },
  '& .close_button': {
    position: 'absolute',
    background: theme.palette.background.paper,
    right: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    top: theme.spacing(1)
  },
  '& .button_wrapper': {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 2),
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(3),
    '& .upload_wrapper': {
      display: 'grid',
      justifyItems: 'center',
      rowGap: theme.spacing(1)
    },
    '& .upload_wrapper.required': {
      color: theme.palette.error.main
    }
  },
  '& .button_wrapper.required': {
    border: `1px solid ${theme.palette.error.main}`
  }
}))
