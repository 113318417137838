import { Box, Stack, Typography, styled } from '@mui/material'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import BigLayout from 'layouts/bigLayout'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getNewsBySearch } from './services'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { TypedNews } from './interface'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import NewsItem from './Components/news.item'
import SearchComponent from 'components/SearchComponent'

const NewsCategory = () => {
  const { slugId } = useParams()
  const navigate = useNavigate()

  const { data, paramRequest, setParamRequest, hasMore, isRefreshing, loadMore, refresh } =
    useInfiniteScroll<TypedNews>(
      {
        category_id: slugId,
        keyAttribute: '_id'
      },
      getNewsBySearch,
      true
    )

  const categoryName = useMemo(() => {
    return data?.[0]?.category_id?.news_category_name || ''
  }, [data])

  const handleChangeSearch = useCallback(
    (value: string) => {
      if (!value) return navigate('/news', { replace: true })
      setParamRequest(paramRequest => ({ ...paramRequest, query: value }))
      navigate(`/news/search?query=${value}`, { replace: true })
    },
    [paramRequest]
  )

  useEffect(() => {
    if (!slugId) return navigate('/news')
  }, [slugId])

  return (
    <BigLayout>
      <_Helmet title="" />
      <NewsCategoryContainer>
        <BreadcrumbComponent
          breadcrumbName={{
            '/news': __('news'),
            [`/news/category/${slugId}`]: categoryName
          }}
        />

        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h5" textTransform={'none'} fontWeight={700}>
            {categoryName}
          </Typography>
          <Stack direction={'row'} gap={2}>
            <SearchComponent
              placeholder={__('search_for_news')}
              value={paramRequest?.query || ''}
              onChange={handleChangeSearch}
            />
          </Stack>
        </Stack>

        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
          textEmptyMessage=""
          showEndMessage={false}
          pullDownToRefresh={false}
        >
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 1fr'} gap={3}>
            {data.map((item: TypedNews, idx: number) => (
              <React.Fragment key={idx}>
                <NewsItem newsData={item} />
              </React.Fragment>
            ))}
          </Box>
        </InfiniteScrollContainer>
      </NewsCategoryContainer>
    </BigLayout>
  )
}

export default NewsCategory

const NewsCategoryContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3)
}))
