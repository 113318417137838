import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import '../styles/modal.mission.scss'
import __ from 'languages/index';
import { TypeDataNotification } from 'entities/notification/interface';

interface TypeNotificationModal{
  notification: TypeDataNotification,
  handleOpenNotification: (a: TypeDataNotification) => void,
  setShowModalNotification: (a: boolean) => void
}

export default function NotificationModal({ notification, handleOpenNotification, setShowModalNotification }: TypeNotificationModal) {
  const channel_data: any = useAppSelector(state => state.user.channel_data);
  const [channelAvatar, setChannelAvatar] = useState('https://media.whiteg.app/lgbtapp.s3.ap-southeast-1.amazonaws.com/2023/09/27_1695788119795/64c09b95b9a4ef8b3ed3a385-1695788119795-thumbnail-avatar_default.png');
  useEffect(() => {
    try {
      setChannelAvatar(channel_data?.avatar.media_thumbnail)
    } catch (e) { }
  }, [channel_data])
  const closeModal = () => {
    setShowModalNotification(false)
  }

  return (
    <div id="notification_popup">
      <div className="position-relative">
        <button onClick={closeModal} className="btn p-1 me-2 position-fixed"
          style={{ zIndex: 999, left: '300px', marginTop: 8 }}>
          <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '18px' }} />
        </button>
      </div>
      <button onClick={() => handleOpenNotification(notification)} className="p-2 rounded d-flex align-items-center btn">
        <div className="position-relative">
          <img
            alt=""
            src={JSON.parse(notification?.notification_data)?.thumbnail ?? channelAvatar}
            width="60"
            height="60"
            className="d-inline-block align-top rounded-3 me-1"
          />
        </div>
        
        <div className="ms-2 w-100">
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="my-0 fw-bold text-start" style={{ fontSize: '15px' }}>{notification?.notification_title || __('notification_onscreen_title') } </p>
          </div>
          <div className=" d-flex">
            <p className="mb-0 mt-0 title_modal_notification text-start mt-1">
              <b className='ms-0'></b>
              {notification?.notification_content || '.'}
            </p>
          </div>
        </div>
      </button>

    </div>
  )
}
