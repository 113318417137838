import React from 'react'
import { Box, Button, FormControl, InputLabel, SelectChangeEvent, Stack, Typography } from '@mui/material'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { formatString } from 'entities/support/utils'
import { EnumCategory, optionCategoryStatus } from 'entities/channel-settings/post-category/categoryConfig'
import Select from 'components/Select'
import { useCreateCategoryMutation } from 'entities/homeNew/store/homeApi'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import TextField from 'components/TextField'
import { useAppDispatch } from 'config/store'
import { createCategoryNews, createNews } from 'entities/news/services'
import { createCategoryCourse } from 'entities/channel-settings/service'

type Props = {
  onClose?: () => void
  categoryType?: {
    isAdd: boolean,
    value: EnumCategory
  }
  getCategoryDisplay: (value: EnumCategory) => void
}

const AddCategoryForm = ({ onClose, categoryType, getCategoryDisplay}: Props) => {
  const dispatch = useAppDispatch()
  const [createCategory, {}] = useCreateCategoryMutation()
  const toast = useToast()

  const { fields, submit, dirty, submitting } = useForm({
    fields: {
      category_title: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('channel_setting_validate_category_name')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('channel_setting_validate_category_name')}`
            }
          }
        ]
      }),
      public_status: useField<number>({
        value: 1,
        validates: []
      }),
    },
    async onSubmit(values) {
      try {
        if(categoryType?.value === EnumCategory?.POST){
           await createCategory({
            category_title: values?.category_title,
            public_status: values?.public_status,
            category_language: 'vi',
          })
             .unwrap()
             .then((res)=>{
               getCategoryDisplay(EnumCategory?.POST)
               toast.show({
                 type: EnumTypeToast.Success,
                 content: formatString(__('create_category_successfully'), res?.category_title)
               })
           })

        }
        if(categoryType?.value === EnumCategory?.COURSE){
          await createCategoryCourse({
            course_category_title: values?.category_title,
            category_visibility: values?.public_status
          })
            .then(res => {
              getCategoryDisplay(EnumCategory?.COURSE)
              toast.show({
                type: EnumTypeToast.Success,
                content: formatString(__('create_category_successfully'), res?.data.course_category_title)
              })
            })
        }
        if(categoryType?.value === EnumCategory?.NEWS){
          await createCategoryNews( {
            news_category_name: values?.category_title ,
            category_visibility: values?.public_status
          })
            .then(res => {
              getCategoryDisplay(EnumCategory?.NEWS)
              toast.show({
                content: formatString(__('create_category_successfully'), res?.data?.news_category_name),
                type: EnumTypeToast.Success
              })
            })
            .catch(error => {
              console.log('createCategoryNews error', error)
              toast.show({
                content: `${error}}`,
                type: EnumTypeToast.Error
              })
            })}
        onClose()

        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleChangeStatus = (value: string)=>{
    fields?.public_status.onChange(Number(value))
  }


    return (
      <Stack gap={2} component="form" onSubmit={(e) =>{
        e.preventDefault()
        submit()
      }}>
        <Box>
          <TextField
            {...fields?.category_title}
            placeholder={__('channel_setting_label_category_name')}
            labelHidden
          />
        </Box>


        <FormControl
          variant="filled"
        >
          <InputLabel>{__('channel_setting_category_visibility')}</InputLabel>
          <Select
            {...fields?.public_status}
            onChangeValue={handleChangeStatus}
            value={fields?.public_status?.value?.toString()}
            options={optionCategoryStatus}
            getItemValue={item => item?.key}
            renderItem={item => <Typography>{item?.value}</Typography>}
          />
        </FormControl>
        <Button
          sx={{ ml: 'auto', textTransform: 'none' }}
          type={'submit'}
          disabled={!dirty || submitting}
        >
          {__('save_category')}
        </Button>
      </Stack>
    )
  }

export default AddCategoryForm
