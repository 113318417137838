import React, { useCallback, useEffect, useState } from 'react'
import { Box, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import Table from '../components/Table'
import { pointConfigs } from './pointConfigs'
import RowActions from '../components/RowActions'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelSetting } from '../store/channel.slice'
import TypedChannel, { TypedPointData } from 'interfaces/channel.interface'
import RenderView from 'components/RenderView'
import { EnumPointDataKey } from 'constant/index'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import Level from 'entities/channel-settings/level'
import { StyledTableCell } from 'entities/channel-settings/components/StyledTableCell'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { formatString } from 'entities/support/utils'

const getPointLabel = (key: EnumPointDataKey) => {
  return pointConfigs[key]
}

const PointConfigurations = () => {
  const channel = useAppSelector(channelInfo)
  const [editingPoint, setEditingPoint] = useState<TypedPointData>(null)
  const [channelData,setChannelData] = useState<TypedChannel>(channel)

  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleClickEditBtn = (point: TypedPointData ) => {
    setEditingPoint(point)
  }
  const handleUpdateChannelInfo = (updatedChannel: Partial<TypedChannel>,title: string) => {
    dispatch(updateChannelSetting(updatedChannel))
      .unwrap()
      .then(res => {
        setChannelData(res?.data)
        setEditingPoint(null)
        toast.show({
          content: formatString(__('channel_setting_success'), title),
          type: EnumTypeToast.Success
        })
      })
      .catch(err => {
        console.log('updateChannelSetting', err)
        toast.show({
          content: `${err}}`,
          type: EnumTypeToast.Error
        })
      })
  }

  const handleUpdatePoint= useCallback(()=>{
    const updateDataPoint = channelData?.point_data.filter((item)=>item?.key !== editingPoint?.key)
    updateDataPoint.push(editingPoint)
    handleUpdateChannelInfo({
      point_data: updateDataPoint
    },__('channel_setting_level_point'))
  },[editingPoint,channelData])
  const renderHeader = () => {
    return (
      <TableRow>
        <StyledTableCell>{__('channel_setting_table_react')}</StyledTableCell>
        <StyledTableCell>{__('channel_setting_table_point_receive')}</StyledTableCell>
        <StyledTableCell sx={{ textAlign: 'end' }}>
          {__('channel_setting_table_action')}
        </StyledTableCell>
      </TableRow>
    )
  }

  const renderCell = (item: TypedPointData) => {
    return (
      <>
        <TableCell>
          <Typography fontWeight={600}>{getPointLabel(item?.key)}</Typography>
        </TableCell>
        <TableCell>
          {item.key === editingPoint?.key ? (
            <TextField
              sx={{
                maxWidth: '100px'
              }}
              size="small"
              value={editingPoint?.point}
              onChange={e => {
                setEditingPoint({
                  ...editingPoint,
                  point: +e.target.value
                })
              }}
              autoFocus
              label={__('channel_setting_level_point')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                bgcolor: theme => theme.palette.background.default,
                width: 'fit-content'
              }}
            >
              <Typography
                sx={{
                  color: theme => theme.palette.primary.main,
                  fontWeight: 600
                }}
              >
                {`${item.point} ${__('point_unit')}`}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => handleClickEditBtn(item)}
            editing={item.key === editingPoint?.key}
            onConfirm={handleUpdatePoint}
          />
        </TableCell>
      </>
    )
  }

  return (
    <Stack rowGap={2}>
      <ChannelSettingsContentContainer>
        <Stack gap={2}>
          <ChannelSettingsTitle>{__('channel_setting_data_point')}</ChannelSettingsTitle>
          <Typography>{__('channel_setting_data_point_sub_text')}</Typography>
          <RenderView
            view={
              <Table
                data={channelData?.point_data}
                renderTableRow={item => renderCell(item)}
                renderHeader={renderHeader}
              />
            }
            isEmpty={!Boolean(channelData?.point_data)}
          />
        </Stack>
      </ChannelSettingsContentContainer>
      <ChannelSettingsContentContainer>
        <Level/>
      </ChannelSettingsContentContainer>
    </Stack>
  )
}

export default PointConfigurations
