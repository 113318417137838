import React, { useEffect, useState } from 'react'
import Comment from './Comment'
import { useGetPostCommentsQuery, useGetPostDetailQuery } from '../store/homeApi'
import RenderView from 'components/RenderView'
import { Button, Stack, Typography } from '@mui/material'
import __ from 'languages/index'
import {
  getListComment,
  updateRequestNormal
} from 'entities/homepage/home.store.reducer'
import {useAppDispatch} from "config/store";
import { TypedRequest } from '../../../interfaces/request.interface'
import InfiniteList from 'components/InfinityList'
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { PostCommentType } from '../../../interfaces/home.interface'

type Props = {
  post?: TypedRequest
}

const PostComment = ({ post }: Props) => {
  const { data: postDetail, isLoading: loadingDetail } = useGetPostDetailQuery({ postId: post?._id })
  const dispatch = useAppDispatch()

  const { hasMore, data, isRefreshing, loadMore, updateParamRequest, isLoading, refresh } =
    useInfiniteScroll<PostCommentType>(
      {
        request_id: post?._id,
        limit: 20,
        order_by: 'DESC',
      },
      getListComment,
    )
  const renderHideComments = () => {
    return <Typography textAlign={'center'}>{__('home_page_comment_hidden')}</Typography>
  }

  useEffect( () => {
     dispatch(updateRequestNormal({_id: postDetail?._id, comment_number: postDetail?.comment_number}))

  }, [postDetail?.comment_number,postDetail?.is_comment]);

  const renderListComments = () => {
    return (
      <InfiniteList
        isLoading={isLoading}
        list={data}
        renderItem={(comment) => (
          <Comment post={post} key={`comment_${comment?._id}`} comment={comment} depth={1} />
        )}
        hasNextPage={hasMore}
        onLoadMore={loadMore}
      />
    )
  }

  const renderEmptyList = () => {
    return (
      <div>
        <Typography>There is no comment</Typography>
      </div>
    )
  }

  return (
    <>
      <RenderView
        view={renderListComments()}
        isLoading={isRefreshing || loadingDetail}
        emptyElementFallback={renderEmptyList()}

      />
    </>

  )
}

export default PostComment
