import _Helmet from 'components/helmet'
import './media/member.page.scss'
import __, { ___ } from 'languages/index'
import BigLayout from '../../layouts/bigLayout'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import { Box, Divider, IconButton, Paper, Stack, Typography, styled } from '@mui/material'
import AvatarComponent from 'layouts/components/AvatarComponent'
import { useAppDispatch, useAppSelector } from 'config/store'
import helpers from 'helpers'
import { MessageIcon } from 'icons'
import React, { useCallback, useEffect, useState } from 'react'
import { TypedMyIntroduction, TypedMyIntroductionItem } from './interface'
import { createChatroomToUser, getMyIntroduction } from './store/member.store.reducer'
import { useInfinite } from 'hooks/useInfinite'
import { AxiosError, AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import ComponentEmpty from 'components/componentEmpty'
import Capacity from 'components/Capacity'
import { EnumRole } from 'constant/index'

const MyIntroductionPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const user_data = useAppSelector(state => state.user.user_data)

  const [myIntroduction, setMyIntroduction] = useState<TypedMyIntroduction>(null)
  const [totalMyIntroduction, setTotalMyIntroduction] = useState<number>(0)
  const [params, setParams] = useState({ page: 1, limit: 10 })

  const { ref } = useInfinite(myIntroduction?.yours?.length < totalMyIntroduction, () => {
    setParams({
      ...params,
      page: params.page + 1
    })
  })

  const _renderItem = useCallback(
    (data: TypedMyIntroductionItem) => {
      return (
        <Stack direction={'row'} gap={1}>
          <AvatarComponent
            userData={data}
            permissionData={data?.channelpermission?.permission}
            channelpermission={data?.channelpermission}
          />
          <Stack gap={0.5}>
            <Typography fontWeight={600} noWrap>
              {data?.display_name || ''}
            </Typography>
            <Capacity
              current_user_can={[
                EnumRole.Super_Admin,
                EnumRole.Admin,
                EnumRole.Boss,
                EnumRole.Mentor
              ]}
              onFail={() => (
                <Typography variant="body1" color={'text.secondary'} fontWeight={400}>
                  {___('header {level_point} point', {
                    level_point: (
                      <>{`${data?.channelpermission?.level_number} - ${data?.channelpermission?.point}`}</>
                    )
                  })}
                </Typography>
              )}
            >
              <Typography color="text.secondary" noWrap>
                {__(`${helpers.checkPermissionUser(data?.channelpermission?.permission)}`)}
              </Typography>
            </Capacity>
          </Stack>
        </Stack>
      )
    },
    [myIntroduction]
  )

  const handleCreateChatroomToUser = useCallback((partner_id: string) => {
    dispatch(createChatroomToUser({ partner_id: partner_id, chat_type: 'personal' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        const { _id } = res?.data?.chat_room_id
        navigate(`/chat/detail/${_id}`)
      })
      .catch((error: AxiosError) => {
        console.log(`createChatroomToUser_${error}`)
        toast.show({
          content: `${__('member_request_failed')}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  useEffect(() => {
    if (!user_data?._id) return
    dispatch(getMyIntroduction({ _id: user_data?._id, params: params }))
      .unwrap()
      .then(res => {
        setMyIntroduction(myIntroduction => ({
          ...res?.data,
          yours: helpers.isEmpty(myIntroduction?.yours)
            ? [...res?.data?.yours]
            : [...myIntroduction?.yours, ...res?.data?.yours]
        }))
        setTotalMyIntroduction(Number(res?.headers['X-Total-Count']) || 0)
      })
      .catch(error => console.log('getMyIntroduction error', error))
  }, [user_data, params])

  return (
    <BigLayout>
      <_Helmet title="my_introduction" />
      <BreadcrumbComponent
        breadcrumbName={{
          '/member': __('member'),
          '/member/my-introduction': __('my_introduction')
        }}
      />
      <MyIntroductionContainer>
        <Typography variant="h5" fontWeight={700} textTransform={'none'}>
          {__('my_introduction')}
        </Typography>

        <Paper className="paper_wrapper">
          <Stack gap={2}>
            <Paper
              sx={{
                p: theme => theme.spacing(1, 2),
                background: theme => theme.palette.background.default
              }}
            >
              {_renderItem(myIntroduction?.you)}
            </Paper>

            {!helpers.isEmpty(myIntroduction?.whois) && (
              <Typography
                variant="subtitle2"
                textTransform={'none'}
                fontWeight={600}
                color="text.secondary"
              >
                {__('recommended_by')}
              </Typography>
            )}

            {!helpers.isEmpty(myIntroduction?.whois) && (
              <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
                {_renderItem(myIntroduction?.whois)}
                <IconButton
                  onClick={() => handleCreateChatroomToUser(myIntroduction?.whois?._id)}
                  sx={{
                    p: theme => theme.spacing(1.25),
                    background: theme => theme.palette.background.default,
                    borderRadius: theme => theme.spacing(1.5)
                  }}
                >
                  <MessageIcon sx={{ color: theme => theme.palette.text.primary }} />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Paper>

        <Paper className="paper_wrapper">
          <Stack gap={2}>
            <Typography variant="h6" fontWeight={700} textTransform={'none'}>
              {__('list_introduced')}
            </Typography>
            <Typography color="text.secondary" fontWeight={600}>
              {__('latest')}
            </Typography>

            {helpers.isEmpty(myIntroduction?.yours) ? (
              <Stack direction={'row'} justifyContent={'center'}>
                <ComponentEmpty text="" />
              </Stack>
            ) : (
              <>
                {myIntroduction?.yours.map((item: TypedMyIntroductionItem, idx: number) => (
                  <Stack
                    ref={ref}
                    key={idx}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={1}
                    sx={{
                      borderBottom: theme => `1px solid ${theme.palette.divider}`,
                      p: theme => theme.spacing(1, 0, 2, 0)
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <Typography
                        sx={{ minWidth: theme => theme.spacing(5), textAlign: 'center' }}
                        fontWeight={600}
                      >
                        {idx + 1}
                      </Typography>
                      {_renderItem(item)}
                    </Stack>
                    <IconButton
                      onClick={() => handleCreateChatroomToUser(item?._id)}
                      sx={{
                        p: theme => theme.spacing(1.25),
                        background: theme => theme.palette.background.default,
                        borderRadius: theme => theme.spacing(1.5)
                      }}
                    >
                      <MessageIcon sx={{ color: theme => theme.palette.text.primary }} />
                    </IconButton>
                  </Stack>
                ))}
              </>
            )}
          </Stack>
        </Paper>
      </MyIntroductionContainer>
    </BigLayout>
  )
}

export default MyIntroductionPage

const MyIntroductionContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  '& .paper_wrapper': {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  }
}))
