import '../media/courses.view.modules.item.scss'
import {TypedModule} from 'entities/courses/courses.interface'
import React, {useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Collapse, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {faAngleUp, faCircleCheck, faFile} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {formatTime} from 'entities/courses/helpers/courses.user.helper'
import __ from 'languages/index'
import __helpers from 'helpers/index'
import helpers from 'helpers/index'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import FaBookIcon from '../../../icons/FaBookIcon'
import { Box, Link, Typography } from '@mui/material'

interface TypedCoursesViewModulesItemProps {
  module: TypedModule;
  isLast: boolean;
  idSelected?: string;
  alreadyOpen?: boolean
  isCanJoinCourse: boolean
  resetCountTime?: (id: string) => void
}

export default function CoursesViewModulesItem({module, isLast, alreadyOpen, idSelected, isCanJoinCourse = true, resetCountTime}: TypedCoursesViewModulesItemProps) {
  const {_id, title, subModule} = module
  const [open, setOpen] = useState(!!alreadyOpen);
  const navigate =  useNavigate()
  const toast = useToast()
  const onClickToView = useCallback((item: TypedModule)=>{
    if(!isCanJoinCourse){
      return toast.show({
        content: `${__("course_item_message_warning")}`,
        type: EnumTypeToast.Warning
      })
    }
     typeof resetCountTime === 'function' && resetCountTime(item?._id)
      return navigate(`${isCanJoinCourse ? `/study/${item?.course_id}/${item?._id}`:'#'}`)
  },[isCanJoinCourse])

  const renderSubModule = useCallback((item: TypedModule) => {
    const handlePreviewFile = useCallback((file_id: string)=>{
      if(item?.media_files.length > 0) {
        const file = item?.media_files.find((file)=> file?._id === file_id )
        window.open( decodeURI(file.media_url), '_blank')
      }
    },[item])

    return (
      <Box key={item?._id}>
        <Link
          onClick={() => onClickToView(item)}
          className="pt-3 pb-3 pe-4 ps-4 coursemoduleitem_container"
          sx={{
            textDecoration: 'none',
            cursor: isCanJoinCourse ? 'pointer' : 'unset',
            width: '-webkit-fill-available',
            border: 'none',
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridGap: '8px'
          }}>
          <Box className="d-flex align-items-center">
            <OverlayTrigger placement="left" overlay={
              <Tooltip><strong>{item.is_view ? `${__('course_status_seen')}` : `${__('course_status_not_see')}`}</strong></Tooltip>}>
              {
                item.is_view ?
                  <FontAwesomeIcon icon={faCircleCheck} className="coursemoduleitem_checkicon" />
                  :
                  <FaBookIcon
                    sx={{
                      width: theme => theme.spacing(1.5),
                      height: theme => theme.spacing(1.5),
                      color: theme => theme.palette.primary.main,
                      marginRight: theme => theme.spacing(1),

                    }}
                  />
              }
            </OverlayTrigger>
            <Typography
              sx={{
                color: idSelected !== item?._id ? theme => theme.palette.text.primary : theme => theme.palette.primary.main,
                ':hover': {
                  color: theme => theme.palette.primary.main
                },

              }}
            >
              {item?.title}
            </Typography>
          </Box>

          <Typography
            sx={{
              color: idSelected !== item?._id ? theme => theme.palette.text.primary : theme => theme.palette.primary.main,
            }}
          >
            {item?.media_id?.media_mime_type?.includes("video")?formatTime(item?.media_id?.media_meta?.duration * 1000):helpers.getFileExtension(item?.media_id?.media_file_name)}
          </Typography>

        </Link>
        {
          !helpers.isEmpty(item?.media_files) && item?.media_files.map((file)=> (
            <a href={'#'} key={file?._id}
               className="pe-4 ps-4 btn btn-outline-primary border-0 d-flex flex-column courseupdate_file"
               onClick={() => handlePreviewFile(file?._id)}
            >
              <div className="courseupdate_file_name">
                <FontAwesomeIcon icon={faFile} />
                <span
                  className="courseupdate_media_file_name">{__helpers.getTrimContent(helpers.convertUTF8ToUnicodeText(`${file?.media_file_name}`), 50) || ''}</span>
              </div>
            </a>
          ))
        }
      </Box>
    )
  }, [idSelected, isCanJoinCourse, module])

  return (
    <div className='wrapper_coursemoduleite' style={{ textDecoration: 'none' }}>
      <div className={'coursemoduleitem ' + (isLast ? 'last_module' : '')}>
        <div className={`coursemoduleitem_groupheader ${subModule.length > 0 ? 'not_empty' : ''}`}>
          <div onClick={() => setOpen(!open)} className="d-flex align-items-center justify-content-between"
               aria-controls={'collapseTarget' + _id} aria-expanded={open}>
            <div className="d-flex align-items-center item_left">
              <div className={`coursemoduleitem_title ${subModule.length > 0 ? '' : 'empty_module'}`}>{title}</div>
            </div>
            <div className="coursemoduleitem_numModule">
              <span>{`${subModule?.length} ${__('course_view_less')}`}</span>
              <span>
              <FontAwesomeIcon
                icon={faAngleUp}
                className={`coursemoduleitem_angleicon ${open ? '' : 'rotate_arrow_module'} ${module?.subModule?.length > 0 ? '' : 'invisible'}`} />
              </span>
            </div>
          </div>
        </div>

        {
          subModule.length > 0 ?
            <Collapse in={open}>
              <Box id={'collapseTarget' + _id}>
                {
                  subModule.map(renderSubModule)
                }
              </Box>
            </Collapse>
            :
            null
        }

      </div>


    </div>
  )
}
