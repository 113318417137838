import {createEntitySlice, IQueryParams, serializeAxiosError} from 'config/reducer.utils'
import {createAsyncThunk, isFulfilled} from '@reduxjs/toolkit'
import axios from 'axios'
import helpers from 'helpers/index'
import {TypedMemberRanking} from 'entities/leaderBoard/interface'
import {
  CreatePoll,
  DataCreateReport,
  DataCreateRequest,
  DataGetCommentDTO, DataGetListCommentDTO,
  DetailCategoryDTO,
  DetailRequestDTO,
  GetCommentDto,
  GetLikeDto
} from './home.interface'
import {TypedCategory, TypedRequest} from "../../interfaces/request.interface";
import {uniqBy} from 'lodash'

interface InitialState {
  dataComment: any[],
  currentRequest:TypedRequest,
  dataRequest:TypedRequest[],
  categories:TypedCategory[],
  loadingRequest: boolean
}

export const initialState: InitialState = {
  dataComment: [],
  currentRequest:null,
  dataRequest:[],
  categories:[],
  loadingRequest: true,
}


const apiUrl = 'request'
export const getListRequest = createAsyncThunk(
  "home/getListRequest",
  async ({ page = 1, limit = 12, post_category, auth_id = "", order_type = "time", is_pin, comment_number, channel_id }: { page: number, limit: number, post_category?: string, auth_id: string, is_pin?: "0" | "1", comment_number?: string, order_type?: "time" | "most_popular" | "most_upvote" | "trending" | string, user_id?: string, channel_id?: string }) => {
    const response = await axios.get<TypedRequest[]>(`${apiUrl}/list?page=${page}&limit=${limit}${post_category ? `&post_category=${post_category}` : ""}&auth_id=${auth_id}&order_type=${order_type}${is_pin ? "&is_pin=1" : ""}${comment_number ? "&comment_number=0" : ""}&order_by=DESC&channel_id=${channel_id}`);
    return { ...response, page }
  },
  { serializeError: serializeAxiosError }
);

export const getRequestByIdOrSlug = createAsyncThunk(
  "home/getRequestByIdOrSlug",
  async (idOrSlug: string) => {
    return await axios.get<TypedRequest>(`request/detail/${idOrSlug}`)
  },
  { serializeError: serializeAxiosError }
);

export const getRequestHome = async (params: DetailRequestDTO) => {
  const requestUrl = `${apiUrl}/list?page=${params?.page || 1}&search=${params.search || ''}&limit=${params?.limit || 12}&user_id=${params.user_id || ""}&post_category=${params?.post_category || ''}&order_by=DESC&order_type=${params?.order_type || 'time'}&auth_id=${params?.auth_id || ''}&channel_id=${params?.channel_id || ''}&post_status=${params?.post_status || ''}&data_json_type=${params?.data_json_type || ''}`
  return axios.get<TypedRequest[]>(requestUrl)
}

export const getListRequestCategory = createAsyncThunk(
  "home/getListRequestCategory",
  async () => {
    const requestUrl = `${apiUrl}/list-category?page=1&limit=100&public_status=1&order_by=DESC`
    return axios.get<any>(requestUrl)
  },
  { serializeError: serializeAxiosError }
);

export const updateRequest = createAsyncThunk(
  'request/update_request',
  async (data: DataCreateRequest) => {
    let url = "/request/update";
    const response = await axios.patch<TypedRequest>(url, helpers.cleanEntity(data))
    return response;
  },
  { serializeError: serializeAxiosError }
)

export const renewRequest = createAsyncThunk(
  'request/update_request',
  async (data: {_id: string, auth_id: string}) => {
    let url = `/request/detail/${data?._id}?auth_id=${data?.auth_id}`;
    return await axios.get<any>(url);
  },
  { serializeError: serializeAxiosError }
)

export const getListApproval = createAsyncThunk(
  'homePage/list_approval',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<never>(`${apiUrl}/list`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const likePost = createAsyncThunk(
  'request/likePost',
  async (data: GetLikeDto) => {
    let url = "/request/create-like";
    return await axios.post<GetLikeDto>(url, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)


export const getNotification = async (params: { limit?: number, page?: number, all?: boolean}) => {
  let url = `notification?page=${params?.page || 1}&limit=${params.limit || 15}&order_by=DESC&all=${params.all}`;
  return axios.get(url, {});
};
export const updateNotification = async (_id: string) => {
  return await axios.patch(`/notification/${_id}`);
};

export const getListComment = (params: DataGetListCommentDTO) => {
  const EndURL = helpers.buildEndUrl(params);
  let dataUrl = `/request/list-comment${EndURL}`;
  return axios.get<any>(dataUrl)
}
export const saveComment = createAsyncThunk(
  'request/saveComment',
  async (data: Array<any>) => {
    return data
  },
  { serializeError: serializeAxiosError }
)


export const voteRequest = async (data: { request_id: string, poll_id: string }) => {
  let dataUrl = `/request/vote-poll`;
  return await axios.post(dataUrl, data)
}


export const deletePost = createAsyncThunk(
  'request/deletePost',
  async (id: string) => {
    let url = "/request/delete/" + id;
    return await  axios.delete<TypedRequest>(url);
  },
  { serializeError: serializeAxiosError }
)

const HOME_REDUCER = createEntitySlice({
  name: 'home',
  initialState,
  reducers: {
    clearError: state => {

    },
    isFulfilledListRequest: (state, action)=>{
      state.dataRequest = action.payload
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.dataComment = []
    },
    updateRequestNormal: (state, action)=>{
      state.dataRequest = state.dataRequest.map(i => i._id === action.payload?._id ? { ...i,...action.payload, type: "update" } : i)
      state.currentRequest = state.currentRequest?._id === action.payload?._id? { ...state.currentRequest,...action.payload, type: "update" } : state.currentRequest
    }
  },
  extraReducers(builder) {
    builder
      .addCase(saveComment.fulfilled, (state, action) => {
        state.dataComment = action.payload
      })
      .addCase(getRequestByIdOrSlug.fulfilled, (state, action) => {
        state.currentRequest = action.payload.data
      })
      .addMatcher(isFulfilled(getListRequest), (state, action) => {
        state.dataRequest = action.payload.page === 1 ? action.payload.data : uniqBy(state.dataRequest.concat(action.payload.data), "_id")
      })
      .addMatcher(isFulfilled(getListRequestCategory), (state, action) => {
        state.categories = Array.isArray(action.payload.data)? action.payload.data : []
      })
      .addMatcher(isFulfilled(deletePost), (state, action) => {
        state.dataRequest = state.dataRequest.filter(i => i._id !== action.payload.data?._id)
        state.currentRequest = state.currentRequest?._id === action.payload.data?._id? null : state.currentRequest
      })
      .addMatcher(isFulfilled(likePost), (state, action) => {
        state.dataRequest = state.dataRequest.map(i => i._id === action.payload.data?.request_id ? { ...i, is_like: action.payload.data.is_like, like_number: action.payload.data.like_number, type: "like", updatedAt: new Date().toISOString() } : i)
        state.currentRequest = state.currentRequest?._id === action.payload.data?.request_id? {...state.currentRequest, is_like: action.payload.data.is_like, like_number: action.payload.data.like_number, type: "like", updatedAt: new Date().toISOString()} : state.currentRequest
      })
      .addMatcher(isFulfilled(updateRequest), (state, action) => {
        // if (action.meta.arg.is_pin == "1") {
        //   state.dataRequest = [{ ...action.payload.data, type: "update" }, ...state.dataRequest.filter(i => i._id !== action.payload.data._id)]
        // } else {
        //   state.dataRequest = state.dataRequest.map(i => i._id === action.payload.data?._id ? { ...action.payload.data, type: "update" } : i)
        // }
        state.currentRequest = state.currentRequest?._id === action.payload.data?._id ? { ...action.payload.data, type: "update" } : state.currentRequest
        state.dataRequest = state.dataRequest.map(i => i._id === action.payload.data?._id ? { ...action.payload.data, type: "update" } : i)
      })
      .addMatcher(isFulfilled(renewRequest), (state, action) => {
        state.dataRequest = state.dataRequest.map(i => i._id === action.payload.data?._id ? action.payload.data : i)
        state.currentRequest = state.currentRequest?._id === action.payload.data?._id ? action.payload.data : state.currentRequest
      })
  }
})
export const { clearError, updateRequestNormal, isFulfilledListRequest, reset, clearEntity } = HOME_REDUCER.actions
export default HOME_REDUCER.reducer
