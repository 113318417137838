import { Box, Checkbox, Stack, styled, Typography } from '@mui/material'
import __helpers from 'helpers/index'
import __ from 'languages/index'
import Select from 'components/Select'
import { convertMonthYearTime } from 'helpers/dateAndTime'
import React, { useEffect, useState } from 'react'
import { EnumCourseOffline, TypeDataCourse } from '../CoursesOffline'
import { TypeCoursesOffline } from 'entities/channel-settings/service/service.type'

interface TypeCoursesOfflineItem {
  selectTime: {
    isCheck: EnumCourseOffline,
    course: TypeDataCourse[]
  },
  course: TypeCoursesOffline
  setSelectTime: React.Dispatch<React.SetStateAction<{
    isCheck: EnumCourseOffline,
    course: TypeDataCourse[]
  }>>
}
const CourseOfflineItem = ({selectTime, course, setSelectTime}: TypeCoursesOfflineItem) =>{
  const [timeId,setTimeId]= useState<string>( null)
  const handleChangeTime = (value: string, course_id: string)=>{
    setTimeId(value)
    if(selectTime){
      setSelectTime({
        ...selectTime,
        course: [...selectTime?.course].map(item => {
          return item?._id  === course_id ? {
            _id: course_id,
            time_id: value
          } : item
        })
      })
    }
  }
  console.log('course',course)

  return (
    <CourseOfflineItemContainer
      direction={'column'}
      key={`courses_offline_item_${course?._id}`}
    >
      <Box className={'courses_offline_item_content'}>
        <Box>
          <Typography variant={'body2'} color={'text.secondary'}>{course?.course_offline_code}</Typography>
          <Typography>{course?.course_offline_title}</Typography>
        </Box>
        <Checkbox
          checked={selectTime?.isCheck == EnumCourseOffline.JOINED && !!selectTime?.course?.find(data => data?._id === course?._id)}
          onChange={()=> setSelectTime({
            isCheck: EnumCourseOffline.JOINED,
            course: __helpers.isEmpty(selectTime?.course) ? [
              {
                _id: course?._id,
                time_id: timeId ?? convertMonthYearTime(course?.course_offline_time)?.[0]?._id
              }
            ] : [
              ...selectTime?.course,
              {
                _id: course?._id,
                time_id: timeId ?? convertMonthYearTime(course?.course_offline_time)?.[0]?._id
              }
            ]
          })}
          color="primary"
        />
      </Box>
      <Box className={'courses_offline_item_content'}>
        <Typography>{__('course_offline_sub_text')}</Typography>
        <Select
          onChangeValue={(value)=>handleChangeTime(value, course?._id)}
          inputProps={{
            sx:{
              padding: theme => theme.spacing(0, 1),
              minWidth: theme => theme.spacing(19.25),
              width: `max-content`,
              height: theme => theme.spacing(4),
              borderRadius: theme => theme.spacing(1.5)
            }
          }}
          value={timeId ?? convertMonthYearTime(course?.course_offline_time)?.[0]?._id}
          options={!__helpers.isEmpty(course?.course_offline_time) && convertMonthYearTime(course?.course_offline_time)}
          getItemValue={item => item?._id}
          renderItem={item => <Typography key={`Select_time_${item?._id}`}>{item?.time}</Typography>}
        />
      </Box>

    </CourseOfflineItemContainer>
  )
}
export default CourseOfflineItem
const CourseOfflineItemContainer = styled(Stack)(({theme}) => ({
  borderRadius: theme.spacing(1.5),
  border: `${theme.spacing(0.2)} solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.75,1),
  marginBottom: theme.spacing(2),
  '& .courses_offline_item_content':{
    width: '100%',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

}))
