import axios from 'axios'
import { IQueryParams } from 'config/reducer.utils'
import helpers from 'helpers'
import { TypedNewsForCategory, TypedNews, TypePaloadCategory, TypedCategory } from '../interface'

async function getNewsToCategory(params: IQueryParams) {
  helpers.clearValueEmptyInObject(params)
  return await axios.get<TypedNewsForCategory[]>(`/news-category`, { params: params })
}

async function getNewsDetailById(_newsId: string) {
  return await axios.get<TypedNews>(`/news/${_newsId}`)
}

async function getNewsBySearch(params: IQueryParams) {
  return await axios.get<TypedNews[]>(`/news`, { params: params })
}

async function createCategoryNews(category_id: Partial<TypedCategory>) {
  helpers.clearValueEmptyInObject(category_id)
  return await axios.post<TypedCategory>(`/news-category`, category_id)
}

async function updateCategoryNews(category_id: Partial<TypedCategory>) {
  return await axios.patch<TypedCategory>(`/news-category/${category_id?._id}`, category_id)
}
async function deleteCategoryNews(_id: string) {
  return await axios.delete<any>(`/news-category/${_id}`)
}
async function createNews(body) {
  helpers.clearValueEmptyInObject(body)
  return await axios.post<any>(`/news`, body)
}

async function deleteNews(newsId: string) {
  return await axios.delete<any>(`/news/${newsId}`)
}

async function updateNews(newsId: string, body: any) {
  return await axios.patch<any>(`/news/${newsId}`, body)
}

export { getNewsToCategory, getNewsDetailById, getNewsBySearch, createNews, deleteNews, updateNews, createCategoryNews, updateCategoryNews , deleteCategoryNews }
