import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../../media/challenge.add.scss'
import { Form, Image, Spinner } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ChallengeCondition from '../challenge.add/challenge.condition.component'
import {
  TypedChallengeDetail,
  TypedChallengeGift,
  TypedCreateChallenge,
  TypedCustomFiled,
  TypedLeg
} from 'entities/challenge/challenge.interface'
import ChallengeLegComponent from '../challenge.add/challenge.leg.component'
import ListLegComponent from './list.leg.component'
import ListGiftComponent from './list.gift.component'
import { useNavigate } from 'react-router-dom'
import DateTime from 'date-and-time'
import ImgBlur from 'entities/courses/media/img_blur.png'
import { useAppDispatch, useAppSelector } from 'config/store'
import {
  createChallenge,
  createGameChallenge,
  getDetailChallenge,
  getDetailGame,
  getListGiftChallenge,
  removeDataGiftAdd,
  setCookbook,
  updateChallenge
} from 'entities/challenge/challenge.store.reducer'
import CustomGame from './challenge.custom.game'
import { uploadMedia } from 'entities/calendar/service'
import { TypedPublicAlbum } from 'entities/calendar/calendar.interface'
import DeleteConfirmModal from 'components/deleteConfirmModal'
import { addTimeZone, convertTimeToTimeZone } from 'entities/challenge/helpers'
import { getImageDefaultChallenge } from 'entities/challenge/services'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../../hooks/useToast'
import { Box, Button, Radio, Stack, Tooltip, Typography, styled } from '@mui/material'
import { CheckInactiveIcon, LockIcon, PlusIcon, UploaderIcon } from 'icons'
import GlobalIcon from 'icons/GlobalIcon'
import GiftReceiverGiftAddModal from 'entities/gift/components/gift.receivergift.add.modal'
import BreadcrumbComponent from 'components/BreadcrumbComponent'

interface Props {
  isUpdate: string
}

const ChallengeAddDetail = (props: Props) => {
  const { isUpdate } = props

  const checkCookbook = isUpdate.includes('cookbook=')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const cookbook = useAppSelector(state => state.challenge.cookbook)

  // useMemo
  const getDefaultDate = useMemo(() => {
    let d = new Date()
    d.setMinutes(d.getMinutes() + 30)
    d.setHours(d.getHours() + 7)
    return d.toISOString().slice(0, 16)
  }, [])

  const getDefaultEndDate = useMemo(() => {
    let d = new Date(getDefaultDate)
    d.setDate(d.getDate() + 30)
    return d.toISOString().slice(0, 16)
  }, [])

  // Refs Input
  const descriptionInputRef = useRef(null)
  const titleInputRef = useRef(null)
  const titleGameRef = useRef(null)
  const searchSelectRef = useRef(null)
  const giftModalRef = useRef(null)
  const conditionRef = useRef(null)
  const customGameRef = useRef(null)
  // =======================================

  // Refs
  const coverRef = useRef<TypedPublicAlbum>(null)
  const descriptionRef = useRef<string>('')
  const titleRef = useRef<string>('')
  const refGiftReceiverGiftAddModal = useRef<any>()
  const refListGiftIdsAdded = useRef<string[]>([])

  const deleteConfirmRef = useRef(null)
  // =======================================

  // State
  const [validate, setValidate] = useState<boolean>(false)
  const [listLeg, setListLeg] = useState<TypedLeg[]>([])
  const [listGift, setListGift] = useState<TypedChallengeGift[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [openDate, setOpenDate] = useState<string>(getDefaultDate)
  const [endDate, setEndDate] = useState<string>(getDefaultEndDate)
  const [condition, setCondition] = useState({ key: 'all', value: '' })
  const [listGame, setListGame] = useState<TypedCustomFiled[]>([])
  const [status, setStatus] = useState<string>('public')
  const [nameGame, setNameGame] = useState<string>('')
  const [createdNextCycle, setCreatedNextCycle] = useState<boolean>(false)
  const [addAllUser, setAddAllUser] = useState<boolean>(false)
  const toast = useToast()
  // =======================================

  // useEffect
  useEffect(() => {
    getListGift()
    getDataCookbook()
    dispatch(removeDataGiftAdd())
    return () => {
      dispatch(removeDataGiftAdd())
    }
  }, [])
  // =======================================

  // Function

  const getDataCookbook = useCallback(async () => {
    if (checkCookbook) {
      const newArr = isUpdate.split('cookbook=')
      setDataDefault()
      if (newArr[1] == '') {
        dispatch(setCookbook('empty'))
      } else {
        const res: any = await dispatch(getDetailGame({ _id: newArr[1] }))
        dispatch(setCookbook(res?.payload?.data))
        if (descriptionInputRef.current.editor?.setData) {
          descriptionInputRef.current.editor.setData(res?.payload?.data?.description)
        }
        if (titleInputRef.current) {
          titleInputRef.current.value = res?.payload?.data?.title
          titleRef.current = res?.payload?.data?.title
        }
        setAddAllUser(true)
        setCreatedNextCycle(true)
      }
    } else {
      getDataUpdate()
    }
  }, [])

  const getListGift = useCallback(async () => {
    await dispatch(getListGiftChallenge())
  }, [])

  const setDataDefault = useCallback(async () => {
    const res: any = await getImageDefaultChallenge()
    if (res?.data?.media_url) {
      setCoverUrl(res?.data?.media_url)
      coverRef.current = res?.data
    }
  }, [])

  const getDataUpdate = useCallback(async () => {
    let detail: TypedChallengeDetail = null
    const res: any = await dispatch(getDetailChallenge({ _id: !checkCookbook && isUpdate }))
    if (res?.payload?.data) {
      detail = res?.payload?.data
    }
    if (detail) {
      // set cover
      setCoverUrl(detail?.avatar?.media_url || detail?.avatar?.media_thumbnail)
      coverRef.current = detail?.avatar
      // add all user
      setAddAllUser(detail?.add_all_user || false)

      // create next level
      setCreatedNextCycle(detail?.create_next_cycle)

      // set title
      if (titleInputRef.current) {
        titleInputRef.current.value = detail?.title
        titleRef.current = detail?.title
      }
      // set open date
      const start_time = addTimeZone(detail?.start_time)?.replace(/:00.000Z/, '')
      setOpenDate(start_time)

      // set end date
      const end_time = addTimeZone(detail?.end_time)?.replace(/:00.000Z/, '')

      setEndDate(end_time)
      // set gifts
      setListGift(detail?.gift_data)
      if (searchSelectRef.current) {
        searchSelectRef.current?.setValue(detail?.gift_data)
      }
      // set list leg
      setListLeg(detail?.challenge_stage)
      // set description
      setTimeout(() => {
        if (descriptionInputRef.current.editor?.setData) {
          descriptionInputRef.current.editor.setData(detail?.long_description)
        }
      }, 300)
      // set public_status
      setStatus(detail?.public_status)
      if (detail?.game_id) {
        if (
          Array.isArray(detail?.game_id?.custom_field) &&
          detail?.game_id?.custom_field.length > 0 &&
          detail?.game_id?.game_type == 'custom'
        ) {
          setListGame(detail?.game_id?.custom_field)
          setNameGame(detail?.game_id?.title)
          dispatch(setCookbook('empty'))
          setTimeout(() => {
            customGameRef.current.setTitleName(detail?.game_id?.title)
            titleGameRef.current.value = detail?.game_id?.title
          }, 200)
        } else {
          dispatch(setCookbook(detail?.game_id))
        }
      }
      // condition
      if (detail?.level_value == 1 && detail?.coin_value == 0) {
        conditionRef.current.setDefaultValue('all', '1')
      } else if (detail?.level_value == 1 && detail?.coin_value > 0) {
        conditionRef.current.setDefaultValue('point', `${detail?.coin_value}`)
      } else if (detail?.level_value > 1 && detail?.coin_value == 0) {
        conditionRef.current.setDefaultValue('level', `${detail?.level_value}`)
      }
    }
  }, [])

  const _onCreateChallenge = async event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      if (!coverRef.current) {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content')}`,
          type: EnumTypeToast.Error
        })
      }
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      if (openDate == endDate) {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content_attention')}`,
          type: EnumTypeToast.Error
        })
        return
      }
      if (listLeg.length > 0 && endDate > listLeg[listLeg.length - 1]?.end_time) {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content_more_races')}`,
          type: EnumTypeToast.Error
        })
        return
      }

      if (titleRef.current.trim() == '') {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_sub_text')}`,
          type: EnumTypeToast.Error
        })
        titleInputRef?.current?.focus()
        return
      }

      if (nameGame.trim() == '' && typeof cookbook == 'string') {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content_game_name')}`,
          type: EnumTypeToast.Error
        })
        customGameRef?.current?.focusTitle()
        return
      }

      if (typeof cookbook === 'string' && listGame.length == 0) {
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content_method_scoring')}`,
          type: EnumTypeToast.Error
        })
        customGameRef.current.focusKey()
        return
      }
      setLoading(true)
      let newItem: TypedCreateChallenge = {
        create_next_cycle: createdNextCycle,
        add_all_user: addAllUser,
        title: titleRef.current.trim(),
        start_time: openDate.includes(':00.000Z')
          ? openDate
          : convertTimeToTimeZone(`${openDate}:00.000Z`),
        end_time: endDate.includes(':00.000Z')
          ? endDate
          : convertTimeToTimeZone(`${endDate}:00.000Z`),
        avatar: coverRef.current?._id,
        long_description: descriptionRef.current,
        // @ts-ignore
        challenge_stage: JSON.stringify(
          listLeg.map(i => {
            // delete i?._id
            return {
              description: i?.description,
              name: i?.name.trim(),
              start_time: i?.start_time.includes(':00.000Z')
                ? i?.start_time
                : convertTimeToTimeZone(`${i?.start_time}:00.000Z`),
              end_time: i?.end_time.includes(':00.000Z')
                ? i?.end_time
                : convertTimeToTimeZone(`${i?.end_time}:00.000Z`),
              gift_data: i?.gift_data.map(item => {
                return item?._id
              })
            }
          })
        ),
        // @ts-ignore
        gift_data: JSON.stringify(
          listGift.map(item => {
            return item?._id
          })
        ),
        game_id: '',
        level_value: condition?.key == 'level' ? condition?.value : '1',
        coin_value: condition?.key == 'point' ? condition?.value : '0',
        public_status: status,
        description: ''
      }

      if (typeof cookbook !== 'string') {
        newItem = { ...newItem, game_id: cookbook?._id }
      } else {
        try {
          const res: any = await dispatch(
            createGameChallenge({
              title: nameGame.trim(),
              custom_field: JSON.stringify(
                listGame.map(j => {
                  return { name: j?.name, field_type: j?.field_type }
                })
              ),
              game_type: 'custom'
            })
          )

          if (res?.payload?.data) {
            newItem = { ...newItem, game_id: res?.payload?.data?._id }
          }
        } catch (error) {
          console.log('error asdasd', error)
        }
      }

      if (!checkCookbook && isUpdate) {
        if (newItem?.game_id) {
          try {
            if (newItem?.gift_data == '[]') {
              delete newItem?.gift_data
            }
            const res: any = await dispatch(updateChallenge({ ...newItem, _id: isUpdate }))
            if (res?.payload?.data) {
              toast?.show({
                title: `${__('notification')}`,
                content: `${__('challenge_add_detail_content_update')}`,
                type: EnumTypeToast.Success
              })
              setTimeout(() => {
                setLoading(false)
                navigate('/challenge')
              }, 1500)
            } else {
              alert(`${__('challenge_add_detail_content_error')}`)
              setLoading(false)
            }
          } catch (error) {
            alert(`${__('challenge_add_detail_content_error')}`)
            setLoading(false)
          }
        }
      } else {
        try {
          if (newItem?.game_id) {
            if (newItem?.gift_data == '[]') {
              delete newItem?.gift_data
            }
            const res: any = await dispatch(createChallenge(newItem))
            if (res?.payload?.data) {
              toast.show({
                title: `${__('notification')}`,
                content: `${__('challenge_add_detail_content_success')}`,
                type: EnumTypeToast.Success
              })
              setTimeout(() => {
                setLoading(false)
                navigate('/challenge')
              }, 1500)
            } else {
              alert(`${__('challenge_add_detail_content_error')}`)
              setLoading(false)
            }
          } else {
            alert(`${__('challenge_add_detail_content_error')}`)
            setLoading(false)
          }
        } catch (error) {
          alert(`${__('challenge_add_detail_content_error')}`)
          setLoading(false)
        }
      }
    }
    setValidate(true)
  }

  const _onDescriptionChange = useCallback((event, editor) => {
    descriptionRef.current = editor.getData()
  }, [])

  const _addItemLeg = useCallback((item: TypedLeg) => {
    setListLeg(prev => [...prev, ...[item]])
  }, [])

  const _goBack = useCallback(() => {
    navigate(-1)
  }, [])

  const _onCoverChange = useCallback(async event => {
    if (event.target.files[0]) {
      setCoverUrl(ImgBlur)
      const link = URL.createObjectURL(event.target.files[0])

      try {
        const res: any = await uploadMedia(event.target.files[0])

        if (Array.isArray(res) && res.length > 0) {
          coverRef.current = res[0]?.callback
        }
      } catch (error) {
        console.log('error_234234', error)
      }
      setTimeout(() => {
        setCoverUrl(link)
      }, 1000)
    }
  }, [])

  const _onTitleChange = useCallback(event => {
    titleRef.current = event.target.value
  }, [])

  const _onOpenDateChange = useCallback(event => {
    setOpenDate(event.target.value)
    let newOpenDate = new Date(event.target.value)
    newOpenDate.setDate(newOpenDate.getDate() + 30)
    setEndDate(newOpenDate.toISOString().slice(0, 16))
  }, [])

  const _onEndDateChange = useCallback(event => {
    setEndDate(event.target.value)
  }, [])

  const _onSetCondition = useCallback(result => {
    setCondition(result)
  }, [])

  const onAddGame = useCallback((item: TypedCustomFiled) => {
    setListGame(prev => [...prev, ...[item]])
    toast.show({
      title: `${__('notification')}`,
      content: `${__('challenge_add_detail_content_method_scoring_success')}`,
      type: EnumTypeToast.Success
    })
  }, [])

  const _onDeleteGame = useCallback((item: TypedCustomFiled) => {
    deleteConfirmRef.current?.show(
      `${__('challenge_add_detail_content_delete_method_scoring')}`,
      () => {
        setListGame(prev => prev.filter(i => i?._id != item?._id))
        toast.show({
          title: `${__('notification')}`,
          content: `${__('challenge_add_detail_content_delete_method_scoring')}`,
          type: EnumTypeToast.Success
        })
      }
    )
  }, [])

  const _onDeleteGift = useCallback((item: TypedChallengeGift) => {
    deleteConfirmRef.current.show(`${__('challenge_add_detail_content_delete_gift')}`, () => {
      let newArr: TypedChallengeGift[] = []
      setListGift(prev => {
        newArr = prev.filter(i => i?._id != item?._id)
        return newArr
      })
      if (searchSelectRef.current) {
        searchSelectRef.current?.setValue(newArr)
      }
      toast.show({
        title: `${__('notification')}`,
        content: `${__('challenge_add_detail_content_delete_gift_success')}`,
        type: EnumTypeToast.Success
      })
    })
  }, [])

  const _onGiftChange = useCallback(data => {
    setListGift(old => [...old, data])
    refListGiftIdsAdded.current = [...refListGiftIdsAdded.current, data?._id]
  }, [])

  const _onChangeStatus = useCallback((status: string) => {
    setStatus(status)
  }, [])

  const _onNameGameChange = useCallback((name: string) => {
    setNameGame(name)
  }, [])

  const _onDeleteLeg = useCallback((_id: string) => {
    deleteConfirmRef.current.show(`${__('challenge_add_detail_content_delete_race')}`, () => {
      setListLeg(prev => prev.filter(item => item?._id != _id))
      toast.show({
        title: `${__('notification')}`,
        content: `${__('challenge_add_detail_content_delete_race_success')}`,
        type: EnumTypeToast.Success
      })
    })
  }, [])

  const _onCreatedNextCycleChange = useCallback(() => {
    setCreatedNextCycle(prev => !prev)
  }, [])

  const _onAddAllUserChange = useCallback(() => {
    setAddAllUser(prev => !prev)
  }, [])

  // =======================================

  const renderHeader = useCallback(() => {
    return (
      <Box className="w-100  position-relative box_header mb-3">
        <Box className="image-cover">
          <Form.Control
            className="file-input-cover"
            type="file"
            id="image"
            accept=".png,.jpg,.jpeg,.gif"
            required={!coverUrl}
            onChange={_onCoverChange}
          />
          {/* <div className="button-choose-image-cover">
            <i className="bi bi-image-fill fs-4" />
          </div> */}
          {coverUrl && (
            <div className="image-cover-add-container-cover">
              <Image src={coverUrl} className="image-cover-add" alt="placeholder" />
            </div>
          )}
          <Stack justifyContent={'center'} alignItems={'center'} className="main-content-add-cover">
            <UploaderIcon width={'44px'} height={'44px'} />

            <Typography mt={2} variant="subtitle2">
              {__('file_uploader_text')}
            </Typography>
            <Typography mt={2} color={'text.secondary'}>
              {__('challenge_update_image')}
            </Typography>
            <Typography color={'text.secondary'}>{__('challenge_type_image')}</Typography>
          </Stack>
        </Box>
        <Box className="info">
          <Typography variant="h6" textTransform={'none'}>
            {__('challenge_new_game')}
          </Typography>

          <Box className="box_form" mt={2}>
            <Tooltip title={__('challenge_add_detail_header_title')}>
              <Form.Group className="mb-1">
                <Form.Control
                  maxLength={100}
                  onChange={_onTitleChange}
                  type="search"
                  ref={titleInputRef}
                  id="title"
                  required
                  placeholder={__('challenge_add_detail_header_title')}
                />

                <div className="invalid-feedback">{__('challenge_add_detail_sub_text')}</div>
              </Form.Group>
            </Tooltip>

            {cookbook === 'empty' ? (
              <Tooltip className="mt-3" title={__('challenge_custom_game_name')}>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    ref={titleGameRef}
                    maxLength={100}
                    onChange={e => _onNameGameChange(e.target.value)}
                    id="gameName"
                    placeholder={__('challenge_custom_game_name')}
                  />
                  <div className="invalid-feedback">{__('challenge_custom_game_text')}</div>
                </Form.Group>
              </Tooltip>
            ) : null}

            <Tooltip className="mt-3" title={__('challenge_add_detail_start_time')}>
              <Form.Group className="mb-1">
                <Form.Control
                  required
                  type={'datetime-local'}
                  id={'openDate'}
                  value={openDate}
                  defaultValue={getDefaultDate}
                  onChange={_onOpenDateChange}
                  min={DateTime.format(new Date(), 'YYYY-MM-DD 00:00')}
                />
                <div className="invalid-feedback">{__('challenge_add_detail_start_time_text')}</div>
              </Form.Group>
            </Tooltip>
            <Tooltip className="mt-3" title={__('challenge_add_detail_end_time')}>
              <Form.Group>
                <Form.Control
                  required
                  type={'datetime-local'}
                  id={'endDate'}
                  // ref={endDateInputRef}
                  value={endDate}
                  onChange={_onEndDateChange}
                  min={DateTime.format(new Date(openDate), 'YYYY-MM-DD HH:mm')}
                />
                <div className="invalid-feedback">{__('challenge_add_detail_end_time_text')}</div>
              </Form.Group>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    )
  }, [coverUrl, openDate, endDate])

  const renderStatusChallenge = () => {
    return (
      <Form.Group className="mt-3 mb-3">
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <div
            onClick={() => _onChangeStatus('public')}
            className={`clickable ${status == 'public' && 'status-active'} item_status_challenge `}
          >
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography textTransform={'none'} variant="subtitle2">
                <GlobalIcon /> {__('challenge_add_detail_status_public')}{' '}
              </Typography>
              <Radio
                checkedIcon={<CheckInactiveIcon color="primary" />}
                checked={status === 'public'}
              />
            </Stack>
            <Typography>{__('challenge_add_detail_status_public_text')}</Typography>
          </div>
          <div
            onClick={() => _onChangeStatus('private')}
            className={`clickable ${status == 'private' && 'status-active'} item_status_challenge`}
          >
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography textTransform={'none'} variant="subtitle2">
                <LockIcon /> {__('challenge_add_detail_status_private')}{' '}
              </Typography>
              <Radio
                checkedIcon={<CheckInactiveIcon color="primary" />}
                checked={status === 'private'}
              />
            </Stack>
            <Typography>{__('challenge_add_detail_status_private_text')}</Typography>
          </div>
        </Stack>
      </Form.Group>
    )
  }

  const onShowGiftList = useCallback(() => {
    refGiftReceiverGiftAddModal.current?.show()
  }, [])

  return (
    <ChallengeAddDetailContainer id="add_challenge">
      <DeleteConfirmModal ref={deleteConfirmRef} />
      <BreadcrumbComponent
        breadcrumbName={{
          '/challenge': 'Challenge',
          '/challenge/add/cookbook': __('challenge_add_detail_title')
        }}
      />
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography textTransform={'none'} mt={2} variant="h6">
          {!checkCookbook && isUpdate
            ? `${__('challenge_add_detail_title_edit')}`
            : `${__('challenge_add_detail_title')}`}
        </Typography>

        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Button
            variant={'outlined'}
            onClick={_goBack}
            sx={{
              marginRight: theme => theme.spacing(1),
              textTransform: 'none !important'
            }}
          >
            {__('home_btn_cancel')}
          </Button>
          <Button
            onClick={() => {
              document.getElementById('submit-form').click()
            }}
            disabled={loading}
            sx={{
              textTransform: 'none !important'
            }}
          >
            {' '}
            {loading && (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="visually-hidden">{__('loading')}</span>
              </>
            )}
            {!checkCookbook && isUpdate
              ? `${__('btn_update')}`
              : `${__('challenge_add_detail_btn_create')}`}
          </Button>
        </Stack>
      </Stack>
      <Form
        noValidate
        validated={validate}
        onSubmit={_onCreateChallenge}
        style={{
          marginTop: '16px'
        }}
      >
        {renderHeader()}

        <Box className="box_options">
          <Box className="box_options-left">
            <Box className="box_options-left_ckeditor item_box">
              <Typography variant="h6" textTransform={'none'}>
                {__('livestream_information')}
              </Typography>
              <Form.Group className="mt-3">
                <CKEditor
                  editor={ClassicEditor}
                  ref={descriptionInputRef}
                  onChange={_onDescriptionChange}
                  config={{
                    plugins: ['Bold', 'Italic', 'Heading', 'Link', 'List', 'Table', 'TableToolbar']
                  }}
                />
              </Form.Group>
            </Box>

            <Box mt={2} className="box_options-left_gift item_box">
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="h6" textTransform={'none'}>
                  {__('challenge_add_detail_gift')}
                </Typography>
                <Button
                  sx={{
                    textTransform: 'none !important'
                  }}
                  onClick={onShowGiftList}
                  variant="outlined"
                >
                  <PlusIcon
                    sx={{
                      width: theme => theme.spacing(2.5),
                      height: theme => theme.spacing(2.5),
                      marginRight: theme => theme.spacing(1)
                    }}
                  />
                  {__('mission_add_reward')}
                </Button>
              </Stack>
              <Form.Group className="mb-3" style={{ order: 99 }}>
                <ListGiftComponent listGift={listGift} onDeleteGift={_onDeleteGift} />
                <GiftReceiverGiftAddModal
                  ref={refGiftReceiverGiftAddModal}
                  addGift={_onGiftChange}
                  listGiftIdsAdded={refListGiftIdsAdded.current}
                />
              </Form.Group>
            </Box>

            <Box mt={2} className="box_options-left_count item_box">
              <CustomGame
                listGame={listGame}
                ref={customGameRef}
                addGame={onAddGame}
                deleteItemGame={_onDeleteGame}
                setName={_onNameGameChange}
              />
            </Box>
          </Box>
          <Box className="box_options-right">
            <Box className="box_options-right_setting_high item_box">
              <Typography variant="h6" textTransform={'none'}>
                {__('challenge_add_detail_advanced_setting')}
              </Typography>
              <Box mt={2} className="box_options-right_setting_high-form">
                <Form.Group className="mb-3">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    checked={createdNextCycle}
                    onChange={_onCreatedNextCycleChange}
                    label={__('challenge_add_detail_automatic')}
                  />
                  <i style={{ fontSize: 12 }}>{__('challenge_add_detail_automatic_text')}</i>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    checked={addAllUser}
                    onChange={_onAddAllUserChange}
                    label={__('challenge_add_detail_automatic_add_user')}
                  />
                </Form.Group>

                {!addAllUser ? (
                  <>
                    <Form.Group className="mb-3 detail_condition">
                      <ChallengeCondition ref={conditionRef} setCondition={_onSetCondition} />
                      {renderStatusChallenge()}
                    </Form.Group>
                  </>
                ) : null}
              </Box>
            </Box>

            <Box mt={2} className="box_options-right_setting_race item_box">
              <Form.Group style={{ order: 99 }}>
                <ChallengeLegComponent
                  listLeg={listLeg}
                  startDate={openDate}
                  finishedDate={endDate}
                  addItemLeg={_addItemLeg}
                />
                <ListLegComponent listLeg={listLeg} onDelete={_onDeleteLeg} />
              </Form.Group>
            </Box>
          </Box>
        </Box>
        <Button
          id="submit-form"
          className="mx-3 button-event d-none"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="visually-hidden">{__('loading')}</span>
            </>
          )}
          {!checkCookbook && isUpdate
            ? `${__('btn_update')}`
            : `${__('challenge_add_detail_btn_create')}`}
        </Button>
      </Form>
    </ChallengeAddDetailContainer>
  )
}

export default ChallengeAddDetail

const ChallengeAddDetailContainer = styled(Box)(({ theme }) => ({
  '& .box_header': {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    background: '#fff',
    borderRadius: theme.spacing(1.5),
    height: '433px',
    padding: theme.spacing(2),

    '& .image-cover': {
      position: 'relative',

      '& .main-content-add-cover, .image-cover-add-container-cover': {
        height: '400px',
        width: '95%',
        top: 0,
        left: 0,
        borderRadius: theme.spacing(1.5),
        background: '#F3F4F5',
        border: '1px dashed #C0C5CD',
        position: 'absolute',
        zIndex: 0
      },

      '& .image-cover-add-container-cover': {
        zIndex: 1
      }
    },

    '& .info': {
      '& .box_form': {
        '& input': {
          padding: theme.spacing(1, 2),
          background: '#F3F4F5',
          border: 'none',
          height: '56px',
          borderRadius: theme.spacing(1.5)
        }
      }
    }
  },

  '& .box_options': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),

    '& .item_box': {
      width: '100%',
      background: '#fff',
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(3)
    },
    '&-left': {
      '&_ckeditor': {
        '& .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners ':
          {
            borderTopLeftRadius: `${theme.spacing(1.5)}`,
            borderTopRightRadius: `${theme.spacing(1.5)}`,
            background: '#F2F4F7',
            borderColor: '#E0E2E6',

            '& button': {
              color: '#626F82'
            }
          },

        '& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners':
          {
            borderBottomLeftRadius: `${theme.spacing(1.5)}`,
            borderBottomRightRadius: `${theme.spacing(1.5)}`,
            borderColor: '#E0E2E6'
          }
      }
    },

    '&-right': {
      '&_setting_high': {
        width: '100%',
        background: '#fff',
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(3),

        '&-form': {
          '& .detail_condition': {
            '& .item_status_challenge': {
              borderRadius: theme.spacing(1.5),
              border: '1px solid #E0E2E6',
              padding: theme.spacing(1.5),
              height: '134px',
              width: '48%',

              '@media(max-width: 1306px)': {
                '& svg': {
                  width: '24px'
                },
                '& h6': {
                  fontSize: '1rem'
                },
                '& p': {
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box'
                }
              }
            }
          }
        }
      }
    }
  }
}))
