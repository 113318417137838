//Library
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  styled
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

//Components
import HomeActionEvent from 'entities/homepage/components/home.action.event'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'

//Config
import { useAppSelector } from 'config/store'

//Store
import { getRequestHome } from 'entities/homepage/home.store.reducer'

//Hooks
import { EnumTypeToast, useToast } from 'hooks/useToast'

//Icon
import { CloseIcon } from 'icons'

//Helpers
import __ from 'languages/index'
import helpers from 'helpers'

const EventRightBarComponent = () => {
  const toast = useToast()
  const checkMobile = helpers.isMobile()

  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [eventData, setEventData] = useState([])
  const [isShowModalAllEvent, setIsShowModalAllEvent] = useState<boolean>(false)

  const handleCloseModal = useCallback(() => {
    setIsShowModalAllEvent(false)
  }, [])

  /**
   * Logic from layout/header
   */
  const handleFetchData = useCallback(async () => {
    try {
      const params: any = {
        auth_id: user_data?._id,
        limit: 10,
        data_json_type: 'challenge,livestream,gift,course,event,redeem'
      }

      const res = await getRequestHome(params)
      setEventData(res?.data)
    } catch (error) {
      console.error(`getRequestHome_${error}`)
      toast.show({
        content: `${__(error)}`,
        type: EnumTypeToast.Error
      })
    }
  }, [user_data, channel_data])

  useEffect(() => {
    if (helpers.isEmpty(user_data) || helpers.isEmpty(channel_data)) return
    handleFetchData()
  }, [user_data, channel_data])

  return (
    <>
      {helpers.isEmpty(eventData) ? (
        <></>
      ) : (
        <EventContainer
          sx={{
            padding: theme => (checkMobile ? '0 8px 0 8px' : theme.spacing(2))
          }}
          id="eventRightBarComponent"
        >
          <Stack gap={2}>
            {!checkMobile && (
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  color="text.primary"
                  textTransform="none"
                >
                  {__('event')}
                </Typography>
                <Button variant="text" onClick={() => setIsShowModalAllEvent(true)}>
                  <Typography textTransform={'none'} variant="body1">
                    {__('header_see_all')}
                  </Typography>
                </Button>
              </Box>
            )}

            {checkMobile ? (
              <Box>
                <HomeActionEvent item={eventData[0]} />
                <Box textAlign="center">
                  <Button variant="text" onClick={() => setIsShowModalAllEvent(true)}>
                    <Typography textTransform={'none'} variant="body1">
                      {__('header_see_all')}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                {eventData?.slice(0, 2)?.map((value, idx) => (
                  <React.Fragment key={`event_${idx}`}>
                    <HomeActionEvent item={value} />
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Stack>
          {isShowModalAllEvent && (
            <Modal open={true} onClose={handleCloseModal}>
              <ModalContainer
                sx={{
                  width: checkMobile ? '90%' : '100%',
                  maxWidth: theme => theme.spacing(95.75),
                  margin: '0 auto'
                }}
              >
                <Box
                  position={'sticky'}
                  top={0}
                  width={'100%'}
                  zIndex={10}
                  sx={{
                    background: theme => theme.palette.background.paper
                  }}
                >
                  <ModalHeader padding={checkMobile ? '8px 16px' : '8px 24px'} direction={'row'}>
                    <Typography variant={'h6'} className={`title ${checkMobile && 'mobile'}`}>
                      {__('event')}
                    </Typography>
                    <IconButton onClick={handleCloseModal}>
                      <CloseIcon />
                    </IconButton>
                  </ModalHeader>
                  <Divider />
                </Box>
                <ModalBody
                  sx={{
                    maxHeight: 'calc(100% - 80px)',
                    overflowY: 'auto'
                  }}
                >
                  {eventData?.map((value, idx) => (
                    <React.Fragment key={`event1_${idx}`}>
                      <HomeActionEvent isHidePx={checkMobile} item={value} />
                    </React.Fragment>
                  ))}
                </ModalBody>
              </ModalContainer>
            </Modal>
          )}
        </EventContainer>
      )}
    </>
  )
}

export default EventRightBarComponent

const EventContainer = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))
