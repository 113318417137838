import {
  Button,
  Stack,
} from '@mui/material'
import React, { useState } from 'react'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelSetting } from '../store/channel.slice'
import TypedChannel from 'interfaces/channel.interface'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { validateLabel } from 'entities/support/utils'
import TextField from 'components/TextFieldMUI'
import { notEmpty, useField, useForm } from '@shopify/react-form'

const ChannelSettingsAdvance = () => {
  const channel = useAppSelector(channelInfo)
  const [channelData,setChannelData] = useState<TypedChannel>(channel)

  const dispatch = useAppDispatch()
  const toast = useToast()

  const { fields , submit, dirty, submitting }  = useForm({
    fields: {
      facebook_login_client_id: useField<string>({
        value: channelData?.facebook_login_client_id ?? '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_advance_facebook_js_url')}`)?.required)]
      }),
      google_login_client_id: useField<string>({
        value: channelData?.google_login_client_id ?? '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_advance_facebook_js_url')}`)?.required)]
      })
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateChannelSetting(values)
        )
          .unwrap()
          .then(res=>{
            setChannelData(res?.data)
            toast.show({
              content: `${__('channel_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(err => {
            console.log('err',err)
            toast.show({
              content: `${__('channel_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })
  const fieldsFirebase  = useForm({
    fields: {
      firebase_notification_key: useField<string>({
        value: channelData?.firebase_notification_key ?? '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_overview_firebase_key')}`)?.required)]
      })
    },
    async onSubmit(values) {
      console.log('values',values)
      try {
        dispatch(
          updateChannelSetting(values)
        )
          .unwrap()
          .then(res=>{
            console.log('res?.data',res?.data)
            setChannelData(res?.data)
            toast.show({
              content: `${__('channel_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(err => {
            console.log('err',err)
            toast.show({
              content: `${__('channel_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  return (
    <Stack gap={2} >

      {/*<Configuration*/}
      {/*  handleSubmit={(e) =>{*/}
      {/*  e.preventDefault()*/}
      {/*  fieldsFirebase.submit()*/}
      {/*  }}*/}
      {/*  disabled={!fieldsFirebase.dirty || fieldsFirebase.submitting}*/}
      {/*  title={__('channel_setting_advance_firebase')}>*/}
      {/*  <TextField*/}
      {/*    {...fieldsFirebase?.fields.firebase_notification_key}*/}
      {/*    rows={3}*/}
      {/*    multiline*/}
      {/*    label={__('channel_setting_overview_firebase_key')}*/}
      {/*    placeholder={__('channel_setting_overview_firebase_key_placeholder')}*/}
      {/*  />*/}

      {/*</Configuration>*/}
      <Configuration
        handleSubmit={(e) =>{
          e.preventDefault()
          submit()
        }}
        disabled={!dirty || submitting}
        title={__('channel_setting_advance_SSO')}
      >
        <Stack gap={2}>
          <TextField
            label={__('channel_setting_advance_facebook_js_url')}
            value={window.location.origin}
            disabled
          />
          <TextField
            label={__('channel_setting_advance_facebook_callback_url')}
            value={window.location.origin + '/login/facebook'}
            disabled
          />
          <TextField
            {...fields?.facebook_login_client_id}
            label={__('channel_setting_advance_facebook_js_url')}
          />
          <TextField
            label={__('channel_setting_advance_google_origin_url')}
            value={window.location.origin}
            disabled
          />
          <TextField
            label={__('channel_setting_advance_google_navigate_url')}
            value={window.location.origin + '/login/google'}
            disabled
          />
          <TextField
            {...fields?.google_login_client_id}
            label={__('channel_setting_advance_google_navigate_url')}
        />
        </Stack>
      </Configuration>
      {/*<AddvanceSMTP channelSetting={channelData}/>*/}
    </Stack>
  )
}

export default ChannelSettingsAdvance

export const Configuration = ({handleSubmit, disabled,title, children }: {disabled:boolean, title: string; children: React.ReactNode,handleSubmit:(e:  React.FormEvent<HTMLFormElement>)=>void }) => {
  return (
    <ChannelSettingsContentContainer>
      <Stack direction={'row'} component={'form'} onSubmit={handleSubmit}>
        <ChannelSettingsTitle mb={1}>{title}</ChannelSettingsTitle>
        <Button
          type={'submit'}
          disabled={disabled}
          sx={{
            marginLeft: 'auto',
            textTransform: 'none',
            fontSize: theme => theme.spacing(2),
            minWidth: theme => theme.spacing(12),
            marginBottom: theme => theme.spacing(1)
          }}
        >
          {__('btn_save')}
        </Button>
      </Stack>
      {children}
    </ChannelSettingsContentContainer>
  )
}
