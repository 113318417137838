//Library
import React, { memo, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Components
import Dropdown from 'components/Dropdown'
import _Helmet from 'components/helmet'

//Store
import { useAppSelector } from 'config/store'

//Data
import { dataDropdownPost } from './data'

//Helpers
import helpers from 'helpers'

//Scss
import './media/approval.list.scss'
import __ from 'languages/index'
import { EnumPostStatus, TypedRequest } from '../../interfaces/request.interface'
import SmallLayout from '../../layouts/smallLayout'
import {
  getRequestHome,
  isFulfilledListRequest,
  updateRequest
} from 'entities/homepage/home.store.reducer'
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { Stack } from '@mui/material'
import NewsFeedPost from 'entities/homeNew/components/NewsFeedPost'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'

const ListApprovalPost = () => {
  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)
  const dataRequest = useAppSelector(
    state => state.home.dataRequest,
    (next, prev) => helpers.isEqual(next, prev)
  )

  const { hasMore, isRefreshing, loadMore, updateParamRequest, isLoading, paramRequest, refresh } =
    useInfiniteScroll<TypedRequest>(
      {
        limit: 12,
        order_type: 'time',
        user_id: user_data?._id
          ? helpers.current_user_can('boss', permission_data)
            ? ''
            : user_data?._id
          : null,
        post_status: EnumPostStatus.Pending,
        order_by: 'DESC'
      },
      getRequestHome,
      false,
      dataRequest,
      isFulfilledListRequest
    )

  useEffect(() => {
    if (!user_data?._id || helpers.isEmpty(permission_data)) return

    updateParamRequest({
      user_id: user_data?._id
        ? helpers.current_user_can('boss', permission_data)
          ? ''
          : user_data?._id
        : null
    })
  }, [user_data?._id, permission_data])

  const handleChangeFilter = (value: string) => {
    updateParamRequest({
      post_status: value
    })
  }

  const dropdownToggle = React.forwardRef(
    ({ onClick }: { onClick: Function }, ref: RefObject<HTMLDivElement>) => (
      <div
        ref={ref}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault()
          onClick(e)
        }}
        className="mentorPaymentManage_dropdown"
      >
        <FontAwesomeIcon className="filter_icon" icon={faFilter} />
        <span>{`${__('list_approval_post_filter')}: ${
          dataDropdownPost?.find(
            (item: { id?: string; label?: string; value?: string }) =>
              item?.value === paramRequest.post_status
          )?.label || `${__('list_approval_post_pending')}`
        }`}</span>
      </div>
    )
  )

  return (
    <SmallLayout>
      <_Helmet title="list_approval_post" />
      <div id="listApprovalPost" className="listApprovalPost_container">
        <h3>{__('list_approval_post')}</h3>
        {helpers.current_user_can('boss', permission_data) && (
          <div className="listApprovalPost_dropdown">
            <Dropdown
              items={dataDropdownPost}
              renderItem={(item: { id?: string; label?: string; value?: string }) => (
                <DropdownItem key={item?.id} onClick={() => handleChangeFilter(item?.value)}>
                  {item?.label}
                </DropdownItem>
              )}
              title={__('list_approval_post_filter')}
              DropdownToggle={dropdownToggle}
            />
          </div>
        )}

        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={dataRequest.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
        >
          <Stack gap={2.5}>
            {dataRequest?.map(post => {
              return (
                <NewsFeedPost
                  key={post?._id}
                  isInReview
                  post={post}
                  is_user_logged_in={is_user_logged_in}
                />
              )
            })}
          </Stack>
        </InfiniteScrollContainer>
      </div>
    </SmallLayout>
  )
}

export default memo(ListApprovalPost)
