import axios from 'axios'
import helpers from 'helpers/index'
import {
  TypedCategory,
  TypedCourse,
  TypedCourseByCategory,
  TypedCourseForCategory,
  TypedMember
} from 'entities/courses/courses.interface'
import { IQueryParams } from 'config/reducer.utils'

export function getListCourses(param: object) {
  return axios.get<TypedCourse[]>(`course/list${helpers.buildEndUrl(param)}`)
}

export function getListCoursesByCategoryId(params: IQueryParams) {
  return axios.get<TypedCourseByCategory[]>(`/course_category/${params?.category_id}`, {
    params: params
  })
}

export function getListCoursesForCategory(params: IQueryParams) {
  return axios.get<TypedCourseForCategory[]>(`/course_category/list`, { params: params })
}

export function getListCourseJoined(params: IQueryParams) {
  return axios.get<TypedCourse[]>(`course/list-join`, { params: params })
}

export function getCategoryList(params: IQueryParams) {
  return axios.get<TypedCategory[]>(`course_category`, { params: params })
}

export function getListMembers(params: IQueryParams) {
  const courseId = params?.course_id
  delete params.course_id
  helpers.clearValueEmptyInObject(params)
  return axios.get<TypedMember[]>(`course/list-available-member/${courseId}`, { params: params })
}
