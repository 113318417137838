import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import React, { memo } from 'react'

const CustomPagination = ({ count, page, handleChangePage }) => {
  return (
    count > 0 && (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page}
        count={count || 1}
        renderItem={otherProps => {
          return (
            <PaginationItem
              sx={{ borderRadius: theme => theme.spacing(1.5) }}
              {...(otherProps as any)}
              disableRipple
              selected={otherProps.page === page}
            />
          )
        }}
        onChange={handleChangePage}
      />
    )
  )
}
export default memo(CustomPagination)
