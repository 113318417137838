import { Box, Breadcrumbs, Typography, styled, ListItemProps } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Link, { LinkProps } from '@mui/material/Link'
import __helpers from 'helpers/index'
import HomeIcon from 'icons/HomeIcon'
import ArrowChevronRightIcon from 'icons/ArrowChevronRightIcon'

interface TypeBreadcrumbs {
  breadcrumbName?: { [key: string]: string }
}

interface ListItemLinkProps extends ListItemProps {
  to: string
  open?: boolean
}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />
}

const BreadcrumbComponent: React.FC<TypeBreadcrumbs> = ({ breadcrumbName }) => {
  const location = useLocation()
  const pathnames = location.pathname?.split('/')?.filter(x => x)

  return (
    <BreadcrumbContainer className='wrapper-breadcrumb'>
      <Breadcrumbs
        separator={
          <ArrowChevronRightIcon
            fontSize="small"
            color="inherit"
            sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
          />
        }
        aria-label="breadcrumb"
      >
        <LinkRouter
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          to="/"
        >
          <HomeIcon
            color="inherit"
            sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
          />
        </LinkRouter>
        {pathnames?.map((value, index) => {
          const lastPoint = index === pathnames?.length - 1
          const to = `/${pathnames.slice(0, index + 1)?.join('/')}`
          return (
            breadcrumbName[to] &&
            (lastPoint ? (
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.primary"
                key={to}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
              >
                {__helpers.getTrimContent(breadcrumbName[to], 100)}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="text.secondary"
                  key={to}
                  whiteSpace={'nowrap'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  {__helpers.getTrimContent(breadcrumbName[to], 100)}
                </Typography>
              </LinkRouter>
            ))
          )
        })}
      </Breadcrumbs>
    </BreadcrumbContainer>
  )
}
export default BreadcrumbComponent

const BreadcrumbContainer = styled(Box)(({ theme }) => ({
  // padding:theme.spacing(2, 3),
}))
