import axios from 'axios'

// const orderApi = axios.create({
//   baseURL: `${process.env.REACT_APP_AJAX_URL}/order`
// })

export const getOrderDetail = (_id: string) => {
  const url = `order/detail-order/${_id}`
  return axios.get(url)
}

export const createOrder = (payload: {
  plan_id: string
  amount_of_package: string
  payment_method: string
  status: 'pending' | 'draft'
}) => {
  const url = 'order/channel-n-extension'
  return axios.post(url, payload)
}
