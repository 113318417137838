import React, { useEffect, useState } from 'react'
import __ from 'languages/index'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import { TypeChannelConfig } from 'entities/channel-settings/Capability/index'
import { Box, Button, Stack, Typography } from '@mui/material'
import TextField from 'components/TextFieldMUI'
import {  useField, useForm } from '@shopify/react-form'
import __helpers from 'helpers/index'

const CourseCommission = ({channelSetting, handleUpdateChannelInfo}:TypeChannelConfig)=>{
  const [commissionOwner, setCommissionOwner] = useState<string>('0')
  const { fields , submit, dirty, submitting }  = useForm({
    fields: {
      user_commission: useField<string>({
        value: !__helpers.isEmpty(channelSetting?.user_commission) ? channelSetting?.user_commission.toString() :'0',
        validates: [
          inputVal => {
            if (Number(inputVal) > 100) {
              return `${__('channel_setting_validate_user_commission')}`
            }
          }
        ]
      }),

    },
    async onSubmit(values) {
      const userCommission = Number(values?.user_commission)
      try {
        handleUpdateChannelInfo({
          user_commission: userCommission
        },__('channel_course_commission_notification'))
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(()=>{
    const commissionOwner = (100 - Number(fields?.user_commission?.value)).toString()
    setCommissionOwner(commissionOwner)
  },[fields?.user_commission])

  return(
    <ChannelSettingsContentContainer>
      <form
        onSubmit={(e) =>{
          e.preventDefault()
          submit()
        }}
      >
        <Stack direction={'row'}>
          <ChannelSettingsTitle>{__('channel_course_commission')}</ChannelSettingsTitle>
          <Button
            type={'submit'}
            disabled={!dirty || submitting}
            sx={{
              marginLeft: 'auto',
              textTransform: 'none',
              fontSize: theme => theme.spacing(2),
              minWidth: theme => theme.spacing(12)
            }}
          >
            {__('btn_save')}
          </Button>
        </Stack>
        <Box gap={2}>
          <Typography p={theme => theme.spacing(2,0)} variant={'subtitle2'} textTransform={'none'}>{__("channel_course_commission_title")}</Typography>
          <Stack direction={'row'} gap={2} component={'form'}>
            <TextField
              label={__("channel_course_commission_owner")}
              value={commissionOwner ?? '100'}
              disabled
            />
            <TextField
              {...fields?.user_commission}
              max={100}
              label={__("channel_course_commission_referrer")}
            />
          </Stack>
          <Typography p={theme => theme.spacing(2,0)} variant={'body1'} color={'text.secondary'}>{__("channel_course_commission_sub_text")}</Typography>
        </Box>
      </form>
    </ChannelSettingsContentContainer>
  )
}
export default CourseCommission
