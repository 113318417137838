import React from 'react'
import { Box, Button, Stack, styled, SxProps } from '@mui/material'
import { colorGenerator } from '../styles/utils/colorPaletteGenerator'
import { CheckIcon } from '../icons'
import { CurrentTheme } from '../styles/themes'

const getLightColor = (color: string) => {
  const colorObj = colorGenerator(color, 'color')
  return colorObj.color.light
}
const ColorPickerButton = styled(Button, {
  shouldForwardProp(prop) {
    return prop !== 'backgroundColor'
  }
})<{ backgroundColor: string }>(({ theme, backgroundColor }) => {
  return {
    backgroundColor: backgroundColor,
    padding: 0,
    minWidth: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&:hover': {
      backgroundColor: getLightColor(backgroundColor)
    },
    '&:focus': {
      backgroundColor: backgroundColor
    }
  }
})

const defaultColors = ['#DC3545', '#1677FF', '#FAAD14', '#E23F96', '#722ED1', '#52C41A', '#13C2C2']

type Props = {
  color: string
  onChangeColor: (color: string) => void
}
const ColorSelector = ({ color, onChangeColor }: Props) => {
  const handleChangeColor = (color: string) => {
    onChangeColor(color)
  }

  return (
    <Stack direction={'row'} sx={colorSelectorStyled} alignItems={'center'} gap={2} whiteSpace={'wrap'}>
      <Stack direction={'row'} alignItems={'center'} className={'input-color-picker'} gap={1}>
        <Box
          component={'input'}
          type={'color'}
          value={color}
          onChange={event => handleChangeColor(event.target.value)}
        />
        <Box component={'input'} readOnly value={color} type={'text'} />
      </Stack>
      <Stack direction={'row'} gap={2}>
        {defaultColors.map((c, index) => (
          <ColorPickerButton key={`ColorPickerButton${index}`} backgroundColor={c} onClick={() => handleChangeColor(c.toLowerCase())}>
            {color.toLowerCase() === c.toLowerCase() && <CheckIcon />}
          </ColorPickerButton>
        ))}
      </Stack>
    </Stack>
  )
}

export default ColorSelector

const colorSelectorStyled: SxProps<CurrentTheme> = {
  '& .input-color-picker': {
    borderRadius: theme => theme.spacing(1.5),
    border: theme => `1px solid ${theme.palette.divider}`,
    padding: '3px',
    '& > input[type="color" i]': {
      borderRadius: '50%',
      inlineSize: '30px',
      blockSize: '30px',
      border: 'solid 1px #ccc'
      // height: theme => theme.spacing(5),
      // WebkitAppearance: 'none',
      // border: 'none',
      // '&::-moz-color-swatch,&::-webkit-color-swatch': {
      //   border: 'none',
      //   borderColor: 'transparent'
      // },
      // '&::-webkit-color-swatch-wrapper': {
      //   padding: 0,
      //   borderRadius: 0
      // }
    },
    '& > input[type="text"]': {
      maxWidth: '100px',
      height: theme => theme.spacing(5),
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    }
  }
}

const inputColorStyled: SxProps<CurrentTheme> = {}
