import React from 'react'
import {
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  OutlinedInputProps,
  styled,
  SxProps
} from '@mui/material'
import { CurrentTheme } from 'styles/themes'

type Props<T> = {
  value: string
  options: T[]
  renderItem: (item: T) => React.ReactNode
  getItemValue: (item: T) => string | number
  onChangeValue?: (value: string) => void
  isError?: boolean
  inputProps?: OutlinedInputProps
}

const Select = <T,>({
  inputProps,
  options,
  getItemValue,
  renderItem,
  onChangeValue,
  isError = false,
  value
}: Props<T>) => {
  return (
    <MuiSelect
      input={<InputSelect {...inputProps} />}
      value={value}
      error={isError}
      onChange={e => onChangeValue(e.target.value)}
      MenuProps={{
        elevation: 6
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={`MuiSelect${index}`} value={getItemValue(option)}>{renderItem(option)}</MenuItem>
      ))}
    </MuiSelect>
  )
}

export default Select

const InputSelect = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.625, 1.5, 0.5),
  borderRadius: theme.spacing(1.5)
}))

const menuItemStyled: SxProps<CurrentTheme> = {}
