import BigLayout from 'layouts/bigLayout'
import React, { useCallback, useEffect, useState } from 'react'
import _Helmet from 'components/helmet'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled
} from '@mui/material'
import __ from 'languages/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteNews, getNewsDetailById } from './services'
import { TypedNews } from './interface'
import ArrowChevronRightIcon from 'icons/ArrowChevronRightIcon'
import HomeIcon from 'icons/HomeIcon'
import Capacity from 'components/Capacity'
import { TrashAltIcon, WatchIcon } from 'icons'
import EditIcon2 from 'icons/EditIcon2'
import avatarDefault from 'media/images/avatar_default.png'
import helpers from 'helpers'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import ConfirmModal from 'components/ConfirmModal'
import { useAppSelector } from 'config/store'
import { useGetThumbnail } from 'hooks/useGetThumbnail'
import imageDefault from 'media/images/image_default.png'

const NewsDetails = () => {
  const { slugId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const user_data = useAppSelector(state => state.user.user_data)

  const [newsData, setNewsData] = useState<TypedNews>()
  const [isShowModalDelete, setIsShowModalDelete] = useState<boolean>(false)

  const { ref, getThumbnailUrl } = useGetThumbnail()

  const breadcrumbs = [
    <Link to="/">
      <HomeIcon
        color="inherit"
        sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
      />
    </Link>,
    <Typography
      component={Link}
      color="text.secondary"
      style={{ textDecoration: 'none' }}
      to={`/news`}
    >
      {__('news')}
    </Typography>,
    <Typography
      color="text.secondary"
      component={Link}
      style={{ textDecoration: 'none' }}
      to={`/news/category/${newsData?.category_id?._id || ''}`}
    >
      {newsData?.category_id?.news_category_name || ''}
    </Typography>,
    <Typography color="text.primary" noWrap>
      {newsData?.news_title || ''}
    </Typography>
  ]

  const handleDeleteNews = useCallback(() => {
    deleteNews(slugId)
      .then(() => {
        navigate('/news', { replace: true })
        toast.show({
          content: `${__('news_delete_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch(error => {
        console.log('deleteNews error', error)
        toast.show({
          content: `${error}`,
          type: EnumTypeToast.Error
        })
      })
  }, [slugId])

  const handleEditNews = useCallback(() => {
    navigate(`/news/edit/${newsData?._id}`, { replace: true })
  }, [newsData])

  const _viewActionWithNews = useCallback(() => {
    return (
      <Stack direction={'row'} gap={2}>
        <IconButton onClick={() => setIsShowModalDelete(true)} className="delete_button">
          <TrashAltIcon color="inherit" />
        </IconButton>
        <Button
          onClick={handleEditNews}
          className="edit_button"
          startIcon={<EditIcon2 sx={{ color: theme => theme.palette.background.paper }} />}
        >
          {__('news_edit_news')}
        </Button>
      </Stack>
    )
  }, [newsData])

  useEffect(() => {
    if (!slugId) return navigate('/news')
    getNewsDetailById(slugId)
      .then(res => {
        setNewsData(res?.data)
      })
      .catch(error => {
        console.log('getNewsDetailById error', error)
      })
  }, [slugId])

  return (
    <BigLayout>
      <_Helmet title={newsData?.news_title || ''} />
      <NewsDetailContainer>
        <Breadcrumbs
          separator={
            <ArrowChevronRightIcon
              fontSize="small"
              color="inherit"
              sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>

        <Paper className="wrapper">
          <Stack gap={2}>
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant="h6" textTransform={'none'} color="primary.main" fontWeight={700}>
                {newsData?.category_id?.news_category_name || ''}
              </Typography>
              <Capacity
                current_user_can={['boss', 'admin']}
                onFail={() => (
                  <Capacity current_user_can={'mentor'}>
                    {user_data?._id === newsData?.user?._id && _viewActionWithNews()}
                  </Capacity>
                )}
              >
                {_viewActionWithNews()}
              </Capacity>
            </Stack>
            <Typography variant="h4" textTransform={'none'} fontWeight={700}>
              {newsData?.news_title || ''}
            </Typography>

            <Stack direction={'row'} gap={2}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Avatar
                  ref={ref}
                  src={getThumbnailUrl(newsData?.user?.user_avatar) || avatarDefault}
                  alt="avatar/author"
                />
                <Stack>
                  <Typography fontWeight={600} noWrap>
                    {newsData?.user?.display_name || ''}
                  </Typography>
                  <Typography textTransform={'capitalize'} variant="body2" fontWeight={400}>
                    {__(
                      `${helpers.checkPermissionUser(newsData?.user?.channelpermission?.permission)}`
                    )}
                  </Typography>
                </Stack>
              </Stack>
              <Box
                sx={{ width: '1px', background: theme => theme.palette.divider, height: '100%' }}
              />
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={1}
                justifyContent={'space-between'}
              >
                <WatchIcon sx={{ color: theme => theme.palette.text.secondary }} />
                <Typography fontWeight={400} color="text.secondary">
                  {`${__('news_date_submitted')}: ${helpers.convertDateTime(newsData?.createdAt)}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Box
            ref={ref}
            component={'img'}
            src={getThumbnailUrl(newsData?.news_thumbnail) || imageDefault}
            alt="image/news"
            sx={{
              height: theme => theme.spacing(74.625),
              width: '100%',
              borderRadius: theme => theme.spacing(1.5),
              objectFit: 'cover'
            }}
          />

          <Box sx={{}} dangerouslySetInnerHTML={{ __html: newsData?.news_content }} />
        </Paper>

        {isShowModalDelete && (
          <ConfirmModal
            onClose={() => setIsShowModalDelete(false)}
            open={isShowModalDelete}
            onConfirm={handleDeleteNews}
            title={__('btn_confirm')}
            cancelTextBtn={__('home_btn_cancel')}
            confirmText={__('news_delete_title')}
          />
        )}
      </NewsDetailContainer>
    </BigLayout>
  )
}

export default NewsDetails

const NewsDetailContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  '& .wrapper': {
    border: `1px solid ${theme.palette.divider}`,
    display: 'grid',
    gap: theme.spacing(4.7),
    padding: theme.spacing(2)
  },
  '& .delete_button': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.5),
    color: theme.palette.primary.main
  },
  '& .MuiBreadcrumbs-ol': {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto 1fr'
  },
  '& .MuiBreadcrumbs-li': {
    overflow: 'hidden'
  },
  '& .edit_button': {
    textTransform: 'none',
    ':focus': {
      background: theme.palette.primary.main
    }
  }
}))
