//Library
import __ from 'languages/index'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//Components
import DetailPaymentIncome from './components/detail.payment.income'
import Capacity from 'components/Capacity'
import DetailPaymentHistory from './components/detail.payment.history'
import DetailPaymentAccountBank from './components/detail.payment.account.bank'
import DetailPaymentModalWithdraw from './components/detail.payment.modal.withdraw'
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import Title from 'components/Title'
import _Helmet from 'components/helmet'

//Stores
import { useAppDispatch, useAppSelector } from 'config/store'
import {
  getDataTransaction,
  getListBank,
  MENTOR_REDUCER,
  withDrawal
} from 'entities/mentor/store/mentor.store.reducer'

//Interface
import { TypeAccountBank, TypeWithdrawal, TypeYourIncome } from './payment.interface'
import { TypeDataPayment } from 'entities/mentor/mentor.interface'

//Scss
import './media/detail.payment.scss'
import BigLayout from '../../layouts/bigLayout'
import { EnumTypeToast, useToast } from '../../hooks/useToast'

interface TypePaymentHistory {
  listPaymentHistory: TypeDataPayment[]
  totalData: number
}
interface TypeDataBank {
  listBank: TypeAccountBank[]
  totalData: number
}

export default function DetailPayment() {
  const dispatch = useAppDispatch()
  const { actionsCheck } = useAppSelector(state => state.mentor)
  const toast = useToast()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTriggerFetch, setIsTriggerFetch] = useState<boolean>(false)

  const [isShowModalWithdraw, setIsShowModalWithdraw] = useState<boolean>(false)
  const [paymentHistory, setPaymentHistory] = useState<TypePaymentHistory>({
    listPaymentHistory: [],
    totalData: 0
  })
  const [dataBank, setDataBank] = useState<TypeDataBank>({
    listBank: [],
    totalData: 0
  })
  const [yourIncome, setYourIncome] = useState<TypeYourIncome[]>([])
  const [paramsPayment, setParamsPayment] = useState({
    page: 1,
    limit: 10,
    method: 'minus',
    order_by: 'DESC'
  })

  const handleShowModalWithdraw = useCallback(() => {
    setIsShowModalWithdraw(!isShowModalWithdraw)
    setParamsPayment({ ...paramsPayment, page: 1 })
  }, [isShowModalWithdraw, paramsPayment])

  const handleLoadMorePaymentHistory = useCallback(() => {
    setParamsPayment({ ...paramsPayment, page: paramsPayment?.page + 1 })
  }, [paramsPayment])

  const handleSubmitWithdraw = useCallback((data: TypeWithdrawal) => {
    dispatch(withDrawal(data))
      .unwrap()
      .then(() => {
        setIsShowModalWithdraw(false)
        toast.show({
          title: `${__('notification')}`,
          content: `${__('payment_withdrawal_successful')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch(error => {
        console.log(error)
        toast.show({
          title: `${__('notification')}`,
          content: `Rút tiền thất bại`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  useEffect(() => {
    if (isShowModalWithdraw) return
    setIsLoading(true)
    dispatch(getDataTransaction({ page: 1, limit: 1, order_by: 'DESC' }))
      .unwrap()
      .then(res => {
        setYourIncome(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        setYourIncome([])
        setIsLoading(false)
        console.log(error)
      })
  }, [isShowModalWithdraw])

  useEffect(() => {
    if (isShowModalWithdraw) return
    dispatch(getDataTransaction(paramsPayment))
      .unwrap()
      .then(res => {
        setPaymentHistory(paymentHistory => ({
          listPaymentHistory:
            paramsPayment?.page > 1
              ? [...paymentHistory?.listPaymentHistory, ...res?.data]
              : res?.data,
          totalData: parseInt(res.headers['x-total-count'] || 1000, 10)
        }))
      })
      .catch(error => {
        setPaymentHistory({
          listPaymentHistory: [],
          totalData: 0
        })
        console.log(error)
      })
  }, [paramsPayment, isShowModalWithdraw])

  useEffect(() => {
    if (isShowModalWithdraw) return
    setIsLoading(true)
    dispatch(getListBank({ payment_method: 'transfer' }))
      .unwrap()
      .then(res => {
        setDataBank({
          listBank: res?.data,
          totalData: parseInt(res.headers['x-total-count'] || 1000, 10)
        })
        setIsLoading(false)
      })
      .catch(error => {
        setDataBank({
          listBank: [],
          totalData: 0
        })
        setIsLoading(false)
        console.log(error)
      })
  }, [isTriggerFetch, isShowModalWithdraw])

  useEffect(() => {
    if (actionsCheck?.isSuccess && actionsCheck?.statusCode === 200) {
      dispatch(
        MENTOR_REDUCER.actions.setActionsCheck({
          type: '',
          isSuccess: false,
          statusCode: 0
        })
      )
      toast.show({
        title: `${__('notification')}`,
        content: `${actionsCheck?.type} ${__('payment_success')}`,
        type: EnumTypeToast.Success
      })
    }
  }, [actionsCheck])

  return (
    <BigLayout>
      <_Helmet title="payment_title" />
      <div id="detailPayment" className="detailPayment__container">
        <div className="d-flex justify-content-between align-items-center">
          <Title text={__('payment_title')} />
          <Capacity
            current_user_can={'boss'}
            onFail={() =>
              yourIncome[0]?.current_token ? (
                <Button
                  variant="primary"
                  onClick={handleShowModalWithdraw}
                  className="detailPayment__container--buttonWithdraw"
                >
                  {__('payment_withdrawal')}
                </Button>
              ) : null
            }
          ></Capacity>
        </div>
        {isLoading ? (
          <div className="bg-white p-4 rounded mt-4">
            <SettingMentorSkeleton />
          </div>
        ) : (
          <>
            <DetailPaymentIncome yourIncome={yourIncome} />
            <div className="detailPayment__second">
              <DetailPaymentHistory
                paymentHistory={paymentHistory}
                _onLoadMore={handleLoadMorePaymentHistory}
              />
              <div className="w-100">
                <div className="bg-white mt-4 rounded-top">
                  <h4 className="pt-4 ps-4">{__('payment_receive')}</h4>
                  {dataBank?.listBank?.length ? (
                    <DetailPaymentAccountBank
                      dataBank={dataBank?.listBank.at(0)}
                      setIsTriggerFetch={setIsTriggerFetch}
                    />
                  ) : (
                    <span className="pt-4 ps-4">{__('payment_no_account')}</span>
                  )}
                </div>
                <Link
                  to={'/info-payment/add'}
                  className="btn w-100 text-primary event-button text-decoration-underline border-top bg-white py-3 text-center rounded-bottom"
                >
                  <span>{__('payment_manage_payment_methods')}</span>
                </Link>
              </div>
            </div>
          </>
        )}

        {isShowModalWithdraw && (
          <DetailPaymentModalWithdraw
            show={isShowModalWithdraw}
            yourIncome={yourIncome}
            dataBank={dataBank?.listBank}
            onClose={handleShowModalWithdraw}
            onSubmitWithdraw={handleSubmitWithdraw}
          />
        )}
      </div>
    </BigLayout>
  )
}
