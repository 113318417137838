import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import __ from 'languages/index'
import React, { useState } from 'react'
import { Box, Radio, Stack, Switch, SxProps, Typography } from '@mui/material'
import { GlobeIcon, LockIcon } from '../../../icons'
import { CurrentTheme } from '../../../styles/themes'
import { ChannelStatus } from 'entities/channel-settings/service/service.type'
import { TypeChannelConfig } from 'entities/channel-settings/Capability/index'

const ChannelCensorship = ({channelSetting, handleUpdateChannelInfo}: TypeChannelConfig) => {
  const [isApproval,setIsApproval] = useState<boolean>(channelSetting?.need_approval ?? false)
  const [isPublic,setIsPublic] = useState<string>(channelSetting?.channel_public_status ?? ChannelStatus.PUBLIC)
  const isPublicChannel = isPublic === ChannelStatus.PUBLIC

  return (
    <ChannelSettingsContentContainer>
      <ChannelSettingsTitle>{__('channel_censorship')}</ChannelSettingsTitle>
      <Stack direction={'row'} alignItems={'center'}>
        <Switch
          sx={{
            '& .MuiSwitch-root': {
              paddingLeft: `0 !important`
            }
          }}
          checked={isApproval}
          onChange={e =>{
            setIsApproval(e.target.checked)
            handleUpdateChannelInfo({
              need_approval: e.target.checked
            },__('channel_setting_overview_approval_post'))
          }}
        />
        <Typography>{__('channel_setting_overview_approval_post')}</Typography>
      </Stack>
      <Typography color={'text.secondary'} p={theme => theme.spacing(1, 0)}>
        {__('channel_setting_overview_approval_post_sub_text')}
      </Typography>
      <Typography variant={'subtitle2'} textTransform={'none'} p={theme => theme.spacing(1, 0)}>
        {__('channel_setting_overview_channel')}
      </Typography>
      <Stack direction={'row'} gap={3}>
        <Box
          role="button"
          onClick={() => {
            setIsPublic(ChannelStatus.PUBLIC)
            handleUpdateChannelInfo({
              channel_public_status: ChannelStatus.PUBLIC
            },__('channel_setup'))
          }}
          sx={{
            ...channelStatusStyled,
            backgroundColor: theme => isPublicChannel && theme.palette.background.default
          }}
        >
          <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
            <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
              <GlobeIcon />
              <Typography variant={'subtitle2'}>{__('channel_setting_overview_public')}</Typography>
            </Stack>
            <Radio className={'selector'} checked={isPublicChannel} />
          </Stack>
          <Typography>{__('channel_setting_overview_public_sub_text')}</Typography>
        </Box>

        <Box
          role="button"
          onClick={() =>
            {
              setIsPublic(ChannelStatus.PRIVATE)
              handleUpdateChannelInfo({
                channel_public_status: ChannelStatus.PRIVATE
              },__('channel_setup'))
            }
          }
          sx={{
            ...channelStatusStyled,
            backgroundColor: theme => !isPublicChannel && theme.palette.background.default
          }}
        >
          <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
            <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
              <LockIcon />
              <Typography variant={'subtitle2'}>
                {__('challenge_add_detail_status_private')}
              </Typography>
            </Stack>
            <Radio className={'selector'} checked={!isPublicChannel} />
          </Stack>
          <Typography>{__('channel_setting_overview_private_sub_text')}</Typography>
        </Box>
      </Stack>
    </ChannelSettingsContentContainer>
  )
}

export default ChannelCensorship
export const channelStatusStyled: SxProps<CurrentTheme> = {
  padding: theme => theme.spacing(2),
  borderRadius: theme => theme.spacing(1.5),
  border: theme => `1px solid ${theme.palette.divider}`,
  '& > .selector': {
    marginRight: 'auto',
    padding: 0
  }
}
