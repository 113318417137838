import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import imageDefault from 'media/images/image_default.png'
import avatarDefault from 'media/images/avatar_default.png'
import { TypedNews } from '../interface'
import __ from 'languages/index'
import helpers from 'helpers'
import { Link } from 'react-router-dom'

const NewsItem = ({ newsData }: { newsData?: TypedNews }) => {
  return (
    <NewsItemContainer>
      <CardActionArea component={Link} to={`/news/detail/${newsData?._id || ''}`}>
        <CardMedia
          component="img"
          onError={e => {
            e.currentTarget.src = imageDefault
          }}
          sx={{ height: theme => theme.spacing(20.625) }}
          image={newsData?.news_thumbnail || imageDefault}
          alt="news/image"
        />
        <Stack
          component={CardContent}
          height={theme => `calc(100% - ${theme.spacing(20.625)})`}
          width={'100%'}
          gap={1.5}
          direction={'column'}
          justifyContent={'space-between'}
        >
          <Stack gap={1.5}>
            <Typography variant="body2" fontWeight={600} color="primary.main">
              {__('categories')}
            </Typography>
            <Stack gap={0.5}>
              <Tooltip title={newsData?.news_title || ''}>
                <Typography fontWeight={600} className="card_item_title">
                  {newsData?.news_title || ''}
                </Typography>
              </Tooltip>
              <Tooltip title={newsData?.news_short_description || ''}>
                <Typography
                  className="card_item_content"
                  variant="body2"
                  fontWeight={400}
                  color="text.secondary"
                >
                  {newsData?.news_short_description || ''}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={1.5} alignItems={'flex-end'}>
            <Avatar src={newsData?.user?.user_avatar || avatarDefault} alt="avatar/author" />
            <Stack flex={1} width={'70%'}>
              <Tooltip title="">
                <Typography variant="caption" fontWeight={600} noWrap>
                  {newsData?.user?.display_name || ''}
                </Typography>
              </Tooltip>
              <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
                <Typography textTransform={'capitalize'} variant="caption" fontWeight={400}>
                  {__(
                    `${helpers.checkPermissionUser(newsData?.user?.channelpermission?.permission)}`
                  )}
                </Typography>
                <Typography variant="caption" fontWeight={400} color="text.secondary">
                  {helpers.convertDateTime(newsData?.createdAt)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </NewsItemContainer>
  )
}

export default NewsItem

const NewsItemContainer = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  height: '99%',
  '& .css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root, a': {
    height: '100%'
  },
  '& button': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  '& .card_item_title': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  '& .card_item_content': {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}))
