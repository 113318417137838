import __ from 'languages/index'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { changePassword } from 'entities/forgotPassword/forgot.password.store.reducer'
import { AxiosError, AxiosResponse } from 'axios'
import { useAppDispatch, useAppSelector } from 'config/store'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import './media/css/index.scss'
import helpers from 'helpers/index'
import ProgressBarPassword from 'components/ProgressBarPassword'
import { Box, Button, IconButton, Paper, Typography, keyframes, styled } from '@mui/material'
import { ArrowLeftIcon, EyeIcon, EyeOffIcon, SafetyClockIcon } from 'icons'
import TextFieldMUI from 'components/TextFieldMUI'
import _Helmet from 'components/helmet'
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'

const ChangePassword = () => {
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false)
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string>('')

  const newPasswordRef = useRef(null)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const checkMobile = helpers.isMobile()
  const navigate = useNavigate()
  const channel_data = useAppSelector(state => state.user.channel_data)
  const useFields = {
    newPassword: useField<string>({
      value: '',
      validates: [
        notEmpty(`${__('user_setting_password_new')}`),
        inputVal => {
          if (!inputVal) {
            return `${__('user_setting_password_new')}`
          }
          if (helpers.getPasswordStrength(inputVal) < 1) {
            return `${__('user_setting_change_password_sub_text')}`
          }
        }
      ]
    }),
    confirmPassword: useField({
      value: '',
      validates: [
        notEmpty(`${__('user_setting_confirm_password_new')}`),
        inputVal => {
          if (!inputVal) {
            return `${__('user_setting_confirm_password_new')}`
          }
          if (inputVal !== newPasswordRef.current) {
            return `${__('user_setting_password_error')}`
          }
        }
      ]
    })
  }

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await dispatch(
          changePassword({
            user_password: values?.newPassword,
            session_id: sessionId
          })
        )
          .unwrap()
          .then((res: AxiosResponse) => {
            toast.show({
              content: `${__('forgot_password_success')}`,
              type: EnumTypeToast.Success
            })
            navigate('/login')
          })
          .catch((error: AxiosError) => {
            console.log(`send_${error}`)
            toast.show({
              content: `${__('forgot_password_failed')}`,
              type: EnumTypeToast.Error
            })
          })

        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
    try {
      const session: { sessionId: string } = JSON.parse(
        localStorage.getItem('forgotPasswordSession')
      )
      if (!session?.sessionId) {
        navigate('/login')
      }
      setSessionId(session?.sessionId)
    } catch (error) {
      toast.show({
        content: `${error}`,
        type: EnumTypeToast.Error
      })
      navigate('/login')
    }
    return () => {
      localStorage.removeItem('forgotPasswordSession')
    }
  }, [])

  return (
    <>
      <_Helmet title={'user_setting_change_password'} />
      <ChangePasswordContainer id="changePassword">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />
        <Box className="wrapper" id={'box_form'}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: checkMobile ? '100%' : 'auto',
              width: '100%'
            }}
          >
            {checkMobile && (
              <IconButton
                sx={{
                  mt: '24px',
                  justifyContent: 'flex-start'
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowLeftIcon />
              </IconButton>
            )}
            <Box display={'grid'} rowGap={theme => theme.spacing(3)} p={theme => theme.spacing(3)}>
              <h4 className="changePassword_label">{__('user_setting_change_password')}</h4>
              <Box component={'form'} display={'grid'} rowGap={3} onSubmit={submit}>
                <div className="changePassword_body">
                  <div className="changePassword_body_item">
                    <TextFieldMUI
                      id="newPassword"
                      name="newPassword"
                      type={isShowNewPassword ? 'text' : 'password'}
                      placeholder={__('user_setting_password_new_title')}
                      value={fields.newPassword.value || ''}
                      onChange={value => {
                        fields.newPassword.onChange(value)
                        newPasswordRef.current = value
                      }}
                      iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      iconEnd={
                        <IconButton
                          type="button"
                          onClick={() => setIsShowNewPassword(!isShowNewPassword)}
                          sx={{ p: 0 }}
                        >
                          {isShowNewPassword ? (
                            <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          ) : (
                            <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          )}
                        </IconButton>
                      }
                    />

                    {fields.newPassword?.error && !fields.newPassword.value && (
                      <span className="form-text text-danger">{fields.newPassword?.error}</span>
                    )}
                    {fields.newPassword.value && (
                      <ProgressBarPassword
                        strength_password={helpers.getPasswordStrength(fields.newPassword.value)}
                      />
                    )}
                  </div>
                  <div className="changePassword_body_item">
                    <TextFieldMUI
                      id="confirmPassword"
                      name="confirmPassword"
                      type={isShowConfirmPassword ? 'text' : 'password'}
                      placeholder={__('user_setting_re-enter_password_new')}
                      value={fields.confirmPassword.value || ''}
                      onChange={fields.confirmPassword.onChange}
                      iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      iconEnd={
                        <IconButton
                          type="button"
                          onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                          sx={{ p: 0 }}
                        >
                          {isShowConfirmPassword ? (
                            <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          ) : (
                            <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />
                          )}
                        </IconButton>
                      }
                    />

                    {fields.confirmPassword?.error && (
                      <span className="form-text text-danger">{fields.confirmPassword?.error}</span>
                    )}
                  </div>
                </div>
                <div className="changePassword_footer">
                  {/*<Link to={'/forgot-password'} className="changePassword_footer_forgotPassword">*/}
                  {/*    {__("user_setting_forgot_password")}*/}
                  {/*</Link>*/}

                  <Button
                    onClick={submit}
                    disabled={!dirty || submitting}
                    type="submit"
                    sx={{ p: theme => theme.spacing(2.1875, 2) }}
                  >
                    <Typography textTransform={'none'}>
                      {submitting ? __('btn_processing') : __('btn_update')}
                    </Typography>
                  </Button>
                </div>
                {/*{isShowUpdatePassword && (*/}
                {/*  <UserModalConfirm*/}
                {/*    show={isShowUpdatePassword}*/}
                {/*    title={__("user_setting_modal_title")}*/}
                {/*    onClose={handleConfirmModalQuestion}*/}
                {/*  >*/}
                {/*    <p>{__("user_setting_modal_text")}</p>*/}
                {/*  </UserModalConfirm>*/}
                {/*)}*/}
              </Box>
            </Box>
          </Paper>
        </Box>
      </ChangePasswordContainer>
    </>
  )
}
export default ChangePassword

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`

const ChangePasswordContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',

  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 468px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  }
}))
