import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import TextField from 'components/TextFieldMUI'
import _Helmet from 'components/helmet'
import { CheckIcon, ChevronRightIcon } from 'icons'
import __ from 'languages/index'
import BigLayout from 'layouts/bigLayout'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { validateLabel } from 'entities/support/utils'
import { createNews, getNewsDetailById, getNewsToCategory, updateNews } from './services'
import helpers from 'helpers'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import UploadFileComponent from 'components/UploadFileComponent'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
    elevation: 1
  }
}

const NewsAdd = () => {
  const editorRef = useRef(null)
  const toast = useToast()
  const navigate = useNavigate()
  const { slugId } = useParams()

  const [categoryData, setCategoryData] = useState<{ value: string; label: string }[]>([])
  const [isShowSelectCategory, setIsShowSelectCategory] = useState<boolean>(false)
  const [initialNewsData, setInitialNewsData] = useState({
    news_title: '',
    news_short_description: '',
    category_id: { value: '', label: '' },
    news_content: '',
    news_thumbnail: ''
  })
  const [isShowConfirmBack, setIsShowConfirmBack] = useState<boolean>(false)

  const handleAddNews = useCallback(formData => {
    createNews(formData)
      .then(res => {
        const newsIs = res?.data?.news_id
        navigate(newsIs ? `/news/detail/${newsIs}` : '/news', { replace: true })
        toast.show({
          content: `${__('news_create_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch(error => {
        console.log('createNews error', error)
        toast.show({
          content: `${error}}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  const handleEditNews = useCallback(formData => {
    if (!slugId) return
    updateNews(slugId, formData)
      .then(res => {
        navigate(`/news/detail/${slugId}`, { replace: true })
        toast.show({
          content: `${__('news_update_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch(error => {
        console.log('updateNews error', error)
        toast.show({
          content: `${error}}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  const { fields, submit, reset, dirty, submitting } = useForm({
    fields: {
      news_title: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('news_title')}`)?.required)]
      }),
      news_short_description: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(__('news_short_description'))?.required),
          inputVal => {
            if (inputVal?.length > 100) {
              return validateLabel(100)?.maxLength
            }
          }
        ]
      }),
      news_thumbnail: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('news_cover_photo')}`)?.required)]
      }),
      news_content: useField<any>({
        value: '',
        validates: [
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('news_long_description'))?.required
            }
          }
        ]
      }),
      category_id: useField<any>({
        value: { value: '', label: '' },
        validates: [
          inputVal => {
            if (!inputVal?.value) {
              return validateLabel(__('category'))?.required
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          news_title: values.news_title.trim(),
          news_short_description: values.news_short_description.trim(),
          news_thumbnail: values.news_thumbnail,
          news_content: values.news_content,
          category_id: values.category_id.value
        }
        slugId ? handleEditNews(formData) : handleAddNews(formData)
        return { status: 'success' }
      } catch (e) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        toast.show({
          content: `${e}`,
          type: EnumTypeToast.Error
        })
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleBack = useCallback(() => {
    const data = initialNewsData
    const fieldsData = {
      news_title: fields.news_title.value,
      news_short_description: fields.news_short_description.value,
      category_id: fields.category_id.value,
      news_content: fields.news_content.value,
      news_thumbnail: fields.news_thumbnail.value
    }
    const redirectUrl = slugId ? `/news/detail/${slugId}` : '/news'
    return isEqual(data, fieldsData)
      ? navigate(redirectUrl, { replace: true })
      : setIsShowConfirmBack(true)
  }, [fields, initialNewsData, slugId])

  useEffect(() => {
    getNewsToCategory({})
      .then(res => {
        setCategoryData([
          ...res?.data?.map(item => ({
            label: item?.news_category_name,
            value: item?._id
          }))
        ])
      })
      .catch(error => {
        console.log('getNewsToCategory error', error)
      })
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(categoryData)) return
    if (!slugId) return
    getNewsDetailById(slugId)
      .then(res => {
        const data = res?.data
        const category = categoryData?.find(item => item?.value === data?.category_id?._id)
        fields.news_title.onChange(data?.news_title)
        fields.news_short_description.onChange(data?.news_short_description)
        fields.category_id.onChange(category)
        fields.news_content.onChange(data?.news_content || '')
        fields.news_thumbnail.onChange(data?.news_thumbnail || '')
        setInitialNewsData({
          news_title: data?.news_title,
          news_short_description: data?.news_short_description,
          category_id: category,
          news_content: data?.news_content || '',
          news_thumbnail: data?.news_thumbnail || ''
        })
      })
      .catch(error => {
        console.log('getNewsDetailById error', error)
      })
  }, [slugId, categoryData])

  return (
    <BigLayout>
      <_Helmet title={slugId ? __('news_edit_news') : __('add_news')} />
      <NewsAddContainer>
        <Box component={'form'} onSubmit={submit} display={'grid'} gap={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'}>
              <IconButton onClick={handleBack}>
                <ChevronRightIcon
                  sx={{ transform: 'rotate(180deg)', color: theme => theme.palette.text.primary }}
                />
              </IconButton>
              {isShowConfirmBack && (
                <DialogComponent
                  open={isShowConfirmBack}
                  handleSubmit={() => navigate(slugId ? `/news/detail/${slugId}` : '/news')}
                  handleClose={() => setIsShowConfirmBack(false)}
                />
              )}
              <Typography variant="h5" textTransform={'none'} fontWeight={700}>
                {slugId ? __('news_edit_news') : __('add_news')}
              </Typography>
            </Stack>
            <Button
              type="submit"
              disabled={!dirty || submitting}
              sx={{
                textTransform: 'none',
                ':focus': { background: theme => theme.palette.primary.main }
              }}
              endIcon={<CheckIcon />}
            >
              {slugId ? __('btn_update') : __('news_post')}
            </Button>
          </Stack>
          <Box className="body">
            <Paper className="paper_wrapper" sx={{ gridRow: 1, gridColumn: 1 }}>
              <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
                {__('news_title')}
              </Typography>
              <TextField
                {...fields.news_title}
                autoFocus
                placeholder={__('news_enter_title')}
                isShowMessage={false}
                maxLength={100}
              />
            </Paper>
            <Paper className="paper_wrapper" sx={{ gridColumn: 2, gridRow: 1 }}>
              <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
                {__('category')}
              </Typography>

              <Stack>
                <FormControl sx={{ width: '100%', height: '100%' }}>
                  <Select
                    displayEmpty
                    error={Boolean(fields.category_id?.error)}
                    value={fields.category_id.value?.label || ''}
                    open={isShowSelectCategory}
                    onClick={() => setIsShowSelectCategory(!isShowSelectCategory)}
                    onBlur={() => fields.category_id.runValidation(fields.category_id.value)}
                    input={
                      <OutlinedInput
                        value={fields.category_id.value?.label || ''}
                        className="select_input"
                      />
                    }
                    renderValue={selected => {
                      if (!selected) {
                        return <em>{__('news_select_category')}</em>
                      }
                      return selected
                    }}
                    MenuProps={menuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem disabled value="">
                      <em>{__('news_select_category')}</em>
                    </MenuItem>
                    {categoryData?.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item?.value || ''}
                        sx={{
                          p: theme => theme.spacing(2),
                          borderRadius: theme => theme.spacing(1.5),
                          background: theme =>
                            item?.value === fields.category_id.value?.value
                              ? theme.palette.primary.backgroundHover
                              : theme.palette.background.paper
                        }}
                        onClick={() => {
                          setIsShowSelectCategory(!isShowSelectCategory)
                          fields.category_id.onChange(item)
                        }}
                      >
                        <Tooltip title={item?.label || ''}>
                          <Typography
                            noWrap
                            fontWeight={600}
                            color={
                              item?.value === fields.category_id.value?.value
                                ? 'primary.main'
                                : 'text.primary'
                            }
                          >
                            {item?.label || ''}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {fields.category_id?.error && (
                  <Typography
                    component={'span'}
                    variant="body2"
                    color={'error.main'}
                    fontWeight={400}
                  >
                    {fields.category_id?.error?.toString()}
                  </Typography>
                )}
              </Stack>
            </Paper>
            <Paper className="paper_wrapper" sx={{ gridColumn: 1, gridRow: 2 }}>
              <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
                {__('news_short_description')}
              </Typography>
              <TextField
                {...fields.news_short_description}
                placeholder={__('news_short_description_placeholder')}
                isShowMessage={false}
                multiline={true}
                rows={4}
                maxLength={100}
                className="textarea_input"
              />
            </Paper>
            <Paper className="paper_wrapper" sx={{ gridColumn: 2, gridRow: 2 }}>
              <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
                {__('news_cover_photo')}
              </Typography>
              <Stack gap={0.5} sx={{ height: theme => theme.spacing(26.125) }}>
                <UploadFileComponent
                  title={__('news_cover_photo_note')}
                  mediaUrl={fields.news_thumbnail.value || ''}
                  removeMediaUrl={() => {
                    fields.news_thumbnail.onChange('')
                  }}
                  isRequired={Boolean(fields.news_thumbnail?.error)}
                  setMediaUrl={data => {
                    fields.news_thumbnail.onChange(data)
                  }}
                  accept="image/png, image/jpg, image/jpeg"
                />
                {fields.news_thumbnail?.error && (
                  <Typography
                    component={'span'}
                    variant="body2"
                    color={'error.main'}
                    fontWeight={400}
                  >
                    {fields.news_thumbnail?.error?.toString()}
                  </Typography>
                )}
              </Stack>
            </Paper>
            <Paper className="paper_wrapper" sx={{ gridColumn: 'span 2', gridRow: 3 }}>
              <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                  {__('news_long_description')}
                </Typography>
                {/*Start:  Ckeditor */}
                <Box>
                  <Box
                    sx={{
                      border: theme =>
                        `1px solid ${fields.news_content.error ? theme.palette.error.main : theme.palette.divider}`,
                      '& .ck-editor__editable_inline': {
                        minHeight: theme => theme.spacing(50)
                      }
                    }}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      ref={editorRef}
                      data={fields.news_content.value}
                      onChange={(event, editor) => {
                        fields.news_content.onChange(editor.getData())
                      }}
                      config={{
                        placeholder: `${__('')}`,
                        plugins: [
                          'Bold',
                          'Italic',
                          'Heading',
                          'Link',
                          'List',
                          'Table',
                          'TableToolbar'
                        ]
                      }}
                    />
                  </Box>
                  {fields.news_content?.error && (
                    <Typography
                      component={'span'}
                      variant="body2"
                      color={'error.main'}
                      fontWeight={400}
                    >
                      {fields.news_content?.error?.toString()}
                    </Typography>
                  )}
                </Box>
                {/*End:  Ckeditor */}
              </Box>
            </Paper>
          </Box>
        </Box>
      </NewsAddContainer>
    </BigLayout>
  )
}

export default NewsAdd

const DialogComponent = ({ open, handleClose, handleSubmit }) => {
  return (
    <Dialog
      PaperProps={{ elevation: 1 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textTransform: 'none' }} id="alert-dialog-title">
        {__('news_leave')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {__('news_leave_title')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ textTransform: 'none' }} onClick={handleSubmit}>
          {__('news_leave_button')}
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            textTransform: 'none',
            background: theme => theme.palette.text.disabled
          }}
        >
          {__('upgrade_compare_modal_btn_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const NewsAddContainer = styled(Stack)(({ theme }) => ({
  '& .body': {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'auto auto auto',
    gap: theme.spacing(2)
  },
  '& .paper_wrapper': {
    padding: theme.spacing(2.5),
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateRows: 'auto 1fr',
    border: `1px solid ${theme.palette.divider}`
  },
  '& .select_input': {
    height: '100%',
    maxHeight: theme.spacing(7),
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.default,
    outline: 'none'
  },
  '& .textarea_input': {
    alignItems: 'flex-start'
  }
}))
