//Library
import _ from 'lodash'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FieldDictionary, notEmpty, useDynamicList, useField, useForm } from '@shopify/react-form'
import { useNavigate } from 'react-router-dom'

//Components
import Title from 'components/Title'
import TextField from 'components/TextField'
import AddListMission from './mission.add.list'
import OptionGiftMission from '../components/mission.option.gift'
import _Helmet from 'components/helmet'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import {
  createRedeem,
  getListLevel,
  setActionsCheck,
  setDataEditRedeem,
  updateRedeem
} from '../store/mission.store.reduce'

//Interface
import { TypeDataConvert, TypeListLevel, TypeMissionAction, TypeMissionData } from '../interface'

//Helpers
import helpers from 'helpers'

//Scss
import 'entities/mission/media/mission.add.scss'
import __ from 'languages/index'
import SmallLayout from '../../../layouts/smallLayout'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { Box, Button } from '@mui/material'

interface TypeDataMission {
  action_name?: string
  action_point?: string
  mission_action?: {
    action_name?: string
    action_point?: string
  }[]
  number_of_day?: string
  option_award?: string
  gift_data?: string
  gift_coin?: string
}

const AddMission = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const channel_data = useAppSelector(state => state.user.channel_data)
  const { actionsCheck, dataEditRedeem } = useAppSelector(state => state.mission)

  const [optionConditions, setOptionConditions] = useState<TypeDataConvert[]>([
    {
      _id: '0',
      value: '',
      label: `${__('mission_condition_join')}`
    }
  ])
  const [isRerender, setIsRerender] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isChangeNumberDay, setIsChangeNumberDay] = useState<boolean>(false)

  const { fields, submit, submitting, dynamicLists, dirty, reset } = useForm({
    fields: {
      redeem_level: useField<string>({
        value: optionConditions[0]?.value || '',
        validates: [
          notEmpty(`${__('mission_chose_condition_join')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('mission_chose_condition_join')}`
            }
          }
        ]
      }),
      total_day: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('mission_period_no_empty')}`),
          inputVal => {
            if (helpers.parseNumeric(inputVal) > 10) {
              return `${__('mission_period_max10_no_empty')}`
            }
            if (helpers.parseNumeric(inputVal) <= 0) {
              return `${__('mission_period_no_empty')}`
            }
          }
        ]
      }),
      redeem_name: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('mission_name_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('mission_name_empty')}`
            }
          }
        ]
      }),
      option_award: useField<string>({
        value: 'award',
        validates: [
          notEmpty(`${__('mission_chose_reward_note')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('mission_chose_reward_note')}`
            }
          }
        ]
      }),
      gift_data: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('mission_chose_reward_note')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('mission_chose_reward_note')}`
            }
          }
        ]
      }),
      gift_coin: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('mission_quantity_diamond_not_empty')}`),
          inputVal => {
            if (helpers.parseNumeric(inputVal) > 1000000000) {
              return `${__('mission_quantity_diamond_max')}`
            }
            if (helpers.parseNumeric(inputVal) <= 0) {
              return `${__('mission_quantity_diamond_not_empty')}`
            }
          }
        ]
      })
    },
    dynamicLists: {
      fulfillments: useDynamicList<TypeDataMission>([], emptyValueInput)
    },
    async onSubmit(values) {
      try {
        await helpers.sleep(1000)
      } catch (e: any) {
      } finally {
        return { status: 'success' }
      }
    }
  })

  const {
    fulfillments: { addItem, removeItem, fields: fulfillmentFields }
  } = dynamicLists

  async function deleteNumberDays(arr: FieldDictionary<TypeDataMission>[], _value: string) {
    for (let idx = arr.length - 1; idx >= 0; idx--) {
      if (idx + 1 > Number(_value)) {
        await removeItem(idx)
      }
    }
  }

  const deleteNumberDaysCallback = useMemo(() => {
    return _.debounce((_value: string, arr: FieldDictionary<TypeDataMission>[]) => {
      let argLength = Array.isArray(arr) ? arr.length : 0
      if (!argLength && Number(_value) && Number(_value) <= 10) return addItem()
      if (argLength <= Number(_value)) return null

      deleteNumberDays(arr, _value)
    }, 100)
  }, [])

  const validationField = useCallback(
    (item: FieldDictionary<TypeDataMission>, name: string, isError: boolean) => {
      let isValid = true

      if (!item[`${name}`]?.value) {
        isValid = false
        item[`${name}`]?.setError(isError)
        item[item['option_award']?.value === 'award' ? 'gift_coin' : 'gift_data']?.setError(
          undefined
        )
      } else {
        item[`${name}`]?.setError(undefined)
      }
      return isValid
    },
    [fulfillmentFields]
  )

  const handleFocusField = useCallback(
    (item: FieldDictionary<TypeDataMission>, name: string) => {
      item[`${name}`]?.setError(undefined)
    },
    [fulfillmentFields]
  )

  function emptyValueInput(data: TypeDataMission) {
    return {
      number_of_day: data?.number_of_day || '',
      action_name: '',
      mission_action: data?.mission_action || [],
      action_point: '',
      option_award: data?.option_award || 'award',
      gift_data: data?.gift_data || '',
      gift_coin: data?.gift_coin || ''
    }
  }

  const handleClose = useCallback(() => {
    navigate(`/setting-mission`)
    reset()
  }, [])

  const handleValidateAll = useCallback(() => {
    if (!fulfillmentFields?.length) return
    let arrIsError = []
    fulfillmentFields?.forEach((item: FieldDictionary<TypeDataMission>) => {
      delete item['number_of_day']

      if (!item['gift_coin'].value || item['gift_coin'].value.length === 0) {
        delete item['gift_coin']
      }
      if (!item['gift_data'].value || item['gift_data'].value.length === 0) {
        delete item['gift_data']
      }

      item.option_award?.value === 'award' ? delete item['gift_coin'] : delete item['gift_data']
      if (item['action_name']?.value && item['action_point']?.value) {
        item['action_name']?.onChange('')
        item['action_point']?.onChange('')
        item['mission_action']?.value.push({
          action_name: item['action_name']?.value,
          action_point: item['action_point']?.value
        })
      }
      if (item.mission_action?.value?.length) {
        if (!item['action_name']?.value || !item['action_point']?.value) {
          delete item['action_name']
          delete item['action_point']
        }
      }
      Object.keys(item)?.forEach((key: string) => {
        if (key === 'gift_coin') {
          if (!helpers.formatNumberCommasToNumeric(item['gift_coin']?.value)) {
            item['gift_coin']?.setError('error')
            arrIsError.push(true)
            return
          }
        } else if (!item[key]?.value || !item[key]?.value?.length) {
          item[key]?.setError('error')
          arrIsError.push(true)
        } else {
          item[key]?.setError(undefined)
          arrIsError.push(false)
        }
      })
    })

    return arrIsError?.every((value: boolean) => value === false)
  }, [fulfillmentFields])

  const handleValidationFields = useCallback(() => {
    const isValidateCondition = fields.redeem_level?.runValidation(fields.redeem_level?.value)
    const isValidateNumberDays = fields.total_day?.runValidation(fields.total_day?.value)
    const isValidateRedeemName = fields.redeem_name?.runValidation(fields.redeem_name?.value)
    const isValidateAward =
      fields.option_award?.value === 'coin'
        ? fields.gift_coin?.runValidation(fields.gift_coin?.value)
        : fields.gift_data?.runValidation(fields.gift_data?.value)

    return (
      !isValidateCondition && !isValidateNumberDays && !isValidateAward && !isValidateRedeemName
    )
  }, [fields])

  const handleSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (!handleValidationFields()) return
      if (!handleValidateAll()) return

      const convertFulfillment = fulfillmentFields?.map(
        (item: FieldDictionary<TypeDataMission>, idx: number) => {
          const mission_data = {
            number_of_day: idx + 1,
            mission_action: item?.mission_action?.value?.map(
              (data: { action_name?: string; action_point?: string }) => ({
                action_name: data?.action_name,
                action_point: `${helpers.parseNumeric(data?.action_point)}`
              })
            ),
            gift_data: [item?.gift_data?.value],
            gift_coin: `${helpers.parseNumeric(item?.gift_coin?.value)}`
          }
          item?.option_award?.value === 'award' && delete mission_data['gift_coin']
          item?.option_award?.value === 'coin' && delete mission_data['gift_data']
          return { ...mission_data }
        }
      )

      const formData = {
        redeem_level: fields.redeem_level?.value,
        mission_data: JSON.stringify(convertFulfillment),
        gift_data: JSON.stringify([fields.gift_data?.value]),
        gift_coin: `${helpers.parseNumeric(fields.gift_coin?.value)}`,
        redeem_name: fields.redeem_name?.value?.trim(),
        total_day: `${helpers.parseNumeric(fields.total_day?.value)}`,
        redeem_status: 'open'
      }
      fields.option_award?.value === 'award' && delete formData['gift_coin']
      fields.option_award?.value === 'coin' && delete formData['gift_data']

      setIsLoading(true)

      dataEditRedeem
        ? dispatch(
            updateRedeem({
              _id: dataEditRedeem?._id,
              ...formData,
              gift_data: fields.option_award?.value === 'award' ? formData?.gift_data : null,
              gift_coin: fields.option_award?.value === 'coin' ? formData?.gift_coin : '0'
            })
          )
            .unwrap()
            .then(res => {
              setIsLoading(false)
              dispatch(
                setActionsCheck({
                  type: `${__('mission_update')}`,
                  isSuccess: true,
                  statusCode: res?.status
                })
              )
              handleClose()
            })
            .catch(error => {
              console.log(`updateRedeem_${error}`)
              setIsLoading(false)
              toast.show({
                title: `${__('notification')}`,
                content: `${__('mission_update_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        : dispatch(createRedeem(formData))
            .unwrap()
            .then(res => {
              setIsLoading(false)
              dispatch(
                setActionsCheck({
                  type: `${__('mission_setting')}`,
                  isSuccess: true,
                  statusCode: res?.status
                })
              )
              handleClose()
            })
            .catch(error => {
              console.log(`createRedeem_${error}`)
              setIsLoading(false)
              toast.show({
                title: `${__('notification')}`,
                content: `${__('mission_setting_success')}`,
                type: EnumTypeToast.Error
              })
            })
    },
    [fulfillmentFields, fields]
  )

  const handleAcceptMission = useCallback(() => {
    if (handleValidationFields()) {
      deleteNumberDaysCallback(fields.total_day?.value, fulfillmentFields)
      setIsChangeNumberDay(false)
    }
  }, [fields, fulfillmentFields])

  const handleAddDay = useCallback(() => {
    if (!handleValidateAll()) return
    addItem()
  }, [fulfillmentFields, fields])

  useEffect(() => {
    if (
      actionsCheck?.isSuccess &&
      actionsCheck?.statusCode === 200 &&
      actionsCheck?.type === `${__('mission_add_reward')}`
    ) {
      dispatch(
        setActionsCheck({
          type: '',
          isSuccess: false,
          statusCode: 0
        })
      )
      toast.show({
        title: `${__('notification')}`,
        content: `${actionsCheck?.type} ${__('mission_success')}`,
        type: EnumTypeToast.Success
      })
    }
  }, [actionsCheck])

  useEffect(() => {
    dispatch(getListLevel({}))
      .unwrap()
      .then(res => {
        res?.data?.map((item: TypeListLevel) => {
          if (optionConditions?.some((data: TypeDataConvert) => data?._id === item?._id)) return
          setOptionConditions(optionConditions => [
            ...optionConditions,
            {
              _id: item?._id,
              value: `${item?.level_number}`,
              label: `${__('mission_level')} ${item?.level_number} - ${item?.title}`
            }
          ])
        })
      })
      .catch(error => {
        console.log(`getListLevel_${error}`)
      })
  }, [])

  useEffect(() => {
    if (!dataEditRedeem) return

    fields.redeem_level?.onChange(`${dataEditRedeem?.redeem_level}`)
    fields.redeem_name?.onChange(dataEditRedeem?.redeem_name || '')
    fields.total_day?.onChange(dataEditRedeem?.total_day || '')
    fields.option_award?.onChange(dataEditRedeem?.gift_coin ? 'coin' : 'award')
    fields.gift_data?.onChange(dataEditRedeem?.gift_data?.[0]?._id || '')
    fields.gift_coin?.onChange(dataEditRedeem?.gift_coin)

    dataEditRedeem?.mission_data?.map((item: TypeMissionData) => {
      addItem({
        number_of_day: item?.number_of_day,
        action_name: '',
        mission_action: item?.mission_action?.map((data: TypeMissionAction) => ({
          action_name: data?.action_name,
          action_point: data?.action_point
        })),
        action_point: '',
        option_award: item?.gift_coin ? 'coin' : 'award',
        gift_data: item?.gift_data?.[0]?._id,
        gift_coin: `${item?.gift_coin}`
      })
    })
    return () => {
      dispatch(setDataEditRedeem(null))
    }
  }, [dataEditRedeem])

  return (
    <SmallLayout>
      <_Helmet title={__('mission_title')} />
      <div id="addMission">
        <Title
          text={`${dataEditRedeem ? `${__('mission_update')}` : `${__('mission_setting')}`}`}
        />
        <Box
          component={Form}
          sx={{ backgroundColor: '#fff !important' }}
          className="addMission_body_container"
        >
          <div className="addMission_body_header">
            <div className="addMission_body_header_wrap">
              <div className="addMission_body_header_first">
                <div className="addMission_body_header_label">
                  <label className="addMission_body_header_title">{__('mission_name')}</label>
                  <span className="text-danger">&#42;</span>
                </div>
                <TextField
                  {...fields.redeem_name}
                  label={__('mission_name')}
                  placeholder={__('mission_name')}
                  labelHidden
                  maxLength={50}
                  type="text"
                  requiredIndicator
                  value={fields.redeem_name?.value}
                  onChange={value => {
                    fields.redeem_name?.onChange(value)
                  }}
                />
              </div>
            </div>
            <div className="addMission_body_second_wrap">
              <div className="addMission_body_header_second">
                <div className="addMission_body_header_label">
                  <label className="addMission_body_header_title">
                    {__('mission_condition_join')}
                  </label>
                  <span className="text-danger">&#42;</span>
                </div>
                <Form.Select
                  value={fields.redeem_level?.value}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const { value } = e.target
                    fields.redeem_level?.onChange(value)
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const { value } = e.target
                    fields.redeem_level?.runValidation(value)
                  }}
                  onFocus={() => fields.redeem_level?.setError('')}
                  className={`form-select ${fields.redeem_level?.error && 'validate_failed'}`}
                >
                  {optionConditions?.map((item: any, idx: number) => (
                    <option key={idx} value={item?.value || ''} disabled={!idx}>
                      {item?.label || ''}
                    </option>
                  ))}
                </Form.Select>
                {fields.redeem_level?.error && (
                  <span className="form-text text-danger">{fields.redeem_level?.error}</span>
                )}
              </div>
              <div>
                <div className="addMission_body_header_label">
                  <label className="addMission_body_header_title">{__('mission_period')}</label>
                  <span className="text-danger">&#42;</span>
                </div>
                <TextField
                  {...fields.total_day}
                  label={__('mission_period')}
                  labelHidden
                  maxLength={10}
                  type="text"
                  requiredIndicator
                  value={helpers.parseNumeric(fields.total_day?.value)}
                  onChange={value => {
                    fields.total_day?.onChange(value)
                    setIsChangeNumberDay(true)
                  }}
                />
              </div>
            </div>
            <div>
              <div className="addMission_right_info_award_wrapper">
                <div className="addMission_header_right_info_award">
                  <div className="addMission_body_header_title_wrapper">
                    <label className="addMission_body_header_title">{__('mission_reward')}</label>
                    <span className="text-danger">&#42;</span>
                  </div>
                  <div className="addMission_body_header_option_award">
                    <div className="addMission_body_header_option_body">
                      <input
                        type="radio"
                        value="award"
                        id="award"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fields.option_award?.onChange(e.target.value)
                          fields.gift_coin?.reset()
                        }}
                        checked={fields.option_award?.value === 'award'}
                      />
                      <label htmlFor="award">{__('mission_physical_gifts')}</label>
                    </div>
                    <div className="addMission_body_header_option_body">
                      <input
                        type="radio"
                        value="coin"
                        id="coin"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fields.option_award?.onChange(e.target.value)
                          fields.gift_data?.reset()
                        }}
                        checked={fields.option_award?.value === 'coin'}
                      />
                      <label htmlFor="coin">{__('mission_gift_diamond')}</label>
                    </div>
                  </div>
                </div>
                {fields.option_award.value === 'coin' && (
                  <div className="addMission_right_info_award_coin">
                    <div className="addMission_header_right_content_title">
                      <div className="addMission_body_header_title_wrapper">
                        <label className="addMission_body_header_title">
                          {__('mission_quantity_diamond')}
                        </label>
                        <span className="text-danger">&#42;</span>
                      </div>
                      <TextField
                        {...fields.gift_coin}
                        label={__('mission_quantity_diamond')}
                        labelHidden
                        maxLength={12}
                        placeholder={__('mission_quantity_diamond')}
                        type="text"
                        value={helpers.formatNumberWithCommas(fields.gift_coin?.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
              {fields.option_award.value === 'award' && (
                <div className="addMission_body_header_option_gift">
                  <OptionGiftMission
                    onChange={e => {
                      fields.gift_data?.onChange(e?._id)
                    }}
                    onBlurCallback={(e: string) => {
                      fields.gift_data?.runValidation(e)
                    }}
                    onFocusCallback={() => fields.gift_data?.setError('')}
                    className={`${fields.gift_data?.error && 'validate_failed'}`}
                    value={fields.gift_data?.value}
                    required
                  />
                  {fields.gift_data?.error && (
                    <span className="form-text text-danger">{fields.gift_data?.error}</span>
                  )}
                </div>
              )}
            </div>
            <div className="addMission_body_header_third">
              <Button
                sx={{ textTransform: 'none' }}
                onClick={handleAcceptMission}
                disabled={!dirty || submitting}
              >
                {__('btn_confirm')}
              </Button>
            </div>
          </div>
          {fulfillmentFields?.length > 0 && (
            <div className="addMission_body_add_mission_wrapper">
              <div className="addMission_body_add_mission">
                {!isChangeNumberDay &&
                  fulfillmentFields?.map((field: FieldDictionary<TypeDataMission>, idx: number) => (
                    <AddListMission
                      fulfillmentFields={fulfillmentFields}
                      field={field}
                      idx={idx}
                      key={idx}
                      validationField={validationField}
                      handleFocusField={handleFocusField}
                      isRerender={isRerender}
                      setIsRerender={setIsRerender}
                    />
                  ))}
              </div>
              {fulfillmentFields?.length > 0 &&
                !isChangeNumberDay &&
                fulfillmentFields?.length < Number(fields.total_day?.value) &&
                Number(fields.total_day?.value) <= 10 && (
                  <div className="d-flex justify-content-center">
                    <Button sx={{ textTransform: 'none' }} onClick={handleAddDay}>
                      {__('mission_btn_add_date')}
                    </Button>
                  </div>
                )}
            </div>
          )}
          {fields.total_day?.value &&
            fulfillmentFields?.length === Number(fields.total_day?.value) &&
            !isChangeNumberDay && (
              <div className="d-flex justify-content-end">
                <Button variant="text" sx={{ textTransform: 'none' }} onClick={handleClose}>
                  Hủy
                </Button>
                <Button
                  sx={{ textTransform: 'none' }}
                  type="submit"
                  disabled={!dirty || submitting}
                  className="ms-2"
                  onClick={handleSubmit}
                >
                  {isLoading || submitting ? `${__('btn_processing')}` : `${__('btn_confirm')}`}
                </Button>
              </div>
            )}
        </Box>
      </div>
    </SmallLayout>
  )
}

export default memo(AddMission)
