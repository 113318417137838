//Library
import React, { useCallback, useEffect, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faPlus, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

//Components
import Capacity from 'components/Capacity'
import ModalConfirm from './components/about.modal.confirm'
import ModalUploadImage from './components/about.modal.upload.image'
import AboutSkeleton from './components/about.skeleton'
import _Helmet from 'components/helmet'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { getDataAbout, updateChannel, uploadImage } from './store/about.store.reducer'

//Image
import imageDefault from 'media/images/image_default.png'
import avatarDefault from 'media/images/avatar_default.png'

//Interface
import { TypeAboutMedia, TypeDataAbout } from './interface'

//Helpers
import helpers from 'helpers'
//Scss
import './media/about.page.scss'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import { getDefaultChannel } from 'store/user.store.reducer'
import SmallLayout from '../../layouts/smallLayout'

const AboutPage = () => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingUpdateDesc, setIsLoadingUpdateDesc] = useState<boolean>(false)
  const [deleteImage, setDeleteImage] = useState<string>('')
  const [isShowEditDes, setIsShowEditDesc] = useState<boolean>(false)
  const [isShowUploadImage, setIsShowShowUploadImage] = useState<boolean>(false)
  const [dataAbout, setDataAbout] = useState<TypeDataAbout>()
  const [descriptionChange, setDescriptionChange] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<TypeAboutMedia>()
  const toast = useToast()

  const handleConfirmDeleteImage = useCallback(
    (result: boolean) => {
      setDeleteImage('')
      if (result) {
        if (helpers.isEmpty(dataAbout?.attach_files)) return
        const newAttachFiles = dataAbout?.attach_files
          .filter((item: TypeAboutMedia) => item?._id !== deleteImage)
          .map((data: TypeAboutMedia) => data?._id)
        dispatch(updateChannel({ attach_files: JSON.stringify(newAttachFiles) }))
          .unwrap()
          .then((res: AxiosResponse<TypeDataAbout>) => {
            dispatch(getDefaultChannel())
            // localStorage.setItem('channel', JSON.stringify(res?.data))
            setDataAbout(res?.data)
            toast.show({
              content: `${__('about_page_update_info_success')}`,
              type: EnumTypeToast.Success
            })
            if (helpers.isEmpty(res?.data?.attach_files)) {
              setSelectedImage(null)
            }
          })
          .catch((error: AxiosError) => {
            console.log(`updateChannel_${error}`)
            toast.show({
              content: `${__('about_page_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [deleteImage, dataAbout, channel_data]
  )

  const handleUploadImage = useCallback(
    (result: boolean, file: File) => {
      setIsShowShowUploadImage(false)
      if (result) {
        let formData = new FormData()
        formData.append('file[]', file)
        dispatch(uploadImage(formData))
          .unwrap()
          .then((res: AxiosResponse) => {
            const newAttachFiles = dataAbout?.attach_files?.map((data: TypeAboutMedia) => data?._id)
            dispatch(
              updateChannel({
                attach_files: JSON.stringify([...newAttachFiles, res?.data[0]?.callback?._id])
              })
            )
              .unwrap()
              .then(() => {
                dispatch(getDataAbout())
                  .unwrap()
                  .then((res: AxiosResponse<TypeDataAbout>) => {
                    setDataAbout(res?.data)
                    setSelectedImage(res?.data?.attach_files[0])
                  })
                  .catch((error: AxiosError) => {
                    console.log(`getDataAbout_${error}`)
                  })
                toast.show({
                  content: `${__('about_page_update_info_success')}`,
                  type: EnumTypeToast.Success
                })
              })
              .catch((error: AxiosError) => {
                console.log(`updateChannel_${error}`)
                return toast.show({
                  content: `${__('about_page_update_info_failed')}`,
                  type: EnumTypeToast.Error
                })
              })
          })
          .catch((error: AxiosError) => {
            console.log(`uploadImage_${error}`)
            return toast.show({
              content: `${__('about_page_upload_failed')}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [dataAbout, channel_data]
  )

  const handleChangeDes = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setDescriptionChange(value)
  }, [])

  const handleUpdateDes = useCallback(() => {
    setIsLoadingUpdateDesc(true)
    dispatch(updateChannel({ description: descriptionChange.trim() }))
      .unwrap()
      .then((res: AxiosResponse<TypeDataAbout>) => {
        setIsLoadingUpdateDesc(false)
        setIsShowEditDesc(false)
        setDescriptionChange('')
        setDataAbout(res?.data)
        toast.show({
          content: `${__('about_page_update_info_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((error: AxiosError) => {
        console.log(`updateChannel_${error}`)
        setIsLoadingUpdateDesc(false)
        toast.show({
          content: `${__('about_page_update_info_failed')}`,
          type: EnumTypeToast.Error
        })
      })
  }, [descriptionChange, channel_data])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getDataAbout())
      .unwrap()
      .then((res: AxiosResponse<TypeDataAbout>) => {
        setDataAbout(res?.data)
        setSelectedImage(res?.data?.attach_files[0])
        setIsLoading(false)
      })
      .catch((error: AxiosError) => {
        console.log(`getDataAbout_${error}`)
        setIsLoading(false)
      })
  }, [])

  return (
    <SmallLayout>
      <_Helmet title="about_page_title" />
      <div id="aboutPage" className="aboutPage_container">
        <div className="aboutPage_wrapper">
          {isLoading ? (
            <AboutSkeleton />
          ) : (
            <>
              <div className="aboutPage_first">
                <span className="aboutPage_title">{dataAbout?.name || ''}</span>
                <Capacity current_user_can={'boss'}>
                  <Link to={'/channel-settings/information'} className="aboutPage_button">
                    <FontAwesomeIcon icon={faGear} />
                    <span className="aboutPage_button_text">{__('about_page_edit_channel')}</span>
                  </Link>
                </Capacity>
              </div>
              <div className="aboutPage_second">
                <div className="aboutPage_second_media">
                  {helpers.isEmpty(selectedImage) ? (
                    <Capacity current_user_can={'boss'}>
                      <div
                        className="aboutPage_second_empty_image"
                        onClick={() => setIsShowShowUploadImage(true)}
                        role="button"
                      >
                        <span>{__('about_page_chose_image')}</span>
                      </div>
                    </Capacity>
                  ) : (
                    <>
                      {selectedImage?.media_type === 'video' ? (
                        <video
                          controlsList={'nodownload'}
                          controls
                          key={selectedImage?.media_url}
                          className="aboutPage_second_video"
                          preload={'metadata'}
                          disablePictureInPicture
                          muted
                          id="video_source"
                        >
                          <source src={selectedImage?.media_url} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          className="aboutPage_second_image"
                          src={selectedImage?.media_url || imageDefault}
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                            (e.currentTarget.src = imageDefault)
                          }
                          alt="image"
                          loading="lazy"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="aboutPage_second_listMedia">
                  {!helpers.isEmpty(dataAbout?.attach_files) &&
                    dataAbout?.attach_files?.map((item: TypeAboutMedia, idx: number) => (
                      <div className="aboutPage_second_List_item" key={`listMedia_${idx}`}>
                        {item?.media_type === 'video' ? (
                          <video
                            role="button"
                            onClick={() => setSelectedImage(item)}
                            className="aboutPage_second_List_item_image"
                          >
                            <source src={item?.media_url} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            role="button"
                            onClick={() => setSelectedImage(item)}
                            className="aboutPage_second_List_item_image"
                            src={item?.media_url || imageDefault}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                              (e.currentTarget.src = imageDefault)
                            }
                            alt="image"
                            loading="lazy"
                          />
                        )}
                        <Capacity current_user_can={'boss'}>
                          <FontAwesomeIcon
                            onClick={() => setDeleteImage(item?._id)}
                            className="aboutPage_second_List_item_icon"
                            icon={faXmark}
                          />
                        </Capacity>
                      </div>
                    ))}
                  {!helpers.isEmpty(dataAbout?.attach_files) && (
                    <Capacity current_user_can={'boss'}>
                      <div
                        className="aboutPage_second_List_item_button_plus"
                        onClick={() => setIsShowShowUploadImage(true)}
                        role="button"
                      >
                        <FontAwesomeIcon className="aboutPage_second_icon_plus" icon={faPlus} />
                      </div>
                    </Capacity>
                  )}
                </div>
              </div>
              <div className="aboutPage_four">
                <div className="aboutPage_four_members">
                  <FontAwesomeIcon icon={faUsers} style={{ color: '#000000' }} />
                  <span>{`${dataAbout?.member_number || '0'} ${__('about_page_member')}`}</span>
                </div>
                <div className="aboutPage_four_author">
                  <img
                    className="aboutPage_four_author_image"
                    src={
                      dataAbout?.user_id?.user_avatar_thumbnail ||
                      dataAbout?.user_id?.user_avatar ||
                      avatarDefault
                    }
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                      (e.currentTarget.src = avatarDefault)
                    }
                    alt="image"
                    loading="lazy"
                  />
                  <span>{`${__('about_page_sub_text')} ${dataAbout?.user_id?.display_name}`}</span>
                </div>
              </div>
              <div className="aboutPage_five">
                <span className="aboutPage_five_header">{__('about_page_des')}</span>
                {isShowEditDes ? (
                  <textarea
                    value={descriptionChange || ''}
                    onChange={handleChangeDes}
                    placeholder={`${__('about_page_des')}...`}
                    className="form-control"
                  />
                ) : (
                  <span className="aboutPage_five_content">
                    {dataAbout?.description
                      ? dataAbout?.description
                      : `${__('about_page_none_des')}`}
                  </span>
                )}
                <Capacity current_user_can={'boss'}>
                  <div className="aboutPage_button_wrapper">
                    {!isShowEditDes && (
                      <Button
                        size="sm"
                        onClick={() => {
                          setIsShowEditDesc(true)
                          setDescriptionChange(dataAbout?.description)
                        }}
                        className="button-event"
                      >
                        {__('about_page_edit_des')}
                      </Button>
                    )}
                    {isShowEditDes && (
                      <>
                        <Button
                          size="sm"
                          onClick={() => {
                            setIsShowEditDesc(false)
                            setDescriptionChange('')
                          }}
                          variant="secondary"
                          className="aboutPage_five_button"
                        >
                          Huỷ
                        </Button>
                        <Button size="sm" onClick={handleUpdateDes} className="button-event">
                          {isLoadingUpdateDesc ? `${__('btn_processing')}` : `${__('btn_save')}`}
                        </Button>
                      </>
                    )}
                  </div>
                </Capacity>
              </div>
            </>
          )}
        </div>
        {Boolean(deleteImage) && (
          <ModalConfirm
            show={Boolean(deleteImage)}
            title={__('about_page_modal_title')}
            onClose={handleConfirmDeleteImage}
          >
            <span>{__('about_page_modal_sub_text')}</span>
          </ModalConfirm>
        )}
        {isShowUploadImage && (
          <ModalUploadImage show={isShowUploadImage} onClose={handleUploadImage} />
        )}
      </div>
    </SmallLayout>
  )
}

export default AboutPage
