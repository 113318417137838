import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faClipboard,
  faHashtag,
  faImage,
  faMoneyBill1Wave,
  faQrcode,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {InfoChannel, Media, Order} from "entities/orders/order.type";
import {Form} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import __helpers from "helpers/index";
import __ from "languages/index";
import { Typography , Button } from '@mui/material'
import { useAppSelector } from 'config/store'
import { channelInfo } from 'entities/channel-settings/store/channel.slice'

export  interface  TypeInfoPaymentProp{
  onChooseImageChange:(event)=>void,
  imageUrl: string,
  loadingImage:boolean,
  order: Order,
  mediaUrl: Media,
  shouldDisplayData: boolean,
  status: string,
};
const  OrderInformationPayment: React.FC<TypeInfoPaymentProp> =
  ({
     imageUrl,
     loadingImage,
     order,
     mediaUrl,
     onChooseImageChange,
     shouldDisplayData,
  }) =>{
    const channel: any = useAppSelector(channelInfo)
    console.log('channel?.transfer_info?.qr_transfer',channel?.transfer_info?.qr_transfer)
  return (
    <>
      <div className="order__item">
        <p className="order__title">{__("order_information_payment")}</p>
        <div className="order-detail-wrapper order__status" style = {{background: "#fff"}}>
            <FontAwesomeIcon icon={faHashtag} style={{color: "#000000",}} />
            <div>{__("order_information_banking_number")} {channel?.transfer_info?.bank_number}</div>
        </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <FontAwesomeIcon icon={faUser} style={{color: "#000000",}} />
            <div>{__("order_information_banking_holders")} {channel?.transfer_info?.bank_account}</div>
        </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <FontAwesomeIcon icon={faBuildingColumns} style={{color: "#000000",}} />
            <div>{__("order_information_banking")} {channel?.transfer_info?.bank_name}</div>
        </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <FontAwesomeIcon icon={faMoneyBill1Wave} style={{color: "#000000",}} />
            <div>{__("order_information_money")} {__helpers.formatNumberWithCommas(order?.price)} {__("order_unit")}</div>
        </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <FontAwesomeIcon icon={faClipboard} style={{color: "#000000",}} />
            <div>{__("order_information_transfer")} {order?.short_id}</div>
        </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <FontAwesomeIcon icon={faQrcode} style={{color: "#000000"}} />
            <div><img style={{width: "200px", height:'auto'}} src={channel?.transfer_info?.qr_transfer}/></div>
      </div>
        <hr  className="hr m-0" />
        <div className="order-detail-wrapper order__status">
            <div>
              <p>{__("order_information_content")}  <span className = 'order_payment_text'>{__("order_detail_button_confirm")}</span> {__("order_information_text")} </p>
              <p>{__("order_information_text_support")} <span className = 'order_payment_text' >{channel?.support_info?.phonenumber || __('order_information_telephone_number')}</span> {__("order_information_sub_text")} <span className = 'order_payment_text' >{`E-mail: ${channel?.support_info?.email ||__('order_information_email')}`}</span> </p>
            </div>
        </div>
      </div>

      <div className="order__item">
        <p className="order__title">{__("order_information_transfer_image")}</p>
        <div className="order-detail-wrapper position-relative d-flex justify-content-center" >
          <Form.Group className="my-3" >
            <div className="file-drop-area form-group form-upload-image-event" style={{height: "430px"}} >
              <div className="text-lg-center w-100 d-flex flex-column text-center align-items-center " >
                <div >
                  <FontAwesomeIcon icon={faImage}  style={{color: "#b9bcc0", fontSize: '50px'}} />
                  <p style={{marginTop: '16px'}}>{__("order_information_text_upload")}</p>
                </div>
                <Button
                  disabled={shouldDisplayData ?? false}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {__("order_information_btn_upload")}
                </Button>
              </div>
              {!shouldDisplayData && <Form.Control className="file-input" type="file" id="image" accept=".png,.jpg,.jpeg,.gif" onChange={onChooseImageChange}/>}
              <div className="error-image invalid-feedback">
                {__("media_form_text")}
              </div>
              {
                imageUrl || mediaUrl ?
                  <Image id="target" className={`choose_file_image choose-file-button fit-image`} src={imageUrl || mediaUrl?.media_url} alt="placeholder" />
                  :
                  null
              }
              {
                imageUrl ? !loadingImage ?
                    <div className="view-bottom-image">{__('media_form_sub_text')}</div>
                    :
                    null
                  :
                  null
              }
            </div>
          </Form.Group>
        </div>
        {shouldDisplayData && <Typography margin={theme => theme.spacing(2)} color={'primary.main'} >{__("order_information_uploaded")}</Typography>}
      </div>
    </>
  )
}
export  default OrderInformationPayment;
