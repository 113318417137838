import React from 'react'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import __ from 'languages/index'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import { verifyUserConfigs } from 'entities/channel-settings/Capability/verifyConfigs'
import { Box, Stack, Switch, Typography } from '@mui/material'
import {  TypeChannelConfig } from 'entities/channel-settings/Capability/index'


const VerifyUser = ({channelSetting, handleUpdateChannelInfo}:TypeChannelConfig )=>{

  return(
    <ChannelSettingsContentContainer>
      <ChannelSettingsTitle>{__('channel_verify_user')}</ChannelSettingsTitle>
      {
        verifyUserConfigs?.map((config, index)=>(
          <Box key={`verifyUserConfigs-${index}`} >
            <Stack direction={'row'} alignItems={'center'}>
              <Switch
                checked={channelSetting?.channel_register_mode === config?.key}
                onChange={() =>{
                  handleUpdateChannelInfo({
                    channel_register_mode: config?.key
                  },__('channel_verify_user'))
                }}
              />
              <Typography>{config?.value}</Typography>
            </Stack>
            <Typography
              color={'text.secondary'}
              p={theme => theme.spacing(1,0)}
            >{config?.description}</Typography>
          </Box>
        ))
      }

    </ChannelSettingsContentContainer>
  )
}

export default VerifyUser
