import {faArrowUp91, faCircleXmark, faMedal} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useAppSelector} from 'config/store'
import {postAddUserToCourse} from 'entities/gift/services/gift.receiver.service'
import React, {useState} from 'react'
import {useInfiniteScroll} from 'hooks/useInfiniteScroll'
import avatarDefault from 'media/images/avatar_default.png'
import ModalAccept from 'entities/homepage/components/modal.accept'
import LoadingSelectUserSkeleton from '../skeletons/course.select.user.skeleton'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import __ from "languages/index";
import {getListMembers} from '../services/courses.service'
import {TypedMember} from '../courses.interface'
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { formatString } from 'entities/support/utils'
import { Avatar, Box, Button, IconButton, InputBase, Modal, styled, Typography } from '@mui/material'
import { SearchIcon } from '../../../icons'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'

export default function ModalAddUser(props) {
  const [modalShow, setModalShow] = useState(false);
  const entity = useAppSelector(state => state.courses.entity)
  const toast = useToast()

  const [selectUser, setSelectUser] = useState<TypedMember>()

  const { data, hasMore, loadMore, updateParamRequestDebounce, isRefreshing, refresh } = useInfiniteScroll < TypedMember > ({
    search: "",
    course_id: props?.courseId,
    limit: 10
  }, getListMembers)

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    updateParamRequestDebounce({ search: inputValue });
  };
  console.log('modalShow >>>>',modalShow)
  const addUserToCourse = (item) => {
    console.log('item', item)
    setModalShow(true)
    setSelectUser(item)
  }

  const acceptAdd = () => {
    setModalShow(false)
    const params = {
      user_id: selectUser?._id || '',
      course_id: props?.courseId,
      add_type: entity ? entity?.coin_value > 0 ? "payment" : 'free' : 'free'
    }
    console.log("params",params)
    postAddUserToCourse(params).then(res => {
      setTimeout(() => {
        refresh()
      }, 500);
      props.refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_modal_add_user_content_success")}` })
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_modal_add_user_content_failed")}` })

    })
  }
  const acceptAddSecond = () => {
    setModalShow(false)
    const params = {
      user_id: selectUser?._id || '',
      course_id: props?.courseId,
      add_type: 'free'
    }
    postAddUserToCourse(params).then(res => {
      setTimeout(() => {
        refresh()
      }, 500);
      props.refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_modal_add_user_content_success")}` })
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_modal_add_user_content_failed")}` })
    })
  }


  return (
    <ModalAddUserContainer
      {...props}
      open={props.show}
      onClose={() => props.onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      disableScrollLock
    >
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <ModalHeader
          direction={'row'}
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: `2fr auto`,
            textAlign: 'center'

          }}
        >
          <Typography variant={'h6'}>
            {__("course_modal_add_member")}
          </Typography>
          <Button
            sx={{
              color: theme => theme.palette.text.disabled
            }}
            variant={'text'}
            onClick={() => props.onHide()}
          >
            <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: 22 }} />
          </Button>
        </ModalHeader>
        <ModalBody
          className="body"
          id="scrollableDiv"
          sx={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
          <Box className="course_modal_input">
            <IconButton>
              <SearchIcon
                sx={{
                  width: theme =>theme.spacing(2.5),
                  height: theme =>theme.spacing(2.5),
                }}
              />
            </IconButton>
            <InputBase
              placeholder={__("course_modal_search_member")}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={handleInputChange}
              className='course_modal_input_search'
            />
          </Box>

          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
            scrollableTarget={"scrollableDiv"}
            skeleton={<LoadingSelectUserSkeleton />}
            textEmptyMessage={formatString(__('data_empty'), `${__('course_student')}`)}
          >
            <Box>
              {data?.map((item, index) => {
                return (
                  <Box
                    className='mt-3 p-2 rounded'
                    sx={{
                      backgroundColor: theme => theme.palette.background.default
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box display={'flex'}>
                        <Avatar
                          sx={{
                            width: theme => theme.spacing(7.5),
                            height: theme => theme.spacing(7.5)
                          }}
                          src={item?.user_avatar_thumbnail.length > 0 ? item?.user_avatar_thumbnail : item?.user_avatar.length > 0 ? item?.user_avatar : avatarDefault}
                          onError={(e) =>
                            (e.currentTarget.src = avatarDefault)
                          }
                          {...props}
                        />
                        <Box className='ms-3'>
                          <Typography variant={'body1'} fontWeight={'bold'}>{item?.display_name}</Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: theme => theme.palette.text.primary
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />
                            <Box sx={{ marginRight: theme => theme.spacing(2) }}>{item?.point} {__("point_unit")}</Box>
                            <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
                            {`${__("course_level")} ${item?.level_number}`}
                          </Box>
                        </Box>

                      </Box>
                      <Button
                        sx={{
                          textTransform: 'none',
                          fontSize: theme => theme.spacing(2)
                        }}
                        onClick={() => addUserToCourse(item)}
                      >
                        {__("course_modal_text_add")}
                      </Button>
                    </Box>

                  </Box>
                )
              })}
            </Box>
          </InfiniteScrollContainer>

        </ModalBody>
        {entity?.coin_value > 0 ?
          <Box>
            {
              modalShow &&
              <ModalAccept
                show={modalShow}
                onHide={() => setModalShow(false)}
                onAccept={acceptAdd}
                onAcceptSecond={acceptAddSecond}
                title={__("course_add_user")}
                desciption={__("course_add_user_desciption")}
                showMultiSelect={true}
                btnFirst={__("course_add_user_paid")}
                btnSecond={__("course_add_user_free")}

              />
            }
          </Box> :
          <Box>
            {
              modalShow &&
              <ModalAccept
                show={modalShow}
                onHide={() => setModalShow(false)}
                onAccept={acceptAdd}
                title={__("course_add_user")}
                desciption={__("course_add_user_confirm")}
              />
            }
          </Box>

        }
      </ModalContainer>
    </ModalAddUserContainer>
  )
}

const ModalAddUserContainer = styled(Modal)(({theme}) => ({

  '& .course_modal_input':{
    display: 'flex',
    width: '80%',
    background: theme.palette.background.default,
    border: `${theme.spacing(0.2)} solid ${theme.palette.text.disabled}`,
    borderRadius: theme.spacing(1.5),
    '& .course_modal_input_search': {
      width: '100%',
      '& ::placeholder': {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(2),
        fontWeight: 400,
        lineHeight: '133%'
      }
    }
  }
}))
