//Library
import {memo, useCallback, useEffect, useState} from 'react'
import { Modal} from 'react-bootstrap'
import {DateRange} from 'react-date-range'
import dateandtime from 'date-and-time'
//Interface
import {TypeDataTime} from 'entities/mentor/mentor.interface'

//Scss
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css'
import './../media/course.filter.time.scss'
import __ from "languages/index";
import { Button, Typography, styled } from '@mui/material'
import { GAMIFA_PRIMARY_COLOR } from 'constant/index'
import { useAppSelector } from 'config/store'
import { channelInfo } from 'entities/channel-settings/store/channel.slice'

const addDays = dateandtime.addDays
//Stores

const CourseIncomeFilterTime = ({
                                  data,
                                  show,
                                  onClose,
                                  onSubmit,
                                  title,
                                  ...args
                                }: {
  data
  show: boolean
  onClose: () => void
  onSubmit
  title: string
  args?: any
}) => {
  const channel_data = useAppSelector(channelInfo)
  const [stateTime, setStateTime] = useState<TypeDataTime[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  const handleSubmit = useCallback(() => {
    onSubmit(stateTime.at(0))
  }, [stateTime])

  const handleClose = useCallback(() => {
    onClose()
    setStateTime([data])
  }, [stateTime])

  useEffect(() => {
    if (!data?.startDate) return
    setStateTime([data])
  }, [data])

  return (
    <>
      <CourseIncomeFilterTimeContainer
        show={show}
        size="lg"
        onHide={handleClose}
        className="courseIncomeFilterTime__container"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRange
            onChange={(item: any) => setStateTime([item.selection])}
            months={1}
            minDate={addDays(new Date(), -300)}
            maxDate={addDays(new Date(), 900)}
            direction="vertical"
            scroll={{ enabled: true }}
            moveRangeOnFirstSelection
            retainEndDateOnFirstSelection
            ranges={stateTime}
            dateDisplayFormat="dd/MM/yyyy"
            rangeColors={[channel_data?.channel_primary_color || GAMIFA_PRIMARY_COLOR]}
            // locale={vi}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Typography
            role="button"
            color={'primary.main'}
            onClick={() =>
              onSubmit({
                startDate: undefined,
                endDate: undefined,
                key: 'selection'
              })
            }
          >
            {__("course_deselect_time")}
          </Typography>
          <Button sx={{textTransform: 'none'}} variant="contained" onClick={handleSubmit}>
            {__("course_btn_apply")}
          </Button>
        </Modal.Footer>
      </CourseIncomeFilterTimeContainer>
    </>
  )
}

export default memo(CourseIncomeFilterTime)

const CourseIncomeFilterTimeContainer = styled(Modal)(({ theme }) => ({
  '& .rdrMonth': {
    marginTop: theme.spacing(7.5),
    ':first-child': {
      marginTop: 0
    }
  },
}))
