import React from 'react';
import '../media/courses.skeleton.scss';

const LoadingSelectUserSkeleton = () => {
  return (
    <div>
      <div className="loading-skeleton-container mt-3">
        <div className='w-100'>
          <div className="bar mt-3 rounded" style={{ height: '60px', width: '100%' }}></div>
          <div className="bar mt-3 rounded" style={{ height: '60px', width: '100%' }}></div>
          <div className="bar mt-3 rounded" style={{ height: '60px', width: '100%' }}></div>
          <div className="bar mt-3 rounded" style={{ height: '60px', width: '100%' }}></div>
          <div className="bar mt-3 rounded" style={{ height: '60px', width: '100%' }}></div>
        </div>

      </div>
    </div>

  );
};

export default LoadingSelectUserSkeleton;
