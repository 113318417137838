import { TypeOfflineTime } from 'entities/channel-settings/service/service.type'

export const renderCourseOfflineTime = (listTime: TypeOfflineTime[])=>{
  const dataConvert = listTime.map(time=>{
    return {
      ...time,
      time: new Date(time?.time)
    };
  })
  const getYear = [...dataConvert].map((time)=>{
    return time?.time.getFullYear()
  })
 return  getYear.length === 1 || Math.min(...getYear) === Math.max(...getYear) ? `${getYear?.[0]}`: `${Math.min(...getYear)} - ${Math.max(...getYear)}`
}


export const groupedDates = (convertData: TypeOfflineTime[])=>{
  return convertData.reduce((item, date) => {
    const [month, year] = date.time.split(", ");
    const existingYear = item.find(item => item.year === year);
    if (existingYear) {
      if (!existingYear.month.includes(month)) {
        existingYear.month.push({ value: month, has_user_register: date?.has_user_register });

      }
    } else {
      item.push({ year, month: [{ value: month, has_user_register: date?.has_user_register }]});
    }
    return item;
  }, []);
}
export const checkValidateTime = (oldTime: any[], newTime: any[]) => {
  if (oldTime.length !== newTime.length) {
    return false;
  }
  for (let i = 0; i < oldTime.length; i++) {
    if (oldTime[i] !== newTime[i]) {
      return false;
    }
  }
  return true;
}
