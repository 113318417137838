import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../media/courses.update.media.modules.item.scss'
import { uploadFile, uploadMedia } from 'entities/courses/services/courses.media.service'
import ImgBlur from 'entities/courses/media/img_blur.png'
import helpers from 'helpers/index'
import __ from 'languages/index'

export default function CoursesUpdateMediaModuleItem({
  callbackSetUrl,
  urlDefaultThumbnail,
  nameFile,
  isVideoFile
}: {
  urlDefaultThumbnail?: string
  nameFile?: string
  isVideoFile?: boolean
  callbackSetUrl?: (media_id: string) => void
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false)
  const [progress, setProgress] = useState(urlDefaultThumbnail ? 0 : -1)
  const [urlThumbnail, setUrlThumbnail] = useState('')
  const [error, setError] = useState('')
  const [isVideo, setIsVideo] = useState(isVideoFile)
  const refFileName = useRef(nameFile)
  const inputRef = useRef(null)
  const [retryCount, setRetryCount] = useState(0)
  const randomId = useMemo(() => helpers.getRandomHash(4), [])
  const refTimeoutHandleError = useRef<any>()

  const handleImageLoadError = () => {
    // Increment the retry count when image loading fails
    refTimeoutHandleError.current = setTimeout(() => {
      setRetryCount(retryCount + 1)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (refTimeoutHandleError.current) {
        clearTimeout(refTimeoutHandleError.current)
      }
    }
  }, [])

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = async function (e) {
    setError('')
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    await onUpload(e.dataTransfer.files)
  }

  // triggers when file is selected with click
  const handleChange = async function (e) {
    setError('')
    e.preventDefault()
    await onUpload(e?.target?.files)
  }

  const onUpload = useCallback(async (files: any[]) => {
    if (files && files[0]) {
      if (files?.length === 1) {
        if (files?.[0]?.size < 5000000000) {
          const formData = new FormData()
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
          }
          setUrlThumbnail(ImgBlur)
          callbackSetUrl?.('')
          let res = files?.[0]?.type?.includes('video')
            ? await uploadMedia(formData, setProgress, null, () => {
                setError(`${__('drag_drop_text_error')}`)
                setProgress(-1)
              })
            : await uploadFile(formData, setProgress, null, () => {
                setError(`${__('drag_drop_text_error')}`)
                setProgress(-1)
              })

          if (
            res &&
            res?.length > 0 &&
            (res?.[0]?.callback?.media_thumbnail ||
              !res?.[0]?.callback?.media_mime_type?.includes('video')) &&
            res?.[0]?.callback?._id
          ) {
            refFileName.current = helpers.convertUTF8ToUnicodeText(
              res?.[0]?.callback?.media_file_name
            )
            setUrlThumbnail(res?.[0]?.callback?.media_thumbnail)
            setIsVideo(res?.[0]?.callback?.media_mime_type?.includes('video'))
            callbackSetUrl?.(res?.[0]?.callback?._id)
            setProgress(0)
          } else {
            setError(`${__('drag_drop_text_error')}`)
            setProgress(-1)
            setUrlThumbnail('')
          }
        } else {
          setError(`${__('drag_drop_max_size')}`)
        }
      } else {
        setError(`${__('course_select_only_video')}`)
      }
    }
  }, [])

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  return (
    <div className="container_media_module">
      {progress < 100 && progress > 0 && (
        <div className="loaded pe-none" style={{ width: '100%', backgroundColor: 'transparent' }}>
          <div className="loaded pe-none opacity-25" style={{ width: progress + '%' }} />
          {progress}%
        </div>
      )}

      <form
        id="form-file-upload_module"
        onDragEnter={handleDrag}
        onSubmit={e => e.preventDefault()}
      >
        <input
          accept="video/mp4, video/mov, .pdf, .doc, .docx, .pptx, .ppt, .xlsx"
          ref={inputRef}
          type="file"
          className="d-none"
          id={`input-file-upload_module${randomId}`}
          multiple={true}
          onChange={handleChange}
        />
        <label
          id="label-file-upload_module"
          htmlFor={`input-file-upload_module${randomId}`}
          className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <div>{__('course_update_media_text')}</div>
            <button className="upload-button_module" onClick={onButtonClick}>
              {__('course_update_media_btn_select_video')}
            </button>
            <div style={{ color: 'red' }}>{error}</div>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element_module"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>

      {!error && ((isVideo && (urlThumbnail || urlDefaultThumbnail)) || !isVideo) ? (
        isVideo ? (
          <div className="thumbnail_module">
            {/*<img src={ImgBlur} className="thumbnail_module position-absolute top-0 start-0" alt=""*/}
            {/*     onError={handleImageLoadError}/>*/}
            <img
              src={urlThumbnail ? urlThumbnail + '?retry=' + retryCount : urlDefaultThumbnail}
              className="thumbnail_module"
              alt=""
              onError={handleImageLoadError}
            />
            {(progress === 0 && urlThumbnail !== ImgBlur) || progress === 100 ? (
              <div className="thumbnail_module_bottom">
                {urlThumbnail === ImgBlur && progress === 100
                  ? `${__('course_loading')}`
                  : `${__('course_update_media_change_video')}`}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="thumbnail_module text-lg-center bg-primary d-flex flex-column justify-content-center align-items-center">
            <div className="fw-bold text-white fs-6">{refFileName?.current}</div>
          </div>
        )
      ) : null}
    </div>
  )
}
