import TypedUser from './user.interface'
import TypedMedia from './media.interface'

export enum EnumPostStatus {
  Publish = 'publish',
  Cancel = 'cancel',
  Pending = 'pending'
}

export interface TypedCategory {
  _id: string
  user_id?: TypedUser
  category_language?: string
  category_content?: string
  category_excerpt?: string
  category_slug?: string
  category_parent?: string
  category_avatar?: TypedMedia
  category_title?: string
  category_type?: string
  category_view?: number
  category_order?: number
  public_status?: number
  category_visibility?: number
  seo_title?: string
  seo_description?: string
  seo_keyword?: string
  createdAt?: string
  updatedAt?: string
}

export interface TypedPoll {
  createAt: string
  created_by: string
  number_choose: number
  question: string
  request_id: string
  updatedAt: string
  _id: string
  users_choose: TypedUser[]
  createBy: string
  updateAt: string
}

export interface TypedRequest {
  _id: string
  user_id: TypedUser
  channelpermission: {
    level_number: number,
    permission: string [],
    point: number
  }
  post_language: string
  post_category?: TypedCategory
  post_title: string
  post_content: string
  post_excerpt: string
  post_slug: string
  post_status: EnumPostStatus
  post_avatar: TypedMedia
  attach_files: TypedMedia[]
  post_information: string
  post_type: string
  view_number: number
  like_number: number
  share_number: number
  dislike_number: number
  comment_number: number
  vote_number: number
  createdAt: string
  updatedAt: string
  is_like: boolean
  is_dislike: boolean
  is_notification: boolean
  is_pin: number | string
  is_comment: number | string
  poll_ids: TypedPoll[]
  data_json?: string
  data_json_type?: 'livestream' | 'challenge' | 'gift' | 'course' | 'event'
  type?: 'update' | 'like'
}
