import React, { useCallback, useEffect, useState } from 'react'
import {Form, Image} from 'react-bootstrap'
import ImgBlur from 'entities/courses/media/img_blur.png'
import {uploadMedia} from 'entities/calendar/service'
import __ from "languages/index";
import { Box, Typography } from '@mui/material'
import FileUploader from 'entities/channel-settings/components/FileUploader'
import { uploadFile } from 'entities/courses/services/courses.media.service'
import UploadFileComponent from 'components/UploadFileComponent'

interface Props {
  getDataImageChange: (media_id: string, id?: string, title?: string) => void
  id: string
  title: string
}

const ImageForm = (props: Props) => {
  const { getDataImageChange, id, title } = props
  const [avatar, setAvatar] = useState<FileList | File[]>(null)

  const handleUploadAvatar =   async ()=>{
    let uploadedLogo = null

    try{
      const formData = new FormData()
      Array.from(avatar).forEach(file => {
        formData.append('file[]', file)
      })
      uploadedLogo = await uploadFile(formData)
      console.log('res data upload', uploadedLogo)
      if (Array.isArray(uploadedLogo) && uploadedLogo.length > 0) {
        getDataImageChange(JSON.stringify(uploadedLogo[0]?.callback), id, title)
      }
    }catch (e:any){
      console.log('uploadedLogo', e)

    }
  }
  useEffect( () => {
    if (!avatar) return
    handleUploadAvatar()
  }, [avatar])


  const urlLogoPlaceholder = () => {
    if (avatar) return URL.createObjectURL(avatar[0])
    return ''
  }

  return (
    <Box
      sx={{
        minWidth: theme => theme.spacing(16),
        height: theme => theme.spacing(25),
        minHeight: theme => theme.spacing(25)
    }}
    >
      <FileUploader
        acceptFiles="image/*"
        onUploaded={setAvatar}
        uploadText={__("media_form_title")}
        placeholder={
          (avatar !== null) && (
            <Box
              component={'img'}
              src={urlLogoPlaceholder()}
              sx={{
                borderRadius: theme => theme.spacing(1.5),
                width: `100%`,
                height: '100%',
                objectFit: 'contain'
              }}
            >
            </Box>
          )
        }
      />
    </Box>
  )
}

export default ImageForm
