import { useAppSelector } from 'config/store'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RedirectToUrl() {
  const navigate = useNavigate()
  const redirect = useAppSelector(state=> state.auth.redirectToUrl)

  useEffect(() => {
    if(!redirect) return 
    navigate(redirect)
  }, [redirect])
  return null
}
