import { SvgIcon, SvgIconProps } from '@mui/material'

const DragAndDropIcon = (props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.16406 3.33333C9.16406 2.8731 9.53716 2.5 9.9974 2.5H18.3307C18.791 2.5 19.1641 2.8731 19.1641 3.33333C19.1641 3.79357 18.791 4.16667 18.3307 4.16667H9.9974C9.53716 4.16667 9.16406 3.79357 9.16406 3.33333ZM3.28018 4.70537C3.76833 4.21722 4.55979 4.21722 5.04795 4.70537L6.41998 6.07741C6.74542 6.40285 6.74542 6.93049 6.41998 7.25592C6.09455 7.58136 5.56691 7.58136 5.24147 7.25592L4.9974 7.01184L4.9974 12.9882L5.24147 12.7441C5.56691 12.4186 6.09455 12.4186 6.41998 12.7441C6.74542 13.0695 6.74542 13.5972 6.41998 13.9226L5.04795 15.2946C4.55979 15.7828 3.76833 15.7828 3.28018 15.2946L1.90814 13.9226C1.5827 13.5972 1.5827 13.0695 1.90814 12.7441C2.23358 12.4186 2.76121 12.4186 3.08665 12.7441L3.33073 12.9882L3.33073 7.01184L3.08665 7.25592C2.76121 7.58136 2.23358 7.58136 1.90814 7.25592C1.5827 6.93049 1.5827 6.40285 1.90814 6.07741L3.28018 4.70537ZM9.16406 6.66667C9.16406 6.20643 9.53716 5.83333 9.9974 5.83333H16.6641C17.1243 5.83333 17.4974 6.20643 17.4974 6.66667C17.4974 7.1269 17.1243 7.5 16.6641 7.5H9.9974C9.53716 7.5 9.16406 7.1269 9.16406 6.66667ZM9.16406 10C9.16406 9.53976 9.53716 9.16667 9.9974 9.16667H18.3307C18.791 9.16667 19.1641 9.53976 19.1641 10C19.1641 10.4602 18.791 10.8333 18.3307 10.8333H9.9974C9.53716 10.8333 9.16406 10.4602 9.16406 10ZM9.16406 13.3333C9.16406 12.8731 9.53716 12.5 9.9974 12.5H13.3307C13.791 12.5 14.1641 12.8731 14.1641 13.3333C14.1641 13.7936 13.791 14.1667 13.3307 14.1667H9.9974C9.53716 14.1667 9.16406 13.7936 9.16406 13.3333ZM9.16406 16.6667C9.16406 16.2064 9.53716 15.8333 9.9974 15.8333H18.3307C18.791 15.8333 19.1641 16.2064 19.1641 16.6667C19.1641 17.1269 18.791 17.5 18.3307 17.5H9.9974C9.53716 17.5 9.16406 17.1269 9.16406 16.6667Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default DragAndDropIcon
