import dayGrid from '@fullcalendar/daygrid' // a plugin!
import timeGrid from '@fullcalendar/timegrid'
import { TypedEvent, TypedItemEvent } from '../calendar.interface'
import { useAppDispatch, useAppSelector } from 'config/store'
import { getListEvent } from '../calendar.store.reducer'
import { configDataDate } from 'entities/helpers/calendar.helpers'
import ModalDetail from './calendar.detail.event'
import { useNavigate } from 'react-router-dom'
import _Helmet from 'components/helmet'
import '../media/index.scss'
import { getStartAndLastDay } from '../helpers'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import TypedChannel from 'interfaces/channel.interface'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { Box, styled } from '@mui/material'

const CalendarComponent = props => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isMentor, setIsMentor] = useState(false)
  const permission_data = useAppSelector(state => state.user.permission_data)
  useEffect(() => {
    if (permission_data) setIsMentor(permission_data.indexOf('admin') > -1)
  }, [permission_data])

  // Refs
  const fullCalendarRef = useRef<FullCalendar>(null)
  const loadingRef = useRef<boolean>(false)
  const modalDetailRef = useRef(null)
  const toast = useToast()

  // ======================================================

  // State

  const [list, setList] = useState<TypedEvent[]>([])
  const [events, setEvents] = useState<TypedItemEvent[]>([])
  const [detailEvent, setDetailEvent] = useState<TypedEvent>(null)
  const [disable, setDisable] = useState<boolean>(false)

  // ======================================================

  // useEffect

  useEffect(() => {
    const date = new Date()
    const { startDay, lastDay } = getStartAndLastDay(date)
    getData(startDay, lastDay)
  }, [])

  // ======================================================

  // Function

  const getData = useCallback(async (startDay, lastDay) => {
    try {
      setDisable(true)
      setTimeout(() => {
        setDisable(false)
      }, 1000)
      if (!loadingRef.current) {
        loadingRef.current = true
        const res: any = await dispatch(
          getListEvent({
            date: `${startDay},${lastDay}`
          })
        )

        // console.log('res data', res)

        if (Array.isArray(res?.payload?.data) && res?.payload?.data.length > 0) {
          setList(res?.payload?.data)
          const list = configDataDate(res?.payload?.data)
          setEvents(list)
        } else {
          setList([])
          setEvents([])
        }
        loadingRef.current = false
      }
    } catch (e) {
      console.log('err_345345345', e)
    }
  }, [])

  const _onClickToday = useCallback(() => {
    if (!disable) {
      fullCalendarRef.current.getApi().today()
      const date = new Date()
      const { startDay, lastDay } = getStartAndLastDay(date)
      getData(startDay, lastDay)
    }
  }, [disable])

  const _onClickPrev = useCallback(() => {
    if (!disable) {
      fullCalendarRef.current.getApi().prev()
      const date = fullCalendarRef.current.getApi().getDate()
      const { startDay, lastDay } = getStartAndLastDay(date)
      getData(startDay, lastDay)
    }
  }, [disable])

  const _onClickNext = useCallback(() => {
    if (!disable) {
      fullCalendarRef.current.getApi().next()
      const date = fullCalendarRef.current.getApi().getDate()
      const { startDay, lastDay } = getStartAndLastDay(date)
      getData(startDay, lastDay)
    }
  }, [disable])

  const eventClick = data => {
    const _id = data?.event?._def?.extendedProps?._id
    const detail = list.find(item => item?._id == _id)
    setDetailEvent(detail)
    modalDetailRef.current?.show()
  }

  const _onDeleteEvent = useCallback(data => {
    let title = `${__('notification')}`
    let content = ''
    let type = EnumTypeToast.Success
    if (data?.type == EnumTypeToast.Success && data?._id) {
      content = `${__('calendar_delete_event_success')}`
      setList(prev => prev.filter(i => i?._id != data?._id))
      setEvents(prev => prev.filter(i => i?._id != data?._id))
    } else {
      content = `${__('calendar_delete_event_error')}`
      type = EnumTypeToast.Error
    }
    toast.show({
      title,
      content,
      type
    })
  }, [])

  const _onMonthClick = useCallback(() => {
    fullCalendarRef.current.getApi().changeView('dayGridMonth')
  }, [])

  const _onDayClick = useCallback(() => {
    fullCalendarRef.current.getApi().changeView('timeGridDay')
  }, [])

  // ======================================================

  const renderModalDetailEvent = useCallback(() => {
    return <ModalDetail detail={detailEvent} deleteSuccess={_onDeleteEvent} ref={modalDetailRef} />
  }, [detailEvent])

  return (
    <CalendarContainer>
      <_Helmet title="calendar_component_title" />
      <div
        className="long_calendar w-100"
        id="long_calendar"
        style={{ height: screen.availHeight - 100 }}
      >
        <FullCalendar
          ref={fullCalendarRef}
          plugins={[dayGrid, timeGrid]}
          height="100%"
          initialView="dayGridMonth"
          titleFormat={{
            year: 'numeric',
            month: '2-digit'
          }}
          customButtons={{
            addEvent: {
              icon: 'bi bi-plus-circle-fill',
              click: () => navigate('/calendar/add')
            },
            nextButton: {
              icon: 'chevrons-right',
              click: _onClickNext
            },
            prevButton: {
              icon: 'chevrons-left',
              click: _onClickPrev
            },
            todayButton: {
              text: `${__('calendar_component_today')}`,
              click: _onClickToday
            },
            monthButton: {
              text: `${__('calendar_component_month')}`,
              click: _onMonthClick
            },
            dayButton: {
              text: `${__('calendar_component_date')}`,
              click: _onDayClick
            }
          }}
          locale={`${localStorage.getItem('languageChannel')}`}
          headerToolbar={{
            left: 'todayButton prevButton,nextButton',
            center: 'title',
            right: isMentor ? 'monthButton,dayButton addEvent' : 'monthButton,dayButton'
          }}
          events={events}
          eventClick={eventClick}
          firstDay={1}
          dayMaxEvents={3}
          moreLinkText={number => `+ ${number} ${__('event_title')}`}
          stickyFooterScrollbar={false}
          contentHeight="100%"
          nextDayThreshold={'23:59:59'}
        />
        {renderModalDetailEvent()}
      </div>
    </CalendarContainer>
  )
}

export default CalendarComponent

const CalendarContainer = styled(Box)(({ theme }) => ({
  '& .fc .fc-button-primary, .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active':
    {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
  '& .fc-daygrid-event-dot': {
    borderColor: theme.palette.primary.main
  },
  '& .fc .fc-daygrid-event-harness a': {
    color: theme.palette.primary.main
  },
  '& .fc-day-today .fc-daygrid-day-number': {
    color: theme.palette.primary.main
  },
  '& .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:focus':
    {
      boxShadow: 'unset'
    },
  '& .fc-v-event': {
    backgroundColor: theme.palette.primary.main
  }
}))
