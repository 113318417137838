import { TypedEvent } from 'entities/calendar/calendar.interface'
import React, { useCallback, useState } from 'react'
import DateTime from 'date-and-time'
import { Button, Dropdown, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import EventDefault from '../media/event_default.jpg'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'config/store'
import { likeEvent, unlikeEvent } from 'entities/calendar/calendar.store.reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faTowerCell } from '@fortawesome/free-solid-svg-icons'
import '../media/event.list.item.scss'
import { checkIsNotShowButtonJoin, checkIsShowEndDate } from '../helpers'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import Capacity from 'components/Capacity'
import { formatString } from 'entities/support/utils'
import { formatDate } from 'entities/support/components/TicketItem'
import { Box, Typography } from '@mui/material'

interface Props {
  item: TypedEvent
  onDelete: (_id: string) => void
  onLike: (result: boolean) => void
}

const EventItem = (props: Props) => {
  const { item, onDelete, onLike } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const permission_data = useAppSelector(state => state.user.permission_data);
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel);
  const [isLoading,setIsLoading] = useState(false)

  // State
  const [like, setLike] = useState < boolean > (item?.is_like)
  const [count, setCount] = useState < number > (item?.like_number)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)

  // ============================================

  // Function
  const _gotoDetailEvent = useCallback(() => {
    if (is_user_logged_in) {
      navigate(`/event/detail/${item?._id}`)
    }
  }, [])

  const _onLike = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true)
      if (like) {
        await dispatch(unlikeEvent({event_id: item?._id}))
          .unwrap()
          .then(() => {
            setCount(count - 1)
            onLike(!like)
            setLike(!like)
          })
          .catch((error) => {
            console.log(error, "oausfhuahsuf")
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
          await dispatch(likeEvent({event_id: item?._id}))
            .unwrap()
            .then(() => {
              setCount(count + 1)
              onLike(!like)
              setLike(!like)
            })
            .catch((error) => {
              console.log(error, "oausecwfhuahsuf")
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 5000)
      }
  }, [like, count, isLoading])

  const _onUpdateEvent = useCallback(() => {
    if (is_user_logged_in) {
      navigate(`/event/add/${item?._id}`)
    }
  }, [])

  const _onDelete = useCallback(() => {
    if (is_user_logged_in) {
      onDelete(item?._id)
    }
  }, [])

  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
      href=""
      ref={ref}
      className="px-1 d-flex justify-content-center"
      onClick={e => {
        if (is_user_logged_in) {
          e.preventDefault()
          onClick(e)
        }
      }}
    >
      <FontAwesomeIcon icon={faEllipsis} className="eventlistitem_iconmenu" />
      {children}
    </a>
  ))

  // ============================================

  return (
    <>
      <_Helmet title='event_title' />
      <div className="col-xl-3 col-sm-6 col-xs-6 eventlistitem my-2">
        <div className="single-box event_element p-2 h-100 border d-flex flex-column justify-content-between">
          <div>
            <div className="avatar-box position-relative">
              <Image
                onClick={_gotoDetailEvent}
                className="image-event-item rounded w-100 clickable"
                src={
                  item?.public_album?.[0]?.media_thumbnail ||
                  item?.public_album?.[0]?.media_url ||
                  EventDefault
                }
              />
          
              {!item?.livestream_id && checkIsShowEndDate(item) && (
                <Box sx={{background: theme=>`${theme.palette.primary.main} !important`}} className="eventlistitem_taglive">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        <strong>{__("event_item_closed")}</strong>
                      </Tooltip>
                    }
                  >
                    <div>{__("event_item_text_closed")}</div>
                  </OverlayTrigger>
                </Box>
              )}

              <Capacity current_user_can={"boss"}>
                <Box component={Dropdown} sx={{'& a':{color: 'primary.main'}}} className="eventlistitem_optionmenu">
                  <Dropdown.Toggle
                    disabled={!is_user_logged_in}
                    variant="success"
                    id="dropdown-basic"
                    as={CustomToggle}
                  />

                  <Dropdown.Menu>
                    {!checkIsShowEndDate(item) && (
                      <Dropdown.Item onClick={_onUpdateEvent}>Sửa</Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={_onDelete} className="delete_event">
                      <Typography color='primary.main'>
                       {__("event_item_btn_delete")}
                      </Typography>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Box>
              </Capacity>
            </div>
            <div onClick={_gotoDetailEvent} className="clickable">
              <h6 className="mt-3 mb-2 event_title">{item?.title}</h6>
              <p className="my-2 delete_event">
                <Typography color='primary.main'>
                  {formatDate(item?.open_date, 'DD/MM/YYYY')}
                </Typography>
              </p>
              <div className="smtxt city-area my-2" style={{ height: 42 }}>
                {count > 0 ? `${formatString(__('event_participants'), count)}` : `${__("event_first_join")}`}
              </div>
            </div>
          </div>

          {!checkIsNotShowButtonJoin(item, user_in_this_channel) && (
            <Button
              onClick={_onLike}
              disabled={!is_user_logged_in || isLoading}
              variant={like ? 'outline-success' : 'outline-danger'}
              className="w-100 mt-3"
            >
              {isLoading?`${__("event_loading")}`:(like ? `${__("event_status_joined")}` : `${__("event_status_join")}`)}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default EventItem
