import { SvgIcon, SvgIconProps } from '@mui/material'

const IconCloseImage = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect y="0.000244141" width="24" height="24" rx="12" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7559 7.24432C17.0814 7.56976 17.0814 8.0974 16.7559 8.42283L13.1785 12.0002L16.7559 15.5777C17.0814 15.9031 17.0814 16.4307 16.7559 16.7562C16.4305 17.0816 15.9029 17.0816 15.5774 16.7562L12 13.1788L8.42259 16.7562C8.09715 17.0816 7.56951 17.0816 7.24408 16.7562C6.91864 16.4307 6.91864 15.9031 7.24408 15.5777L10.8215 12.0002L7.24408 8.42284C6.91864 8.09741 6.91864 7.56977 7.24408 7.24433C7.56952 6.91889 8.09715 6.91889 8.42259 7.24433L12 10.8217L15.5774 7.24432C15.9029 6.91888 16.4305 6.91888 16.7559 7.24432Z" fill="#0E1426" />
      </svg>
    </SvgIcon>

  )
}
export default IconCloseImage
