import axios from 'axios'
import { createAsyncThunk, isFulfilled, isPending, isRejected, createSlice } from '@reduxjs/toolkit'
import {
  TypedCalendar,
  TypedCreateEvent,
  TypedCreateLivestream,
  TypedEvent,
  TypedImage,
  TypedLevel,
  TypedLivestream
} from './calendar.interface'
import { createEntitySlice, serializeAxiosError } from '../../config/reducer.utils'
import helpers from 'helpers'

export const initialState = {
  entity: {} as TypedCalendar,
  entities: null as TypedCalendar[],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false,
  events: null as any[]
}

const apiUrl = 'calendar'

export const getEntity = createAsyncThunk(
  'calendar/fetch_entity',
  async (__id: string) => {
    return await axios.get<any>(`${apiUrl}/${__id}`)
  },
  { serializeError: serializeAxiosError }
)

export const getEntities = createAsyncThunk('calendar/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object)
  const requestUrl = `${apiUrl}${EndURL}`
  return axios.get<any>(requestUrl)
})

export const updateEntity = createAsyncThunk(
  'calendar/update_entity',
  async (entity: TypedCalendar, thunkAPI) => {
    let mainID = String(entity.calendar_id)
    delete entity.calendar_id
    const result = await axios.put<TypedCalendar>(
      `${apiUrl}/${mainID}`,
      helpers.cleanEntity(entity)
    )
    //   thunkAPI.dispatch(getEntities({}));
    return result
  },
  { serializeError: serializeAxiosError }
)

export const deleteEntity = createAsyncThunk(
  'calendar/delete_entity',
  async (_id: string) => {
    return await axios.delete<any>(`${apiUrl}/${_id}`)
  },
  { serializeError: serializeAxiosError }
)

// test checkauthentication
export const createEntity = createAsyncThunk(
  'calendar/create_entity',
  async (entity: TypedCalendar) => {
    return await axios.post<TypedCalendar>(`calendar/create`, helpers.cleanEntity(entity))
  },
  { serializeError: serializeAxiosError }
)

export const getListEvent = createAsyncThunk(
  'calendar/getCalendar',
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `event/list${EndURL}`
    return await axios.get<TypedEvent[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const createEvent = createAsyncThunk(
  'event/createEvent',
  async (data: TypedCreateEvent) => {
    return await axios.post<TypedEvent>(`event/create-event`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const getListLevel = createAsyncThunk(
  'calendar/getListLevel',
  async (data: { channel_id?: string }) => {
    return await axios.get<TypedLevel[]>(`channel/list-level?channel_id=${data.channel_id}`)
  },
  { serializeError: serializeAxiosError }
)

export const deleteEvent = createAsyncThunk(
  'calendar/deleteEvent',
  async (data: { _id?: string }) => {
    return await axios.delete<TypedLevel[]>(`event/delete-event/${data._id}`)
  },
  { serializeError: serializeAxiosError }
)

export const getDetailEvent = createAsyncThunk(
  'calendar/getDetailEvent',
  async (data: { _id?: string; auth_id?: string }) => {
    const EndURL = helpers.buildEndUrl(data)
    return await axios.get<TypedEvent>(
      `event/detail-event/${data?._id}${data?.auth_id ? '?auth_id=' + data?.auth_id : ''}`
    )
  },
  { serializeError: serializeAxiosError }
)

export const updateEvent = createAsyncThunk(
  'event/updateEvent',
  async (data: TypedCreateEvent) => {
    return await axios.patch<TypedEvent>(`event/update-event`, data)
  },
  { serializeError: serializeAxiosError }
)

export const createLivestream = createAsyncThunk(
  'event/createLivestream',
  async (data: TypedCreateLivestream) => {
    return await axios.post<TypedEvent>(`livestream/create`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const updateLivestream = createAsyncThunk(
  'event/updateLivestream',
  async (data: TypedCreateLivestream) => {
    return await axios.patch<TypedLivestream>(`livestream/update`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const getListLikeEvent = createAsyncThunk(
  'calendar/getListLikeEvent',
  async (data: { event_id?: string; page?: number; limit?: number; auth_id?: string }) => {
    const EndURL = helpers.buildEndUrl(data)
    return await axios.get<TypedEvent>(`event/list-like${EndURL}`)
  },
  { serializeError: serializeAxiosError }
)

export const likeEvent = createAsyncThunk(
  'event/likeEvent',
  async (data: { event_id: string }) => {
    return await axios.post<TypedEvent>(`event/like`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const unlikeEvent = createAsyncThunk(
  'event/unlikeEvent',
  async (data: { event_id: string }) => {
    return await axios.post<TypedEvent>(`event/un-like`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const getDefaultImage = createAsyncThunk(
  'event/get_default_image',
  async (_, { rejectWithValue }) => {
    try {
      return await axios.get<TypedImage>(`settings/default-avatar/challenge`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const CALENDAR_REDUCER = createEntitySlice({
  name: 'calendar',
  initialState: initialState as any,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => {
      return { ...state, ...initialState }
    }
  },

  extraReducers(builder) {
    builder

      .addCase(getListEvent.fulfilled, (state, action) => {
        state.loading = false
        state.events = action.payload.data
      })
      .addCase(getListEvent.pending, (state, action) => {
        state.loading = true
        state.events = false
      })
      .addCase(getListEvent.rejected, (state, action) => {
        state.loading = false
        state.events = null
        state.errorMessage = action.payload
      })

      .addCase(createEntity.fulfilled, (state, action) => {
        state.creating = false
        state.createdSuccess = true
        state.entity = action.payload.data
      })
      .addCase(createEntity.pending, (state, action) => {
        state.creating = true
        state.createdSuccess = false
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.creating = false
        state.errorMessage = action.payload
      })

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false
        state.errorMessage = action.payload
      })

      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })

      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })

      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false
        ;(state.entities = action.payload.data),
          (state.totalItems = parseInt(action.payload.headers['x-total-count'], 10))
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        state.errorMessage = action.payload
      })
  }
})
export const { clearError, reset } = CALENDAR_REDUCER.actions
// Reducer
export default CALENDAR_REDUCER.reducer
