import React, {useEffect, useState} from 'react'
import CourseTableUser from './components/course.table.user';
import ModalAddUser from './components/course.modal.add.user';
import {getEntity, getListUserJoin} from './courses.store.reducer';
import {useInfiniteScroll} from 'hooks/useInfiniteScroll';
import {useParams} from 'react-router-dom'
import ModalAccept from 'entities/homepage/components/modal.accept';
import {postDeleteUserToCourse} from 'entities/gift/services/gift.receiver.service';
import {EnumTypeToast, useToast} from 'hooks/useToast'
import {useAppDispatch, useAppSelector} from 'config/store';
import __ from "languages/index";
import helpers from 'helpers/index';
import MediumLayout from "../../layouts/mediumLayout";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Stack,
  styled,
  Typography
} from '@mui/material'
import { SearchIcon } from '../../icons'

export default function CourseManageUser() {
  const [modalAddUser, setModalAddUser] = useState(false);
  const user_data = useAppSelector(state => state.user.user_data)

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const permission_data = useAppSelector(state => state.user.permission_data)

  let useParam = useParams()
  const [courseId, setCourseId] = useState < string | undefined > (useParam.course_id || '')
  const toast = useToast()
  const { data, hasMore, refresh, loadMore, updateParamRequestDebounce, isRefreshing } = useInfiniteScroll < any > ({
    course_id: courseId,
    search: '',
    limit: 10
  }, getListUserJoin)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (courseId) {
      dispatch(
        getEntity({
          _id: courseId,
          auth_id: user_data?._id || ''
        })
      )
    }
  }, [])

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allItemIds = data.map(item => item?._id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };


  const handleCheckboxChange = (itemId) => {
    setSelectAll(false);
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter(id => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const acceptDelete = () => {
    const dataUserDelete = selectedItems.join(',')

    const params = {
      user_id: dataUserDelete,
      course_id: courseId
    }
    postDeleteUserToCourse(params).then(res => {
      setModalShow(false)
      refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_manage_delete_member_success")}` })
      setSelectAll(false);
      setSelectedItems([])
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_manage_delete_member_failed")}` })

    })
  }

  const refreshUser = () => {
    setTimeout(() => {
      refresh()
    }, 1000);
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    updateParamRequestDebounce({ search: inputValue });
  };


  return (
    <MediumLayout>
      <CourseManageUserContainer>
        <Box>
          <Stack
            direction={'row'}
            className='course_manage_user_header'
          >
            <Typography
              variant={'h5'}
              textTransform={'none'}
            >
              {helpers.current_user_can('boss', permission_data) ?
                `${__("course_manage_student")}` : `${__("course_list_student")}`}
            </Typography>
            {helpers.current_user_can('boss', permission_data) ?
              <Button
                sx={{
                  textTransform: 'none',
                  fontSize: theme =>theme.spacing(2)
                }}
                onClick={() => setModalAddUser(true)}
              >
                {__("course_manage_add_student")}
              </Button> : null
            }

          </Stack>
          {
            !!data?.length ?
              <Stack
                direction={'row'}
                className='course_manage_user_header'
                marginTop={theme => theme.spacing(2)}
              >
                <Box >
                  {helpers.current_user_can('boss', permission_data) ?
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        id={`check_all`}
                        label={__('check_all')}
                        onChange={handleSelectAll}
                        checked={selectAll}
                        disabled={Boolean(!data.length)}
                      />
                      <Button
                        variant={'text'}
                        disabled={Boolean(!(data.length && selectedItems.length ))}
                        onClick={() => setModalShow(true)}
                        sx={{
                          textTransform: 'none'
                        }}
                      >
                        {__("course_modal_btn_delete")}
                      </Button>
                    </Box> : null
                  }
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box className="course_manage_user_input">
                    <IconButton>
                      <SearchIcon
                        sx={{
                          width: theme =>theme.spacing(2.5),
                          height: theme =>theme.spacing(2.5),
                        }}
                      />
                    </IconButton>
                    <InputBase
                      placeholder={__("course_search")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={handleInputChange}
                      className='course_manage_user_input_search'
                    />
                  </Box>
                </Box>
              </Stack> : null
          }
          <Box
            marginTop={theme => theme.spacing(3)}
          >
            <CourseTableUser
              dataUnique={data}
              selectedItems={selectedItems}
              handleCheckboxChange={handleCheckboxChange}
              loadMore={loadMore}
              hasMore={hasMore}
              isRefreshing={isRefreshing}
              refresh={refresh}
            />
          </Box>
          {modalAddUser &&
            <ModalAddUser
              show={modalAddUser}
              courseId={courseId}
              onHide={() => setModalAddUser(false)}
              refresh={refreshUser}
            />
          }
          {
            modalShow &&
            <ModalAccept
              show={modalShow}
              onHide={() => setModalShow(false)}
              onAccept={acceptDelete}
              title={__("course_manage_delete_member")}
              desciption={__("course_manage_delete_member_confirm_text")}
            />
          }

        </Box>

      </CourseManageUserContainer>
    </MediumLayout>
  )
}
const CourseManageUserContainer = styled(Box)(({theme})=>({
  '& .course_manage_user_header':{
    justifyContent: 'space-between !important',
    alignItems: 'center'
  },
  '& .course_manage_user_input': {
    display: 'flex',
    background: theme.palette.background.default,
    border: `${theme.spacing(0.2)} solid ${theme.palette.text.disabled}`,
    borderRadius: theme.spacing(1.5),
    '& .course_manage_user_input_search': {
      width: '100%',
      '& ::placeholder': {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(2),
        fontWeight: 400,
        lineHeight: '133%'
      }
    }
  },
}))
