import { Box, Button, Divider, IconButton, Modal, Stack, styled, Typography } from '@mui/material'
import __ from 'languages/index'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import React, { useCallback, useState } from 'react'
import dateandtime from 'date-and-time'
import { DateCalendar } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { monthNames } from 'entities/channel-settings/post-category/dataCourse'
import { TypeCourseOfflineTime } from 'entities/channel-settings/post-category/CoursesOfflineSetting'


const addDays = dateandtime.addDays
dayjs.locale('vi');
const localeText = {
  date: 'Ngày',
  month: 'Tháng',
  year: 'Năm',
  monthAndYear: 'Tháng và Năm',
  today: 'Hôm nay',
  goToToday: 'Đi đến hôm nay',
  goToMonth: 'Chọn tháng',
  goToYear: 'Chọn năm',
};

interface TypeCalendarCourse {
  isShow: boolean,
  handleClose: ()=>void
  handleAddTime: (value: {year: string, month: string})=>void
}
const CalendarCourse = ({isShow,handleClose, handleAddTime}:TypeCalendarCourse)=>{
  const [valueTime, setValueTime] = useState<{year: string, month: string}>({
    year: `${new Date().getFullYear()}`,
    month: `${monthNames[new Date().getMonth()]}`,
  })

  const handleSelectTime = (value)=>{
    setValueTime(
      {
        year: `${new Date(value).getFullYear()}`,
        month:`${monthNames[new Date(value).getMonth()]}`
      }
    )
  }
  const renderCustomDay = (day, _options, start, end, month) => {
    const monthIndex = month.getMonth();
    return (
      <Typography variant="body1">
        {monthNames[monthIndex]}
      </Typography>
    );
  };

  return(
    <CalendarCourseContainer
      open={isShow}
      onClose={handleClose}
      className="courseIncomeFilterTime__container"
    >
      <ModalContainer>
        <ModalHeader
          direction={'row'}
          sx={{
            borderBottom: 'none',
            justifyContent: 'start',
            paddingLeft: theme => theme.spacing(4.75)
        }}>
          <Typography textAlign={'start'} variant={'subtitle2'} textTransform={'none'}>
            {__('course_course_modal_title')}
          </Typography>
        </ModalHeader>
        <ModalBody
          sx={{
            maxHeight: 'calc(100% - 80px)',
            overflowY: 'auto',
            padding: 0
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale={dayjs.locale('vi')}
          >
            <DateCalendar
              // adapterLocale={localeText.month}
              yearsPerRow={4}
              slotProps={{
                calendarHeader: {
                  format: `YYYY`,
                  sx:{
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    marginTop: 0,
                    paddingLeft: 0
                  },
                },
              }}
              // value={dayjs('2022-04-17')}
              defaultValue={dayjs(`${new Date()}`)}
              views={['month', 'year']}
              openTo="month"
              sx={{
                height: 'max-content'
              }}
              onChange={handleSelectTime}
              disableFuture
            />
          </LocalizationProvider>
        </ModalBody>
        <ModalFooter
          sx={{
            alignItems: 'flex-end'
          }}
        >
          <Button
            className={'course_course_modal_btn_add'}
            variant={'outlined'}
            onClick={()=>handleAddTime(valueTime)}

          >
            {__("course_course_modal_btn_add")}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </CalendarCourseContainer>
  )
}

export  default CalendarCourse
const CalendarCourseContainer = styled(Modal)(({theme})=>({
  "& .course_course_modal_btn_add":{
    textTransform: 'none',
    fontsize: theme.spacing(2),
    maxWidth: `max-content`
  }
}))
