import {
  BurgerMenuIcon,
  CupIcon,
  InfoIcon,
  SettingIcon,
} from 'icons'
import __ from 'languages/index'
import ChannelMenusIcon from '../../icons/ChannelMenusIcon'
import ActivityIcon from '../../icons/ActivityIcon'

export const channelSettingsRoutes = {
  information: 'information',
  postCategories: 'categories',
  creditConfigurations: 'credit-configurations',
  pointConfigurations: 'point-configurations',
  generalDisplay: 'display-system',
  capability: 'capability',
  advance: 'advance'
}

export const channelSettingsSidebarConfigs = [

  {
    link: channelSettingsRoutes.information,
    label: __('channel_setting_channel_and_payment'),
    icon: InfoIcon
  },
  {
    link: channelSettingsRoutes.pointConfigurations,
    label: __('channel_setting_point_and_level'),
    icon: CupIcon
  },
  {
    link: channelSettingsRoutes.postCategories,
    label: __('channel_setting_data_category'),
    icon: BurgerMenuIcon
  },
  {
    link: channelSettingsRoutes.generalDisplay,
    label: __('channel_setting_display'),
    icon: ChannelMenusIcon
  },
  {
    link: channelSettingsRoutes.capability,
    label: __('channel_setting_activity'),
    icon: ActivityIcon
  },
  {
    link: channelSettingsRoutes.advance,
    label: __('channel_setting_data_advance'),
    icon: SettingIcon
  }
]
