import { useParams } from "react-router-dom"
import Theme404 from '../../layout/404'

import challenge_list from './challenge.list'
import challenge_detail from './challenge.detail'
import ChallengeAdd from "./challenge.add"
import challenge_cookbook from "./challenge.cookbook"
import './media/index.scss'
import UserJoined from "./UserJoined"
import ChallengeRankings from "./challenge.rankings"
/**
*   Create index file for Comment
*/

export default function Calendar() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';

  let ActualPage: any;
  switch (Param) {

    case 'list':
      ActualPage = challenge_list;
      break;
    case 'detail':
      ActualPage = challenge_detail;
      break;
    case 'add':
      ActualPage = ChallengeAdd;
      break;
    case "cookbook":
      ActualPage = challenge_cookbook
      break;
    case "userJoin":
      ActualPage = UserJoined
      break;
    case "ranking":
      ActualPage = ChallengeRankings
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <div className="container">
      {<ActualPage />}
    </div>
  );
}
