import { TypedCourse } from 'entities/courses/courses.interface'
import React, { useCallback, useState } from 'react'
import CoursesProgress from 'entities/courses/components/courses.progress'
import { Link, useNavigate } from 'react-router-dom'
import { EnumPublicStatus, EnumPublishStatus } from 'entities/courses/media/constants'
import { useAppDispatch, useAppSelector } from 'config/store'
import { faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//Store
import { updateStatusJoinCourse } from '../courses.store.reducer'

//Helper
import helpers from 'helpers'

//Scss
import '../media/courses.list.item.scss'
import imageDefault from 'media/images/image_default.png'
import __ from 'languages/index'
import BookIcon from 'icons/BookIcon'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import ReactPlayer from 'react-player'
import { formatString } from 'entities/support/utils'
import { SafetyClockIcon } from 'icons'

interface TypedCoursesListItemProps {
  course: TypedCourse
  categoryName: string
}

export default function CoursesItem({ course, categoryName }: TypedCoursesListItemProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const theme = useTheme()

  const {
    _id,
    avatar,
    trash_status,
    level_value,
    public_status,
    total_view,
    module_child_count,
    is_join,
    coin_value,
    service_id
  } = course

  const [percentComplete, setPercentComplete] = useState<number>(
    module_child_count == 0
      ? 0
      : Math.min(
          100,
          Math.max(0, Math.round((Number(total_view || 0) * 100) / Number(module_child_count || 1)))
        )
  )
  const permission_data = useAppSelector(state => state.user.permission_data)
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel)

  const handleUpdateStatusIsJoin = useCallback(() => {
    dispatch(updateStatusJoinCourse({ course_id: _id }))
    navigate(`/study/${_id}`)
  }, [course])

  return (
    <CoursesItemContainer>
      <CardActionArea component={Link} to={`/courses/view/${_id || ''}`}>
        {!avatar?.media_mime_type?.includes('video') ? (
          <CardMedia
            component="img"
            onError={e => {
              e.currentTarget.src = imageDefault
            }}
            sx={{ height: theme => theme.spacing(20.625) }}
            image={avatar?.media_url || imageDefault}
            alt="news/image"
          />
        ) : (
          <CardMedia
            component={ReactPlayer}
            sx={{
              width: '100% !important',
              height: '100% !important',
              p: theme => theme.spacing(2)
            }}
            url={avatar?.media_url || ''}
          />
        )}
        <Stack
          component={CardContent}
          height={'100%'}
          width={'100%'}
          gap={0.75}
          direction={'column'}
          justifyContent={'space-between'}
        >
          <Typography color="text.secondary" textTransform="uppercase" noWrap>
            {categoryName || ''}
          </Typography>
          <Typography className="card_item_title">{course?.title || ''}</Typography>
          <Stack direction={'row'} alignItems={'center'} gap={0.5}>
            <Typography variant="caption" color="text.secondary">
              {course?.user_id?.display_name || ''}
            </Typography>
            <Box
              sx={{
                width: '1px',
                height: theme => theme.spacing(1.5),
                background: theme => theme.palette.divider
              }}
            />
            <Typography variant="caption" color="text.secondary">
              {__(`${helpers.checkPermissionUser(course?.user_id?.channelpermission?.permission)}`)}
            </Typography>
          </Stack>

          <Typography
            minHeight={theme => theme.spacing(2.875)}
            variant="subtitle2"
            textTransform={'none'}
            fontWeight={600}
            noWrap
          >
            {coin_value
              ? formatString(
                  __('currency_unit_default'),
                  helpers.formatNumberWithCommas(coin_value || '')
                )
              : ''}
          </Typography>
          <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
            <Stack direction={'row'} gap={0.5} alignItems={'center'}>
              <BookIcon
                sx={{
                  color: theme => theme.palette.text.secondary,
                  width: theme => theme.spacing(2),
                  height: theme => theme.spacing(2)
                }}
              />
              <Typography color="text.secondary">{`${module_child_count} ${__('course_item_lesson')}`}</Typography>
            </Stack>
            {public_status === EnumPublicStatus.public ? (
              <Typography color={'info.light'}>{__('course_public')}</Typography>
            ) : (
              <Stack direction={'row'} alignItems={'center'}>
                <SafetyClockIcon
                  sx={{
                    color: theme => theme.palette.error.main,
                    width: theme => theme.spacing(2),
                    height: theme => theme.spacing(2)
                  }}
                />
                <Typography color="error.main">
                  {formatString(__('course_required_level'), level_value)}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack>
            {helpers.current_user_can('boss', permission_data) ? (
              //Mentor role
              <Button component={Link} to={`/courses/update/${_id}`} sx={{ textTransform: 'none' }}>
                {__('course_update')}
              </Button>
            ) : //User role
            trash_status === EnumPublishStatus.public ? (
              module_child_count > 0 ? (
                user_in_this_channel?.level_number >= level_value ? (
                  percentComplete === 0 ? (
                    //Not yet
                    <>
                      {is_join ? (
                        <Button
                          component={Link}
                          to={`/study/${_id}`}
                          sx={{ textTransform: 'none' }}
                        >
                          {__('course_btn_join')}
                        </Button>
                      ) : (
                        <>
                          {coin_value ? (
                            <Button
                              component={Link}
                              to={`/payment?service_id=${service_id}`}
                              sx={{ textTransform: 'none' }}
                            >
                              {__('course_btn_buy')}
                            </Button>
                          ) : (
                            <Button
                              sx={{ textTransform: 'none' }}
                              onClick={handleUpdateStatusIsJoin}
                            >
                              {__('course_btn_join_now')}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : percentComplete === 100 ? (
                    // Complete
                    <Button component={Link} to={`/courses/view/${_id}`} sx={{ display: 'flex' }}>
                      <FontAwesomeIcon icon={faCircleCheck} />
                      <Typography textTransform={'none'}>{__('course_completed')}</Typography>
                    </Button>
                  ) : (
                    //In-progress
                    <Box
                      component={Link}
                      sx={{ textDecoration: 'none' }}
                      to={`/courses/view/${_id}`}
                    >
                      <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
                        <Typography textTransform={'none'}>{__('course_progress')}</Typography>
                        <Typography textTransform={'none'}>
                          {percentComplete.toFixed(0) + '%'}
                        </Typography>
                      </Stack>
                      <CoursesProgress
                        percentValue={percentComplete}
                        styleProgress={{ backgroundColor: theme.palette.primary.main }}
                      />
                    </Box>
                  )
                ) : (
                  <div>
                    {is_join ? (
                      <Button component={Link} to={`/study/${_id}`} sx={{ textTransform: 'none' }}>
                        {__('course_btn_join')}
                      </Button>
                    ) : (
                      <Stack
                        component={Link}
                        direction={'row'}
                        gap={1}
                        sx={{
                          textDecoration: 'none',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                        to={`/courses/view/${_id}`}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        <Typography>{__('course_not_allow_level')}</Typography>
                      </Stack>
                    )}
                  </div>
                )
              ) : (
                <Stack
                  component={Link}
                  to={`/courses/view/${_id}`}
                  direction={'row'}
                  sx={{
                    textDecoration: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                  <Typography>{__('course_not_ready')}</Typography>
                </Stack>
              )
            ) : (
              <Stack
                component={Link}
                to={`/courses/view/${_id}`}
                direction={'row'}
                sx={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <FontAwesomeIcon icon={faBan} />
                <Typography>{__('course_not_ready')}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardActionArea>
    </CoursesItemContainer>
  )
}

const CoursesItemContainer = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  height: '99%',
  '& button': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  '& .card_item_title': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}))
