import React, { useCallback, useMemo, useRef, useState } from 'react'
import helpers from 'helpers/index'
import ChallengeInformation from './challenge.information'
import ChallengeRanking from './challenge.ranking'
import ChallengeAnnounced from './challenge.announced'
import ChallengeWork from './challenge.work'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import DateTime from 'date-and-time'

//scss
import '../../media/challenge.detail.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'config/store'
import CheckinModal from './checkin.modal'
import CoursesViewSkeleton from 'entities/courses/skeletons/courses.view.skeleton'
import {
  useGetChallengeDetailQuery,
  useJoinChallengeMutation,
  useLeaveChallengeMutation
} from 'entities/challenge/store/challengeApi'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import Avatar from 'components/Avatar'
import { EnumTypeToast, useToast } from '../../../../hooks/useToast'
import __ from 'languages/index'
import { Box, Button, Menu, MenuItem, Stack, Typography, styled, Tab, Tabs } from '@mui/material'
import { CalendarMinusIcon, CheckIcon, CupIcon, ShareIcon } from 'icons'
import GroupUser from 'icons/GroupUserIcon'
import __helpers from 'helpers/index'
import UnionIcon from 'icons/UnionIcon'

const ChallengeDetailComponent = props => {
  const params = useParams()
  const challenge_id = params?.challenge_id || ''
  const account = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { data: challengeDetail, isLoading } = useGetChallengeDetailQuery({
    challengeId: challenge_id,
    authId: account?._id
  })

  const [joinChallenge, { isLoading: joinChallengeLoading }] = useJoinChallengeMutation()
  const [leaveChallenge, { isLoading: leaveChangeLoading, isError }] = useLeaveChallengeMutation()

  const isJoined = challengeDetail?.is_join
  const officialStatus = challengeDetail?.challenge_permission?.official_status

  const toast = useToast()

  const joinedStatus = {
    '1': `${__('challenge_detail_status_joined')}`,
    '2': `${__('challenge_detail_status_cancel')}`,
    '3': `${__('challenge_detail_status_cancel')}`,
    '0': `${__('challenge_detail_status_pending')}`
  }
  const checkCurrentTimeChallenge = useMemo(() => {
    const currentTime = new Date().getTime()
    const endTime = new Date(challengeDetail?.end_time).getTime()
    return challengeDetail && currentTime < endTime
  }, [challengeDetail])

  const SocialList = [
    {
      id: 'copy',
      type: 'copy',
      title: `${__('challenge_detail_social_link')}`,
      icon: <i className="bi bi-link-45deg fs-5 me-2"></i>,
      link: helpers.getRootDomain(`/challenge/detail/${challenge_id}`)
    },
    {
      id: 'fb',
      type: 'link',
      title: `${__('challenge_detail_social_facebook')}`,
      icon: <i className="bi bi-facebook fs-5 me-2" />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${helpers.getRootDomain(
        `/challenge/detail/${challenge_id}`
      )}`
    },
    {
      id: 'twitter',
      type: 'link',
      title: `${__('challenge_detail_social_twitter')}`,
      icon: <i className="bi bi-twitter fs-5 me-2" />,
      link: `https://twitter.com/intent/tweet?text=${helpers.getRootDomain(
        `/challenge/detail/${challenge_id}`
      )}`
    }
  ]

  // Refs
  const checkinRef = useRef(null)
  // =====================================

  // State
  const [chooseTab, setChooseTab] = useState<string>('information')
  // =====================================

  // Function

  const checkConditionToShowBtnJoin = useMemo(() => {
    if (challengeDetail?.is_join) {
      return true
    }
    return (
      challengeDetail?.level_value <= account?.channelpermission?.level_number &&
      (challengeDetail?.join_number < challengeDetail?.max_user || challengeDetail?.max_user == 0)
    )
  }, [challengeDetail, account])

  const conditionCheckin = useMemo(() => {
    const currentTime = new Date().getTime()
    const endTime = new Date(challengeDetail?.end_time)?.getTime()
    const startTime = new Date(challengeDetail?.start_time)?.getTime()
    const checkTimeChallenge = currentTime < endTime && currentTime >= startTime

    return (
      isJoined &&
      officialStatus == 1 &&
      challengeDetail?.game_id.game_type == 'custom' &&
      challengeDetail?.game_id?.custom_field.length > 0 &&
      checkTimeChallenge
    )
  }, [challengeDetail, isJoined, officialStatus])

  const _onCheckIn = useCallback(() => {
    checkinRef.current?.show()
  }, [])

  const _onCreateCheckInSuccess = useCallback((type: EnumTypeToast) => {
    toast.show({
      title: `${__('notification')}`,
      type,
      content:
        type == EnumTypeToast.Error
          ? `${__('challenge_detail_attendance_failed')}`
          : `${__('challenge_detail_attendance_success')}`
    })
  }, [])

  const joinChallengeHandler = async () => {
    if (isJoined) {
      leaveChallenge({ challengeId: challenge_id, userId: account?._id })
        .unwrap()
        .then(result => {
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('challenge_detail_leave_success')}`
          })
        })
        .catch(err => {
          toast.show({
            type: EnumTypeToast.Error,
            content: `${__('challenge_detail_leave_failed')}`
          })
        })
    } else {
      await joinChallenge({ challengeId: challenge_id })
        .unwrap()
        .then(result => {
          const isPending = result.official_status === 0
          const content = isPending
            ? `${__('challenge_detail_join_failed')}`
            : `${__('challenge_detail_join_success')}`
          toast.show({
            type: EnumTypeToast.Success,
            content
          })
        })
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setChooseTab(newValue)
  }

  if (isLoading) {
    return <CoursesViewSkeleton />
  }

  return (
    <ChallengeDetailContainer id="long_challenge">
      <BreadcrumbComponent
        breadcrumbName={{
          '/challenge': 'Challenge',
          [`/challenge/detail/${challengeDetail?._id}`]: challengeDetail?.title
        }}
      />

      <Box className="box_challenge_avatar" mt={3}>
        <div className="w-100 challenge__avatar">
          <Avatar
            className="rounded w-100"
            src={challengeDetail?.avatar?.media_url || challengeDetail?.avatar?.media_thumbnail}
          />
        </div>
        <Box className="box_challenge_info">
          <Typography className="title" variant="h5">
            {challengeDetail?.title}
          </Typography>

          <Box className="info">
            <Stack
              mt={2}
              flexDirection={'row'}
              alignItems={'center'}
              className=" info_item challenge_start_time"
            >
              <CalendarMinusIcon
                width="20px"
                height="22px"
                color="primary"
                sx={{
                  marginRight: theme => theme.spacing(1.5)
                }}
              />{' '}
              <Typography variant="body1">
                {' '}
                {`${__('challenge_detail_start_time')} ${DateTime.format(
                  new Date(challengeDetail?.start_time),
                  `DD/MM/YYYY ${__('challenge_detail_at')} HH:mm`
                )}`}
              </Typography>
            </Stack>

            <Stack
              mt={2}
              flexDirection={'row'}
              alignItems={'center'}
              className=" info_item challenge_end_time"
            >
              <CalendarMinusIcon
                width="24px"
                height="24px"
                color="primary"
                sx={{
                  marginRight: theme => theme.spacing(1.5)
                }}
              />{' '}
              <Typography variant="body1">
                {' '}
                {`${__('challenge_detail_end_time')} ${DateTime.format(
                  new Date(challengeDetail?.end_time),
                  `DD/MM/YYYY ${__('challenge_detail_at')} HH:mm`
                )}`}
              </Typography>
            </Stack>

            <Stack
              mt={2}
              flexDirection={'row'}
              alignItems={'center'}
              className=" info_item challenge_cup"
            >
              <CupIcon
                width="24px"
                height="24px"
                color="primary"
                sx={{
                  marginRight: theme => theme.spacing(1.5)
                }}
              />{' '}
              <Typography variant="body1">
                {' '}
                {challengeDetail?.challenge_stage?.length > 0
                  ? challengeDetail?.challenge_stage?.length + `${__('challenge_detail_race')}`
                  : `${__('challenge_detail_no_race')}`}{' '}
              </Typography>
            </Stack>

            <Stack
              mt={2}
              flexDirection={'row'}
              alignItems={'center'}
              className=" info_item group_user"
            >
              <GroupUser
                width="24px"
                height="24px"
                color="primary"
                sx={{
                  marginRight: theme => theme.spacing(1.5)
                }}
              />{' '}
              <Typography variant="body1">
                {' '}
                {`${
                  challengeDetail?.join_number > 0
                    ? challengeDetail?.join_number + ` ${__('challenge_detail_participants')}`
                    : `${__('challenge_detail_no_participate')}`
                }`}
              </Typography>
            </Stack>

            {!__helpers.current_user_can('boss', permission_data) ? (
              <Button
                disabled={
                  officialStatus === 0 || officialStatus === 2 || !checkCurrentTimeChallenge
                }
                onClick={joinChallengeHandler}
                variant={challengeDetail?.is_join ? 'outlined' : 'contained'}
                sx={{
                  width: '100%',
                  color: challengeDetail?.is_join ? 'primary.main' : '#fff',
                  marginTop: theme => theme.spacing(1.5)
                }}
              >
                {' '}
                {challengeDetail?.is_join ? (
                  <CheckIcon
                    sx={{
                      marginRight: theme => theme.spacing(1)
                    }}
                  />
                ) : null}{' '}
                {__(
                  challengeDetail?.is_join
                    ? 'challenge_detail_status_joined'
                    : 'challenge_detail_status_join'
                )}
              </Button>
            ) : null}

            <Stack className="btn_action">
              <Button
                onClick={() => navigate(`/challenge/userJoin/${challengeDetail?._id}`)}
                variant="outlined"
              >
                {__('challenge_detail_list_join')}
              </Button>
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={!!anchorEl ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={!!anchorEl ? 'true' : undefined}
                  variant="outlined"
                  onClick={handleClick}
                >
                  <UnionIcon />
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  elevation={4}
                >
                  {SocialList.map(item =>
                    item.type === 'link' ? (
                      <MenuItem
                        key={item?.id}
                        onClick={() => {
                          handleClose()
                          window.open(item.link)
                        }}
                      >
                        {' '}
                        {item.icon} {item.title}
                      </MenuItem>
                    ) : (
                      <CopyToClipboard key={item?.id} text={item.link}>
                        <MenuItem onClick={handleClose}>
                          {' '}
                          {item.icon} {item.title}
                        </MenuItem>
                      </CopyToClipboard>
                    )
                  )}
                </Menu>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Box className="box_challenge_infomation">
        <Box className="tabpane">
          <Tabs value={chooseTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={`${__('challenge_detail_information')}`} value={'information'} />
            <Tab label={`${__('challenge_detail_active')}`} value={'work'} />
            <Tab label={`${__('challenge_detail_notice')}`} value={'announced'} />
          </Tabs>
          {chooseTab === 'information' && <ChallengeInformation detail={challengeDetail} />}

          {chooseTab === 'work' && (
            <ChallengeWork onCheckin={_onCheckIn} shouldDisplayCheckinButton={conditionCheckin} />
          )}

          {chooseTab === 'announced' && <ChallengeAnnounced />}
        </Box>
        <Box className="rank_game">
          <ChallengeRanking
            challenge_id={challenge_id}
            challenge_stage={challengeDetail?.challenge_stage}
          />
        </Box>
      </Box>
      <br />
      <br />
      <CheckinModal
        game_id={challengeDetail?.game_id}
        ref={checkinRef}
        challenge_id={challengeDetail?._id}
        createSuccess={_onCreateCheckInSuccess}
      />

      {conditionCheckin && chooseTab !== 'work' && (
        <Button
          onClick={_onCheckIn}
          className="position-sticky"
          style={{
            bottom: '50px',
            left: '100%'
          }}
        >
          {__('challenge_detail_attendance_join')}
        </Button>
      )}
    </ChallengeDetailContainer>
  )
}

export default ChallengeDetailComponent

const ChallengeDetailContainer = styled(Box)(({ theme }) => ({
  '& .box_challenge_avatar': {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    background: '#fff',
    borderRadius: theme.spacing(1.5),

    '& .challenge__avatar': {
      height: '433px',
      borderRadius: theme.spacing(1.5),

      img: {
        height: '100%'
      }
    },

    '& .box_challenge_info': {
      padding: theme.spacing(3),

      '& .title': {
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box'
      },

      '& .info': {
        '&_item': {
          fontSize: '16px'
        },

        '& .btn_action': {
          flexDirection: 'row',
          marginTop: theme.spacing(2),
          width: '100%',
          justifyContent: 'space-between',

          button: {
            borderColor: theme.palette.divider,
            color: '#000',
            '&:first-child': {
              width: '100%'
            },
            '&:last-child': {
              marginLeft: theme.spacing(1)
            }
          }
        }
      }
    }
  },
  '& .box_challenge_infomation': {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '68% 30%',
    gap: theme.spacing(3),

    '& .tabpane': {
      background: '#fff',
      padding: theme.spacing(1, 3, 3, 3),
      borderRadius: theme.spacing(1.5)
    },

    '& .rank_game': {
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(3),
      background: '#fff',
      maxHeight: '430px'
    }
  }
}))
