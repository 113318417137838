import ImageForm from 'components/image.form'
import { TypedCreateAction, TypedDataAction, TypedGame } from 'entities/challenge/challenge.interface'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { uniqueArr } from 'entities/helpers/calendar.helpers'
import { useAppDispatch } from 'config/store'
import { createActionCheckIn } from 'entities/challenge/challenge.store.reducer'
import { convertTimeToTimeZone } from 'entities/challenge/helpers'
import __ from "languages/index";
import { Button, styled } from '@mui/material'
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal'
import __helpers from 'helpers/index'

interface Props {
  game_id: TypedGame
  challenge_id: string
  createSuccess: (type: string) => void
}

const CheckInModal = (props: Props, ref) => {
  const { game_id, challenge_id, createSuccess } = props

  const dispatch = useAppDispatch()

  useImperativeHandle(ref, () => ({
    show: handleShow
  }))

  // Refs Input
  const valueInputRef = useRef(null)
  // ======================================

  // Refs
  const imageRef = useRef<string>('')
  const dataActionRef = useRef<TypedDataAction[]>([])
  const valueRef = useRef<string>('')
  // ======================================

  // State
  const [show, setShow] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(false)
  const [disabled, setDisable] = useState<boolean>(false)
  // ======================================
  console.log('disabled',disabled)
  // Function
  const handleClose = useCallback(() => {
    setShow(false)
    setValidate(false)
    dataActionRef.current = []
  }, [])

  const handleShow = useCallback(() => {
    setShow(true)
  }, [])

  const handleSubmit = async event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setTimeout(async () => {
        const filterArr = uniqueArr(dataActionRef.current, (a, b) => a?.meta_key == b?.meta_key)
        const params: TypedCreateAction = {
          data_activity: JSON.stringify(filterArr),
          challenge_id: challenge_id,
          start_time: new Date().toISOString()
        }
        try {
          const res: any = await dispatch(createActionCheckIn(params))
          if (res?.payload?.data) {
            handleClose()
            createSuccess('success')
          } else {
            createSuccess('danger')
          }
        } catch (error) {
          createSuccess('danger')
        }
      }, 300)
    }
    setValidate(true)
  }

  const getDataImageChange = useCallback((media_id: string, id: string, title: string) => {
    imageRef.current = media_id
    const newItem: TypedDataAction = {
      meta_key: id,
      meta_type: 'image',
      meta_value: media_id,
      meta_title: title
    }
    dataActionRef.current = [...dataActionRef.current, ...[newItem]]
  }, [])
  const _onPointChange = useCallback(event => {
    const value = event.target.value || ''
    if (value.match(/\D+/g)) {
      valueInputRef.current.value = valueRef.current
    } else {
      valueRef.current = value
      valueInputRef.current.value = value
    }
  }, [])

  const _onDateChange = useCallback(event => { }, [])

  const _onTextChange = useCallback(event => { }, [])

  const _onLinkChange = useCallback(event => { }, [])

  const _onPointBlur = useCallback(event => {
    const newItem: TypedDataAction = {
      meta_key: event?.target.id,
      meta_type: 'point',
      meta_value: event?.target?.value,
      meta_title: event?.target.title
    }
    dataActionRef.current = [...dataActionRef.current, ...[newItem]]
  }, [])

  const _onTextBlur = useCallback(event => {
    const newItem: TypedDataAction = {
      meta_key: event?.target.id,
      meta_type: 'text',
      meta_value: event?.target?.value,
      meta_title: event?.target.title
    }
    dataActionRef.current = [...dataActionRef.current, ...[newItem]]
  }, [])

  const _onDateBlur = useCallback(event => {
    const newItem: TypedDataAction = {
      meta_key: event?.target.id,
      meta_type: 'date',
      meta_value: convertTimeToTimeZone(`${event?.target?.value}:00.000Z`),
      meta_title: event?.target.title
    }
    dataActionRef.current = [...dataActionRef.current, ...[newItem]]
  }, [])

  const _onLinkBlur = useCallback(event => {
    const newItem: TypedDataAction = {
      meta_key: event?.target.id,
      meta_type: 'text_link',
      meta_value: event?.target?.value,
      meta_title: event?.target?.title
    }
    dataActionRef.current = [...dataActionRef.current, ...[newItem]]
  }, [])

  const renderTypeText = useCallback((type) => {
    if (type == "image") {
      return "bi bi-image-fill"
    }
    if (type == "point") {
      return "bi bi-9-circle"
    }
    if (type == "date") {
      return "bi bi-calendar-event"
    }
    if (type == "text_link") {
      return "bi bi-link-45deg fs-5"
    }
    return "bi bi-c-square-fill"
  }, [])

  // ======================================

  const renderFormInput = () => {
    if (Array.isArray(game_id?.custom_field) && game_id?.custom_field.length > 0) {
      return game_id?.custom_field.map(item => {
        return (
          <Form.Group key={item?._id} className="mb-3 form_input">
            <Form.Label ><i className={renderTypeText(item?.field_type)} /> {item?.name}</Form.Label>
            {item?.field_type == 'image' ? (
              <ImageForm
                getDataImageChange={getDataImageChange}
                id={item?._id}
                title={item?.name}
              />
            ) : item?.field_type == 'point' ? (
              <Form.Control
                title={item?.name}
                onBlur={_onPointBlur}
                maxLength={12}
                ref={valueInputRef}
                onChange={_onPointChange}
                id={item?._id}
                required
                type={'text'}
                className='form_input'
              />
            ) : item?.field_type == 'date' ? (
              <Form.Control
                title={item?.name}
                id={item?._id}
                onBlur={_onDateBlur}
                onChange={_onDateChange}
                required
                type={'datetime-local'}
              />
            ) : item?.field_type == 'text' ? (
              <Form.Control
                title={item?.name}
                onChange={_onTextChange}
                onBlur={_onTextBlur}
                id={item?._id}
                required

              />
            ) : (
              <Form.Control
                title={item?.name}
                required
                id={item?._id}
                type="url"
                onChange={_onLinkChange}
                onBlur={_onLinkBlur}
                className='form_input'
              />
            )}
            <div className="invalid-feedback">{__("challenge_checkin_modal_sub_text")} {item?.name}</div>
          </Form.Group>
        )
      })
    }
    return null
  }

  useEffect(()=>{
    setDisable(validate || __helpers.isEmpty(dataActionRef?.current))
  },[validate, dataActionRef?.current])

  return (
    <CheckinModalContainer show={show} onHide={handleClose}>
      <Form className="needs-validation" noValidate validated={validate} onSubmit={handleSubmit}>

        <ModalHeader>{__("challenge_checkin_modal_title")}</ModalHeader>
        <ModalBody>
          {
            renderFormInput()
          }

        </ModalBody>
        <ModalFooter
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <Button variant="text" onClick={handleClose}>
            {__("btn_close")}
          </Button>
          <Button type={'submit'} disabled={disabled}>
            {__("challenge_checkin_modal_btn_attendance")}
          </Button>
        </ModalFooter>

      </Form>

    </CheckinModalContainer >
  )
}

export default forwardRef(CheckInModal)
const CheckinModalContainer = styled(Modal)(({ theme }) => ({
  '& .form_input': {
    '& input': {
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1.5),
      background: '#F3F4F5',
      height: '56px'
    }
  }
}))
