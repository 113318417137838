export const ROUTER = {
  AUTH: {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    LOGIN_WITH_FACEBOOK: '/login/facebook',
    LOGIN_WITH_GOOGLE: '/login/google',
  },
  HOME: '/',
  CHANNEL: {
    JOIN: '/channel/join'
  }
}