import { Box, IconButton, Paper, Stack, Typography, styled } from '@mui/material'
import BigLayout from 'layouts/bigLayout'
import _Helmet from 'components/helmet'
import { getListPermission } from './services'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { TypedChallengeRanking, TypedLeg } from './challenge.interface'
import { useInfinite } from 'hooks/useInfinite'
import ArrowChevronLeftIcon from 'icons/ArrowChevronLeftIcon'
import __ from 'languages/index'
import CoursesEmpty from 'entities/courses/components/courses.empty'
import RankTopIcon from 'icons/RankTopIcon'
import RankTopIcon2 from 'icons/RankTopIcon2'
import NavigateUser from './component/NavigateUser'
import { Image } from 'react-bootstrap'
import BreadcrumbComponent from 'components/BreadcrumbComponent'

const limit = 10

const ChallengeRankings = () => {
  const { challenge_id } = useParams()
  const [page, setPage] = useState<number>(1)
  const [chooseStage, setChooseStage] = useState<TypedLeg>(null)
  const [data, setData] = useState<TypedChallengeRanking[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  // useEffect
  useEffect(() => {
    getData()
  }, [page, chooseStage])

  const getData = async () => {
    const res: any = await getListPermission({
      params: {
        challenge_id,
        challenge_stage_id: (chooseStage?._id !== '001ABC' && chooseStage?._id) || undefined,
        page,
        limit
      },
      headers: {
        ['X-Authorization']: localStorage.getItem('session')
      }
    })
    if (res?.data) {
      setData(prev => [...prev, ...res?.data])
      setLoading(false)
    }
  }

  const { ref } = useInfinite(data.length / limit <= page, () => {
    setPage(prev => prev + 1)
  })

  const renderBodyRankTable = (item: TypedChallengeRanking, index: number) => {
    return (
      <Box
        sx={{
          background: theme =>
            !index
              ? theme.palette.primary.main
              : index === 1
                ? theme.palette.primary.backgroundHover
                : '#fff',
          border: theme =>
            index > 0 && index < 3 ? `1px solid ${theme.palette.primary.main}` : 'none',
          color: index === 0 ? '#fff' : '#000'
        }}
        className="item_rank"
        key={item?._id}
        ref={ref}
      >
        {index === 0 && (
          <Box className="rank_number">
            <RankTopIcon />
            <Typography color={'black'}>{index + 1}</Typography>
          </Box>
        )}
        {index > 0 && index < 3 && (
          <Box className="rank_number">
            <RankTopIcon2 />
            <Typography>{index + 1}</Typography>
          </Box>
        )}
        {index > 2 && (
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <Typography>{index + 1}</Typography>
          </Box>
        )}
        <td>
          <NavigateUser _id={item?.user_id?._id}>
            <div className="d-flex align-items-center gap-2">
              <Image
                className="image-avatar-challenge-rank"
                src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar}
              />
            </div>
          </NavigateUser>
        </td>
        <div className="info">
          {item?.user_id?.display_name}
          <Typography>Cấp độ: {item?.user_id?.level_number}</Typography>
        </div>
        <Box>
          <Typography
            sx={{
              padding: 'var(--spacing-1, 2px) 8px',
              color: 'primary.main',
              background: index < 3 ? '#fff' : '#F3F4F5',
              borderRadius: '12px',
              textAlign: 'center'
            }}
          >
            {item?.total_point}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <BigLayout>
      <_Helmet title="challenge_detail_rating" />
      <BreadcrumbComponent
        breadcrumbName={{
          '/challenge': 'Challenge',
          [`/challenge/ranking/${challenge_id}`]: __('challenge_detail_rating')
        }}
      />
      <ChallengeRankingsContainer elevation={1}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack flexDirection={'row'} alignItems={'center'} ml={1}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowChevronLeftIcon />
            </IconButton>
            <Typography variant="h6">{__('challenge_detail_rating')}</Typography>
          </Stack>

          {/* <Box mt={2} className="box_search">
                        <IconButton aria-label="search" sx={{ padding: 0 }}>
                            <SearchIcon color="inherit" />
                        </IconButton>
                        <InputBase
                            // onChange={searchChangeHandler}
                            placeholder={__('header_search')}
                            inputProps={{ 'aria-label': __('header_search') }}
                            className="search_input"
                        />
                    </Box> */}
        </Stack>

        <Box mt={2}>
          {data?.length <= 0 ? (
            <CoursesEmpty />
          ) : (
            data.slice(0, 5).map((item, index) => renderBodyRankTable(item, index))
          )}
        </Box>
      </ChallengeRankingsContainer>
    </BigLayout>
  )
}

export default ChallengeRankings

const ChallengeRankingsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),

  '& .box_search': {
    background: '#F3F4F5',
    borderRadius: '12px',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',

    '& .search_input': {
      paddingLeft: theme.spacing(2)
    }
  },

  '& .item_rank': {
    display: 'grid',
    gridTemplateColumns: '40px 50px 1fr 50px',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    '& div, td': {
      margin: 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%'
    },

    '& .rank_number': {
      position: 'relative',

      p: {
        position: 'absolute',
        top: '3px',
        left: '9px',

        '&:fisrt-of-type': {
          top: '6px'
        }
      }
    },

    '& .image-avatar-challenge-rank': {
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      objectFit: 'cover'
    }
  }
}))
