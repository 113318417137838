import axios from 'axios'
import { MediaResponse } from 'entities/courses/courses.interface'
import TypedMedia from 'interfaces/media.interface'

export async function uploadSingleMedia(
  formData: FormData,
  callbackProgress?: (progress: number) => void
): Promise<MediaResponse | undefined> {
  try {
    const mediaResponse = await axios.post(`${process.env.REACT_APP_MEDIA_UPLOAD_URL}`, formData, {
      timeout: 1800000,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        callbackProgress?.(progress)
      }
    })

    if (Array.isArray(mediaResponse.data) && mediaResponse.data.length > 0) {
      return mediaResponse.data[0]
    }
    return undefined
  } catch (error) {
    // callbackFail?.();  // ?
    console.log(error, 'syncMessages')
    return null
  }
}

export const getDefaultImage = async (screen: string = 'channel') => {
  const response = await axios.get<TypedMedia>(
    `${process.env.REACT_APP_AJAX_URL}/settings/default-avatar/${screen}`
  )
  return {
    url: response.data.media_url,
    id: response.data._id
  }
}
