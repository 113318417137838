import React, { useEffect, useState } from 'react'
import { FormControl, Stack, Typography, Button, Box, InputLabel } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useAppDispatch } from 'config/store'
import {  updateChannelSetting } from '../store/channel.slice'
import FileUploader from '../components/FileUploader'
import Select from 'components/Select'
import useBanks from 'hooks/useBanks'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import TypedChannel from '../../../interfaces/channel.interface'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import TextField from 'components/TextFieldMUI'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { validateLabel } from 'entities/support/utils'
import { ConfigPayment } from 'entities/channel-settings/service/service.type'
import { uploadFile } from 'entities/courses/services/courses.media.service'

interface TypeCreditConfigurations{
  channel:TypedChannel
}


const CreditConfigurations = ({channel}:TypeCreditConfigurations) => {
  const [channelData, setChannelData] = useState<TypedChannel>(channel)
  const [qr, setQr] = useState<FileList | File[]>(null)
  const banks = useBanks()
  const toast = useToast()
  const dispatch = useAppDispatch()

  const { fields, submit, dirty, submitting } = useForm({
    fields: {
      bank_name: useField<string>({
        value: channelData?.transfer_info?.bank_name ?? '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_bank')}`)?.required)]
      }),
      bank_branch: useField<string>({
        value: channelData?.transfer_info?.bank_branch ?? '',
        validates: [
          notEmpty(validateLabel(`${__('channel_setting_payment_branch_bank')}`)?.required)
        ]
      }),
      bank_account: useField<string>({
        value: channelData?.transfer_info?.bank_account ?? '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_receiver')}`)?.required)]
      }),
      bank_number: useField<string>({
        value: channelData?.transfer_info?.bank_number ?? '',
        validates: [
          notEmpty(validateLabel(`${__('channel_setting_payment_recipient_account')}`)?.required)
        ]
      }),
      qr_transfer: useField<string>({
        value: channelData?.transfer_info?.qr_transfer ?? '',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateChannelSetting({ transfer_info: values })
        )
          .unwrap()
          .then(res=>{
            setChannelData(res?.data)
            toast.show({
              content: `${__('channel_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(err => {
            console.log('err',err)
            toast.show({
              content: `${__('channel_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })
  const urlQR = () => {
    if (qr) return URL.createObjectURL(qr[0])
    return channelData?.transfer_info?.qr_transfer
  }

  const configVNpay = useForm({
    fields: {
      tmn_code: useField<string>({
        value: channelData?.vn_pay?.tmn_code ?? '',
        validates: [
          notEmpty(validateLabel(`${__('channel_setting_tmncode_label')}`)?.required)
        ]
      }),
      secret_key: useField<string>({
        value: channelData?.vn_pay?.secret_key ?? '',
        validates: [
          notEmpty(validateLabel(`${__('channel_setting_secret_key_label')}`)?.required)
        ]
      })
    },
    async onSubmit(values) {
      try {
        dispatch(
          updateChannelSetting({ vn_pay: { ...channelData?.vn_pay, ... values } })
        )
          .unwrap()
          .then(res=>{
            setChannelData(res?.data)
            toast.show({
              content: `${__('channel_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(err => {
            console.log('err',err)
            toast.show({
              content: `${__('channel_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleUploadQr =   async ()=>{
    let uploadedLogo = null

    try{
      const formData = new FormData()
      Array.from(qr).forEach(file => {
        formData.append('file[]', file)
      })
      uploadedLogo = await uploadFile(formData)
    }catch (e:any){
      console.log('uploadedLogo', e)

    }
    fields.qr_transfer.onChange(uploadedLogo[0].callback?.media_url)

  }
  useEffect( () => {
    if (!qr) return
    handleUploadQr()
  }, [qr])

  return (
    <Stack rowGap={2} >
      <ChannelSettingsContentContainer>
        <Stack id={ConfigPayment?.TRANSFER} direction={'row'} component="form" onSubmit={(e) =>{
          e.preventDefault()
          submit()
        }}>
          <ChannelSettingsTitle>{__('payment_add_card_transfer')}</ChannelSettingsTitle>
          <Button
            type={'submit'}
            disabled={!dirty || submitting}
            sx={{
              marginLeft: 'auto',
              textTransform: 'none',
              fontSize: theme => theme.spacing(2),
              minWidth: theme => theme.spacing(12)
            }}
          >
            {__('btn_save')}
          </Button>
        </Stack>
        <Typography p={theme => theme.spacing(2, 0)}>
          {__('channel_setting_payment_sub_text')}
        </Typography>
        <Grid container spacing={2}>
          <Grid container xs={12}>
            <Grid xs={3}>
              <FileUploader
                {...fields?.qr_transfer}
                acceptFiles="image/*"
                onUploaded={setQr}
                placeholder={
                    (qr !== null || channel?.transfer_info?.qr_transfer) && (
                    <Box
                      component={'img'}
                      src={urlQR()}
                      sx={{
                        borderRadius: theme => theme.spacing(1.5),
                        width: '128px',
                        height: '128px'
                      }}
                    />
                  )
                }
              />
            </Grid>
            <Grid xs={9}>
              <Stack gap={2}>
                <FormControl variant="filled">
                  <InputLabel>{__('channel_setting_payment_bank')}</InputLabel>
                  <Select
                    {...fields?.bank_name}
                    onChangeValue={value => fields?.bank_name.onChange(value)}
                    value={fields?.bank_name?.value}
                    options={banks}
                    getItemValue={item => item.shortName}
                    renderItem={(item) => <Typography key={`channel_setting_payment_bank${item?.id}`}>{item.name}</Typography>}
                  />
                </FormControl>

                <TextField
                  {...fields?.bank_branch}
                  label={__('channel_setting_payment_branch_bank')}
                  placeholder={__('channel_setting_payment_branch_bank_placeholder')}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid spacing={2} container xs={12}>
            <Grid xs={6}>
              <TextField
                {...fields?.bank_account}
                label={__('payment_add_card_bank_account_number')}
                placeholder={__('payment_add_card_bank_account_number_placeholder')}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                {...fields?.bank_number}
                label={__('payment_add_card_bank_account_name')}
                placeholder={__('payment_add_card_bank_account_name_placeholder')}
              />
            </Grid>
          </Grid>
        </Grid>
      </ChannelSettingsContentContainer>
      <ChannelSettingsContentContainer>
        <Stack gap={2} id={ConfigPayment?.VNPAY} component="form" onSubmit={(e) =>{
          e.preventDefault()
          configVNpay.submit()
        }}>
          <Stack direction={'row'}>
            <ChannelSettingsTitle>{__('payment_add_card_vnpay')}</ChannelSettingsTitle>
            <Button
              type={'submit'}
              disabled={!configVNpay.dirty || configVNpay.submitting}
              sx={{
                marginLeft: 'auto',
                textTransform: 'none',
                fontSize: theme => theme.spacing(2),
                minWidth: theme => theme.spacing(12)
              }}
            >
              {__('btn_save')}
            </Button>
          </Stack>
          <TextField
            {...configVNpay?.fields?.tmn_code}
            label={__('channel_setting_tmncode_label')} />
          <TextField
            {...configVNpay?.fields?.secret_key}
            label={__('channel_setting_secret_key_label')} />
        </Stack>
      </ChannelSettingsContentContainer>
    </Stack>
  )
}

export default CreditConfigurations
