import OrderTable from './OrderTable'
import '../media/css/index.scss'
import { useParams} from 'react-router-dom'
import __helpers from 'helpers/index'
import DateTime from 'date-and-time'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {updateOrder} from 'entities/orders/order.store.reducer'
import { useAppDispatch } from 'config/store'
import ImgBlur from 'entities/courses/media/img_blur.png'
import {uploadMedia} from 'entities/courses/services/courses.media.service'
import ModalNotification from 'entities/orders/components/ModalNotification'
import {getOrderDetail} from 'entities/orders/service'
import OrderInformationPayment from './OrderInformationPayment'
import { Order} from '../order.type'
import googleImage from '../../../media/images/google.png'
import iconTransfer from '../media/images/dollar.png'
import VNpayIcon from 'media/images/VNpayIcon.png'
import zaloPay from 'media/images/ZaloPayIcon.png'
import applePay from '../../../media/images/apple-pay.png'
import stripe from '../../../media/images/stripe.png'
import paypal from '../../../media/images/paypal.png'
import free from '../../../media/images/free.png'
import ImageDefault from 'media/images/image_default.png'
import {getStatus, hanleShowTextPayment, showStatus} from '../helps'
import Title from 'components/Title'
import __ from "languages/index";
import { Box, Stack, styled, Typography, Button } from '@mui/material'
import { formatString } from 'entities/support/utils'
import { useGetThumbnail } from 'hooks/useGetThumbnail'

interface TypedOrderItemProps {
  orders: Order[]
  status: string
}
const OrderDetail: React.FC<TypedOrderItemProps> = ({ orders, status }) => {
  const [reloadData, setReloadData] = useState<Order>()
  const [imageUrl, setImageUrl] = useState<string>('')
  const [imageId, setImageId] = useState<string>('')
  const [loadingImage, setLoadingImage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowIcon, setIsShowIcon] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const imageRef = useRef(null)
  const [oderStatus, setOderStatus] = useState<string>('')
  const shouldDisplayData =
    ['processing', 'success'].includes(reloadData?.status || orders[0]?.status) ||
    isLoading ||
    isShowIcon

    const {ref, getThumbnailUrl} = useGetThumbnail()

  const useParam = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (['processing'].includes(reloadData?.status || status)) {
      setIsLoading(true)
      setIsShowIcon(false)
    }
    if (['success'].includes(reloadData?.status || status)) {
      setIsLoading(false)
      setIsShowIcon(false)
    }
  }, [status, reloadData])

  useEffect(() => {
    setOderStatus(orders[0]?.status)
    if (isLoading) {
      if (reloadData?.status ? reloadData?.status === 'processing' : oderStatus === 'pending') {
        setTimeout(() => {
          const reloadData = async () => {
            try {
              const res = await getOrderDetail(useParam.order_id)
              setReloadData(res.data)
            } catch (err) {
              console.log(err)
            }
          }
          reloadData().then(r => r)
        }, 10000)
      }
      if (reloadData?.status === 'success' && oderStatus == 'pending') {
        setShow(true)
        setOderStatus('success')
      }
    }
  }, [reloadData, oderStatus, isLoading])

  const onChooseImageChange = useCallback(
    async event => {
      if (shouldDisplayData) return
      if (event.target.files[0]) {
        setLoadingImage(true)
        setImageUrl(ImgBlur)
        const link = URL.createObjectURL(event.target.files[0])

        try {
          const res: any = await uploadMedia(event.target.files)

          if (Array.isArray(res) && res.length > 0) {
            imageRef.current = res[0]?.callback
            setImageId(res[0]?.callback?._id)
          }
        } catch (error) {
          console.log('error_234234', error)
        }
        setImageUrl(link)
        setLoadingImage(false)
      }
    },
    [shouldDisplayData]
  )
  const renderHeaderTable = () => (
    <tr>
      <th style={{ minWidth: 140 }}>{__('order_table_product_name')}</th>
      <th style={{ minWidth: 140 }}>{__('order_table_price')}</th>
      <th style={{ minWidth: 140 }}>{__('order_table_quantity')}</th>
      <th style={{ minWidth: 140 }}>{__('order_table_discount')}</th>
      <th style={{ minWidth: 140 }}>{__('order_table_total_payment')}</th>
      <th style={{ minWidth: 140 }}>{__('order_table_status')}</th>
    </tr>
  )

  const renderBodyItem = (orderItem: Order) => {
    const { service_id, plan_id, amount_of_package, coupon_price } = orderItem
    const product_url = orderItem?.product_url
      ? orderItem?.product_url
      : `/extensions/view/${service_id?._id}`

    return (
      <tr key={orderItem?.short_id} className="order-item-body">
        <td>
          <div className="order-item-wrapper">
            <picture className="product-image">
              <img ref={ref} src={getThumbnailUrl(plan_id?.image) || ImageDefault} />
            </picture>
            <div className="product-detail">
              <div>
                <p className="ellipsis">{plan_id?.name}</p>
                <p>{plan_id?.postedBy}</p>
              </div>
              {/*<div className="order__actions">*/}
              {/*  <Button variant="outline-primary">*/}
              {/*    <Link to={helpers.getRootDomain(`${product_url}`)}>{__("order_detail_view_product")}</Link>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </div>
          </div>
        </td>
        <td>
          <p>
            {__helpers.formatNumberWithCommas(plan_id?.price)} {__('order_unit')}
          </p>
        </td>
        <td>
          <p>{amount_of_package}</p>
        </td>
        <td>
          <p>{coupon_price}</p>
        </td>
        <td>
          <p>
            {formatString(__('currency_unit_default'),__helpers.formatNumberWithCommas(+plan_id?.price * amount_of_package - coupon_price))}        
          </p>
        </td>
        <td>
          <p
            className={`order__payments-status--${getStatus(
              reloadData?.status ? reloadData.status : orderItem.status
            )}`}
          >
            {showStatus(reloadData?.status ? reloadData?.status : orderItem?.status)}
          </p>
        </td>
      </tr>
    )
  }
  const renderIcon = (payment_method: string) => {
    const paymentMethodIcons = {
      transfer: iconTransfer,
      google_payment: googleImage,
      vn_pay: VNpayIcon,
      zalo_pay: zaloPay,
      apple_pay: applePay,
      stripe: stripe,
      paypal: paypal,
      free: free
    }
    return paymentMethodIcons[payment_method]
  }

  const handleSendInfoPayment = useCallback(
    (imageId: string, status: string) => {
      if (status === 'pending') {
        setIsLoading(true)
        setIsShowIcon(true)
        try {
          dispatch(
            updateOrder({
              _id: useParam.order_id,
              status: 'processing',
              media_id: imageId
            })
          )
        } catch (err) {
          console.log(err)
        }
      }
    },
    [orders]
  )
  const handleChange = () => {
    if (show) {
      setShow(false)
    }
  }

  function ViewStep() {
    return (
      <Box>
        <Box
          sx={{
            width: theme => theme.spacing(3.5),
            height: theme => theme.spacing(3.5)
          }}
          className="bg-white rounded-4 border d-flex align-items-center justify-content-center"
        >
          <Box
            className="rounded-4"
            sx={{
              width: theme => theme.spacing(2.5),
              height: theme => theme.spacing(2.5),
              backgroundColor: theme => theme.palette.primary.main
            }}
          />
        </Box>
        <Box
          sx={{
            marginLeft: theme => theme.spacing(1.5),
            marginTop: theme => theme.spacing(1.5),
            height: theme => theme.spacing(3.5)
          }}
          className="order__border border-start mt-2"
        />
      </Box>
    )
  }

  return (
    <OrderDetailContainer>
      {orders.map(order => (
        <div key={`order-detail${order?._id}`} className="order-container">
          <Title
            text={__("order_detail_title")}
            children={
              <span className="order-code" style={{ fontSize: 24 }}>
                #{order.short_id}
              </span>
            }
          />
          <div className="detail-wrapper">
            <div className="row">
              <div className="col-12 ">
                <article className="order__customer h-100 pb-4 m-0">
                  <Box>
                    <Typography
                      textTransform={'uppercase'}
                      variant={'body1'}
                      margin={theme => theme.spacing(2, 0)}
                    >
                      {__('order_detail_sub_title')}
                    </Typography>
                    <Box flex={1} className="order-detail-wrapper">
                      <Box>
                        <Typography className="customer-name">
                          {order?.user_id?.display_name}
                        </Typography>
                        <Typography>{order?.user_id?.user_phone}</Typography>
                        <Typography>{order?.user_id?.user_address}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="order_notification">
                    <Typography
                      textTransform={'uppercase'}
                      variant={'body1'}
                      margin={theme => theme.spacing(2, 0)}
                    >
                      {__('notification')}
                    </Typography>
                    <Box flex={1} className=" order-detail-wrapper">
                      <Box className="order_notification_item">
                        {ViewStep()}
                        <Stack
                          direction={'row'}
                          sx={{
                            marginLeft: theme => theme.spacing(1),
                            marginTop: theme => theme.spacing(0.25),
                            alignItem: 'center',
                            textAlign: 'center'
                          }}
                        >
                          <Box>
                            {DateTime.format(new Date(order?.createdAt), 'YYYY-MM-DD HH:mm')}
                          </Box>
                          <Typography paddingLeft={theme => theme.spacing(1)}>
                            {__('order_detail_payment_request')}
                          </Typography>
                        </Stack>
                      </Box>
                      {order?.vnpay_on && (
                        <Box className="order_notification_item">
                          {ViewStep()}
                          <Stack
                            direction={'row'}
                            sx={{
                              marginLeft: theme => theme.spacing(1),
                              marginTop: theme => theme.spacing(0.25),
                              alignItem: 'center',
                              textAlign: 'center'
                            }}
                          >
                            <Box>
                              {DateTime.format(new Date(order?.vnpay_on), 'YYYY-MM-DD HH:mm')}
                            </Box>
                            <Typography paddingLeft={theme => theme.spacing(1)}>
                              {__('order_detail_payment_confirm')}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      {order?.billing_on && (
                        <Box className="order_notification_item">
                          {ViewStep()}
                          <Stack
                            direction={'row'}
                            sx={{
                              marginLeft: theme => theme.spacing(1),
                              marginTop: theme => theme.spacing(0.25),
                              alignItem: 'center',
                              textAlign: 'center'
                            }}
                          >
                            <Box>
                              {DateTime.format(new Date(order?.billing_on), 'YYYY-MM-DD HH:mm')}
                            </Box>
                            <Typography paddingLeft={theme => theme.spacing(1)}>
                              {__('order_detail_payment_success')}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      {order?.cancelled_on && (
                        <Box className="order_notification_item">
                          {ViewStep()}
                          <Stack
                            direction={'row'}
                            sx={{
                              marginLeft: theme => theme.spacing(1),
                              marginTop: theme => theme.spacing(0.25),
                              alignItem: 'center',
                              textAlign: 'center'
                            }}
                          >
                            <Box>
                              {DateTime.format(new Date(order?.cancelled_on), 'YYYY-MM-DD HH:mm')}
                            </Box>
                            <Typography paddingLeft={theme => theme.spacing(1)}>
                              {__('order_detail_payment_fail')}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </article>
              </div>
            </div>
            {order.payment_method === 'transfer' &&
              (order.status === 'pending' ||
                order.status === 'processing' ||
                order.status === 'success') && (
                <div className="row">
                  <div className="col-12 ">
                    <article>
                      <div className="order__customer">
                        <OrderInformationPayment
                          onChooseImageChange={onChooseImageChange}
                          imageUrl={imageUrl}
                          loadingImage={loadingImage}
                          order={order}
                          status={status}
                          mediaUrl={reloadData?.media_id ?? order?.media_id}
                          shouldDisplayData={shouldDisplayData}
                        />
                      </div>
                      <Box className="order-detail-wrapper order__status bg-transparent position-relative">
                        <Button
                          variant={'contained'}
                          sx={{
                            width: '100%',
                            textTransform: 'none'
                          }}
                          onClick={() => handleSendInfoPayment(imageId, order.status)}
                          disabled={shouldDisplayData ?? false}
                        >
                          {isShowIcon && (
                            <Typography
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {__('order_detail_button_confirm')}
                        </Button>
                      </Box>
                    </article>
                  </div>
                </div>
              )}
            <ModalNotification show={show} redirectTo={reloadData?.product_url || '/'}  handleChange={handleChange} />
            <div className="row">
              <div className="col-12 ">
                <article>
                  <div className="table-responsive-lg">
                    <OrderTable
                      className="table-light table"
                      listItem={orders}
                      renderHeader={renderHeaderTable}
                      renderBodyItem={renderBodyItem}
                    />
                  </div>
                  <div className="order__summary row">
                    <div className="col-6 col-lg-8">
                      <p className="order__title" style={{ textAlign: 'end' }}>
                        {__("order_detail_total_payment")}
                      </p>
                    </div>
                    <div className="col-6 col-lg-4">
                      <Typography variant={'subtitle2'} fontWeight={900} className="total_price">
                        {`${__helpers.formatNumberWithCommas(order.price)} ${__("order_unit")}`}
                      </Typography>
                    </div>
                  </div>
                  <div className="order__summary row">
                    <div className="col-6 col-lg-8">
                      <p className="order__title" style={{ textAlign: 'end' }}>
                        {__("order_detail_payment_method")}
                      </p>
                    </div>
                    <div className="col-6 col-lg-4">
                      <div className="order__payments">
                        <p className="order__payments-name">
                          <img src={renderIcon(order.payment_method)} alt="" />
                          <span className="order__title">
                            {hanleShowTextPayment(order.payment_method)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      ))}
    </OrderDetailContainer>
  )
}

export default OrderDetail
const OrderDetailContainer = styled(Box)(({theme})=>({
  '& .order_notification':{
    '& .order_notification_item': {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(0, 1.5),
      border: theme.spacing(1.25),
      ':last-child':{
        color: theme.palette.primary.main,
        '& .order__border':{
          display: 'none'
        }
      }
    }

  },
  '& .order-detail-wrapper': {
    background: theme.palette.background.paper,
    padding: theme.spacing(1.25, 1.5),
    border: theme.spacing(1.25)
  },
  '& .total_price': {
    color: theme.palette.primary.main,
    textAlign: 'end'
  }
}))
