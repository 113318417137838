import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, InputGroup, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import { uploadMedia } from '../calendar/service'
import {
  TypedCreateEvent,
  TypedEvent,
  TypedLevel,
  TypedLivestream
} from '../calendar/calendar.interface'
import {
  createEvent,
  createLivestream,
  getDefaultImage,
  getDetailEvent,
  getListLevel,
  updateEvent,
  updateLivestream
} from '../calendar/calendar.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'
import { useNavigate, useParams } from 'react-router-dom'
import DateTime from 'date-and-time'
import { getEntities } from 'entities/courses/courses.store.reducer'
import SearchSelectComponent from '../calendar/components/search.select.component'
import { calculatorPeriod } from 'entities/helpers/calendar.helpers'
import ImgBlur from 'entities/courses/media/img_blur.png'
import './media/event.update.scss'
import { EnumLiveStreamSource } from 'entities/event/event.interface'
import Title from 'components/Title'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import Capacity from 'components/Capacity'
import BigLayout from '../../layouts/bigLayout'
import { EnumTypeToast, useToast } from '../../hooks/useToast'

interface Props {
  // onCreateEventSuccess: (data: TypedEvent) => void
}

const DAY_OF_WEEK = [
  {
    key: 'monday',
    title: `${__('mon')}`
  },
  {
    key: 'tuesday',
    title: `${__('tue')}`
  },
  {
    key: 'wednesday',
    title: `${__('wed')}`
  },
  {
    key: 'thursday',
    title: `${__('thu')}`
  },
  {
    key: 'friday',
    title: `${__('fri')}`
  },
  {
    key: 'saturday',
    title: `${__('sat')}`
  },
  {
    key: 'sunday',
    title: `${__('sun')}`
  }
]

const AddEvent = (props: Props, ref) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)

  const params = useParams()

  const isUpdate = params && params?.calendar_id

  // useMemo
  const getTime = useMemo(() => {
    const time = new Date().setMinutes(new Date().getMinutes() + 30)
    const hour = new Date(time).getHours().toString().padStart(2, '0')
    const minutes = new Date(time).getMinutes().toString().padStart(2, '0')
    return `${hour}:${minutes}`
  }, [])

  const getDate = useMemo(() => {
    return new Date().toLocaleDateString('en-CA')
  }, [])

  const renderDataTime = useMemo(() => {
    const tempArr = []
    for (let index = 1; index < 49; index++) {
      const item = {
        key: `${index / 2}`,
        value: `${index / 2}`
      }
      tempArr.push(item)
    }
    return tempArr
  }, [])

  // ===============================

  // Refs data
  const titleRef = useRef<string>('')
  const dateRef = useRef<string>(DateTime.format(new Date(), 'YYYY-MM-DD'))
  const hourRef = useRef<string>(getTime)
  const periodRef = useRef<string>('0.5')
  const repeatLaterRef = useRef<string>('1')
  const periodLaterRef = useRef<string>('1')
  const imageRef = useRef(null)
  const pathLocationRef = useRef<string>('zoom')
  const pathRef = useRef<string>('')
  const descriptionRef = useRef<string>('')
  const attendRef = useRef<string>('')
  const endTimeRef = useRef<string>(DateTime.format(new Date(), 'YYYY-MM-DD'))
  const occurrencesRef = useRef<string>('')
  const chooseDayRef = useRef<string[]>([])
  const chooseCourseRef = useRef<string>('')
  const toast = useToast()
  const searchSelectRef = useRef(null)
  const refLivestream = useRef<TypedLivestream>()

  // ===============================

  // Refs input
  const titleInputRef = useRef(null)
  const pathLocationInputRef = useRef(null)
  const pathInputRef = useRef(null)
  const dateInputRef = useRef(null)
  const hourInputRef = useRef(null)
  const periodInputRef = useRef(null)
  const repeatLaterInputRef = useRef(null)
  const periodLaterInputRef = useRef(null)
  const descriptionInputRef = useRef(null)
  const endTimeInputRef = useRef(null)
  const occurrencesInputRef = useRef(null)
  // ===============================

  // State
  const [fixedEvent, setFixedEvent] = useState<boolean>(false)
  const [isHaveLivestream, setIsHaveLivestream] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [isRemind, setIsRemind] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [validate, setValidate] = useState(false)
  const [periodLater, setPeriodLater] = useState<number>(1)
  const [timeEnd, setTimeEnd] = useState<string>('')
  const [attend, setAttend] = useState<string>('')
  const [openDate, setOpenDate] = useState<string>(dateRef.current)
  const [endDate, setEndDate] = useState<string>()
  const [chooseLevel, setChooseLevel] = useState<number>(1)
  const [listLevel, setListLevel] = useState<TypedLevel[]>([])
  const [listCourse, setCourse] = useState([])
  const [loadingImage, setLoadingImage] = useState<boolean>(false)
  const [pathLocation, setPathLocation] = useState<string>('zoom')
  // ===============================

  // useEffect
  useEffect(() => {
    getData()
    if (isUpdate) {
      setTimeout(() => {
        getDataEvent()
      }, 100)
    }
  }, [])

  useEffect(() => {
    if (attend == 'course' && searchSelectRef.current) {
      setTimeout(() => {
        searchSelectRef.current?.show({ list: listCourse })
      }, 500)
    }
  }, [listCourse, attend])

  useEffect(() => {
    dispatch(getDefaultImage())
      .unwrap()
      .then(res => {
        setImageUrl(res?.data?.media_url)
        imageRef.current = { ...res?.data }
      })
      .catch(error => {
        console.log(`getDefaultImage_${error}`)
        setImageUrl('')
        imageRef.current = null
      })
  }, [])
  // ===============================

  const getDataEvent = useCallback(async () => {
    const res: any = await dispatch(getDetailEvent({ _id: params?.calendar_id }))
    const data: TypedEvent = res?.payload?.data
    if (data) {
      // image
      setTimeout(() => {
        setImageUrl(data?.public_album[0]?.media_url)
        imageRef.current = data?.public_album[0]
      }, 100)
      // ==============================
      // title
      setTimeout(() => {
        titleInputRef.current.value = data?.title
      }, 100)
      titleRef.current = data?.title
      // ==============================
      // path location
      setTimeout(() => {
        pathLocationInputRef.current.value = data?.address[0]?.key || 'zoom'
      }, 100)
      pathLocationRef.current = data?.address[0]?.key
      setPathLocation(data?.address[0]?.key || 'zoom')
      // ==============================
      // path
      setTimeout(() => {
        pathInputRef.current.value = data?.address[0]?.value || ''
      }, 100)
      pathRef.current = data?.address[0]?.value
      // ==============================
      // description
      setTimeout(() => {
        descriptionInputRef.current.value = data?.description || ''
      }, 100)
      descriptionRef.current = data?.description
      // ==============================
      // date open
      setTimeout(() => {
        dateInputRef.current.value = new Date(data?.open_date).toLocaleDateString('en-CA') || ''
      }, 100)
      dateRef.current = DateTime.format(new Date(data?.open_date), 'YYYY-MM-DD')
      setOpenDate(DateTime.format(new Date(data?.open_date), 'YYYY-MM-DD'))
      // ==============================
      // hour
      setTimeout(() => {
        hourInputRef.current.value = DateTime.format(new Date(data?.open_date), 'HH:mm') || ''
      }, 100)
      hourRef.current = DateTime.format(new Date(data?.open_date), 'HH:mm')
      // ==============================
      // period
      if (periodInputRef.current) {
        periodInputRef.current.value = data?.duration
      }
      periodRef.current = data?.duration + ''
      // ==============================
      // is_recurring
      setFixedEvent(data?.is_recurring == 1 ? true : false)
      // ==============================
      // attend
      setAttend(data?.permission)
      attendRef.current = data?.permission
      if (data?.event_level > 0) {
        setChooseLevel(data?.event_level)
      }
      if (data?.permission === 'course') {
        chooseCourseRef.current = data?.event_course?._id
        setTimeout(() => {
          console.log('data?.event_course', data?.event_course)

          searchSelectRef.current?.setDefaultValue({
            _id: data?.event_course?._id,
            label: data?.event_course?.title
          })
        }, 500)
      }
      // ==============================
      // end date
      if (data?.end_date && endTimeInputRef.current && data?.end_occurrences == 0) {
        setTimeEnd('date')
        setTimeout(() => {
          endTimeInputRef.current.value = new Date(data?.end_date).toLocaleDateString('en-CA') || ''
        }, 100)
        endTimeRef.current = DateTime.format(new Date(data?.end_date), 'YYYY-MM-DD')
        setEndDate(DateTime.format(new Date(data?.end_date), 'YYYY-MM-DD'))
      }
      if (data?.end_date && data?.end_occurrences > 0) {
        setTimeEnd('occurrences')
        setTimeout(() => {
          occurrencesInputRef.current.value = `${data?.end_occurrences || ''}`
        }, 100)
        occurrencesRef.current = `${data?.end_occurrences}`
      }
      if (!data?.end_date && data?.end_occurrences == 0) {
        setTimeEnd('')
      }
      // ==============================
      // is_remind
      setIsRemind(Boolean(data?.is_remind))
      // ==============================
      // repeat_every
      if (data?.is_recurring == 1) {
        const repeat_every = calculatorPeriod(data?.repeat_every)
        setPeriodLater(repeat_every.period)
        setTimeout(() => {
          repeatLaterInputRef.current.value = repeat_every.repeat || ''
          repeatLaterRef.current = repeat_every.repeat + ''
          periodLaterInputRef.current.value = repeat_every.period || ''
          periodLaterRef.current = repeat_every.period + ''
        }, 100)
        if (Array.isArray(data?.repeat_on) && data?.repeat_on.length > 0) {
          // choose day
          chooseDayRef.current = data?.repeat_on
          // ==============================
        }
      }
      if (data?.livestream_id) {
        setIsHaveLivestream(true)
        refLivestream.current = data?.livestream_id
      }
      // ==============================
    }
  }, [])

  const getData = useCallback(async () => {
    const resLevel: any = await dispatch(getListLevel({}))
    if (Array.isArray(resLevel?.payload?.data) && resLevel?.payload?.data.length > 0) {
      setListLevel(resLevel?.payload?.data)
    }
    const resCourses: any = await dispatch(
      getEntities({ page: 1, limit: 30, auth_id: user?.user_data?._id })
    )
    if (Array.isArray(resCourses.payload?.data) && resCourses.payload?.data.length > 0) {
      setCourse(resCourses.payload?.data)
    }
  }, [])

  const hideModal = useCallback(() => {
    navigate(-1)
    setLoading(false)
  }, [])

  const _onTitleChange = useCallback(event => {
    titleRef.current = event.target.value.trim()
    if (event.target.value.trim()) {
      titleInputRef.current.value = event.target.value
    } else {
      titleInputRef.current.value = event.target.value.trim()
    }
  }, [])

  const _onDateChange = useCallback(
    event => {
      dateRef.current = event.target.value
      setOpenDate(event.target.value)
      if (fixedEvent) {
        endTimeInputRef.current.value = event.target.value
        endTimeRef.current = event.target.value
      }
    },
    [fixedEvent]
  )

  const _onHourChange = useCallback(event => {
    hourRef.current = event.target.value
  }, [])

  const _onPeriodChange = useCallback(event => {
    periodRef.current = event.target.value
  }, [])

  const _onFixedEventChange = useCallback(event => {
    setFixedEvent(prev => !prev)
    setPeriodLater(1)
  }, [])

  const _onRepeatLaterChange = useCallback(event => {
    repeatLaterRef.current = event.target.value
  }, [])

  const _onPeriodLaterChange = useCallback(event => {
    periodLaterRef.current = event.target.value
    setPeriodLater(event.target.value)
  }, [])

  const _onPathLocationChange = useCallback(event => {
    pathLocationRef.current = event.target.value
    setPathLocation(event.target.value)
  }, [])

  const _onPathChange = useCallback(event => {
    pathRef.current = event.target.value
  }, [])

  const _onDescriptionChange = useCallback(event => {
    descriptionRef.current = event.target.value
  }, [])

  const _onAttendChange = useCallback(event => {
    attendRef.current = event.target.value
    setAttend(event.target.value)
  }, [])

  const _onEndChange = useCallback(event => {
    setTimeEnd(event.target.value)
  }, [])
  const _onRemindChange = useCallback(() => {
    setIsRemind(prev => !prev)
  }, [])
  const _onHaveLivestreamChange = useCallback(() => {
    setIsHaveLivestream(prev => !prev)
  }, [])

  const _onLevelChange = useCallback(event => {
    setChooseLevel(Number(event.target.value))
  }, [])

  const _onEndTimeChange = useCallback(event => {
    endTimeRef.current = event.target.value
    setEndDate(event.target.value)
  }, [])

  const _onOccurrencesChange = useCallback(event => {
    occurrencesRef.current = event.target.value
  }, [])

  const _onSelectCourseChange = useCallback(data => {
    chooseCourseRef.current = data?.value || ''
  }, [])

  const _onChooseDayChange = useCallback(event => {
    const check = chooseDayRef.current.find(i => i == event.target.value)
    if (check) {
      chooseDayRef.current = chooseDayRef.current.filter(i => i != event.target.value)
    } else {
      chooseDayRef.current.push(event.target.value)
    }
  }, [])

  const _onChooseImageChange = useCallback(async event => {
    if (event.target.files[0]) {
      setLoadingImage(true)
      setImageUrl(ImgBlur)
      const link = URL.createObjectURL(event.target.files[0])

      try {
        const res: any = await uploadMedia(event.target.files[0])

        if (Array.isArray(res) && res.length > 0) {
          imageRef.current = res[0]?.callback
        }
      } catch (error) {
        console.log('error_234234', error)
      }
      setImageUrl(link)
      setLoadingImage(false)
    }
  }, [])

  const _onCreateEvent = async event => {
    try {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        event.preventDefault()
        event.stopPropagation()
        setLoading(true)

        let data: TypedCreateEvent = {
          title: titleRef.current.trim(),
          open_date: new Date(`${dateRef.current} ${hourRef.current}`).toISOString(),
          duration: `${periodRef.current}`,
          permission: attendRef.current == 'all' ? '' : attendRef.current,
          is_remind: `${isRemind ? 1 : 0}`,
          is_recurring: `${fixedEvent ? 1 : 0}`,
          description: descriptionRef.current,
          repeat_on: periodLater == 7 ? JSON.stringify(chooseDayRef.current) : JSON.stringify([]),
          end_date: timeEnd == '' ? '' : endTimeRef.current,
          end_occurrences: timeEnd == 'occurrences' ? occurrencesRef.current : '0',
          repeat_every: `${Number(periodLater) * Number(repeatLaterRef.current)}`,
          public_album: JSON.stringify([imageRef.current?._id]),
          address: JSON.stringify([
            { key: pathLocationRef.current.toLowerCase(), value: pathRef.current }
          ]),
          event_level: (attend == 'level' && chooseLevel.toString()) || undefined,
          event_course: (attend == 'course' && chooseCourseRef.current.trim()) || undefined
        }

        if (isHaveLivestream) {
          let dataLivestream = {
            avatar: imageRef.current?._id,
            title: titleRef.current.trim(),
            caption: descriptionRef.current.trim(),
            start_time: new Date(`${dateRef.current} ${hourRef.current}`).toISOString()
          }
          if (refLivestream.current?._id) {
            let resLivestream: any
            resLivestream = await dispatch(
              updateLivestream({ ...dataLivestream, _id: refLivestream.current?._id })
            )
            if (!resLivestream?.payload?.data?._id) {
              toast.show({
                title: `${__('notification')}`,
                type: EnumTypeToast.Error,
                content: `${__('event_text_error')}`
              })
              setLoading(false)
              return
            }
          } else {
            let resLivestream: any
            resLivestream = await dispatch(
              createLivestream({ ...dataLivestream, input_type: EnumLiveStreamSource.Camera })
            )

            if (resLivestream?.payload?.data?._id) {
              data.livestream_id = resLivestream?.payload?.data?._id
            } else {
              toast.show({
                title: `${__('notification')}`,
                type: EnumTypeToast.Error,
                content: `${__('event_text_error')}`
              })
              setLoading(false)
              return
            }
          }
        } else {
          if (refLivestream.current?._id) {
            data.livestream_id = null
          }
        }

        let res: any = null
        if (isUpdate) {
          data = { ...data, _id: params?.calendar_id }
          res = await dispatch(updateEvent(data))
        } else {
          res = await dispatch(createEvent(data))
        }

        if (res?.payload?.data?._id) {
          if (isUpdate) {
            toast.show({
              title: `${__('notification')}`,
              type: EnumTypeToast.Success,
              content: `${__('event_text_success')}`
            })
          }
          setTimeout(() => {
            navigate('/event')
          }, 500)
        } else {
          toast.show({
            title: `${__('notification')}`,
            type: EnumTypeToast.Error,
            content: `${__('event_text_error')}`
          })
        }
        setLoading(false)
      }
      setValidate(true)
    } catch (error) {
      toast.show({
        title: `${__('notification')}`,
        type: EnumTypeToast.Error,
        content: `${__('event_text_error')}`
      })
      setLoading(false)
    }
  }

  const renderPlaceHolderPath = useMemo(() => {
    if (pathLocation == 'meet') {
      return `${__('event_google_meet')}`
    }
    if (pathLocation == 'address') {
      return `${__('event_path_address')}`
    }
    if (pathLocation == 'link') {
      return `${__('event_link')}`
    }
    return `${__('event_zoom')}`
  }, [pathLocation])

  // ===============================

  return (
    <BigLayout>
      <div id={'event-add'}>
        <_Helmet title={isUpdate ? `${__('event_update')}` : `${__('event_create')}`} />
        <div>
          <div className="bg-white container py-3">
            <Form
              className="needs-validation"
              noValidate
              validated={validate}
              onSubmit={_onCreateEvent}
            >
              <Title text={`${isUpdate ? `${__('event_update')}` : `${__('event_create')}`} `} />
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group className="my-3">
                    <div className="file-drop-area form-group form-upload-image-event ">
                      <div className="text-lg-center w-100">
                        <h6 className="">{__('event_chose_image')}</h6>
                        <h6 className="file-message mt-3">{__('event_chose_photo_sub_text')}</h6>
                      </div>
                      <Form.Control
                        className="file-input"
                        type="file"
                        id="image"
                        accept=".png,.jpg,.jpeg,.gif"
                        required={imageUrl ? false : true}
                        onChange={_onChooseImageChange}
                      />
                      <div className="error-image invalid-feedback">
                        {__('event_chose_size_photo')}
                      </div>
                      {imageUrl ? (
                        <Image
                          id="target"
                          className={`choose_file_image choose-file-button fit-image`}
                          src={imageUrl}
                          alt="placeholder"
                        />
                      ) : null}
                      {imageUrl ? (
                        !loadingImage ? (
                          <div className="view-bottom-image">{__('media_form_sub_text')}</div>
                        ) : null
                      ) : null}
                      <div>
                        <Form.Label className="event-update-label">
                          {__('event_image_cover')}
                        </Form.Label>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6" className="p-3">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="title">{__('event_name')}</Form.Label>
                    <Form.Control
                      maxLength={100}
                      onChange={_onTitleChange}
                      type="search"
                      ref={titleInputRef}
                      id="title"
                      required
                      placeholder={__('event_name_placeholder')}
                    />
                    <div className="invalid-feedback">{__('event_name_text')}</div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="description">{__('event_information')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={7}
                      maxLength={10000}
                      ref={descriptionInputRef}
                      placeholder={__('event_information_placeholder')}
                      onChange={_onDescriptionChange}
                      required
                      id="description"
                    />
                    <div className="invalid-feedback">{__('event_information_text')}</div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="date">{__('event_start_time')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        ref={dateInputRef}
                        type={'date'}
                        className="form-control"
                        lang="vi"
                        onChange={_onDateChange}
                        // required
                        id="date"
                        defaultValue={getDate}
                        min={DateTime.format(new Date(), 'YYYY-MM-DD')}
                      />
                      <Form.Control
                        ref={hourInputRef}
                        className="form-control"
                        type={'time'}
                        onChange={_onHourChange}
                        required
                        id="time"
                        defaultValue={getTime}
                      />
                      <div className="invalid-feedback">{__('event_start_time_error_text')}</div>
                    </InputGroup>
                  </Form.Group>
                  <div className="mb-3 row">
                    <Form.Group>
                      <Form.Label htmlFor="period">{__('event_period_time_label')}</Form.Label>

                      <Form.Select
                        aria-label={__('event_chose_period_time')}
                        ref={periodInputRef}
                        onChange={_onPeriodChange}
                        required
                        id="period"
                      >
                        {/* <option>Chọn khoảng thời gian</option> */}
                        {renderDataTime.map(item => {
                          return (
                            <option key={item?.key} value={item?.key}>
                              {item?.value}
                            </option>
                          )
                        })}
                      </Form.Select>
                      <div className="invalid-feedback">{__('event_period_time_error_text')}</div>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="path">{__('event_address')}</Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Select
                        aria-label={__('event_select_address')}
                        ref={pathLocationInputRef}
                        onChange={_onPathLocationChange}
                        id="pathLocation"
                      >
                        <option value={'zoom'}>{__('event_zoom')}</option>
                        <option value={'meet'}>{__('event_google_meet')}</option>
                        <option value={'address'}>{__('event_path_address')}</option>
                        <option value={'link'}>{__('event_link')}</option>
                      </Form.Select>
                      <Form.Control
                        placeholder={renderPlaceHolderPath}
                        ref={pathInputRef}
                        onChange={_onPathChange}
                        id="path"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-event"
                    label={__('event_fixed_label')}
                    checked={fixedEvent}
                    onChange={_onFixedEventChange}
                  />
                  {fixedEvent && (
                    <>
                      <Row className="mb-3">
                        <Form.Group as={Col}>
                          <Form.Label>{__('event_loop_events')}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type={'number'}
                            defaultValue={'1'}
                            ref={repeatLaterInputRef}
                            onChange={_onRepeatLaterChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>{__('event_period')}</Form.Label>
                          <Form.Select
                            ref={periodLaterInputRef}
                            aria-label={__('event_chose_period_time')}
                            onChange={_onPeriodLaterChange}
                          >
                            <option value={1}>{__('event_date')}</option>
                            <option value={7}>{__('event_week')}</option>
                            <option value={30}>{__('event_month')}</option>
                            <option value={365}>{__('event_year')}</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      {periodLater == 7 && (
                        <Form.Group>
                          <Form.Label>{__('event_loop')}</Form.Label>
                          <div className="container">
                            <Row>
                              {DAY_OF_WEEK.map(item => {
                                const checked = chooseDayRef.current.find(i => i === item.key)
                                return (
                                  <Form.Check
                                    className="mb-2"
                                    defaultChecked={!!checked}
                                    id={item?.key}
                                    key={item?.key}
                                    type="checkbox"
                                    onChange={_onChooseDayChange}
                                    label={item?.title}
                                    value={item?.key}
                                  />
                                )
                              })}
                            </Row>
                          </div>
                        </Form.Group>
                      )}
                      <Form.Group>
                        <Form.Label>{__('event_loop_close')}</Form.Label>
                        <InputGroup className="mb-2">
                          <InputGroup.Radio
                            id="never"
                            checked={timeEnd == ''}
                            onChange={_onEndChange}
                            value=""
                            name={'group1'}
                          />
                          <InputGroup.Text>{__('event_loop_never')}</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <InputGroup.Radio
                            checked={timeEnd == 'date'}
                            onChange={_onEndChange}
                            value="date"
                            name={'group1'}
                          />
                          <Form.Control
                            required={timeEnd == 'date'}
                            disabled={timeEnd != 'date'}
                            ref={endTimeInputRef}
                            type="date"
                            defaultValue={openDate}
                            onChange={_onEndTimeChange}
                            min={openDate}
                          />
                          <div className="invalid-feedback">{__('event_radio_error_text')}</div>
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <InputGroup.Radio
                            checked={timeEnd == 'occurrences'}
                            onChange={_onEndChange}
                            value="occurrences"
                            name={'group1'}
                          />
                          <Form.Control
                            min={0}
                            required={timeEnd == 'occurrences'}
                            ref={occurrencesInputRef}
                            disabled={timeEnd != 'occurrences'}
                            type="number"
                            placeholder={__('event_number_loop')}
                            onChange={_onOccurrencesChange}
                          />
                          <div className="invalid-feedback">{__('event_appear_error_text')}</div>
                        </InputGroup>
                        <InputGroup></InputGroup>
                      </Form.Group>
                    </>
                  )}
                </Col>
                <Col xs="12" md="6" className="p-3 rounded-3">
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label htmlFor="attend">{__('event_people_join_label')}</Form.Label>
                    <Form.Check
                      label={__('event_total_member')}
                      name="attend"
                      value={''}
                      type="radio"
                      id="all"
                      checked={attend == ''}
                      onChange={_onAttendChange}
                      className="mb-2"
                    />
                    <Form.Check
                      label={__('event_level_member')}
                      name="attend"
                      value={'level'}
                      type="radio"
                      id="level"
                      checked={attend == 'level'}
                      onChange={_onAttendChange}
                      className="mb-2"
                    />
                    {attend == 'level' && (
                      <Form.Group className="mb-3 px-3">
                        {Array.isArray(listLevel) &&
                          listLevel.length > 0 &&
                          listLevel.map(item => {
                            return (
                              <Form.Check
                                className="mb-2"
                                key={item?._id}
                                checked={chooseLevel == item?.level_number}
                                id={item?._id}
                                onChange={_onLevelChange}
                                type="radio"
                                label={`Level ${item?.level_number} - ` + item?.title}
                                value={item?.level_number}
                              />
                            )
                          })}
                      </Form.Group>
                    )}
                    <Form.Check
                      label={__('event_member_into_course')}
                      name="attend"
                      value={'course'}
                      type="radio"
                      id="course"
                      checked={attend == 'course'}
                      onChange={_onAttendChange}
                      className="mb-2"
                    />
                  </Form.Group>

                  {attend == 'course' && (
                    <Form.Group className="mb-3">
                      <SearchSelectComponent
                        ref={searchSelectRef}
                        required={attend == 'course'}
                        onChange={_onSelectCourseChange}
                      />
                    </Form.Group>
                  )}
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    checked={isRemind}
                    onChange={_onRemindChange}
                    label={__('event_remind_member')}
                  />

                  {/*<Form.Group className="mb-3 eventupdate_livestream_container" id={'livestream'}>*/}
                  {/*  <Form.Label htmlFor="livestream" className="fw-bold">*/}
                  {/*    {__("event_livestream")}*/}
                  {/*  </Form.Label>*/}
                  {/*  <Form.Check // prettier-ignore*/}
                  {/*    type="switch"*/}
                  {/*    id="custom-switch-livestream"*/}
                  {/*    checked={isHaveLivestream}*/}
                  {/*    onChange={_onHaveLivestreamChange}*/}
                  {/*    label={__("event_label_livestream")}*/}
                  {/*  />*/}
                  {/*</Form.Group>*/}
                </Col>
              </Row>

              <Capacity current_user_can={'boss'}>
                <div className="d-flex justify-content-end my-4">
                  <Button variant="secondary" onClick={hideModal}>
                    {__('event_back')}
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-3 button-event"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">{__('loading')}</span>
                      </>
                    )}
                    {isUpdate ? `${__('event_btn_edit')}` : `${__('event_create')}`}
                  </Button>
                </div>
              </Capacity>
            </Form>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </BigLayout>
  )
}

export default AddEvent
