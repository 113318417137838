import { Box, Button, Popover, styled } from '@mui/material'
import React from 'react'

const PopoverComponent = ({
  children,
  renderChildren
}: {
  renderChildren: (setOpen?: Function) => React.ReactNode
  children: React.ReactElement
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <PopperContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ zIndex: 1, marginTop: theme => `${theme.spacing(1.5)} !important` }}
      >
        {renderChildren(handleClose)}
      </Popover>
      <Button sx={{ p: 0, minWidth: 'unset' }} onClick={handleClick} variant="text">
        {children}
      </Button>
    </PopperContainer>
  )
}

export default PopoverComponent

const PopperContainer = styled(Box)(({ theme }) => ({}))
