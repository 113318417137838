import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import __ from 'languages/index'
import RenderView from 'components/RenderView'
import { PlusIcon } from '../../../icons'
import { StyledTableCell } from 'entities/channel-settings/components/StyledTableCell'
import RowActions from 'entities/channel-settings/components/RowActions'
import { TypedCategory } from '../../../interfaces/request.interface'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import Select from 'components/Select'
import DragAndDropIcon from '../../../icons/DragAndDropIcon'
import TableDrapDrop from 'entities/channel-settings/components/TableDrapDrop'
import { EnumCategory, optionCategoryStatus } from 'entities/channel-settings/post-category/categoryConfig'
import __helpers from 'helpers/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { useUpdateCategoryMutation } from 'entities/homeNew/store/homeApi'
import { formatString } from 'entities/support/utils'
import {  updateCategoryNews } from 'entities/news/services'
import { updateCategoryCourse } from 'entities/channel-settings/service'

interface TypeCategoryTable{
  listCategory: any
  categoryType: {
    key: EnumCategory
    title: string
  }
  openAddForm: (type: string)=> void
  setSelectedCategory?: React.Dispatch<React.SetStateAction<any>>
  selectedCategory?: any
  openDeleteForm?: (category: TypedCategory, type: EnumCategory)=> void
  getCategoryDisplay: (value: EnumCategory) => void
  isLoading?: boolean
  isFetching?: boolean
}

const CategoryTable = ({categoryType, openAddForm, setSelectedCategory,openDeleteForm, selectedCategory,getCategoryDisplay,listCategory,isLoading }: TypeCategoryTable)=>{
  const [categories, setCartegories] = useState<TypedCategory[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [updateCategory] = useUpdateCategoryMutation()
  const toast = useToast()

  useEffect(() => {
    if(!categoryType?.key) return
    getCategoryDisplay(categoryType?.key)
  }, [categoryType?.key, isFetching])



  useEffect(() => {
    setCartegories(listCategory)
  }, [listCategory,isFetching])

  const handleOnChangeDisplay = (value: string)=> {
    setSelectedCategory({
      action: 'Edit',
      payload: {
        ...selectedCategory?.payload,
        category_visibility: value
      }
    })
  }

  const renderStatus = (category_visibility: number)=>{
    return  optionCategoryStatus?.find(status => category_visibility === status?.key)?.value
  }

  const handleUpdateCategory = useCallback(async () => {
    try {
      if(categoryType?.key === EnumCategory.POST){
        await updateCategory({
          _id: selectedCategory.payload._id,
          public_status: selectedCategory?.payload?.category_visibility,
          category_title: selectedCategory?.payload?.category_title,

        }).unwrap()
          .then(res => {
            setIsFetching(true)
            setCartegories([...categories.map(item => ( item?._id === res?._id ? {...res, category_visibility: res?.public_status} : item))])
            setSelectedCategory(null)
            toast.show({
              type: EnumTypeToast.Success,
              content: formatString(__('updated_category_successfully'), categoryType?.title)
            })
          })
      }
      if(categoryType?.key === EnumCategory.COURSE){
       await updateCategoryCourse({
          _id: selectedCategory.payload._id,
           course_category_title: selectedCategory?.payload?.category_title,
           category_visibility: selectedCategory?.payload?.category_visibility
       })
         .then(res=>{
           setIsFetching(true)
           setCartegories([...categories.map(item => ( item?._id === res?.data?._id ? {...res?.data,category_title: res?.data?.course_category_title} : item))])
           setSelectedCategory(null)
           toast.show({
             content: formatString(__('updated_category_successfully'), categoryType?.title),
             type: EnumTypeToast.Success
           })
         })
         .catch(error => {
           console.log('updateCategoryCourse error', error)
           toast.show({
             content: `${error}}`,
             type: EnumTypeToast.Error
           })
         })
      }
      if(categoryType?.key === EnumCategory.NEWS){
        await updateCategoryNews({
          _id: selectedCategory.payload._id,
          category_visibility: selectedCategory?.payload?.category_visibility,
          news_category_name: selectedCategory?.payload?.category_title

        })
          .then(res => {
            setIsFetching(true)
            setCartegories([...categories.map(item => ( item?._id === res?.data?._id ? {...res?.data, category_title: res?.data?.news_category_name} : item))])
            setSelectedCategory(null)
            toast.show({
              content: formatString(__('updated_category_successfully'), categoryType?.title),
              type: EnumTypeToast.Success
            })
          })
          .catch(error => {
            console.log('updateCategoryNews error', error)
            toast.show({
              content: `${error}}`,
              type: EnumTypeToast.Error
            })
          })
      }
      setIsFetching(false)
      } catch (err) {
      console.log(err)
    }
  },[categoryType,selectedCategory])

  const renderHeader = () => {
    return (
      <TableRow
        sx={{
          width: '100%'
        }}
      >
        <StyledTableCell>{__("channel_setting_table_category_stt")}</StyledTableCell>
        <StyledTableCell>{__("channel_setting_table_category")}</StyledTableCell>
        <StyledTableCell>{__("channel_setting_table_display")}</StyledTableCell>
        <StyledTableCell  sx={{ textAlign: 'end'}}>{__("channel_setting_table_action")}</StyledTableCell>
      </TableRow>
    )
  }

  const renderBodyRow = (item: TypedCategory, index: number) => {
    return (
      <>
        <TableCell
          sx={{textAlign: 'center'}}

        >
          <Typography variant={'body1'} sx={{fontWeight: 600, textTransform: 'none'}}>
            <DragAndDropIcon
              sx={{
                width: theme => theme.spacing(3),
                height: theme => theme.spacing(3),
                marginRight: theme => theme.spacing(1)
              }}
            />
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell>
          {selectedCategory?.action === 'Edit' && item?._id === selectedCategory?.payload?._id ? (
            <TextField
              sx={{
                maxWidth: 'fit-content'
              }}
              size="small"
              value={selectedCategory?.payload?.category_title}
              onChange={e => {
                setSelectedCategory({
                  action: 'Edit',
                  payload: {
                    ...selectedCategory?.payload,
                    category_title: e.target.value
                  }
                })
              }}
              autoFocus
              label={__('channel_setting_table_category_name')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                bgcolor: theme => theme.palette.background.default,
                width: 'fit-content'
              }}
            >
              <Typography>{item?.category_title}</Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {selectedCategory?.action === 'Edit' && item?._id === selectedCategory?.payload?._id ? (
            <Select
              onChangeValue={handleOnChangeDisplay}
              value={selectedCategory?.payload?.category_visibility}
              options={optionCategoryStatus}
              getItemValue={item => item?.key}
              renderItem={item => <Typography>{item?.value}</Typography>}
              inputProps={{
                sx:{
                  padding: theme => theme.spacing(1.5, 1.5, 0.5),
                }
              }}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ whiteSpace: "nowrap" }}>
                {renderStatus(item?.category_visibility ?? 1)}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => setSelectedCategory({
              action: 'Edit',
              payload: item
            })}
            editing={selectedCategory?.action === 'Edit' && item?._id === selectedCategory?.payload?._id}
            onClickDeleteBtn={() => openDeleteForm(item,categoryType?.key)}
            onConfirm={handleUpdateCategory}
          />
        </TableCell>
      </>
    )
  }

  return(
    <ChannelSettingsContentContainer>
      <Stack gap={2}>
        <ChannelSettingsTitle>{categoryType?.title}</ChannelSettingsTitle>
          <RenderView
            view={
              <TableDrapDrop
                data={categories}
                setData={setCartegories}
                renderTableRow={renderBodyRow}
                renderHeader={renderHeader}
              />
            }
            isLoading={isLoading}
            isEmpty={Boolean(!categories || __helpers.isEmpty(categories))}
          />
        <Button
          sx={{ mr: 'auto' , textTransform: 'none'}}
          startIcon={<PlusIcon />}
          onClick={()=>openAddForm(categoryType?.key)}
        >
          {__('channel_setting_add_category')}
        </Button>
      </Stack>
    </ChannelSettingsContentContainer>
  )
}
export default CategoryTable
