import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ActivityType,
  ChallengeListUserResponse,
  CreateActivityPayload,
  CreateNewNotificationRequest,
  CreateNewNotificationResponse,
  NotificationType,
  TypedChallengeItem,
  UpdatePermissionPayload
} from '../challenge.interface'
import { TypedDataMember } from 'interfaces/member.interface'
import { IQueryParams } from 'config/reducer.utils'
import { TabPaneChallengeData } from '../component/challenge.list/challenge.list.component'

// const challengeApi = axios.create({
//   baseURL: process.env.REACT_APP_AJAX_URL + '/challenge',
//   headers: {
//     ['X-Authorization']: `${localStorage.getItem('session')}`
//   }
// })

export const getJoinedUsers = async (
  options: AxiosRequestConfig
): Promise<AxiosResponse<ChallengeListUserResponse[]>> => {
  const url = 'challenge/list-permission'
  const res = await axios.get(url, options)
  return res
}

export const inviteUsersJoinChallenge = async (challengeId: string, userIds: string[]) => {
  const url = 'challenge/create-permission'
  const data = {
    challenge_id: challengeId,
    user_id: userIds.join(','),
    official_status: '1'
  }
  return axios.post(url, data)
}

export const getListChallenge = async (
  params: IQueryParams & {
    challenge_status?: TabPaneChallengeData
  }
) => {
  const requestUrl = `challenge/list`
  return await axios.get<TypedChallengeItem[]>(requestUrl, { params: params })
}

export const removeUserFromChallenge = async (challengeId: string, userId: string) => {
  const url = 'challenge/delete-permission'
  return axios.delete(url, {
    data: {
      challenge_id: challengeId,
      user_id: userId
    }
  })
}

export const createNewNotify = (notification: CreateNewNotificationRequest) => {
  const url = 'challenge/create-notification'
  const reqData = { ...notification, public_album: JSON.stringify(notification.public_album) }
  return axios.post<CreateNewNotificationRequest, AxiosResponse<CreateNewNotificationResponse>>(
    url,
    reqData
  )
}

export const getListNotification = (options?: AxiosRequestConfig) => {
  const url = 'challenge/list-notification'
  return axios.get<NotificationType[]>(url, options)
}

export const getListPermission = async (options?: AxiosRequestConfig) => {
  const url = `challenge/ranking/${options?.params?.challenge_id}`
  return axios.get(url, options)
}

export const getActivities = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ActivityType[]>> => {
  const url = 'challenge/list-activity'
  return axios.get(url, options)
}

export const updateActivity = (payload: CreateActivityPayload) => {
  const url = 'challenge/update-activity'
  return axios.patch(url, payload)
}

export const getChannelUsers = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TypedDataMember[]>> => {
  const challenge_id = options?.params?.challenge_id
  delete options?.params?.challenge_id
  const url = `/challenge/list-available-member/${challenge_id}`
  return axios.get(url, options)
}

export const updatePermission = async (payload: UpdatePermissionPayload) => {
  const url = 'challenge/update-permission'
  return axios.patch(url, payload)
}

export const getImageDefaultChallenge = async () => {
  const url = `settings/default-avatar/challenge`
  return axios.get(url)
}
