import React, { ReactNode } from 'react'
import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow, Box
} from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

type Props<T extends { _id: string }> = {
  data: T[]
  renderHeader?: () => ReactNode
  renderTableRow?: (item: T, index?: number) => ReactNode
  setData: React.Dispatch<React.SetStateAction<T[]>>
  onClickRow?: (item: T) => void
}

const TableDrapDrop= <T extends { _id: string },>({ data, renderHeader, renderTableRow, setData, onClickRow }: Props<T>) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
  };
  return (
    <Paper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tasks">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              <TableContainer>
                <MuiTable size="small">
                  {renderHeader && <TableHead>{renderHeader()}</TableHead>}
                  <TableBody>
                    {data?.map((item, index) => (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          onClickRow ?
                            <TableRow
                            onClick={()=>onClickRow(item)}
                            key={index}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {renderTableRow(item, index)}
                          </TableRow> :
                            <TableRow
                            key={index}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {renderTableRow(item, index)}
                          </TableRow>


                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>

            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  )
}

export default TableDrapDrop
