import { Box, Stack, Typography, styled } from '@mui/material'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import BigLayout from 'layouts/bigLayout'
import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getNewsBySearch } from './services'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { TypedNews } from './interface'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import NewsItem from './Components/news.item'
import SearchComponent from 'components/SearchComponent'
import { formatString } from 'entities/support/utils'

const NewsSearch = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    data,
    paramRequest,
    totalItems,
    setParamRequest,
    hasMore,
    isRefreshing,
    loadMore,
    refresh
  } = useInfiniteScroll<TypedNews>(
    {
      query: new URLSearchParams(location.search).get('query') || '',
      keyAttribute: '_id'
    },
    getNewsBySearch,
    true
  )

  const handleChangeSearch = useCallback(
    (value: string) => {
      if (!value) return navigate('/news', { replace: true })
      setParamRequest(paramRequest => ({ ...paramRequest, query: value }))
      navigate(`/news/search?query=${value}`, { replace: true })
    },
    [paramRequest]
  )

  useEffect(() => {
    if (new URLSearchParams(location.search).get('query')) return
    navigate('/news', { replace: true })
  }, [location])

  return (
    <BigLayout>
      <_Helmet title="news" />
      <NewsSearchContainer>
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h5" textTransform={'none'} fontWeight={700}>
            {__('news')}
          </Typography>
          <Stack direction={'row'} gap={2}>
            <SearchComponent
              placeholder={__('search_for_news')}
              value={paramRequest?.query || ''}
              onChange={handleChangeSearch}
            />
          </Stack>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography variant="subtitle1" textTransform={'none'} color="text.secondary">
            {formatString(__('news_title_search'), totalItems)}
          </Typography>
          <Typography variant="h5" color="primary.main" textTransform={'none'} fontWeight={700}>
            {`"${paramRequest?.query || ''}"`}
          </Typography>
        </Stack>

        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
          textEmptyMessage=""
          showEndMessage={false}
          pullDownToRefresh={false}
        >
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 1fr'} gap={3}>
            {data.map((item: TypedNews, idx: number) => (
              <React.Fragment key={idx}>
                <NewsItem newsData={item} />
              </React.Fragment>
            ))}
          </Box>
        </InfiniteScrollContainer>
      </NewsSearchContainer>
    </BigLayout>
  )
}

export default NewsSearch

const NewsSearchContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3)
}))
