import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { Box, Button, Divider, IconButton, Modal, Stack, styled, Typography } from '@mui/material'
import __ from 'languages/index'
import React, { useCallback, useEffect, useState } from 'react'
import TextField from 'components/TextFieldMUI'
import { CloseIcon, PlusIcon } from '../../../icons'
import { TypeCourseOfflineTime } from 'entities/channel-settings/post-category/CoursesOfflineSetting'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { validateLabel } from 'entities/support/utils'
import {
  courseOfflineUpdate,
  createCourseOffline,
} from 'entities/channel-settings/store/channel.slice'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { useAppDispatch } from 'config/store'
import { TypeCoursesOffline, TypeOfflineTime } from 'entities/channel-settings/service/service.type'
import __helpers from 'helpers/index'
import { formatDate } from 'entities/support/components/TicketItem'


interface TypeAddEditCourseOfflineModal{
  showModal: boolean
  handleCloseModal: ()=>void
  handleOpenAddTime: ()=> void
  handleDeleteTimeItem: (value: {year: string, month: string})=> void
  coursesOfflineTime: Partial<TypeCourseOfflineTime>[]
  updateCourseOffline: {
    type: 'EDIT' | 'ADD' | 'VIEW'
    value: TypeCoursesOffline
  }
  getListCourse: ()=>void
  isValidate: boolean
}
const AddEditCourseOfflineModal =({showModal,handleCloseModal,handleOpenAddTime, coursesOfflineTime, handleDeleteTimeItem,updateCourseOffline,getListCourse,isValidate}:TypeAddEditCourseOfflineModal)=>{
  const {type, value} = updateCourseOffline

  const dispatch = useAppDispatch()
  const toast = useToast()

  const { fields, submit, dirty, submitting,reset } = useForm({
    fields: {
      course_offline_title: useField<string>({
        value: type ==='EDIT' || type === 'VIEW' ? value?.course_offline_title : '',
        validates: [notEmpty(validateLabel(`${__('course_offline_modal_name')}`)?.required)]
      }),
      course_offline_time: useField<Partial<TypeOfflineTime>[]>({
        value: [],
        validates: [
          notEmpty(validateLabel(`${__('course_offline_modal_time')}`)?.required)
        ]
      }),
      course_offline_code: useField<string>({
        value: type ==='EDIT' || type === 'VIEW' ? value?.course_offline_code :'',
        validates: [notEmpty(validateLabel(`${__('course_offline_modal_code')}`)?.required)]
      })
    },
    async onSubmit(values) {
      try {
        if(type === 'ADD'){
          dispatch(
            createCourseOffline(values)
          )
            .unwrap()
            .then(res=>{
              reset()
              handleCloseModal()
              getListCourse()
              toast.show({
                content: `${__('channel_setting_update_info_success')}`,
                type: EnumTypeToast.Success
              })
            })
            .catch(err => {
              console.log('err',err)
              toast.show({
                content: `${__('channel_setting_update_info_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        } else if (type ==='EDIT'){
          const formData = {
            _id: value?._id,
            course_offline_code: values?.course_offline_code,
            course_offline_title: values?.course_offline_title,
            course_offline_time: values?.course_offline_time
          }
          dispatch(
            courseOfflineUpdate(formData)
          )
            .unwrap()
            .then(res=>{
              reset()
              handleCloseModal()
              getListCourse()
              toast.show({
                content: `${__('channel_setting_update_info_success')}`,
                type: EnumTypeToast.Success
              })
            })
            .catch(err => {
              console.log('err',err)
              toast.show({
                content: `${__('channel_setting_update_info_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        }
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })


  useEffect(()=>{
    if(__helpers.isEmpty(coursesOfflineTime)) {
      return fields?.course_offline_time.onChange([])
    }
    const datesList = [...coursesOfflineTime]
    const formattedDates = datesList.map(dateObj => {
      const year = dateObj.year;
      const dates = dateObj.month.map(month => {
        const monthIndex = parseInt(month?.value.split(" ")[1]) - 1;
        const formattedMonth = String(monthIndex + 1).padStart(2, '0');
        const date = value?.course_offline_time?.find(item => {
          return  item?.time ===  new Date(`${year}-${formattedMonth}`).toISOString()
        } )
        return  date ? date : {time: new Date(`${year}-${formattedMonth}`).toISOString()}
      });
      return dates;
    }).flat();

    fields?.course_offline_time.onChange(formattedDates)

  },[coursesOfflineTime])

  console.log('coursesOfflineTime',coursesOfflineTime)

  useEffect(() => {
    reset()
  }, [])

  return(
    <AddEditCourseOfflineModalContainer
      open={showModal}
      onClose={handleCloseModal}
    >
      <ModalContainer
        sx={{
          width: '60%',
          maxWidth: theme => theme.spacing(95.75),
          margin: '0 auto'
        }}
        component={'form'}
        onSubmit={(e) =>{
          e.preventDefault()
          submit()
        }}
      >
        <ModalHeader direction={'row'}>
          <Typography variant={'h6'} className={'title'} textTransform={'none'}>
            {__('course_offline_modal_title')}
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <Divider />
        <ModalBody
          sx={{
            maxHeight: 'calc(100% - 80px)',
            overflowY: 'auto'
          }}
        >
          <Stack
            component={'form'}
            rowGap={2}

          >
            <TextField
              disabled={type === 'VIEW' }
              {...fields?.course_offline_code}
              placeholder={__("course_offline_modal_code")}
              autoFocus
            />
            <TextField
              disabled={type === 'VIEW' }
              {...fields.course_offline_title}
              placeholder={__("course_offline_modal_name")}
            />
            <Stack rowGap={2}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}

              >
                <Typography color={'text.secondary'}>{__("course_offline_modal_time")}</Typography>
                {type !== 'VIEW' &&
                  <Typography color={'primary.main'}>{__("course_offline_modal_time_sub_text")}</Typography>
                }
              </Box>
              <Stack
                sx={{
                  border: theme => `1px solid ${theme.palette.divider}`,
                  borderRadius: theme => theme.spacing(1.5),
                  padding: theme => theme.spacing(2),
                  overflowY: 'auto',
                  whiteSpace: 'noWrap',
                  maxHeight: theme => theme.spacing(35)
                }}
                rowGap={2}
              >
                {
                  type !== 'VIEW' && (
                    <Button
                      variant={'outlined'}
                      className={'course_offline_modal_btn'}
                      onClick={handleOpenAddTime}
                    >
                      {__("course_offline_modal_add_time")}
                      <PlusIcon
                        sx={{
                          width: theme => theme.spacing(2.5),
                          height: theme => theme.spacing(2.5),
                          marginLeft: theme => theme.spacing(1)

                        }}
                      />
                    </Button>
                  )
                }

                {
                  coursesOfflineTime ? (
                    <>
                      {coursesOfflineTime?.map((time, index) =>
                        <Box key={`coursesOfflineTime${index}`}>
                          <Typography
                            variant={'subtitle2'}
                            textTransform={'none'}
                            paddingBottom={theme => theme.spacing(2)}
                          >
                            {time?.year}
                          </Typography>
                          <Stack
                            direction={'column'}
                            display={'grid'}
                            gridTemplateColumns={`1fr 1fr 1fr 1fr`}
                            gap={2}
                          >
                            {
                              time?.month?.map((monthItem, inx) =>
                                <Box key={`monthItem${inx}`}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-around'
                                    }}
                                  >
                                    <Typography>{monthItem?.value}</Typography>
                                    {
                                      type !== 'VIEW' && !monthItem?.has_user_register && (
                                        <IconButton
                                          onClick={()=> {
                                            fields?.course_offline_time?.runValidation(fields?.course_offline_time?.value)
                                            handleDeleteTimeItem({
                                              year: time?.year,
                                              month: monthItem?.value
                                            })
                                          }}
                                          sx={{
                                            backgroundColor: theme=> theme.palette.background.secondary,
                                            padding: 0
                                          }}>
                                          <CloseIcon />
                                        </IconButton>
                                      )}

                                  </Box>
                                  <Divider
                                    sx={{
                                      borderColor: theme => theme.palette.text.disabled,
                                      margin: theme => theme.spacing(1, 0)
                                    }}
                                  />
                                </Box>
                              )
                            }


                          </Stack>
                        </Box>

                      )
                      }
                    </>
                  ) : null
                }
              </Stack>
              {fields?.course_offline_time?.error && <Typography variant={'body2'} color={'error.main'}>{fields?.course_offline_time?.error}</Typography>}
            </Stack>
          </Stack>


        </ModalBody>
        {type !== 'VIEW' &&
          <ModalFooter
            sx={{
              alignItems: 'flex-end'
            }}
          >
            <Button
              disabled={!dirty || submitting || isValidate && !fields?.course_offline_code.dirty && !fields?.course_offline_title.dirty}
              type={'submit'}
              className={'course_offline_modal_btn'}

            >
              {__("course_offline_modal_btn")}
            </Button>
          </ModalFooter>
        }

      </ModalContainer>

    </AddEditCourseOfflineModalContainer>
  )
}
export default AddEditCourseOfflineModal
const AddEditCourseOfflineModalContainer = styled(Modal)(({theme})=>({
  "& .course_offline_modal_btn":{
    textTransform: 'none',
    fontsize: theme.spacing(2),
    maxWidth: `max-content`
  }
}))
