import React, { Dispatch, SetStateAction } from 'react'
import { Box, Divider, List, ListItem, styled, Typography } from '@mui/material'
import SidebarItem from './Sidebar/SidebarItem'
import {
  CalendarIcon,
  ChallengeSidebarIcon,
  CourseSidebarIcon,
  EventIcon,
  GiftSidebarIcon,
  GroupSidebarIcon,
  HomeSidebarIcon,
  MissionSidebarICon,
  NewsIcon,
  OrderSidebarIcon,
  RankingSidebarIcon
} from 'icons'
import __ from 'languages/index'
import { useAppSelector } from 'config/store'
import helpers from 'helpers'
import { Link } from 'react-router-dom'
import ShopSidebarIcon from '../icons/ShopSidebarIcon'
import TypedChannel from 'interfaces/channel.interface'
import __helpers from 'helpers'

type SidebarItemType = {
  key: string
  label: string
  icon: React.ReactNode
  path: string
  subMenu?: Pick<SidebarItemType, 'label' | 'path'>[]
}

const freeChannelConfigsOthers: SidebarItemType[] = [
  {
    key: 'home',
    label: __('home_page'),
    icon: <HomeSidebarIcon />,
    path: '/'
  },
  {
    key: 'event',
    label: __('event_title'),
    icon: <EventIcon />,
    path: '/event'
  },
  {
    key: 'news',
    label: __('news'),
    icon: <NewsIcon />,
    path: '/news'
  },
]


const freeChannelConfigsAdmin: SidebarItemType[] = [
  {
    key: 'home',
    label: __('home_page'),
    icon: <HomeSidebarIcon />,
    path: '/'
  },
  {
    key: 'event',
    label: __('event_title'),
    icon: <EventIcon />,
    path: '/event'
  },
  {
    key: 'news',
    label: __('news'),
    icon: <NewsIcon />,
    path: '/news'
  },
  {
    key: 'calendar',
    label: __('calendar'),
    icon: <CalendarIcon />,
    path: '/calendar'
  },
  {
    key: 'courses',
    label: __('courses'),
    icon: <CourseSidebarIcon />,
    path: '/courses',
    subMenu: [
      {
        label: __('total_course'),
        path: '/courses'
      },
      {
        label: __('course_revenue'),
        path: '/courses/income'
      }
    ]
  },
  {
    key: 'members',
    label: __('member'),
    icon: <GroupSidebarIcon />,
    path: '/member',
    subMenu: [
      {
        label: __('all_systems'),
        path: '/member'
      },
      {
        label: __('my_introduction'),
        path: '/member/my-introduction'
      }
    ]
  },

  {
    key: 'ranking',
    label: __('challenge_rank'),
    icon: <RankingSidebarIcon />,
    path: '/leader-board'
  },

  {
    key: 'order',
    label: __('order'),
    icon: <OrderSidebarIcon />,
    path: '/orders',
    subMenu: [
      {
        label: __('my_order'),
        path: '/orders'
      },
      {
        label: __('channel_orders'),
        path: '/orders/orders-channel'
      }
    ]
  },

  {
    key: 'mission',
    label: __('mission'),
    icon: <MissionSidebarICon />,
    path: '/setting-mission'
  },
  {
    key: 'gift',
    label: __('gift_title'),
    icon: <GiftSidebarIcon />,
    path: '/gift',
    subMenu: [
      {
        label: __('gift_list'),
        path: '/gift'
      },
      {
        label: __('gift_history_receiver'),
        path: '/gift/receivers'
      }
    ]
  },
  {
    key: 'challenge',
    label: __('challenge'),
    icon: <ChallengeSidebarIcon />,
    path: '/challenge'
  }
  // {
  //   key: 'extension',
  //   label: __('extension_breadcrumb'),
  //   icon: <ExtensionSidebarIcon />,
  //   path: '/extensions'
  // }
  // {
  //   label: __('course_income_table_affiliate'),
  //   icon: <AffiliateSidebarIcon />,
  //   path: '/temp/affiliate'
  // }
  // {
  //   label: __('podcast'),
  //   icon: <PodcastSidebarIcon />,
  //   path: '/podcast'
  // }
]

const freeChannelConfigsUser: SidebarItemType[] = [
  {
    key: 'home',
    label: __('home_page'),
    icon: <HomeSidebarIcon />,
    path: '/'
  },
  {
    key: 'event',
    label: __('event_title'),
    icon: <EventIcon />,
    path: '/event'
  },
  {
    key: 'news',
    label: __('news'),
    icon: <NewsIcon />,
    path: '/news'
  },
  {
    key: 'calendar',
    label: __('calendar'),
    icon: <CalendarIcon />,
    path: '/calendar'
  },
  {
    key: 'courses',
    label: __('courses'),
    icon: <CourseSidebarIcon />,
    path: '/courses',
    subMenu: [
      {
        label: __('total_course'),
        path: '/courses'
      },
      {
        label: __('course_joined'),
        path: '/courses/course-joined'
      },
      {
        label: __('course_revenue'),
        path: '/courses/income'
      }
    ]
  },
  {
    key: 'members',
    label: __('member'),
    icon: <GroupSidebarIcon />,
    path: '/member',
    subMenu: [
      {
        label: __('all_systems'),
        path: '/member'
      },
      {
        label: __('my_introduction'),
        path: '/member/my-introduction'
      }
    ]
  },

  {
    key: 'ranking',
    label: __('challenge_rank'),
    icon: <RankingSidebarIcon />,
    path: '/leader-board'
  },

  {
    key: 'order',
    label: __('order'),
    icon: <OrderSidebarIcon />,
    path: '/orders'
  },

  {
    key: 'mission',
    label: __('mission'),
    icon: <MissionSidebarICon />,
    path: '/missions'
  },
  {
    key: 'gift',
    label: __('gift_title'),
    icon: <GiftSidebarIcon />,
    path: '/gift',
    subMenu: [
      {
        label: __('gift_list'),
        path: '/gift'
      },
      {
        label: __('gift_history_receiver'),
        path: '/gift/receivers'
      }
    ]
  },
  {
    key: 'challenge',
    label: __('challenge'),
    icon: <ChallengeSidebarIcon />,
    path: '/challenge'
  }
  // {
  //   label: __('course_income_table_affiliate'),
  //   icon: <AffiliateSidebarIcon />,
  //   path: '/temp/affiliate'
  // }
  // {
  //   label: __('podcast'),
  //   icon: <PodcastSidebarIcon />,
  //   path: '/podcast'
  // }
]

const premiumChannelConfigs: Pick<SidebarItemType, 'key' | 'icon'>[] = [
  // {
  //   key: 'livestream',
  //   icon: <LivestreamSidebarIcon />
  // },
  // {
  //   key: 'mentor',
  //   icon: <MentorSidebarIcon />
  // },
  {
    key: 'gift',
    icon: <GiftSidebarIcon />
  },
  {
    key: 'challenge',
    icon: <ChallengeSidebarIcon />
  },
  {
    key: 'store',
    icon: <ShopSidebarIcon />
  }
]

interface TypedProps {
  setToggle?: Dispatch<SetStateAction<boolean>>
}

const Sidebar = ({ setToggle }: TypedProps) => {
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  return (
    <SidebarContainer>
      <List
        sx={{
          display: 'grid',
          rowGap: theme => theme.spacing(0.5)
        }}
      >
        {(__helpers.isEmpty(permission_data) ? freeChannelConfigsOthers  : helpers.current_user_can('boss', permission_data) ? freeChannelConfigsAdmin :
          freeChannelConfigsUser).map((config, index) => (
          <SidebarItem setToggle={setToggle} key={index} {...config} channel_data={channel_data} />
        ))}
      </List>

      <Divider
        sx={{
          borderColor: theme => theme.palette.text.disabled
        }}
      />
      <Box padding={theme => theme.spacing(1, 0)}>
        {helpers.isGamifa() && (
          <List className="list">
            <ListItem className="item">
              <Link to={'/about-us'} className="link">
                {__('about_us_title')}
              </Link>
            </ListItem>
            <ListItem sx={{ padding: theme => theme.spacing(0, 1), width: 'auto' }}>
              <Box className="doted"></Box>
            </ListItem>
            <ListItem className="item">
              <Link to={'/contact'} className="link">
                {__('contact_title')}
              </Link>
            </ListItem>
            <ListItem sx={{ padding: theme => theme.spacing(0, 1), width: 'auto' }}>
              <Box className="doted"></Box>
            </ListItem>
            <ListItem className="item">
              <Link to={'/tos'} className="link">
                {__('regulations')}
              </Link>
            </ListItem>
          </List>
        )}
        <Typography variant="body2" color={'text.secondary'}>
          {__('contact_version')}{' '}
          {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </SidebarContainer>
  )
}

export default Sidebar

const SidebarContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  height: `calc(100vh - ${theme.spacing(14.5)})`,
  top: theme.spacing(12.5),
  width: '100%',
  overflow: 'auto',
  '& .list': {
    display: 'flex',
    flexWrap: 'wrap',
    '& .item': {
      padding: 0,
      width: 'auto'
    }
  },
  '& .link': {
    textDecoration: 'none',
    fontSize: theme.spacing(1.75),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap'
  },
  '& .doted': {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: '50%',
    background: theme.palette.text.disabled
  }
}))
