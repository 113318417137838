import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'config/store'
import helpers from 'helpers/index'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './media/gift.list.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Form } from 'react-bootstrap'
import {
  EnumGiftConditionType,
  GIFT_CONDITIONS,
  GIFT_SORT_OPTIONS
} from 'entities/gift/constants/gift.constant'
import { FormCheckType } from 'react-bootstrap/FormCheck'
import GiftListItem from 'entities/gift/components/gift.list.item'
import GiftListSkeleton from 'entities/gift/skeletons/gift.list.skeleton'
import {
  getBannerGift,
  getListGifts,
  updateBannerGift
} from 'entities/gift/services/gift.receiver.service'
import BannerImg from './media/banner.png'
import { uploadSingleMedia } from '../../services/media.service'
import Spinner from 'react-bootstrap/Spinner'
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { TypedGift } from 'entities/gift/gift.interface'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import Capacity from 'components/Capacity'
import BigLayout from '../../layouts/bigLayout'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import { Box, Button } from '@mui/material'

export default function GiftList() {
  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const toast = useToast()
  // const refGiftDetailModal = useRef < any > ()
  const refInputBanner = useRef<any>()
  const [urlBanner, setUrlBanner] = useState<string | undefined>()
  const [loadingBanner, setLoadingBanner] = useState<boolean>(false)
  const rootSearchPath = window.location.search
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || {}

  const {
    data,
    hasMore,
    totalItems,
    paramRequest,
    setParamRequest,
    loadMore,
    isRefreshing,
    refresh,
    updateParamRequestDebounce
  } = useInfiniteScroll<TypedGift>(
    {
      search: '',
      limit: 12,
      auth_id: user_data?._id,
      order_type: 'time',
      order_by: 'DESC',
      gift_type: 'gift',
      ...StringQuery
    },
    getListGifts
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    getBannerGift().then(setUrlBanner)
  }, [])

  const onClickUpdateBanner = useCallback(() => {
    refInputBanner.current?.click()
  }, [])

  const onChangeBanner = useCallback(async event => {
    setLoadingBanner(true)
    event.preventDefault()
    const formData = new FormData()
    for (let i = 0; i < event?.target?.files.length; i++) {
      formData.append('files', event?.target?.files[i])
    }
    let media = await uploadSingleMedia(event?.target?.files)
    if (media && media?.src && media?.callback?._id) {
      let isUpdated = await updateBannerGift(media?.callback?._id)
      if (isUpdated) {
        setUrlBanner(media?.src)
        toast.show({
          title: `${__('notification')}`,
          type: EnumTypeToast.Success,
          content: `${__('gift_update_banner_text')}`
        })
      } else {
        toast.show({
          title: `${__('notification')}`,
          type: EnumTypeToast.Error,
          content: `${__('gift_update_banner_text_failed')}`
        })
      }
    } else {
      toast.show({
        title: `${__('notification')}`,
        type: EnumTypeToast.Error,
        content: `${__('gift_update_banner_text_failed')}`
      })
    }
    setLoadingBanner(false)
  }, [])

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(paramRequest)
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Gift', '/gift' + buildURLSearch)
    }
  }, [paramRequest])

  // const showDetailGift = useCallback(item => {
  //   refGiftDetailModal.current?.show?.(item)
  // }, [])

  // ======================     FOR S.E.O @jamviet.com            ======================
  const location = useLocation()
  const navigate = useNavigate()

  const onChangeFilter = useCallback(
    (typeFilter: EnumGiftConditionType, value: boolean) => {
      if (value) {
        setParamRequest({
          ...paramRequest,
          [typeFilter]: 1
        })
      } else {
        let newQuery = { ...paramRequest }
        if (newQuery[typeFilter] !== undefined) {
          delete newQuery[typeFilter]
        }
        setParamRequest(newQuery)
      }
    },
    [paramRequest]
  )

  const onChangeSort = useCallback(
    (event: any) => {
      if (!helpers.isJson(event?.target?.value)) return
      updateParamRequestDebounce({ ...JSON.parse(event?.target?.value) })
    },
    [paramRequest]
  )

  const onChangeSearch = useCallback((event: any) => {
    updateParamRequestDebounce({ search: event?.target?.value.trim() })
  }, [])

  useEffect(() => {
    if (!location?.state?.option_type) return
    onChangeFilter(location?.state?.option_type, true)
    navigate('.', { state: null })
  }, [location?.state])

  const searchUser = () => (
    <div className="gift_container_admin_header">
      <div className="d-flex align-items-center gift_container_search mt-2">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <Form>
          <Form.Control
            type="text"
            placeholder="Tìm kiếm phần quà"
            className="border-0"
            onChange={onChangeSearch}
            defaultValue={paramRequest?.search}
          />
        </Form>
      </div>

      <div className="text-lg-end mt-2">
        {totalItems} {__('gift_receiver_result')}
      </div>
    </div>
  )

  return (
    <BigLayout>
      <div id={'gift-list'}>
        <_Helmet title="gift_title" />
        <div className="gift_container">
          <div className="gift_container_header">
            <Capacity
              current_user_can={'boss'}
              onFail={() => (
                <img alt="image/gift" className="banner_gift" src={urlBanner || BannerImg} />
              )}
            >
              <div className="position-relative w-100 h-100">
                <img alt="image/banner" className="banner_gift" src={urlBanner || BannerImg} />
                <input
                  accept="image/png, image/jpeg, image/jpg"
                  ref={refInputBanner}
                  type="file"
                  id="input-file-upload"
                  multiple={false}
                  onChange={onChangeBanner}
                />
                <Button
                  disabled={loadingBanner}
                  className="position-absolute bottom-0 end-0 m-4"
                  sx={{ textTransform: 'none' }}
                  onClick={onClickUpdateBanner}
                >
                  {loadingBanner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">{__('loading')}</span>
                    </>
                  ) : (
                    `${__('gift_list_edit_cover_photo')}`
                  )}
                </Button>
              </div>
            </Capacity>
          </div>

          <div className="row gift_container_left_sticky_row w-100">
            <div className="col-xl-3 css_prefix-woo-sidebar col-sm-12 mt-5 mt-xl-0 gift_container_left">
              <div className="gift_container_left_sticky">
                <div className="gift_container_fillter">
                  <div className="h5">{__('gift_condition')}</div>
                  <Form>
                    <Box
                      sx={{
                        '& .form-check-input': {
                          ':checked': {
                            backgroundColor: theme => `${theme.palette.primary.main} !important`,
                            borderColor: theme => `${theme.palette.primary.main} !important`
                          },
                          ':focus': { boxShadow: 'unset !important' }
                        }
                      }}
                      key={`inline-checkbox`}
                      className="row mt-2 mx-1"
                    >
                      {GIFT_CONDITIONS.map(itemCheckbox => (
                        <Form.Check
                          key={itemCheckbox.value}
                          inline
                          label={itemCheckbox.title}
                          name="groupGIFT_CONDITIONS"
                          type={itemCheckbox.type as FormCheckType}
                          id={`inline-${itemCheckbox.type}-${itemCheckbox.value}`}
                          value={itemCheckbox.value}
                          className="col-xl-12 col-sm-4"
                          defaultChecked={paramRequest?.[itemCheckbox.value]}
                          checked={paramRequest?.[itemCheckbox.value]}
                          onChange={event =>
                            onChangeFilter(itemCheckbox.value, event?.target?.checked)
                          }
                        />
                      ))}
                    </Box>
                  </Form>
                </div>
              </div>
            </div>

            <div className="col-xl-9 col-sm-12 gift_container_products mb-2">
              <div className="gift_container_products_header_frame">
                <div className="gift_container_products_header flex-wrap">
                  <h5>{__('gift_title')}</h5>

                  <div className="gift_container_sort">
                    <Form.Select
                      aria-label=""
                      className={`gift_container_sortbox`}
                      defaultValue={
                        paramRequest?.order_type && paramRequest?.order_by
                          ? JSON.stringify({
                              order_type: paramRequest?.order_type,
                              order_by: paramRequest?.order_by
                            })
                          : undefined
                      }
                      onChange={onChangeSort}
                    >
                      {GIFT_SORT_OPTIONS.map((item, index: number) => (
                        <option key={index.toString()} value={JSON.stringify(item?.value)}>
                          {item?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <Capacity current_user_can={'boss'} onFail={searchUser}>
                  <div className="gift_container_admin_header">
                    <div className="d-flex align-items-center gift_container_search mt-4">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                      <Box
                        component={Form}
                        sx={{
                          '& .input_search': {
                            ':focus': {
                              borderBottom: theme =>
                                `1px solid ${theme.palette.primary.main} !important`
                            }
                          }
                        }}
                      >
                        <Form.Control
                          type="text"
                          placeholder={__('gift_search_placeholder')}
                          className="border-0 input_search"
                          onChange={onChangeSearch}
                          defaultValue={paramRequest?.search}
                        />
                      </Box>
                    </div>

                    <Button
                      sx={{ textTransform: 'none' }}
                      component={Link}
                      to={'/gift/add'}
                      className="d-flex align-items-center mt-4"
                    >
                      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                      {__('gift_update_create')}
                    </Button>
                  </div>
                </Capacity>
              </div>

              <Capacity current_user_can={'boss'}>
                <div className="text-lg-end mt-2">
                  {totalItems} {__('gift_receiver_result')}
                </div>
              </Capacity>

              <InfiniteScrollContainer
                isRefreshing={isRefreshing}
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                refreshFunction={refresh}
                skeleton={<GiftListSkeleton />}
              >
                <div className="row mt-2" id="course_wrap">
                  {data.map((item, index) => {
                    return (
                      <GiftListItem
                        key={`gift_loop_${index}`}
                        item={item}
                        // showDetailGift={showDetailGift}
                      />
                    )
                  })}
                </div>
              </InfiniteScrollContainer>
            </div>
          </div>
        </div>

        {/*<GiftDetailModal ref={refGiftDetailModal} />*/}
      </div>
    </BigLayout>
  )
}
