//Library
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { AxiosError, AxiosResponse } from 'axios'
import { faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notEmpty, useField, useForm } from '@shopify/react-form'

//Components
//Store
import { uploadImage } from '../store/user.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'

//Interface
//Image
import avatarDefault from 'media/images/avatar_default.png'

//Data
//Helpers
import helpers from 'helpers'

//Scss
import '../media/user.setting.profile.scss'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { updateDataUser } from 'store/user.store.reducer'
import TypedUser from '../../../interfaces/user.interface'
import { Box, Button, styled } from '@mui/material'

const UserProfileSetting = () => {
  const dispatch = useAppDispatch()

  const desRef = useRef<HTMLTextAreaElement>(null)
  const bioRef = useRef<HTMLTextAreaElement>(null)

  const user_data = useAppSelector(state => state.user.user_data)

  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)
  const [dataUser, setDataUser] = useState<TypedUser>()
  const toast = useToast()

  const { fields, dirty, submitting, submit } = useForm({
    fields: {
      user_avatar: useField<string>({
        value: '',
        validates: []
      }),
      user_email: useField<string>({
        value: '',
        validates: []
      }),
      display_name: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('user_setting_note')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('user_setting_note')}`
            }
          }
        ]
      }),
      user_phone: useField<string>({
        value: '',
        validates: []
      }),
      country_phone: useField<string>({
        value: `${__('country')}`,
        validates: []
      }),
      country_code: useField<string>({
        value: `${__('area_code')}`,
        validates: []
      }),
      user_birthday: useField<string>({
        value: '',
        validates: []
      }),
      description: useField<string>({
        value: '',
        validates: []
      }),
      bio: useField<string>({
        value: '',
        validates: []
      }),
      user_address: useField<string>({
        value: '',
        validates: []
      }),
      isSocialConnect: useField<boolean>({
        value: false,
        validates: []
      }),
      website: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(
              /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
            )
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      }),
      instagram: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(/(https?)?:?(www)?instagram\.com\/[a-z].{3}/gim)
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      }),
      twitter: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(
              /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/gim
            )
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      }),
      youTube: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(
              /http(s)?:\/\/(www|m).youtube.com\/((channel|c)\/)?(?!feed|user\/|watch\?)([a-zA-Z0-9-_.])*.*/gim
            )
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      }),
      linkedln: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(
              /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gim
            )
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      }),
      facebook: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(
              /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gim
            )
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__('user_setting_error_link')}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const socialLink = [
          {
            key: 'website',
            value: values?.website?.trim()
          },
          {
            key: 'instagram',
            value: values?.instagram?.trim()
          },
          {
            key: 'twitter',
            value: values?.twitter?.trim()
          },
          {
            key: 'youTube',
            value: values?.youTube?.trim()
          },
          {
            key: 'linkedln',
            value: values?.linkedln?.trim()
          },
          {
            key: 'facebook',
            value: values?.facebook?.trim()
          }
        ]
        const dataUserUpdate = {
          _id: user_data?._id,
          display_name: values?.display_name?.trim(),
          bio: values?.bio?.trim(),
          description: values?.description?.trim(),
          user_phone: `${values?.user_phone}`,
          social_link: JSON.stringify(socialLink),
          user_birthday: values?.user_birthday,
          user_address: values?.user_address?.trim()
        }

        await dispatch(updateDataUser(dataUserUpdate))
          .unwrap()
          .then((res: AxiosResponse<TypedUser>) => {
            setDataUser(res.data)
            toast.show({
              content: `${__('user_setting_update_info_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateDataUser_${error}`)
            toast.show({
              content: `${__('user_setting_update_info_failed')}`,
              type: EnumTypeToast.Error
            })
          })

        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      if (!file) return
      let formData = new FormData()
      formData.append('file[]', file)
      setIsLoadingUpload(true)
      dispatch(uploadImage(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          dispatch(
            updateDataUser({
              _id: user_data?._id,
              user_avatar: res?.data[0]?.src,
              user_avatar_thumbnail: res?.data[0]?.thumbnail
            })
          )
            .unwrap()
            .then((res: AxiosResponse<TypedUser>) => {
              setIsLoadingUpload(false)
              fields.user_avatar.onChange(res?.data?.user_avatar)
              toast.show({
                content: `${__('user_setting_upload_avatar_success')}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((error: AxiosError) => {
              console.log(`updateDataUser_${error}`)
              setIsLoadingUpload(false)
              toast.show({
                content: `${__('user_setting_upload_avatar_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        })
        .catch((error: AxiosError) => {
          console.log(`uploadImage_${error}`)
          toast.show({
            content: `${__('user_setting_upload_avatar_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [user_data]
  )

  const handleSetHeightTextArea = useCallback(
    (ref: React.RefObject<HTMLTextAreaElement>) => {
      const textarea = ref?.current
      if (textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    },
    [user_data, fields]
  )

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target
      if (name === 'user_phone') {
        return fields.user_phone?.onChange(value?.replace(/\D+/g, ''))
      }
      fields[name]?.onChange(value)
    },
    [fields]
  )

  useEffect(() => {
    if (user_data) {
      fields.user_email.onChange(dataUser?.user_email || user_data?.user_email)
      fields.user_avatar.onChange(dataUser?.user_avatar || user_data?.user_avatar)
      fields.display_name.onChange(dataUser?.display_name || user_data?.display_name)
      fields.user_phone.onChange(dataUser?.user_phone || user_data?.user_phone)
      fields.user_birthday.onChange(dataUser?.user_birthday || user_data?.user_birthday)
      fields.description.onChange(dataUser?.description || user_data?.description)
      fields.bio.onChange(dataUser?.bio || user_data?.bio)
      fields.user_address.onChange(dataUser?.user_address || user_data?.user_address)
      handleSetHeightTextArea(desRef)
      handleSetHeightTextArea(bioRef)

      if (helpers.isEmpty(dataUser?.social_link || user_data?.social_link)) return
      ;(dataUser?.social_link || user_data?.social_link)?.forEach(item => {
        fields[item?.key]?.onChange(item?.value)
      })
    }
  }, [user_data, dataUser])

  return (
    <UserProfileSettingContainer id="userProfileSetting" className="userProfileSetting_container">
      <h4 className="userProfileSetting_label">{__('user_profile')}</h4>
      <div className="userProfileSetting_image_user">
        <img
          src={fields.user_avatar.value || avatarDefault}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = avatarDefault
          }}
          className="userProfileSetting_image"
          width={100}
          height={100}
          alt="avatar"
          loading="lazy"
        />
        <input
          type="file"
          id="fileInputAvatar"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        <Button
          variant="outlined"
          component={'label'}
          sx={{ textTransform: 'none' }}
          htmlFor="fileInputAvatar"
          className="userProfileSetting_edit_image"
        >
          {isLoadingUpload ? (
            <span>{__('user_setting_processing')}</span>
          ) : (
            <>
              <FontAwesomeIcon size="lg" icon={faImage} />
              <span>
                {!fields.user_avatar.value
                  ? `${__('user_setting_add_avatar')}`
                  : `${__('user_setting_edit_avatar')}`}
              </span>
            </>
          )}
        </Button>
      </div>
      <div className="userProfileSetting_body">
        <div className="userProfileSetting_body_item">
          <Form.Control
            value={fields.user_email.value || '@example.com'}
            name="user_email"
            readOnly
            id="emailOfUser"
            placeholder=" "
            className={`userProfileSetting_body_item_input`}
          />
          <label htmlFor="emailOfUser" className={`userProfileSetting_body_item_label`}>
            {__('Email')}
          </label>
        </div>
        <div className="userProfileSetting_body_item">
          <Form.Control
            value={fields.display_name.value || ''}
            name="display_name"
            autoFocus
            onChange={handleChangeValue}
            onBlur={() => {
              fields.display_name?.runValidation(fields.display_name.value)
            }}
            onFocus={() => fields.display_name?.setError('')}
            id="nameUser"
            placeholder=" "
            className={`userProfileSetting_body_item_input ${
              fields.display_name?.error && 'validate_failed'
            }`}
          />
          <label
            htmlFor="nameUser"
            className={`userProfileSetting_body_item_label ${
              fields.display_name?.error && 'validate_failed'
            }`}
          >
            {__('user_setting_user_name')}
          </label>
          {fields.display_name?.error && (
            <span className="form-text text-danger">{fields.display_name?.error}</span>
          )}
        </div>
        <div className="userProfileSetting_body_item">
          <Form.Control
            value={fields.user_phone.value || ''}
            name="user_phone"
            maxLength={20}
            onChange={handleChangeValue}
            id="phoneNumber"
            placeholder=" "
            className="userProfileSetting_body_item_input"
          />
          <label htmlFor="phoneNumber" className="userProfileSetting_body_item_label">
            {__('user_setting_telephone_number')}
          </label>
        </div>
        <div className="userProfileSetting_body_item">
          <Form.Control
            value={fields.user_birthday.value || ''}
            name="user_birthday"
            onChange={handleChangeValue}
            id="date"
            type="date"
            placeholder=" "
            className="userProfileSetting_body_item_input"
          />
          <label htmlFor="date" className="userProfileSetting_body_item_label">
            {__('user_setting_date_of_birth')}
          </label>
        </div>
        <div className="userProfileSetting_body_item">
          <textarea
            value={fields.description.value || ''}
            name="description"
            onChange={handleChangeValue}
            id="description"
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder=" "
            className="userProfileSetting_body_item_textArea form-control"
          ></textarea>
          <label htmlFor="description" className="userProfileSetting_body_item_label">
            {__('user_setting_description')}
          </label>
        </div>
        <div className="userProfileSetting_body_item">
          <textarea
            value={fields.bio.value || ''}
            name="bio"
            onChange={handleChangeValue}
            id="bio"
            ref={bioRef}
            onInput={() => handleSetHeightTextArea(bioRef)}
            placeholder=" "
            className="userProfileSetting_body_item_textArea form-control"
          ></textarea>
          <label htmlFor="bio" className="userProfileSetting_body_item_label">
            {__('user_setting_biography')}
          </label>
        </div>
        <div className="userProfileSetting_body_item">
          <Form.Control
            value={fields.user_address.value || ''}
            name="user_address"
            onChange={handleChangeValue}
            id="address"
            placeholder=" "
            className="userProfileSetting_body_item_input"
          />
          <label htmlFor="address" className="userProfileSetting_body_item_label">
            {__('user_setting_address')}
          </label>
        </div>
      </div>
      <div className="userProfileSetting_footer">
        <div className="userProfileSetting_footer_check">
          <span
            role="button"
            onClick={() => fields.isSocialConnect.onChange(!fields.isSocialConnect.value)}
          >
            {__('user_setting_list_social')}
          </span>
          <FontAwesomeIcon
            role="button"
            className={`${
              fields.isSocialConnect.value && 'userProfileSetting_footer_check_active'
            }`}
            onClick={() => fields.isSocialConnect.onChange(!fields.isSocialConnect.value)}
            icon={faChevronDown}
          />
        </div>
        {fields.isSocialConnect.value && (
          <div className="userProfileSetting_footer_body">
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.website.value}
                name="website"
                onChange={handleChangeValue}
                id="website"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.website?.runValidation(fields.website.value)
                }}
                onFocus={() => fields.website?.setError('')}
              />
              <label htmlFor="website" className="userProfileSetting_body_item_label">
                {__('user_setting_website')}
              </label>
              {fields.website?.error && (
                <span className="form-text text-danger">{fields.website?.error}</span>
              )}
            </div>
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.instagram.value}
                name="instagram"
                onChange={handleChangeValue}
                id="instagram"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.instagram?.runValidation(fields.instagram.value)
                }}
                onFocus={() => fields.instagram?.setError('')}
              />
              <label htmlFor="instagram" className="userProfileSetting_body_item_label">
                {__('user_setting_instagram')}
              </label>
              {fields.instagram?.error && (
                <span className="form-text text-danger">{fields.instagram?.error}</span>
              )}
            </div>
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.twitter.value}
                name="twitter"
                onChange={handleChangeValue}
                id="twitter"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.twitter?.runValidation(fields.twitter.value)
                }}
                onFocus={() => fields.twitter?.setError('')}
              />
              <label htmlFor="twitter" className="userProfileSetting_body_item_label">
                {__('user_setting_twitter')}
              </label>
              {fields.twitter?.error && (
                <span className="form-text text-danger">{fields.twitter?.error}</span>
              )}
            </div>
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.youTube.value}
                name="youTube"
                onChange={handleChangeValue}
                id="youtube"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.youTube?.runValidation(fields.youTube.value)
                }}
                onFocus={() => fields.youTube?.setError('')}
              />
              <label htmlFor="youtube" className="userProfileSetting_body_item_label">
                {__('user_setting_youtube')}
              </label>
              {fields.youTube?.error && (
                <span className="form-text text-danger">{fields.youTube?.error}</span>
              )}
            </div>
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.linkedln.value}
                name="linkedln"
                onChange={handleChangeValue}
                id="linkedin"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.linkedln?.runValidation(fields.linkedln.value)
                }}
                onFocus={() => fields.linkedln?.setError('')}
              />
              <label htmlFor="linkedin" className="userProfileSetting_body_item_label">
                {__('user_setting_linkedin')}
              </label>
              {fields.linkedln?.error && (
                <span className="form-text text-danger">{fields.linkedln?.error}</span>
              )}
            </div>
            <div className="userProfileSetting_body_item">
              <Form.Control
                value={fields.facebook.value}
                name="facebook"
                onChange={handleChangeValue}
                id="facebook"
                placeholder=" "
                className="userProfileSetting_body_item_input"
                onBlur={() => {
                  fields.facebook?.runValidation(fields.facebook.value)
                }}
                onFocus={() => fields.facebook?.setError('')}
              />
              <label htmlFor="facebook" className="userProfileSetting_body_item_label">
                {__('user_setting_facebook')}
              </label>
              {fields.facebook?.error && (
                <span className="form-text text-danger">{fields.facebook?.error}</span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="userProfileSetting_button_save">
        <Button onClick={submit} disabled={!dirty || submitting} sx={{ textTransform: 'none' }}>
          {submitting ? `${__('btn_processing')}` : `${__('btn_update')}`}
        </Button>
      </div>
    </UserProfileSettingContainer>
  )
}

export default memo(UserProfileSetting)

const UserProfileSettingContainer = styled(Box)(({ theme }) => ({
  '& .form-control': {
    ':focus': {
      boxShadow: `${theme.palette.primary.main} 0px 0px 6px 0.2rem !important`,
      borderColor: `${theme.palette.primary.main} !important`
    }
  }
}))
