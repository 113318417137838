import './media/courses.update.scss'

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'config/store'

import { TypedLevel } from 'entities/courses/courses.interface'
import {
  clearEntity,
  createCourse,
  getDefaultImage,
  getEntity,
  getLevels,
  updateCourse
} from 'entities/courses/courses.store.reducer'
import { EnumPublicStatus, EnumPublishStatus } from 'entities/courses/media/constants'
import { isEqual } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import helpers from 'helpers'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ConfirmModal } from 'components/modalConfirm'
import _Helmet from 'components/helmet'
import Title from 'components/Title'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import imageDefault from 'media/images/image_default.png'
import __ from 'languages/index'
import Capacity from 'components/Capacity'
import BigLayout from '../../layouts/bigLayout'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
  styled
} from '@mui/material'
import BreadcrumbComponent from 'components/BreadcrumbComponent'
import { uploadMedia } from './services/courses.media.service'
import TextField from 'components/TextFieldMUI'
import { formatString } from 'entities/support/utils'
import Select from 'components/Select'
import { UploaderIcon } from 'icons'

export default function CourseAdd() {
  let useParam = useParams()
  const dispatch = useAppDispatch()
  const user_data = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const listLevel: TypedLevel[] = useAppSelector(state => state.courses.listLevel)
  const entity = useAppSelector(state => state.courses.entity)
  const [urlCourseMedia, setUrlCourseMedia] = useState(entity?.avatar?.media_thumbnail)

  const navigate = useNavigate()

  const refInputTitle = useRef<any>()
  const refInputDescription = useRef<any>()
  const refConfirmModal = useRef<any>()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [validate, setValidate] = useState(false)
  const toast = useToast()
  const { fields, reset } = useForm({
    fields: {
      title: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('course_update_name_error_text')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('course_update_name_error_text')}`
            }
          }
        ]
      }),
      description: useField<string>({
        value: '',
        validates: []
      }),
      long_description: useField<string>({
        value: '',
        validates: []
      }),
      avatar: useField<string>({
        value: '',
        validates: []
      }),
      coin_value: useField<string>({
        value: '',
        validates: []
      }),
      level_value: useField<string>({
        value: '1',
        validates: []
      }),
      public_status: useField<string>({
        value: EnumPublicStatus.public,
        validates: []
      }),
      trash_status: useField<string>({
        value: 'trash',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const refValueDefaultCourse = useRef({
    title: useParam.courses_id ? entity?.title || '' : '',
    description: useParam.courses_id ? entity?.description || '' : '',
    long_description: useParam.courses_id ? entity?.long_description || '' : '',
    avatar: useParam.courses_id ? entity?.avatar?._id || '' : '',
    level_value: useParam.courses_id ? entity?.level_value || 1 : 1,
    coin_value: useParam.courses_id ? entity?.coin_value || '0' : '0',
    public_status: useParam.courses_id
      ? entity?.public_status || EnumPublicStatus.public
      : EnumPublicStatus.public,
    trash_status: useParam.courses_id
      ? entity?.trash_status || EnumPublishStatus.public
      : EnumPublishStatus.public
  })

  useLayoutEffect(() => {
    window.scrollTo(0, 0)

    if (permission_data) {
      if (helpers.current_user_can('boss', permission_data)) {
        dispatch(getLevels({}))
        if (useParam.courses_id) {
          dispatch(
            getEntity({
              _id: useParam.courses_id,
              auth_id: user_data?._id
            })
          )
        }
      }
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [permission_data])

  useEffect(() => {
    if (entity) {
      fields.avatar.onChange(entity?.avatar?._id)
      fields.title.onChange(entity?.title)
      fields.description.onChange(entity?.description)
      fields.long_description.onChange(entity?.long_description)
      fields.level_value.onChange(entity?.level_value)
      fields.coin_value.onChange(entity?.coin_value)
      fields.public_status.onChange(entity?.public_status)
      fields.trash_status.onChange(entity?.trash_status)

      refValueDefaultCourse.current = {
        title: entity?.title,
        description: entity?.description,
        long_description: entity?.long_description,
        avatar: entity?.avatar?._id,
        level_value: entity?.level_value,
        coin_value: `${entity?.coin_value}`,
        public_status: entity?.public_status,
        trash_status: entity?.trash_status
      }

      if (
        refInputTitle?.current?.scrollHeight !== undefined &&
        refInputTitle.current?.style?.height !== undefined
      ) {
        refInputTitle.current.style.height = 'auto'
        refInputTitle.current.style.height = refInputTitle.current.scrollHeight + 'px'
      }

      if (
        refInputDescription?.current?.scrollHeight !== undefined &&
        refInputDescription.current?.style?.height !== undefined
      ) {
        refInputDescription.current.style.height = 'auto'
        refInputDescription.current.style.height = refInputDescription.current.scrollHeight + 'px'
      }

      setUrlCourseMedia(entity?.avatar?.media_thumbnail)
    }
  }, [entity])

  const onSubmitCourse = useCallback(
    async event => {
      try {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
        } else {
          event.preventDefault()
          event.stopPropagation()

          setLoading(true)
          if (!fields.avatar?.value) {
            delete fields.avatar
          }

          const formData = {
            title: fields.title?.value,
            description: fields.description?.value,
            long_description: fields.long_description?.value,
            avatar: fields.avatar?.value,
            level_value: fields.level_value?.value,
            coin_value: `${helpers.parseNumeric(fields.coin_value?.value || '0')}`,
            public_status: fields.public_status?.value,
            trash_status: fields.trash_status?.value
          }

          let res: any = useParam.courses_id
            ? await dispatch(
                updateCourse({
                  ...formData,
                  _id: useParam.courses_id,
                  level_value: fields.level_value?.value + ''
                })
              )
            : await dispatch(
                createCourse({
                  ...formData,
                  level_value: fields.level_value?.value + ''
                })
              )

          if (res?.type?.includes('fulfilled')) {
            toast.show({
              content: useParam.courses_id
                ? `${__('course_update_success')}`
                : `${__('course_create_success')}`,
              type: EnumTypeToast.Success
            })

            if (res?.payload?.data?._id) {
              navigate('/courses/view/' + res.payload.data?._id)
            } else {
              navigate('/courses/list')
            }
          } else {
            if (res?.type?.includes('rejected')) {
              toast.show({
                content: `${__('course_create_error_text')}`,
                type: EnumTypeToast.Error
              })
            }

            setLoading(false)
          }
        }

        setValidate(true)
      } catch (error) {}
    },
    [useParam.courses_id, fields]
  )

  const handleSelectLevelChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    fields.level_value.onChange(event?.target?.value)
  }, [])

  const handleSelectPublicChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      fields.public_status.onChange(value)
      value === EnumPublicStatus.public && fields.level_value.onChange('1')
    },
    [fields, listLevel]
  )

  const handleAvatarChange = useCallback(
    (media_id: string) => {
      fields.avatar.onChange(media_id)
    },
    [fields]
  )

  const onPressCancel = useCallback(() => {
    const currentData = {
      title: fields.title?.value,
      description: fields.description?.value,
      long_description: fields.long_description?.value,
      avatar: fields.avatar?.value,
      level_value: fields.level_value?.value,
      coin_value: `${fields.coin_value?.value}`,
      public_status: fields.public_status?.value,
      trash_status: fields.trash_status?.value
    }
    if (isEqual(refValueDefaultCourse.current, currentData)) {
      if (useParam.courses_id) {
        navigate('/courses/view/' + entity?._id)
      } else {
        navigate('/courses/list')
      }
    } else {
      refConfirmModal.current?.show({
        title: 'Hủy bỏ',
        description:
          (useParam.courses_id ? `${__('course_update_cancel')}` : `${__('course_drop')}`) +
          `${__('course_confirm_text')}`,
        confirmText: useParam.courses_id ? 'Tiếp tục chỉnh sửa' : 'Giữ khóa học',
        onCancel: () => navigate('/courses/list')
      })
    }
  }, [entity?._id, fields, useParam])

  const adjustTextareaHeight = (textarea: any) => {
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if ('e+-'.includes(event.key)) {
      event.preventDefault() // Chặn sự kiện nhập ký tự e, -, +
    }
  }, [])

  const preventPasteNegative = useCallback(e => {
    const clipboardData = e.clipboardData
    const pastedData = parseFloat(clipboardData.getData('text'))

    if (isNaN(pastedData) || pastedData < 0) {
      e.preventDefault()
    }
  }, [])

  useEffect(() => {
    if (useParam.courses_id) {
      dispatch(
        getEntity({
          _id: useParam.courses_id,
          auth_id: user_data?._id || ''
        })
      )
      return
    }

    reset()
    dispatch(getDefaultImage())
      .unwrap()
      .then(res => {
        setUrlCourseMedia(res?.data?.media_url)
        fields.avatar?.onChange(res?.data?._id)
      })
      .catch(error => {
        console.log(`getDefaultImage_${error}`)
        setUrlCourseMedia('')
        fields.avatar?.onChange('')
      })
  }, [])

  return (
    <BigLayout>
      <_Helmet title="course_add_title" />

      <CourseAddContainer id="course_add" gap={2}>
        <BreadcrumbComponent
          breadcrumbName={{
            '/courses': __('courses'),
            '/courses/add': __('course_add_title')
          }}
        />
        <Stack
          component={'form'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h5" textTransform={'none'} fontWeight={700}>
            {__('course_add')}
          </Typography>
          <Stack direction={'row'} gap={1}>
            <Button
              sx={{
                p: theme => theme.spacing(2, 3),
                background: theme => theme.palette.background.paper,
                ':focus,:hover': { background: theme => theme.palette.background.paper }
              }}
            >
              <Typography textTransform={'none'} color="text.secondary" fontWeight={600}>
                {__('home_btn_cancel')}
              </Typography>
            </Button>
            <Button
              sx={{
                p: theme => theme.spacing(2, 3),
                ':focus': { background: theme => theme.palette.primary.main }
              }}
            >
              <Typography textTransform={'none'} fontWeight={600}>
                {__('course_add_title')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Box className="body">
          <Paper className="paper_wrapper" sx={{ gridColumn: 'span 2' }}>
            <Stack direction={'row'} gap={6}>
              <Box flex={0.6} sx={{ height: theme => theme.spacing(48.125) }}>
                <DragDropUploadMedia
                  callbackSetUrl={handleAvatarChange}
                  urlDefaultThumbnail={urlCourseMedia}
                  placeholderText={<div>{__('course_size_image_cover')}</div>}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </Box>
              <Box flex={0.4}>
                <Stack gap={2}>
                  <Select
                    options={[{ label: __('course_categories'), value: 'default' }]}
                    value="default"
                    inputProps={{
                      placeholder: __('course_categories'),
                      sx: {
                        padding: theme => theme.spacing(1, 2)
                      }
                    }}
                    getItemValue={item => item?.value}
                    renderItem={item => <Typography>{item?.label}</Typography>}
                  />
                  <TextField isShowMessage={false} placeholder={__('course_name')} />
                  <TextField
                    isShowMessage={false}
                    multiline
                    rows={3}
                    placeholder={__('course_short_des')}
                  />
                  <Stack
                    gap={2}
                    sx={{
                      border: theme => `1px solid ${theme.palette.divider}`,
                      borderRadius: theme => theme.spacing(1.5)
                    }}
                  >
                    <TextField
                      isShowMessage={false}
                      placeholder={__('course_price_less')}
                      iconEnd={<>{formatString(__('currency_unit_default'), '')}</>}
                    />
                    <Stack gap={2} sx={{ p: theme => theme.spacing(0, 1.5, 1.25, 1.5) }}>
                      <Typography fontWeight={600}>{__('course_revenue_share_info')}</Typography>
                      <Stack className="revenue_share_wrapper">
                        <Stack direction={'row'} gap={1}>
                          <Typography color="text.secondary">Chủ kênh</Typography>
                          <Typography color="text.secondary">90%</Typography>
                        </Stack>
                        <Typography className="share_value">
                          {formatString(__('currency_unit_default'), '_')}
                        </Typography>
                      </Stack>
                      <Stack className="revenue_share_wrapper">
                        <Stack direction={'row'} gap={1}>
                          <Typography color="text.secondary">Người giới thiệu</Typography>
                          <Typography color="text.secondary">10%</Typography>
                        </Stack>
                        <Typography className="share_value">
                          {formatString(__('currency_unit_default'), '_')}
                        </Typography>
                      </Stack>
                      <Typography variant="body2" color="text.secondary" fontWeight={600}>
                        Lưu ý: Trường hợp không có người giới thiệu, phần doanh thu này sẽ thuộc về
                        người xxx
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Paper>
          <Paper className="paper_wrapper" sx={{ gridColumn: 1, gridRow: 2 }}>
            <Stack gap={2}>
              <Typography variant="h6" textTransform={'none'} fontWeight={700}>
                {__('course_detail')}
              </Typography>
              <Box className="ck_editor">
                <CKEditor
                  {...fields.long_description}
                  id={'description'}
                  editor={ClassicEditor}
                  data={fields.long_description?.value}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    fields.long_description.onChange(data)
                  }}
                  config={{
                    placeholder: `${__('course_placeholder')}`,
                    plugins: ['Bold', 'Italic', 'Heading', 'Link', 'List', 'Table', 'TableToolbar']
                  }}
                />
              </Box>
            </Stack>
          </Paper>
          <Paper className="paper_wrapper" sx={{ gridColumn: 2, gridRow: 2 }}>
            <Stack gap={2}>
              <Typography variant="h6" textTransform={'none'} fontWeight={700}>
                {__('course_advanced_setting')}
              </Typography>
              <Typography>{__('course_public_label')}</Typography>
              <FormGroup sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={__('course_everybody')}
                />
                <FormControlLabel
                  control={<Checkbox className="checkbox" defaultChecked />}
                  label={__('course_limit')}
                />
              </FormGroup>
              <Select
                options={[{ label: __('course_level_study'), value: 'default' }]}
                value="default"
                inputProps={{
                  placeholder: __('course_level_study'),
                  sx: {
                    padding: theme => theme.spacing(1, 2)
                  }
                }}
                getItemValue={item => item?.value}
                renderItem={item => <Typography>{item?.label}</Typography>}
              />
            </Stack>
          </Paper>
        </Box>
      </CourseAddContainer>
    </BigLayout>
  )
}

const CourseAddContainer = styled(Stack)(({ theme }) => ({
  '& .body': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: theme.spacing(2)
  },
  '& .paper_wrapper': {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(3),
    height: 'fit-content',
    minHeight: theme.spacing(31.375)
  },
  '& .revenue_share_wrapper': {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1)
  },
  '& .share_value': {
    color: 'text.secondary',
    fontWeight: 600,
    padding: theme.spacing(0.5, 1.25),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1.5)
  },
  '& .ck_editor': {
    '& .ck-editor__editable_inline': {
      minHeight: theme.spacing(14.625)
    }
  }
}))

const DragDropUploadMedia = ({
  callbackSetUrl,
  placeHolderBelow,
  errorFromParent = '',
  placeHolder,
  urlDefaultThumbnail,
  accept,
  placeholderText
}: {
  urlDefaultThumbnail?: string
  callbackSetUrl?: (media_id: string) => void
  placeHolder?: string
  errorFromParent?: string
  accept?: string
  placeHolderBelow?: string
  placeholderText?: string | React.ReactNode
}) => {
  // drag state
  const [dragActive, setDragActive] = useState(false)
  const [progress, setProgress] = useState(urlDefaultThumbnail ? 0 : -1)
  const [urlThumbnail, setUrlThumbnail] = useState('')
  const [error, setError] = useState('')
  const inputRef = useRef(null)
  const [retryCount, setRetryCount] = useState(0)
  const [mediaType, setMediaType] = useState<string>('')
  const refTimeoutHandleError = useRef<any>()

  useEffect(() => {
    setProgress(urlDefaultThumbnail ? 0 : -1)
  }, [urlDefaultThumbnail])
  const handleImageLoadError = () => {
    // Increment the retry count when image loading fails
    refTimeoutHandleError.current = setTimeout(() => {
      setRetryCount(retryCount + 1)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (refTimeoutHandleError.current) {
        clearTimeout(refTimeoutHandleError.current)
      }
    }
  }, [])

  useEffect(() => {
    setError(errorFromParent)
  }, [errorFromParent])

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = async function (e) {
    setError('')
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    await onUpload(e.dataTransfer.files)
  }

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault()
    setError('')

    await onUpload(e?.target?.files)
  }

  const onUpload = useCallback(async (files: any[]) => {
    if (files && files[0]) {
      if (files?.length === 1) {
        if (files?.[0]?.size < 5000000000) {
          callbackSetUrl?.('')
          const formData = new FormData()
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
          }
          setUrlThumbnail('')
          let res = await uploadMedia(formData, setProgress, undefined, () => {
            setError(`${__('drag_drop_text_error')}`)
            setProgress(-1)
          })

          if (res && res?.length > 0) {
            setUrlThumbnail(res?.[0]?.src)
            callbackSetUrl?.(res?.[0]?.callback?._id)
            setProgress(0)
            setMediaType(res?.[0]?.callback?.media_type)
          } else {
            setError(`${__('drag_drop_text_error')}`)
            setProgress(-1)
            setUrlThumbnail('')
            setMediaType('')
          }
        } else {
          setError(`${__('drag_drop_max_size')}`)
        }
      } else {
        setError(`${__('drag_drop_select_image_or_video')}`)
      }
    }
  }, [])

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  return (
    <DragDropUploadMediaContainer>
      {progress < 100 && progress > 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 1
          }}
        >
          <CircularProgress variant="determinate" value={progress || 0} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography component="div" color="text.secondary">{`${progress}%`}</Typography>
          </Box>
        </Box>
      )}

      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
        <input
          accept={accept || 'video/mp4, video/mov, image/png, image/jpeg, image/jpg'}
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
        />
        <Stack
          component={'label'}
          sx={{ alignItems: 'center', gap: theme => theme.spacing(3) }}
          id="label-file-upload"
          htmlFor="input-file-upload"
        >
          <UploaderIcon />
          <Stack direction={'row'} gap={0.5}>
            <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
              Kéo và thả tập tin hoặc
            </Typography>
            <Typography
              htmlFor="input-file-upload"
              component={'label'}
              variant="subtitle1"
              textTransform={'none'}
              color="primary.main"
              sx={{ textDecoration: 'underline' }}
            >
              Tải lên
            </Typography>
          </Stack>
          <Stack>
            <Typography color="text.secondary">
              Chọn ảnh tải lên tỷ lệ 16:9 (1280 x 720 px)
            </Typography>
            <Typography color="text.secondary">Định dạng JPG, PNG</Typography>
          </Stack>
          {/* <div>
            <div>{placeHolder || `${__('drag_drop_text')}`}</div>
            <button className="upload-button" onClick={onButtonClick} type={'button'}>
              {placeHolderBelow || `${__('drag_drop_select_image_or_video_to_upload')}`}
            </button>
            <div style={{ color: 'red' }}>{error}</div>
          </div> */}
        </Stack>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>

      {!error && (urlThumbnail || urlDefaultThumbnail) ? (
        <Box className="image_wrapper">
          {mediaType !== 'video' ? (
            <Box
              component={'img'}
              className="image_wrapper"
              src={urlThumbnail ? urlThumbnail + '?retry=' + retryCount : urlDefaultThumbnail}
              alt="course/media"
              onError={handleImageLoadError}
            />
          ) : (
            <video
              controlsList={'nodownload'}
              key={urlThumbnail}
              autoPlay={true}
              preload={'auto'}
              id="video_source"
            >
              <source src={urlThumbnail} type="video/mp4" />
            </video>
          )}
        </Box>
      ) : null}
    </DragDropUploadMediaContainer>
  )
}

const DragDropUploadMediaContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  objectFit: 'cover',
  cursor: 'pointer',
  '& #label-file-upload': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${theme.palette.text.disabled}`,
    borderRadius: theme.spacing(1.5)
  },
  '& .image_wrapper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    objectFit: 'cover',
    borderRadius: theme.spacing(1.5)
  }
}))
