import { SvgIcon, SvgIconProps } from '@mui/material'

const HomeSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
      >
        <path
          d="M18.9844 0.863529V8.31637C19.6254 8.31475 20.2412 8.56635 20.6977 9.01643L27.0659 15.2928L31.49 19.654L32.7958 20.9477C33.2533 21.3953 33.8679 21.6459 34.5079 21.6459C35.1479 21.6459 35.7625 21.3953 36.22 20.9477C36.51 20.6636 36.7405 20.3244 36.8978 19.9501C37.0551 19.5758 37.1361 19.1738 37.1361 18.7677C37.1361 18.3617 37.0551 17.9597 36.8978 17.5854C36.7405 17.2111 36.51 16.8719 36.22 16.5878L22.9091 3.45945L21.1946 1.76672C20.6053 1.1867 19.8112 0.862207 18.9844 0.863529Z"
          fill="url(#paint0_linear_9774_224192)"
        />
        <path
          d="M18.9629 7.29126V20.5127H18.9726V20.5018H32.3487L19.0149 7.24048L18.9629 7.29126Z"
          fill="#B6D4F2"
        />
        <path d="M18.9727 20.5015V20.5123H32.3597L32.3488 20.5015H18.9727Z" fill="#C5E1E6" />
        <path
          d="M18.9727 20.5125V28.6666H21.5117V36.1279C21.5127 36.2612 21.5399 36.3931 21.5918 36.516C21.6437 36.6388 21.7194 36.7503 21.8143 36.8439C21.9093 36.9375 22.0218 37.0116 22.1454 37.0617C22.269 37.1119 22.4013 37.1372 22.5346 37.1362H29.0722C29.9361 37.1408 30.7666 36.8026 31.3817 36.1959C31.9968 35.5892 32.3463 34.7634 32.3537 33.8995V20.5125H18.9727Z"
          fill="#B6D4F2"
        />
        <path d="M32.3597 20.5015H18.9727V20.5123H32.3597V20.5015Z" fill="#C5E1E6" />
        <path
          d="M19.015 0.863532V8.31637C18.3744 8.31494 17.7591 8.56653 17.3029 9.01643L10.9347 15.2928L6.5106 19.654L5.20478 20.9477C4.74706 21.3953 4.1323 21.646 3.4921 21.646C2.8519 21.646 2.23714 21.3953 1.77942 20.9477C1.48937 20.6636 1.25894 20.3244 1.10162 19.9501C0.94431 19.5758 0.863281 19.1738 0.863281 18.7677C0.863281 18.3617 0.94431 17.9597 1.10162 17.5854C1.25894 17.2111 1.48937 16.8719 1.77942 16.5878L15.0951 3.45945H15.0891L16.8036 1.76672C17.3932 1.18641 18.1877 0.861891 19.015 0.863532Z"
          fill="url(#paint1_linear_9774_224192)"
        />
        <path
          d="M19.0382 7.29126V20.5127H19.0285V20.5018H5.65234L18.9862 7.24048L19.0382 7.29126Z"
          fill="#E7EFFF"
        />
        <path d="M19.0277 20.5015V20.5123H5.64062L5.65151 20.5015H19.0277Z" fill="#E7F0EF" />
        <path
          d="M19.0275 20.5125V28.6666H16.4884V36.1279C16.4875 36.2612 16.4602 36.3931 16.4083 36.516C16.3564 36.6388 16.2808 36.7503 16.1858 36.8439C16.0908 36.9375 15.9783 37.0116 15.8547 37.0617C15.7311 37.1119 15.5989 37.1372 15.4655 37.1362H8.92917C8.06501 37.1411 7.2342 36.8031 6.61885 36.1963C6.0035 35.5896 5.65383 34.7636 5.64648 33.8995V20.5125H19.0275Z"
          fill="#E7EFFF"
        />
        <path d="M19.0277 20.5015H5.64062V20.5123H19.0277V20.5015Z" fill="#E7F0EF" />
        <defs>
          <linearGradient
            id="paint0_linear_9774_224192"
            x1="19.4168"
            y1="4.19895"
            x2="34.8431"
            y2="19.6253"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0209108" stopColor="#F47C27" />
            <stop offset="1" stopColor="#FF5200" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_9774_224192"
            x1="9.93868"
            y1="20.3213"
            x2="9.93868"
            y2="-1.58921"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
export default HomeSidebarIcon
