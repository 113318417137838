import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import MemberPage from './member.page'
import MyIntroductionPage from './member.myIntroduction'

export default function Member() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'member'
  let ActualPage: any

  switch (Param) {
    case 'member':
      ActualPage = MemberPage
      break
    case 'my-introduction':
      ActualPage = MyIntroductionPage
      break

    default:
      ActualPage = Theme404
      break
  }
  return <div className="mainFrame">{<ActualPage />}</div>
}
