import __ from 'languages/index'
import React from 'react'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { Button, Divider, Modal, Typography } from '@mui/material'

export default function ModalAccept(props) {
  const descriptionLines = props.desciption
    ?.split('\n')
    .map((line, index) => <p key={index}>{line}</p>)
  return (
    <Modal
      {...props}
      open={props?.show}
      onClose={() => props?.onHide()}
    >
      <ModalContainer >
        <ModalHeader >
          <Typography
            variant={'h6'}
            id="contained-modal-title-vcenter"
          >
            {props?.title}
          </Typography>
        </ModalHeader>
        <ModalBody
          sx={{
            padding: theme => theme.spacing(1.5, 3)
          }}
        >
          {descriptionLines}
        </ModalBody>
        <Divider sx={{ borderColor: theme => theme.palette.text.disabled }}/>
        <ModalFooter
          sx={{
            display: 'flex',
            alignItems: 'flex-end !important',
            justifyContent: 'flex-end',
            flexDirection: 'row'
          }}
        >
          {props?.showMultiSelect &&
            <Button
              sx={{
                marginRight: theme => theme.spacing(1),
                textTransform: 'none',
                fontSize: theme => theme.spacing(2),
              }}
              onClick={props?.onAcceptSecond}
            >
              {props?.btnSecond ?? __('btn_confirm')}</Button>
          }
          <Button
            sx={{
              textTransform: 'none',
              fontSize: theme => theme.spacing(2),
            }}
            onClick={props?.onAccept}
          >
            {props?.btnFirst ?? __('btn_confirm')}
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: theme => theme.spacing(2),
              background: theme => theme.palette.primary.backgroundHover,
              marginLeft: theme => theme.spacing(1)

            }}
            variant="text"
            onClick={() => props.onHide()}
          >
            {__('btn_cancel')}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
