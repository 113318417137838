import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material'
import ChannelSettingsTitle from 'entities/channel-settings/components/ChannelSettingsTitle'
import __ from 'languages/index'
import RenderView from 'components/RenderView'
import Table from 'entities/channel-settings/components/Table'
import React, { useCallback } from 'react'
import { StyledTableCell } from 'entities/channel-settings/components/StyledTableCell'
import { TypeTreasureConfig } from '../../../interfaces/channel.interface'
import RowActions from 'entities/channel-settings/components/RowActions'
import { TypeChannelConfig } from 'entities/channel-settings/Capability/index'
import TextField from 'components/TextFieldMUI'

const ChannelMission = ({channelSetting, handleUpdateChannelInfo, setEditCommission, editCommission}:TypeChannelConfig)=>{

  const handleClickEditBtn = (data: TypeTreasureConfig) => {
    setEditCommission(data)
  }

  const handleUpdateMission = useCallback(()=>{
    const updateData = channelSetting?.treasure_config.filter((item)=>item?.key_treasure !== editCommission?.key_treasure)
    updateData.push(editCommission)
    handleUpdateChannelInfo({
      treasure_config: updateData
    },__('channel_setting_mission'))
  },[editCommission])

  const renderHeader = () => {
    return (
      <TableRow>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_mission_type")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_mission_time")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_mission_gift_min")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_mission_gift_max")}</StyledTableCell>
        <StyledTableCell sx={{whiteSpace: "nowrap"}}>{__("channel_setting_mission_ratio")}</StyledTableCell>
        <StyledTableCell sx={{ whiteSpace: "nowrap",textAlign: 'end'}}>{__("channel_setting_table_action")}</StyledTableCell>
      </TableRow>
    )
  }

  const renderCell = (item: TypeTreasureConfig) => {
    return (
      <>
        <TableCell sx={{whiteSpace: "nowrap"}}>
          <Typography>{item?.title}</Typography>
        </TableCell>
        <TableCell>
          {item?.key_treasure === editCommission?.key_treasure  ? (
            <TextField
              type="number"
              minLength={10}
              min={0}
              value={editCommission?.time_step.toString()}
              onChange={value => {
                setEditCommission({
                  ...editCommission,
                  time_step: Number(value)
                })
              }}
              autoFocus
              label={__('channel_setting_time_open')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {item?.time_step}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {item?.key_treasure === editCommission?.key_treasure ? (
            <TextField
              type="number"
              minLength={10}
              min={0}
              value={editCommission?.min_reward.toString()}
              onChange={value => {
                setEditCommission({
                  ...editCommission,
                  min_reward: Number(value)
                })
              }}
              label={__('channel_setting_gift_min')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {item?.min_reward}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {item?.key_treasure === editCommission?.key_treasure  ? (
            <TextField
              type="number"
              minLength={10}
              min={0}
              value={editCommission?.max_reward.toString()}
              onChange={value => {
                setEditCommission({
                  ...editCommission,
                  max_reward: Number(value)
                })
              }}
              label={__('channel_setting_gift_max')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {item?.max_reward}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {item?.key_treasure === editCommission?.key_treasure  ? (
            <TextField
              type="number"
              minLength={10}
              min={0}
              value={editCommission?.percent_reward.toString()}
              onChange={value => {
                setEditCommission({
                  ...editCommission,
                  percent_reward: Number(value)
                })
              }}
              label={__('channel_setting_mission_ratio')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                width: 'fit-content'
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {item?.percent_reward}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => handleClickEditBtn(item)}
            editing={item?.key_treasure === editCommission?.key_treasure}
            onConfirm={handleUpdateMission}
          />
        </TableCell>
      </>
    )
  }

  return(
    <ChannelSettingsContentContainer>
      <Stack gap={2}>
        <ChannelSettingsTitle>{__('channel_setting_mission')}</ChannelSettingsTitle>
        <Typography color={'text.secondary'}>
          {__("channel_setting_mission_sub_text")}
        </Typography>
        <RenderView
          view={
            <Table
              data={channelSetting?.treasure_config}
              renderTableRow={item => renderCell(item)}
              renderHeader={renderHeader}
            />
          }
          isEmpty={!Boolean(channelSetting?.treasure_config)}
        />
        <Box>
          <Typography p={theme => theme.spacing(1,0)} color={'text.secondary'}>{__("channel_setting_mission_open_time_note")}</Typography>
          <Typography p={theme => theme.spacing(1,0)} color={'text.secondary'}>{__("channel_setting_mission_gift_note")}</Typography>
          <Typography p={theme => theme.spacing(1,0)} color={'text.secondary'}>{__("channel_setting_mission_ratio_note")}</Typography>
        </Box>
      </Stack>
    </ChannelSettingsContentContainer>
  )
}
export default ChannelMission
