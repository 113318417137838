import React, {forwardRef, memo, useCallback, useImperativeHandle, useState} from "react";
import Modal from "react-bootstrap/Modal";
import __ from "languages/index";


const EventPlaybackLivestreamModal = forwardRef((_, ref) => {
    const [show, setShow] = useState(false);
    const [dataVideo, setDataVideo] = useState({
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      muted: false,
      volume:1,
      sources: [{
        src: "",
        type: "application/x-mpegURL"
      }],
      name: ""
    })


    useImperativeHandle(ref, () => ({
      showVideoModal: (urlVideo: string, nameVideo: string) => {
        console.log("kakakaka")
        setDataVideo(old=>({
          ...old,
          sources: [{
            src: urlVideo,
            type: "application/x-mpegURL"
          }],
          name: nameVideo
        }))
        setShow(true);
      }
    }));

    const handleClose = useCallback(() => setShow(false), []);

    return (
      <Modal show={show} onHide={handleClose}
             size={"lg"}
             aria-labelledby="contained-modal-title-vcenter"
             className={"livestreamAdminDeviceModal"}
             centered>
        <Modal.Header className="livepreview_modal_body_bg" closeButton>
          <Modal.Title className="text-white">{__("event_detail_review_live")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"livepreview_modal_body_bg"}>
          <h5 className="text-lg-center text-white">{dataVideo?.name}</h5>
        </Modal.Body>
      </Modal>
    )
  }
)

export default memo(EventPlaybackLivestreamModal)
