import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'config/store'
import { createNewNotify, getListNotification } from 'entities/challenge/services'
import {
  CreateNewNotificationRequest,
  NotificationType
} from 'entities/challenge/challenge.interface'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import ComponentEmpty from 'components/componentEmpty'
import CreateNewNotificationModal from '../CreateNewNotificationModal'
import { useInfinite } from '../../../../hooks/useInfinite'
import Avatar from 'components/Avatar'
import { useGetChallengeDetailQuery } from 'entities/challenge/store/challengeApi'
import { outOfTime } from 'entities/challenge/helpers'
import DateTime from 'date-and-time'
import __ from 'languages/index'
import helpers from 'helpers/index'
import { Box, Button, Typography, styled } from '@mui/material'

const LIMIT = 10

type NotificationCardType = NotificationType

function ChallengeAnnounced() {
  const [showModalNotifyModal, setShowModalNotifyModal] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<NotificationCardType[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const permission_data = useAppSelector(state => state.user.permission_data)

  const { challenge_id } = useParams()
  const account = useAppSelector(state => state.user.user_data)
  const { data: challengeDetail } = useGetChallengeDetailQuery({
    challengeId: challenge_id,
    authId: account?._id
  })

  const toast = useToast()
  const { ref } = useInfinite(notifications.length < totalCount, () =>
    setPage(prevPage => prevPage + 1)
  )

  const openModalHandler = () => {
    setShowModalNotifyModal(true)
  }

  const createNotificationHandler = async detail => {
    const newNotification: CreateNewNotificationRequest = {
      ...detail,
      challenge_id
    }
    try {
      const res = await createNewNotify(newNotification)
      setNotifications(prev => [res.data, ...prev])
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__('challenge_create_new_notification_success')}`
      })
      setShowModalNotifyModal(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    ;(async function () {
      try {
        const res = await getListNotification({
          params: {
            challenge_id,
            order_by: 'DESC',
            limit: LIMIT,
            page
          }
        })
        const totalDataLength = res.headers['x-total-count']
        setTotalCount(totalDataLength)
        setNotifications(prev => [...prev, ...res.data])
      } catch (err) {
        console.log(err)
      }
    })()
  }, [page])

  return (
    <ChallengeAnnouncedContainer className="w-100 mt-3">
      {helpers.current_user_can('boss', permission_data) && (
        <div className="d-flex mb-2 justify-content-end">
          {!outOfTime(challengeDetail?.end_time) && (
            <Button onClick={openModalHandler}>{__('challenge_create_new_notification')}</Button>
          )}
        </div>
      )}
      <CreateNewNotificationModal
        show={showModalNotifyModal}
        onSave={async detail => await createNotificationHandler(detail)}
        onHide={() => setShowModalNotifyModal(false)}
      />

      {!notifications.length ? (
        <ComponentEmpty />
      ) : (
        notifications.map((notification, index) => (
          <Box className="item_noti" key={index}>
            <Box className="item_noti_content">
              <Avatar
                src={
                  notification?.user_id?.user_avatar_thumbnail || notification?.user_id?.user_avatar
                }
                roundedCircle
                width={60}
                height={60}
              />
              <Typography variant="subtitle2" className={'fw-semibold fs-6 text-black'}>
                {notification?.user_id?.display_name} đã đăng {notification?.title}
              </Typography>
              <Typography className="text-muted" style={{ fontSize: '12px' }}>
                {DateTime.format(new Date(notification?.createdAt), 'HH:mm, DD/MM/YYYY')}
              </Typography>
            </Box>

            {notification?.public_album[0]?.media_url && (
              <Card.Img
                className="item_noti_media"
                src={notification?.public_album[0]?.media_url}
                height="auto"
              />
            )}
          </Box>
        ))
      )}
    </ChallengeAnnouncedContainer>
  )
}

export default ChallengeAnnounced

const ChallengeAnnouncedContainer = styled(Box)(({ theme }) => ({
  '& .item_noti': {
    '&_content': {
      display: 'grid',
      gridTemplateColumns: '83px 1fr 110px',
      margin: theme.spacing(2, 0),

      '& h6, p': {
        margin: 'auto 0'
      },

      '& p': {
        textAlign: 'end'
      }
    }
  }
}))
