//Library
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Paper, Typography, keyframes, styled } from '@mui/material'

//Components
import _Helmet from 'components/helmet'

//Image
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { checkOTP, getOTP } from './store/validateCode.store.reducer'

//Scss
import './media/validateCode.page.scss'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import { checkJoinChannel, getCurrentUserData, logout } from 'store/user.store.reducer'
import InputVerificationCode from 'components/InputVerificationCode'
import { formatString } from '../support/utils/index'

const ValidateCodePage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [validateCode, setValidateCode] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState<number>(120)
  const [isCounting, setIsCounting] = useState<boolean>(false)
  const [isLoadingValidated, setIsLoadingValidated] = useState<boolean>(false)
  const [isEmail, setIsEmail] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string>('')
  const toast = useToast()

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [])

  const handleCheckJoinChannel = async () => {
    return await dispatch(checkJoinChannel())
      .unwrap()
      .then(res => {
        if (res?.data?.is_join) {
          dispatch(getCurrentUserData())
          navigate('/', { replace: true })
        } else {
          navigate(`/channel/join`)
        }
      })
      .catch(error => {
        console.log('checkJoinChannel Error >>>', error)
      })
  }

  const handleSubmitCode = useCallback(
    async (verification_code: string) => {
      const formData = {
        verification_code: verification_code,
        session_id: sessionId
      }
      setIsLoadingValidated(true)
      await dispatch(checkOTP(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          setIsLoadingValidated(false)
          toast.show({
            content: `${__('validate_code_page_verify_success')}`,
            type: EnumTypeToast.Success
          })
          handleCheckJoinChannel()
        })
        .catch((error: AxiosError) => {
          console.log(`sendOTP_${error}`)
          setIsLoadingValidated(false)
          toast.show({
            content: `${error}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [validateCode, sessionId, channel_data]
  )

  const handleGetOTP = useCallback(() => {
    const user_input = localStorage.getItem('user_input_verify')
    try {
      setIsEmail(JSON.parse(user_input).indexOf('@') === -1 ? false : true)
      dispatch(getOTP({ user_input: JSON.parse(user_input) }))
        .unwrap()
        .then((res: AxiosResponse) => {
          setSessionId(res?.data?.session_id || '')
        })
        .catch((error: AxiosError) => {
          console.log(`getOTP_${error}`)
          toast.show({
            content: `${error}`,
            type: EnumTypeToast.Error
          })
        })
    } catch (error) {}
  }, [])

  const countTime = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }, [timeLeft])

  useEffect(() => {
    let timer
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    }
    if (timeLeft === 0) {
      setIsCounting(false)
      setTimeLeft(120)
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isCounting, timeLeft])

  useEffect(() => {
    if (!localStorage.getItem('user_input_verify')) return navigate('/login')
    handleGetOTP()
    return () => {
      localStorage.removeItem('user_input_verify')
    }
  }, [])

  return (
    <>
      <_Helmet title={'validate_code_page_title'} />
      <ValidateCodePageContainer id="validateCodePage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.channel_primary_color }))}')`
          }}
        />

        <Box id={'background_svg_default'} />
        <Box className="wrapper">
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: 'auto'
            }}
          >
            <Box padding={theme => theme.spacing(4.5)} display={'grid'} rowGap={4.5}>
              <Box display={'grid'} rowGap={2}>
                <Typography
                  fontWeight={700}
                  textAlign={'center'}
                  color="primary.main"
                  textTransform={'none'}
                  variant="h5"
                >
                  {__('validate_code_page_title')}
                </Typography>

                <Typography
                  fontWeight={600}
                  textAlign={'center'}
                  color="text.primary"
                  variant="body1"
                >
                  {formatString(
                    __('validate_code_page_sub_title'),
                    __(isEmail ? 'Email' : 'phone_number')
                  )}
                </Typography>
              </Box>

              <Box
                component={'form'}
                display={'grid'}
                rowGap={4.5}
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmitCode(validateCode)
                }}
              >
                <InputVerificationCode
                  onCompleted={handleSubmitCode}
                  onChange={(value: string) => {
                    setValidateCode(value)
                  }}
                />
                <Button
                  type="submit"
                  sx={{ textTransform: 'none' }}
                  disabled={validateCode?.length < 6 || isLoadingValidated}
                  className="mt-3"
                >
                  {isLoadingValidated ? __('btn_processing') : __('validate_code_page_verify')}
                </Button>
              </Box>
              <Box justifyContent={'center'} display={'flex'}>
                <span>{__('validate_code_page_question')}</span>
                <Button
                  variant="text"
                  disabled={isCounting}
                  onClick={() => {
                    handleGetOTP()
                    setIsCounting(true)
                  }}
                  sx={{ p: theme => theme.spacing(0, 0.5), columnGap: theme => theme.spacing(0.5) }}
                >
                  <Typography textTransform={'none'} color="info.main">
                    {isCounting ? __('validate_code_page_resend_later') : __('validate_code_page_resend')}
                  </Typography>
                  {isCounting && (
                    <Typography textTransform={'none'} color="info.main">
                      {`(${countTime})`}
                    </Typography>
                  )}
                </Button>
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Button
                  variant="text"
                  onClick={handleLogout}
                  sx={{ p: theme => theme.spacing(0, 0.5), columnGap: theme => theme.spacing(0.5) }}
                >
                  <Typography
                    textTransform={'none'}
                    sx={{ textDecorationLine: 'underline' }}
                    color="info.main"
                  >
                    {__('header_logout')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </ValidateCodePageContainer>
    </>
  )
}

export default ValidateCodePage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`

const ValidateCodePageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  '& #background_svg': {
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    '@media(max-height: 648px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  }
}))
