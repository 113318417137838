import {Box, Modal, styled, Typography} from '@mui/material'
import __ from 'languages/index'
import logoutLottie from 'lotties/logout.json'
import Lottie from 'lottie-react'
import {useAppDispatch, useAppSelector} from "config/store";
import {useCallback, useEffect} from "react";
import {userLoggedOut} from "../store/auth.store.reducer";
import helpers from "helpers/index";
import {resetLogout} from "../store/user.store.reducer";
import { ROUTER } from 'constant/router';
import { useNavigate } from 'react-router-dom';

const LogoutModal = () => {
  const isSigningOut = useAppSelector(state => state.user.isSigningOut)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logout = useCallback(async () => {
    try {
      await dispatch(userLoggedOut());
      localStorage.removeItem('user');
      localStorage.removeItem('channel');
      localStorage.removeItem('new-bie');
      localStorage.removeItem('success_mission');
      localStorage.removeItem('permission');
      localStorage.removeItem('session');
      localStorage.removeItem('referrer_code');
      helpers.setCookie('session', '', new Date().getTime() - 84600);
      await helpers.sleep(500);
      navigate(ROUTER.AUTH.LOGIN, {replace: true})
      dispatch(resetLogout());
    } catch (e) {
    }
  }, []);

  useEffect(() => {
    if (isSigningOut) {
      logout()
    }
  }, [isSigningOut]);

  return (
    <LogoutModalContainer open={isSigningOut} hideBackdrop={false}>
      <Box className="wrapper">
        <Box display={'flex'} alignItems={'center'}>
          <Box
            sx={{
              width: theme => theme.spacing(10),
              height: theme => theme.spacing(10)
            }}
          >
            <Lottie
              animationData={logoutLottie}
              style={{width: '100%', height: '100%'}}
              autoPlay
              loop
            />
          </Box>
        </Box>
        <Typography textTransform={'none'} variant="h6">
          {__('signing_out')}
        </Typography>
      </Box>
    </LogoutModalContainer>
  )
}

export default LogoutModal

const LogoutModalContainer = styled(Modal)(({theme}) => ({
  '& .wrapper': {
    display: 'grid',
    justifyContent: 'center',
    justifyItems: 'center',
    outline: 'none',
    height: theme.spacing(25),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 12px 0px rgba(14, 20, 38, 0.12)',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1.5)
  }
}))
