//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'


export const initialState = {
  redirectToUrl: ''
}

/**
 * @deprecated remove in a near future ...
 */
export const getListSystem = createAsyncThunk(
  'auth/get_list_system',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/config/list/system`)
      return response?.data?.config?.option_content.filter(
        (e: any) => e.key === 'is_enable_validate_phone'
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const userLoggedOut = createAsyncThunk('user/logout', async () => {
  return await axios.get<any>(`/logout`)
})

export const loginWithPassword = createAsyncThunk(
  'user/login_with_password',
  async (entities: any) => {
    return await axios.post<any>(`/login`, entities)
  }
)

export const loginWithFacebook = createAsyncThunk(
  'user/login_with_facebook',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`/login/facebook`, body)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const loginWithGoogle = createAsyncThunk(
  'user/login_with_google',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`/login/google`, body)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const registerAccount = createAsyncThunk(
  'auth/register_account',
  async (body: any) => {
    return await axios.post<unknown>(`/register`, body)
  },

  { serializeError: serializeAxiosError }
)

export const AUTH_REDUCER = createEntitySlice({
  name: 'auth',
  initialState: initialState as any,
  reducers: {
    redirectTo(state, action){
     state.redirectToUrl = action.payload
    }
  },
  extraReducers(builder) {}
})

export const {redirectTo} = AUTH_REDUCER.actions

export default AUTH_REDUCER.reducer
