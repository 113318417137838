import { SvgIcon, SvgIconProps } from '@mui/material'

const WatchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 7C12.5523 7 13 7.44771 13 8V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H12.5C11.6716 13 11 12.3284 11 11.5V8C11 7.44771 11.4477 7 12 7Z"
        />
      </svg>
    </SvgIcon>
  )
}
export default WatchIcon
