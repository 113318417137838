//Library
import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import Grid2 from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material'

//Components
import Sidebar from 'components/Sidebar'
import HeaderComponent from './Header'
import _Helmet from 'components/helmet'
import Capacity from 'components/Capacity'
import WithPodcastLayout from 'layout/WithPodcastLayout.layout'

//Config
import __ from 'languages/index'

//Helper
import helpers from 'helpers'
import { useEffect } from 'react'
import { fetchChannelInfo } from 'entities/channel-settings/store/channel.slice'
import { useAppDispatch } from 'config/store'

const MainLayout = () => {
  const checkMobile = helpers.isMobile()
  const dispatch = useAppDispatch()

  const init = () => {
    //TODO: init channel info, user info in this function
    dispatch(fetchChannelInfo())
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <MainLayoutContainer container spacing={3}>
        <Grid
          xs={12}
          sx={{
            padding: '0',
            height: theme => theme.spacing(10.5)
          }}
        >
          <HeaderComponent />
        </Grid>
        <Grid
          xs={2}
          sx={{
            padding: theme => theme.spacing(2, 0, 2, 3)
          }}
          className="side_bar"
        >
          <Sidebar />
        </Grid>

        <Grid
          className="content"
          sx={{
            padding: theme => (checkMobile ? 0 : theme.spacing(2, 2))
          }}
          xs={checkMobile ? 12 : 10}
        >
          <WithPodcastLayout>
            {' '}
            <Outlet />
          </WithPodcastLayout>
        </Grid>
      </MainLayoutContainer>
    </>
  )
}

export default MainLayout

const MainLayoutContainer = styled(Grid2)(({ theme }) => ({
  maxWidth: theme.spacing(240),
  margin: '0 auto',
  // padding: theme.spacing(2, 3)
  '@media(max-width: 1024px)': {
    '& .side_bar': {
      display: 'none'
    },
    '& .content': {
      width: '100%'
    }
  }
}))
