//Library
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import __ from 'languages/index'

//Components
import NotificationLevel from 'components/notification_level'
import { optionMissionData } from 'entities/mission/data'

//Icons
import JsonGoldRing from 'media/json/goldRing.json'
import JsonCoin from 'media/json/coin.json'
import Diamond from 'media/json/dinamo.json'

//Config
import { useAppDispatch, useAppSelector } from 'config/store'
import { socket } from 'config/socket.io'

//Helpers
import helpers from 'helpers'
import {
  createListRedeemJoinPermission,
  getListRedeem
} from 'entities/mission/store/mission.store.reduce'
import { AxiosError, AxiosResponse } from 'axios'
import { EnumTypeToast, useToast } from 'hooks/useToast'

const LottieComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const permission_data = useAppSelector(state => state.user.permission_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)

  const [firstMissionData, setFirstMissionData] = useState(null)
  const [showGift, setShowGift] = useState<boolean>(false)

  /**
   * Logic from layout/header.tsx
   */
  const closeCongratulation = () => {
    setShowGift(false)
  }

  const hideNewBie = () => {
    document.body.style.overflow = 'auto'
    var currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    currentDate.setTime(currentDate.getTime() + 86400000)
    localStorage.setItem('new-bie', JSON.stringify(currentDate))
  }

  const formatNumber = useCallback((number: number | string) => {
    return number.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }, [])

  const getGiftScreen = type => {
    if (firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id) {
      if (type == 'gift') {
        navigate('/gift/detail/' + firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id)
      } else {
        navigate('/gift', { state: { option_type: 'coin' } })
      }
      setShowGift(false)
    } else {
      navigate('/gift')
      setShowGift(false)
    }
  }

  const audio_mission_complete: any = useRef(new Audio('./misionComplete.mp3'))
  audio_mission_complete.current.muted = true

  const handleClick = check => {
    try {
      audio_mission_complete.current.currentTime = 0
      audio_mission_complete.current.muted = check
      audio_mission_complete.current
        .play()
        .then(() => {})
        .catch(error => {
          console.error('Lỗi phát âm thanh:', error)
        })
    } catch (error) {}
  }

  window.addEventListener('click', () => {
    handleClick(true)
  })

  const filterMissionFormDay = (response, nameAction) => {
    const today = new Date() // lấy ngày hôm nay
    // Xử lý ngày tạo + numberOfDay - 1
    const filteredData = response.filter((item: any) => {
      const createByDate = new Date(item?.redeem_mission_id.createdAt)
      const numberOfDay = item?.redeem_mission_id?.number_of_day
      createByDate.setDate(createByDate.getDate() + (numberOfDay - 1))
      return createByDate.toDateString() === today.toDateString()
    })
    setFirstMissionData(filteredData[0])

    // Xử lý nếu lọc ra được trên 2 redeem thì chỉ lấy phần đầu tiên
    let listMission = []
    let firstElement: any = {}
    if (filteredData?.length >= 2) {
      firstElement = filteredData[0]
      listMission = filteredData[0]?.redeem_mission_id?.mission_action
    } else {
      if (filteredData?.length === 1) {
        firstElement = filteredData[0]
        listMission = filteredData[0]?.redeem_mission_id?.mission_action
      } else {
        listMission = []
      }
    }
    const findItem = response.findIndex((e: any) => e?._id === firstElement?._id)
    const result = listMission.map((item: any) => {
      const matchingItem = response[findItem].point_data.find(
        (element: any) => element.action_name === item.action_name
      )
      const action_point = parseFloat(item.action_point)
      const point_number = matchingItem ? parseFloat(matchingItem.point_number) : 0
      const widthProgress = (point_number / action_point) * 100
      const nameLabelMission = (name: string) => {
        const filterName = optionMissionData.filter((e: any) => e.key === name)
        return filterName[0].label
      }
      const nameMissionData = (name: string) => {
        const filterName = optionMissionData.filter((e: any) => e.key === name)
        return filterName[0]?.mission
      }

      const nameMission = `${nameLabelMission(item?.action_name)} ${
        item?.action_name !== 'update_profile' ? item?.action_point : ''
      }
      ${nameMissionData(item?.action_name)}`

      return {
        ...item,
        point_number,
        widthProgress,
        nameMission
      }
    })
    const filterData = result.filter((e: any) => Number(e?.action_point) > e.point_number)
    if (nameAction === 'socket') {
      if (helpers.isEmpty(filterData)) {
        setShowGift(true)
        audio_mission_complete.current.play()
        audio_mission_complete.current.mute = false

        setTimeout(() => {
          handleClick(false)
        }, 8000)
      } else {
        setShowGift(false)
      }
    }
  }

  const handleRedeemResponse = useCallback(redeemData => {
    if (helpers.isEmpty(redeemData)) return
    const idArray = redeemData.map(item => item._id)
    const idString = idArray.join(',')
    dispatch(
      createListRedeemJoinPermission({
        user_id: user_data?._id,
        redeem_id: idString
      })
    )
      .unwrap()
      .then((response: AxiosResponse) => {
        filterMissionFormDay(response?.data, 'noSocket')
      })
      .catch((error: AxiosError) => {
        console.error('createListRedeemJoinPermission error:', error)
        toast.show({
          content: `${__(`${error}`)}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  const getDataListRedeem = useCallback(() => {
    if (is_user_logged_in) {
      dispatch(getListRedeem())
        .unwrap()
        .then((res: AxiosResponse) => {
          handleRedeemResponse(res.data)
        })
        .catch((error: AxiosError) => {
          console.error('getListRedeem error:', error)
          toast.show({
            content: `${__(`${error}`)}`,
            type: EnumTypeToast.Error
          })
        })
    }
  }, [is_user_logged_in])

  useEffect(() => {
    getDataListRedeem()
  }, [is_user_logged_in])

  useEffect(() => {
    try {
      socket.on('redeemToClient', data => {
        if (!helpers.isJson(data)) return
        let dataParse = JSON.parse(data)
        if (firstMissionData?._id === dataParse._id) {
          filterMissionFormDay([dataParse], 'socket')
        }
      })
    } catch (error) {
      console.log(error, 'redeemError')
    }
  }, [socket, firstMissionData])

  return (
    <LottieComponentContainer>
      {!helpers.current_user_can('boss', permission_data) && <NotificationLevel />}
      {showGift && !helpers.current_user_can('boss', permission_data) && user_data && (
        <button
          className=" btn header_title_button position-fixed"
          style={{ right: '40px', top: '80px', zIndex: 99999 }}
          onClick={() => closeCongratulation()}
        >
          <i className="bi bi-x-circle" style={{ fontSize: '26px', color: 'white' }}></i>
        </button>
      )}

      {showGift && !helpers.current_user_can('boss', permission_data) && user_data && (
        <div className="position-fixed new_bie_back" style={{ zIndex: 9999 }}>
          <div
            onClick={hideNewBie}
            style={{ background: 'black', opacity: 0.7, width: '100vw', height: '100vh' }}
          ></div>
          <div className="animation_action">
            <Lottie
              animationData={JsonGoldRing}
              style={{
                width: '100vw',
                height: firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? '54vh' : '70vh'
              }}
              autoPlay
              loop
            />
          </div>
          <div className="animation_action">
            <Lottie
              animationData={JsonGoldRing}
              style={{
                width: '100vw',
                height: firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? '54vh' : '70vh'
              }}
              autoPlay
              loop
            />
          </div>
          <div className="animation_action">
            <Lottie
              animationData={JsonGoldRing}
              style={{
                width: '100vw',
                height: firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? '54vh' : '70vh'
              }}
              autoPlay
              loop
            />
          </div>
          <div className="animation_action">
            <Lottie
              animationData={JsonCoin}
              style={{
                width: '100vw',
                height: firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? '54vh' : '70vh'
              }}
              autoPlay
              loop
            />
          </div>
          <h3
            className="position-absolute fw-bold text-white text-center"
            style={{
              width: '100vw',
              top: firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? '54vh' : '70vh'
            }}
          >
            {__('header_notification_complete_mission_today')}
          </h3>
          {firstMissionData && (
            <div style={{ zIndex: 99999 }}>
              {firstMissionData?.redeem_mission_id?.gift_coin > 0 ? (
                <div
                  className="position-absolute text-center"
                  style={{ width: '100vw', top: '75vh' }}
                >
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center ">
                      <Lottie
                        animationData={Diamond}
                        style={{ width: '100px', height: '100px' }}
                        autoPlay
                        loop
                      />
                      <p
                        className="fw-bold text-white m-0"
                        style={{ fontSize: '20px', marginLeft: '-21px' }}
                      >
                        {formatNumber(Number(firstMissionData?.redeem_mission_id?.gift_coin ?? 0)) +
                          ` ${__('header_diamond')}`}
                      </p>
                    </div>
                  </div>
                  <button onClick={() => getGiftScreen('coin')} className="btn btn-link text-white">
                    {__('header_change_gift')}
                  </button>
                </div>
              ) : (
                <div>
                  {firstMissionData?.redeem_mission_id?.gift_data?.[0]?._id ? (
                    <div
                      className="position-absolute text-center"
                      style={{ width: '100vw', top: '58vh' }}
                    >
                      <p className="fw-bold text-white mb-0" style={{ fontSize: '16px' }}>
                        {__('header_receiver_gift')}
                      </p>
                      <div className="mt-2">
                        {firstMissionData?.redeem_mission_id?.gift_data?.[0]?.media_id
                          ?.media_thumbnail ||
                        firstMissionData?.redeem_id?.gift_data?.[0]?.media_id?.media_url ? (
                          <img
                            style={{
                              width: '150px',
                              height: '150px',
                              objectFit: 'cover',
                              borderRadius: '30px'
                            }}
                            src={
                              firstMissionData?.redeem_id?.gift_data?.[0]?.media_id
                                ?.media_thumbnail ??
                              firstMissionData?.redeem_mission_id?.gift_data?.[0]?.media_id
                                ?.media_url
                            }
                          />
                        ) : (
                          <img
                            style={{
                              width: '150px',
                              height: '150px',
                              objectFit: 'cover',
                              borderRadius: '30px'
                            }}
                            src="../media/images/banner_default.png"
                            alt=""
                          />
                        )}
                      </div>

                      <p className="fw-bold text-white mb-0 mt-2 pt-0" style={{ fontSize: '18px' }}>
                        {`${
                          firstMissionData?.redeem_mission_id?.gift_data?.[0]?.name ??
                          `${__('course')}`
                        } - ${formatNumber(
                          Number(firstMissionData?.redeem_mission_id?.gift_data?.[0]?.price ?? 0)
                        )} ${__('currency_unit')}`}
                      </p>

                      <button
                        onClick={() => getGiftScreen('gift')}
                        className="btn btn-link text-white"
                      >
                        {__('header_seen_gift')}
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </LottieComponentContainer>
  )
}

export default LottieComponent

const LottieComponentContainer = styled(Box)(({ theme }) => ({}))
