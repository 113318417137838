import React, { useCallback } from 'react'
import '../media/courses.list.item.scss'
import '../media/courses.skeletons.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faMedal } from '@fortawesome/free-solid-svg-icons'

export default function CoursesListSkeleton() {
  const renderItem = useCallback((_, index) => {
    return (
      <div key={index + '_jddjh_sk'} className="courselistitem col-sm-6 col-lg-4 col-xl-3">
        <div className="courselistitem_container">
          <div className="courselistitem_container_img">
            <div className="courselistitem_img skeleton" />
          </div>

          <div className="courselistitem_bottom d-flex flex-column justify-content-between">
            <div className="courselistitem_container_info">
              <div className="courselistitem_title skeleton skeleton-text" style={{ height: 22 }} />
              <div
                className="courselistitem_title skeleton skeleton-text w-50"
                style={{ height: 22 }}
              />
              <div className="courselistitem_description skeleton skeleton-text-bottom mt-2" />
              <div className="courselistitem_description skeleton skeleton-text-bottom" />
              <div className="courselistitem_description skeleton skeleton-text-bottom w-25" />
            </div>

            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center w-50">
                  <FontAwesomeIcon icon={faLayerGroup} className="courselistitem_img_layer_time" />
                  <div className="courselistitem_txt_layer_time skeleton skeleton-text w-50" />
                </div>
                <div className="d-flex align-items-center w-50 justify-content-end">
                  <FontAwesomeIcon icon={faMedal} className="courselistitem_img_layer_time" />
                  <div className="courselistitem_txt_layer_time skeleton skeleton-text w-50" />
                </div>
              </div>

              <div className="mt-2">
                <div className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg skeleton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [])

  return <div className="row ps-3 pe-3">{[1, 2, 3, 4].map(renderItem)}</div>
}
