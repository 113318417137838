import React, {useCallback} from 'react'
import Table from 'react-bootstrap/Table';
import DateTime from 'date-and-time'
import {Form} from 'react-bootstrap'
import avatarDefault from 'media/images/avatar_default.png'
import Card from 'react-bootstrap/Card';
import helpers from 'helpers/index'
import chatLeftDot from 'media/icons/message.svg'
import {createChatroomToUser} from 'entities/mentor/store/mentor.store.reducer';
import {useAppDispatch, useAppSelector} from 'config/store';
import {faArrowUp91, faMedal} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router';
import __ from "languages/index";
import { TypedMember } from '../courses.interface';
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import {AxiosError, AxiosResponse} from "axios";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { formatString } from 'entities/support/utils'
import { Checkbox, FormControlLabel } from '@mui/material'

interface TypedCourseTableUserProps{
  selectedItems: string[]
  handleCheckboxChange: (itemId: string) => void
  dataUnique: TypedMember[]
  loadMore: ()=>void
  hasMore: boolean
  isRefreshing: boolean
  refresh: ()=>void
}

export default function CourseTableUser({ selectedItems, isRefreshing, refresh, handleCheckboxChange, dataUnique, loadMore, hasMore }: TypedCourseTableUserProps) {
  const navigate = useNavigate()
  const permission_data = useAppSelector(state => state.user.permission_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleCreateChatroomToUser = useCallback((data: TypedMember) => {
    dispatch(createChatroomToUser({ partner_id: data?._id, chat_type: 'personal' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        const { _id } = res?.data?.chat_room_id
        navigate(`/chat/detail/${_id}`)
      })
      .catch((error: AxiosError) => {
        console.log(`createChatroomToUser_${error}`)
        toast.show({
          content: `${__("member_request_failed")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  const detailUser = (item: TypedMember) => {
    return (
      <div className='d-flex justify-content-between'>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/user/detail/${item?._id}`)} className='d-flex'>
          <div >
            <img
              src={item?.user_avatar_thumbnail?.length > 0 ? item?.user_avatar_thumbnail : item?.user_avatar?.length > 0 ? item?.user_avatar : avatarDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                (e.currentTarget.src = avatarDefault)
              }
              width={50}
              height={50}
              style={{ borderRadius: 40 }}
              alt="avatar"
              loading="lazy"
            />
          </div>
          <div className='ms-3'>
            <p className='my-0 fw-bold' style={{ fontSize: 18 }}>{item?.display_name}</p>
            <div className="d-flex align-items-center giftreceiveradd_container_user_txtlvl">
              <FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />
              <div style={{ marginRight: 25 }}>{item?.point} điểm</div>
              <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
              cấp độ {item?.level_number}
            </div>
            <p className='my-0'>{item?.user_email}</p>
            <p className='my-0' style={{ paddingTop: item?.user_phone?.length > 0 ? 0 : 16 }}>{item?.user_phone ?? ``}</p>
          </div >
        </div >
        {user_data?._id !== item?._id ?
          <div
            role="button"
            onClick={() => handleCreateChatroomToUser(item)}
            className="d-flex align-items-center justify-content-center bg-secondary-subtle rounded-circle mt-2"
            style={{ width: '35px', height: '35px' }}
          >
            <img src={chatLeftDot} alt="icon_chat" />
          </div> : null
        }

      </div>

    )
  }

  function TableUser() {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th scope="col" className="col-1 text-center">{helpers.current_user_can('boss', permission_data) ? __("manager_course_member_select") : __("manager_course_member_stt")}</th>
          <th scope="col" className="col-7">{__("manager_course_member_name")}</th>
          <th scope="col" className="col-2 text-center">{__("manager_course_member_status")}</th>
          <th scope="col" className="col-2 text-center">{__("manager_course_member_join_date")}</th>
        </tr>
        </thead>
        <tbody>
        {dataUnique?.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                {helpers.current_user_can('boss', permission_data) ?
                  <Checkbox
                    id={`check_item`}
                    onChange={() => handleCheckboxChange(item?._id)}
                    checked={selectedItems.includes(item?._id)}
                  /> :
                  <div className='d-flex  justify-content-center'>
                    {index}
                  </div>
                }
              </td>
              <td>{detailUser(item)}</td>
              <td style={{ color: 'green', textAlign: 'center' }}>{item?.official_status? __("manager_course_member_active") : __("manager_course_member_inactive")}</td>
              <td style={{ textAlign: 'center' }}>{DateTime.format(new Date(item?.createdAt), 'DD/MM/YYYY')}</td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    )
  }

  function CardUser() {
    return (
      <div>

        {dataUnique?.map((item, index) => {
          return (
            <Card style={{ width: '100%', marginTop: index > 0 ? 16 : 0 }}>
              <div className='text-end me-2 mt-2'>
                {helpers.current_user_can('boss', permission_data) ?
                  <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    onChange={() => handleCheckboxChange(item?._id)}
                    checked={selectedItems.includes(item?._id)}
                  /> :
                  <div style={{ height: 16 }}>

                  </div>
                }

              </div>

              <Card.Body className='pt-0'>
                <Card.Title>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/user/detail/${item?._id}`)} className='d-flex'>
                      <div>
                        <img
                          src={item?.user_avatar_thumbnail?.length > 0 ? item?.user_avatar_thumbnail : item?.user_avatar?.length > 0 ? item?.user_avatar : avatarDefault}
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                            (e.currentTarget.src = avatarDefault)
                          }
                          width={50}
                          height={50}
                          style={{ borderRadius: 40 }}
                          alt="avatar"
                          loading="lazy"
                        />
                      </div>
                      <div className='ms-3'>
                        <p className='my-0 fw-bold' style={{ fontSize: 18 }}>{item?.display_name}</p>
                        <div className="d-flex align-items-center giftreceiveradd_container_user_txtlvl">
                          <FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />
                          <div style={{ marginRight: 25 }}>{item?.point} {__("point_unit")}</div>
                          <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
                          {`${__("course_level")} ${item?.level_number}`}
                        </div>
                        <p className='my-0'>{item?.user_email}</p>
                        <p className='my-0' style={{ paddingTop: item?.user_phone?.length > 0 ? 0 : 16 }}>{item?.user_phone ?? ``}</p>
                      </div>
                    </div>
                    {user_data?._id !== item?._id ?
                      <div
                        role="button"
                        onClick={() => handleCreateChatroomToUser(item)}
                        className="d-flex align-items-center justify-content-center bg-secondary-subtle rounded-circle"
                        style={{ width: '35px', height: '35px' }}
                      >
                        <img src={chatLeftDot} alt="icon_chat" />
                      </div>
                      : null}
                  </div>


                </Card.Title>

                <Card.Text className='mt-0'>
                  <div style={{ color: 'green' }}>{__("course_text_active")}</div>
                  <div >{DateTime.format(new Date(item?.createdAt), 'DD/MM/YYYY')}</div>
                </Card.Text>
              </Card.Body>
            </Card>
          )
        })}
      </div>
    )
  }
  return (
    <div>
      <InfiniteScrollContainer
        isRefreshing={isRefreshing}
        dataLength={dataUnique.length}
        next={loadMore}
        hasMore={hasMore}
        refreshFunction={refresh}
        showEndMessage={Boolean(!dataUnique.length)}
        textEmptyMessage={formatString(__('data_empty'), `${__('course_student')}`)}
      >
        {helpers.isMobile() ?
          <div>
            {CardUser()}
          </div> :
          <div>
            {TableUser()}
          </div>
        }
      </InfiniteScrollContainer>
    </div>
  );
}
