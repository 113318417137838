import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import helpers from 'helpers'

export const initialState = {
  entity: {},
  entities: [],
  plans: [],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}
export const updatePassword = createAsyncThunk(
  'user/forgot-password',
  async (user_input: { user_input: string }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(user_input)
      return axios.post<any>(`/user/forgot-password`, user_input)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const verifyPassword = createAsyncThunk(
  'user/verify-password',
  async (data: { session_id?: string; verification_code: string }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(data)
      return axios.post<any>(`/verification_code`, data)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const changePassword = createAsyncThunk(
  'user/change-password',
  async (body: { user_password: string; session_id: string }, { rejectWithValue }) => {
    try {
      return axios.post<any>(`/user/change-password`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

const FORGOT_PASSWORD_REDUCER = createEntitySlice({
  name: 'forgot_password',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  }
})
export const { clearError, reset, clearEntity } = FORGOT_PASSWORD_REDUCER.actions
export default FORGOT_PASSWORD_REDUCER.reducer
