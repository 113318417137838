//Library
import React, {useCallback, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {EnumTypeToast, useToast} from "../../hooks/useToast";

//Image
import avatarDefault from 'media/images/avatar_default.png'

//Store
import {useAppDispatch} from 'config/store'

//Helper
import helpers from 'helpers'

//interface
import {TypeDataNotification} from './interface'

//Scss
import './media/notification.list.scss'
import __ from "languages/index";
import notification_parse_action from "entities/notification/notification.helpers";
import {updateNotification} from "entities/homepage/home.store.reducer";
import {subTotalNotificationUnread} from "../../store/user.store.reducer";
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import {getListNotificationData} from "entities/notification/notification.service";
import MediumLayout from "../../layouts/mediumLayout";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { Box } from '@mui/material';

const NotificationList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const toast = useToast();
  const { data, refresh, hasMore, isRefreshing, totalItems, loadMore } = useInfiniteScroll <TypeDataNotification> ({
    limit: 20,
    order_by: 'DESC',
    all: true
  }, getListNotificationData)

  const onClickItem = useCallback((item: TypeDataNotification) => {
    if (!item) {
      toast.show({
        content: `${__("notification_content_error")}`,
        type: EnumTypeToast.Error
      })
      return
    }

    const link_to_action = notification_parse_action(item?.notification_data);
    document.body.style.overflow = 'auto'
    updateNotification(item?._id).then(res => { }).catch(error => { })
    dispatch(subTotalNotificationUnread(1))
    if ( link_to_action ) {
      navigate(`${link_to_action}`);
    }else {
      toast.show({
        content: `${__("notification_content_error")}`,
        type: EnumTypeToast.Error
      })
    }
  }, [])


  const renderNotificationItem = useCallback((item: TypeDataNotification)=>{
    return(
      <div
        key={`notification_${item?._id}`}
        role="button"
        onClick={() => onClickItem(item)}
        className="notificationList_second_wrapper"
      >
        <img
          src={JSON.parse(item?.notification_data)?.thumbnail ?? avatarDefault}
          alt="avatar"
          className="notificationList_second_image"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = avatarDefault
          }}
          loading="lazy"
        />
        <Box sx={{':hover': {color: theme => theme.palette.primary.light}}} className="notificationList_second_body">
          <span className="notificationList_second_body_label">{item?.notification_title || ''}</span>
          <span className="notificationList_second_body_content">
                    {item?.notification_content || ''}
                  </span>
          <span className="notificationList_second_body_time">
                    {helpers.subtractTimeHistory(new Date(item?.createdAt).getTime())}
                  </span>
        </Box>
      </div>
    )
  },[])

  const NotificationSkeleton = useMemo(()=>{
    return(
      <div className="gap-3">
        {[1, 2, 3, 4, 5, 6].map((_,index)=>(
          <div
            key={`notification_${-index}`}
            className="notificationList_second_wrapper"
          >
            <div
              className={"notificationList_second_image rounded-circle skeleton"}
            />
            <div className="notificationList_second_body">
              <div className="notificationList_second_body_label skeleton skeleton-text w-50" style={{height: 18, marginBottom: 8}}/>
              <div className="notificationList_second_body_content skeleton skeleton-text" style={{height: 14}}/>
              <div className="notificationList_second_body_time skeleton skeleton-text w-25" style={{height: 14, marginTop: 8}}/>
            </div>
          </div>
        ))}
      </div>
    )
  },[])

  return (
    <MediumLayout>
      <div id="notificationList" className="notificationList_container">
        <div className="notificationList_wrapper">
          <span className="notificationList_header">{__("notification")}</span>
          <div className="notificationList_second">
            <InfiniteScrollContainer
              isRefreshing={isRefreshing}
              dataLength={data.length}
              next={loadMore}
              hasMore={hasMore}
              refreshFunction={refresh}
              skeleton={NotificationSkeleton}
            >
                {data.map(renderNotificationItem)}
            </InfiniteScrollContainer>
          </div>
        </div>
      </div>
    </MediumLayout>
  )
}

export default NotificationList
