//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//Config
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'

export const initialState = {}

export const checkOTP = createAsyncThunk(
  'validate_code/checkOTP',
  async (body: { verification_code?: string; session_id?: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/verification_code`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getOTP = createAsyncThunk(
  'validate_code/getOTP',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<unknown>(`/verification_code`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const VALIDATE_CODE_REDUCER = createEntitySlice({
  name: 'validate_code',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = VALIDATE_CODE_REDUCER.actions

export default VALIDATE_CODE_REDUCER.reducer
