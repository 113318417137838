import {useAppDispatch, useAppSelector} from 'config/store'
import {TypedEvent} from 'entities/calendar/calendar.interface'
import {getDetailEvent, getListLikeEvent} from 'entities/calendar/calendar.store.reducer'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {TypedListLikeEvent} from './event.interface'
import HeaderEvent from 'entities/event/components/header.event'
import EventAction from 'entities/event/components/event.action'
import UserLikeEvent from 'entities/event/components/user.like.event'
import EventContent from 'entities/event/components/event.content'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import EventMap from 'entities/event/components/event.map'
import helpers from 'helpers/index'
import './media/event.detail.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faPlay} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import DateTime from 'date-and-time'
import date from 'date-and-time'
import EventPlaybackLivestreamModal from 'entities/event/component/event.playback.livestream.modal'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import MaxLayout from "../../layouts/maxLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";

const EventDetail = props => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const event_id = params?.calendar_id
  const account = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel);

  // Refs
  const toast = useToast()
  const refEventPlaybackLivestreamModal = useRef < any > ()
  // ============================================

  // State
  const [detail, setDetail] = useState < TypedEvent > (null)
  const [listUserLike, setListUserLike] = useState < TypedListLikeEvent[] > ([])
  const [countUser, setCountUser] = useState < number > (0)
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  const liveStream = detail?.livestream_id

  // ============================================
  // ============================================

  // Function
  const getData = useCallback(async () => {
    try {
      const res: any = await dispatch(getDetailEvent({ _id: event_id, auth_id: account?._id }))
      if (res?.payload?.data) {
        setDetail(res?.payload?.data)
      }
      const resListLike: any = await dispatch(
        getListLikeEvent({ event_id, page: 1, limit: 3, auth_id: account?._id })
      )

      if (Array.isArray(resListLike?.payload?.data) && resListLike?.payload?.data.length > 0) {
        setListUserLike(resListLike?.payload?.data)
        setCountUser(parseInt(resListLike?.payload?.headers['x-total-count']) || 0)
      }
    } catch (error) { }
  }, [])

  // useEffect
  useEffect(() => {
    getData()
  }, [])

  const onLike = useCallback(like => {
    let title = `${__("notification")}`
    let content = ''
    let type = EnumTypeToast.Success

    getData()

    if (like) {
      content = `${__("event_join_success")}`
    } else {
      content = `${__("event_join_cancel")}`
      type = EnumTypeToast.Error
    }
    toast.show({
      title,
      content,
      type
    })
  }, [])

  const onCopy = useCallback((success: boolean) => {
    if (success) {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Success,
        content: `${__("event_copy_link_success")}`
      })
    }
  }, [])
  // ============================================

  // Render View

  const renderHeader = useCallback(() => {
    return (
      <HeaderEvent
        open_date={detail?.open_date}
        img={detail?.public_album?.[0]?.media_url}
        title={detail?.title}
      />
    )
  }, [detail?.open_date, detail?.public_album?.[0]?.media_url, detail?.title])

  const renderAction = useCallback(() => {
    return (
      <EventAction data={detail} is_like={detail?.is_like} _id={detail?._id} onLike={onLike} onCopy={onCopy} />
    )
  }, [detail?.is_like, detail?._id])

  const renderUserLike = useCallback(() => {
    return <UserLikeEvent listUserLike={listUserLike} countUser={countUser} detail={detail} />
  }, [listUserLike, countUser, detail])

  const renderContent = useCallback(() => {
    return (
      <EventContent
        open_date={detail?.open_date}
        user={detail?.user_id}
        address={detail?.address[0]}
        duration={detail?.duration}
      />
    )
  }, [detail?.open_date, detail?.user_id, detail?.address, detail?.duration])

  const renderMap = useCallback(() => {
    return <EventMap address={detail?.address[0]} />
  }, [detail?.address])

  const onPressWatchPlaybackLivestream = (urlVideo: string, nameVideo: string) => {
    refEventPlaybackLivestreamModal.current?.showVideoModal(urlVideo, nameVideo)
  }

  const handleClickBtnClose = useCallback(()=>setIsShowPopup(true),[])

  const renderPlaybackItem = useCallback((item: any) => {
    return (
      <div
        className="col-xl-4 col-md-6 col-sm-12 my-2 clickable"
        onClick={() =>
          onPressWatchPlaybackLivestream(
            item?.playback?.hls,
            date.format(new Date(item?.created), 'HH:mm DD/MM/YYYY')
          )
        }
      >
        <div className="position-relative rounded-2 overflow-hidden">
          <img className="overlay_banner_live" src={item?.thumbnail} />

          <div className="overlay_img_live">
            <h5 className="overlay_text_live">
              {'Phiên live ' + date.format(new Date(item?.created), 'HH:mm DD/MM/YYYY')}
            </h5>
            <FontAwesomeIcon className="h2 text-white mt-4" icon={faPlay} />
          </div>
        </div>
      </div>
    )
  }, [])

  return (
    <MaxLayout>
      <div id={'eventDetail'}>
        <_Helmet title={detail?.title || `${__("event_detail_title")}`} />
        {renderHeader()}
        <div className="mainFrame">
          <div className="container">
          
            {helpers.current_user_can('boss', permission_data) ||
            user_in_this_channel?.level_number >= detail?.event_level ? (
              <div className="detail-area p-4 mb-5 mt-4 bg-white rounded border_light">
                {renderAction()}
                <hr />
                <Row>
                  <Col xs="12" md="7">
                    {renderUserLike()}
                    {renderContent()}
                    <p className={'p-3 w-100'}>{detail?.description}</p>
                  </Col>
                  <Col xs="12" md="5" className="p-3">
                    {renderMap()}
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="detail-area p-4 mb-5 mt-4 bg-white rounded border_light">
                <p className="my-0 text-center" style={{ fontSize: '20px' }}>
                  {__("event_detail_error_text")}
                </p>
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />

        {Array.isArray(detail?.livestream_id?.history_media) &&
          detail?.livestream_id?.history_media.length > 0 && (
            <EventPlaybackLivestreamModal ref={refEventPlaybackLivestreamModal} />
          )}
      </div>
    </MaxLayout>
  )
}

export default EventDetail
